<template>
  <div class="pa-2">
    <v-btn
      :text="component.title.text"
      style="max-width: 90%"
      class="ma-2 text-truncate"
      variant="outlined"
      color="white"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentButton } from '#types'

  @Component({})
  class ButtonPreview extends Vue {
    @Prop() public component!: UIComponentButton
  }

  export default toNative(ButtonPreview)
</script>
