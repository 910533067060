<template>
  <div class="pa-2">
    <div class="caption grey--text">{{ component.title.text }}</div>

    <v-sparkline
      color="white"
      :model-value="mockChartValue"
      :labels="mockChartLabels as any"
      :smooth="4 as any"
      :padding="8"
      :line-width="3"
    />

    <span color="gray-lighten-1">Chart</span>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentNightTimeHeartRate } from '#types'

  @Component({})
  class NightTimeHeartRatePreview extends Vue {
    @Prop() public component!: UIComponentNightTimeHeartRate

    public mockChartValue = [80, 75, 60, 55, 65, 60, 62, 58, 55, 65, 60, 68, 62, 58, 65, 70, 65, 75, 70]

    public mockChartLabels = [
      '22:00',
      ' ',
      ' ',
      '0',
      ' ',
      ' ',
      '2',
      ' ',
      ' ',
      '4',
      ' ',
      ' ',
      '6',
      ' ',
      ' ',
      '8',
      ' ',
      ' ',
      '8:30',
    ]
  }

  export default toNative(NightTimeHeartRatePreview)
</script>

<style lang="scss" scoped>
  .caption {
    font-size: 10px !important;
  }
</style>
