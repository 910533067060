<template>
  <div v-if="override?.rolloutOneOf?.$case === 'rollout'" class="mt-1">
    <v-row>
      <v-col cols="12">
        <v-row v-for="(stage, i) in override.rolloutOneOf.rollout.stages" :key="i" class="mt-n4">
          <template v-if="showPast || !isRolloutStageUneditable(i)">
            <v-col
              :class="{
                'text-black font-weight-bold': i == 0 || isRolloutStageCurrent(i),
                'text-blue font-weight-bold': (i == 0 && !isRolloutStageUneditable(i)) || isRolloutStageCurrent(i - 1),
              }"
              style="max-width: 40px"
            >
              {{ i + 1 }}:
            </v-col>

            <v-col class="px-0">
              <v-text-field
                hide-details
                type="number"
                label="Percent"
                density="compact"
                validate-on="blur lazy"
                :disabled="disabled || isRolloutStageUneditable(i)"
                :model-value="fixedPointToString(stage.percentile)"
                :rules="[(v: string) => updateRolloutPercent(i, v)]"
              />
            </v-col>

            <v-col>
              <v-text-field
                hide-details
                density="compact"
                validate-on="blur lazy"
                :model-value="
                  stage.type === stageTypes.ACTUATED
                    ? 'paused'
                    : stage.type === stageTypes.FINALIZING
                      ? 'infinite'
                      : $dayjs.duration(parseInt(stage.duration!.seconds), 's').asDays()
                "
                :rules="[(v: string) => updateRolloutDuration(i, v)]"
                :disabled="disabled || isRolloutStageUneditable(i)"
                :label="stage.type === stageTypes.ACTUATED ? 'Rollout state' : 'Duration (days)'"
              >
                <template #append-inner>
                  <v-btn
                    v-if="stage.percentile!.integerPart !== 100"
                    class="mr-n4 mb-n2"
                    icon="mdi-swap-horizontal"
                    @click="
                      stage.type === stageTypes.ACTUATED ? updateRolloutDuration(i, '1') : updateRolloutDuration(i, '0')
                    "
                  />
                </template>
              </v-text-field>
            </v-col>

            <v-col style="max-width: 24px; padding: 0" class="mx-2 pt-8">
              <v-tooltip top>
                <template #activator="{ props }">
                  <v-btn
                    icon="mdi-delete"
                    color="grey-darken-2"
                    class="mt-n5 ml-n4"
                    :disabled="
                      disabled || override.rolloutOneOf.rollout.stages.length === 1 || isRolloutStageUneditable(i)
                    "
                    v-bind="props"
                    @click="deleteRolloutStep(i)"
                  />
                </template>

                <span style="white-space: nowrap">Delete rollout stage</span>
              </v-tooltip>
            </v-col>
          </template>

          <v-col
            v-if="
              (isRolloutStageUneditable(i) && i === override.rolloutOneOf.rollout.stages.length - 1) ||
              (i < override.rolloutOneOf.rollout.stages.length - 1 &&
                isRolloutStageUneditable(i) &&
                !isRolloutStageUneditable(i + 1))
            "
            cols="12"
          >
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  :icon="showPast ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  block
                  variant="text"
                  rounded="0"
                  class="mt-n2 mb-n1 pr-3"
                  color="grey"
                  size="small"
                  @click="showPast = !showPast"
                />
              </template>

              <span v-if="!showPast">Show past / current steps</span>
              <span v-else>Hide past / current steps</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row justify-end">
        <v-btn
          color="primary"
          size="small"
          :disabled="
            disabled ||
            rolloutFinalized ||
            (override.rolloutOneOf.rollout.stages[override.rolloutOneOf.rollout.stages.length - 2]?.percentile!
              .integerPart || 0) >= 99
          "
          @click="appendRolloutStep()"
        >
          Add rollout step
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Rollout_Stage_StageState, Rollout_Stage_StageType, Rollout_State } from '@jouzen/control-api/rollout'

  import { createRolloutStage, fixedPointToString, stringToFixedPoint } from '#views/features/utilities'

  import { Override, Reference } from '#types'

  @Component({})
  class RolloutStages extends Vue {
    @Prop() public active!: number

    @Prop() public disabled!: boolean

    @Prop() public override!: Override

    @Prop() public references!: Reference[]

    public showPast = false

    public readonly stageTypes = Rollout_Stage_StageType

    public readonly fixedPointToString = fixedPointToString

    public get rolloutFinalized() {
      return (
        this.override.rolloutOneOf?.$case === 'rollout' &&
        this.override.rolloutOneOf.rollout.state === Rollout_State.FINALIZED
      )
    }

    public appendRolloutStep(index?: number) {
      if (this.override.rolloutOneOf?.$case === 'rollout') {
        if (index !== undefined) {
          // Step added between existing steps

          const previous = this.override.rolloutOneOf.rollout.stages[index - 1]

          this.override.rolloutOneOf.rollout.stages.splice(
            index,
            0,
            createRolloutStage(
              Math.min(100, previous.percentile!.integerPart + 1),
              previous.duration ? this.$dayjs.duration(parseInt(previous.duration.seconds), 's').asDays() : undefined,
            ),
          )
        } else {
          // Step added after the existing steps

          if (!this.isRolloutStageUneditable(this.override.rolloutOneOf.rollout.stages.length - 1)) {
            // If last step can be edited then we will adjust it to be logical compared to the added step

            if (
              this.override.rolloutOneOf.rollout.stages[this.override.rolloutOneOf.rollout.stages.length - 1]
                .percentile!.integerPart === 100 ||
              this.override.rolloutOneOf.rollout.stages[this.override.rolloutOneOf.rollout.stages.length - 1].type ===
                Rollout_Stage_StageType.FINALIZING
            ) {
              // If last step is 100% step the we change it to have one percent larger percentage than previous step and to
              // be fixed length type step with duration of 7 days, then we can add the defaiult 100% step as the last step

              this.override.rolloutOneOf.rollout.stages[
                this.override.rolloutOneOf.rollout.stages.length - 1
              ].percentile!.integerPart =
                (this.override.rolloutOneOf.rollout.stages[this.override.rolloutOneOf.rollout.stages.length - 2]
                  ?.percentile!.integerPart || 49) + 1

              this.override.rolloutOneOf.rollout.stages[this.override.rolloutOneOf.rollout.stages.length - 1].type =
                Rollout_Stage_StageType.FIXED_LENGTH

              this.override.rolloutOneOf.rollout.stages[this.override.rolloutOneOf.rollout.stages.length - 1].duration =
                { nanos: 0, seconds: this.$dayjs.duration({ days: 7 }).asSeconds().toString() }
            }
          }

          this.override.rolloutOneOf.rollout.stages.push(createRolloutStage(100))
        }
      }
    }

    public deleteRolloutStep(index: number) {
      if (this.override.rolloutOneOf?.$case === 'rollout') {
        this.override.rolloutOneOf.rollout.stages.splice(index, 1)

        if (index > 0 && this.override.rolloutOneOf.rollout.stages.length) {
          this.updateRolloutStep(index - 1)
        }
      }
    }

    public updateRolloutStep(index: number) {
      if (this.isRolloutStageUneditable(index)) {
        return true
      }

      if (this.override.rolloutOneOf?.$case === 'rollout') {
        if (
          index > 0 &&
          parseFloat(
            [
              this.override.rolloutOneOf.rollout.stages[index - 1].percentile!.integerPart,
              this.override.rolloutOneOf.rollout.stages[index - 1].percentile!.thousandthPart,
            ].join('.'),
          ) >=
            parseFloat(
              [
                this.override.rolloutOneOf.rollout.stages[index].percentile!.integerPart,
                this.override.rolloutOneOf.rollout.stages[index].percentile!.thousandthPart,
              ].join('.'),
            )
        ) {
          this.override.rolloutOneOf.rollout.stages[index].percentile!.integerPart = Math.min(
            100,
            this.override.rolloutOneOf.rollout.stages[index - 1].percentile!.integerPart + 1,
          )
        }

        if (
          index === this.override.rolloutOneOf.rollout.stages.length - 1 ||
          this.override.rolloutOneOf.rollout.stages[index].percentile!.integerPart === 100
        ) {
          delete this.override.rolloutOneOf.rollout.stages[index].duration
        }

        if (this.override.rolloutOneOf.rollout.stages[index].duration == undefined) {
          if (this.override.rolloutOneOf.rollout.stages[index].percentile!.integerPart !== 100) {
            this.override.rolloutOneOf.rollout.stages[index].type = Rollout_Stage_StageType.ACTUATED
          } else {
            this.override.rolloutOneOf.rollout.stages[index].type = Rollout_Stage_StageType.FINALIZING
          }
        } else if (
          this.override.rolloutOneOf.rollout.stages[index].duration &&
          this.$dayjs
            .duration(parseInt(this.override.rolloutOneOf.rollout.stages[index].duration!.seconds), 's')
            .asDays() > 0
        ) {
          this.override.rolloutOneOf.rollout.stages[index].type = Rollout_Stage_StageType.FIXED_LENGTH
        }

        if (
          index + 1 < this.override.rolloutOneOf.rollout.stages.length &&
          parseFloat(
            [
              this.override.rolloutOneOf.rollout.stages[index + 1].percentile!.integerPart,
              this.override.rolloutOneOf.rollout.stages[index + 1].percentile!.thousandthPart,
            ].join('.'),
          ) >=
            parseFloat(
              [
                this.override.rolloutOneOf.rollout.stages[index].percentile!.integerPart,
                this.override.rolloutOneOf.rollout.stages[index].percentile!.thousandthPart,
              ].join('.'),
            )
        ) {
          this.updateRolloutStep(index + 1)
        }
      }
    }

    public updateRolloutPercent(index: number, percent?: string) {
      if (this.override.rolloutOneOf?.$case === 'rollout') {
        const fp = stringToFixedPoint(percent || '0', 3)

        this.override.rolloutOneOf.rollout.stages[index].percentile!.integerPart = !fp
          ? 0
          : Math.abs(Math.min(100, fp.integerPart))

        this.override.rolloutOneOf.rollout.stages[index].percentile!.thousandthPart =
          this.override.rolloutOneOf.rollout.stages[index].percentile!.integerPart === 100
            ? 0
            : !fp
              ? 0
              : fp.thousandthPart

        this.updateRolloutStep(index)

        return (
          index === 0 ||
          parseFloat(fixedPointToString(fp)) >=
            ((this.override?.rolloutOneOf?.$case === 'rollout' &&
              parseFloat(fixedPointToString(this.override.rolloutOneOf.rollout.stages[index - 1].percentile!))) ||
              0)
        )
      }

      return false
    }

    public updateRolloutDuration(index: number, duration?: string) {
      if (this.override.rolloutOneOf?.$case === 'rollout') {
        if (duration !== undefined) {
          const days = parseInt(duration)

          if (!days || isNaN(days)) {
            delete this.override.rolloutOneOf.rollout.stages[index].duration
          } else {
            this.override.rolloutOneOf.rollout.stages[index].duration = {
              nanos: 0,
              seconds: this.$dayjs.duration({ days }).asSeconds().toString(),
            }
          }
        }

        this.updateRolloutStep(index)
      }

      return true
    }

    public isRolloutStageCurrent(stage: number) {
      return (
        this.override.rolloutOneOf?.$case === 'rollout' &&
        this.override.rolloutOneOf.rollout.stages[stage]?.state === Rollout_Stage_StageState.CURRENT
      )
    }

    public isRolloutStageUneditable(stage: number) {
      return (
        this.override.rolloutOneOf?.$case === 'rollout' &&
        (this.override.rolloutOneOf.rollout.stages[stage]?.state === Rollout_Stage_StageState.CURRENT ||
          this.override.rolloutOneOf.rollout.stages[stage]?.state === Rollout_Stage_StageState.COMPLETED)
      )
    }
  }

  export default toNative(RolloutStages)
</script>
