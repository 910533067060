<template>
  <div v-if="message.facets.notification">
    <Translatable :data="message.facets.notification.title">
      <v-text-field v-model="message.facets.notification.title.text" label="Title" />
    </Translatable>

    <Translatable :data="message.facets.notification.body">
      <v-textarea
        v-model="message.facets.notification.body.text"
        spellcheck="true"
        lang="en"
        label="Body"
        rows="1"
        auto-grow
      />
    </Translatable>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Message } from '#types'

  @Component({})
  class NotificationFacet extends Vue {
    @Prop() public message!: Message
  }

  export default toNative(NotificationFacet)
</script>
