export const listHeaders: any = [
  {
    key: 'icon',
    width: 48,
  },
  {
    key: 'title.text',
    title: 'Title',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'category',
    title: 'Category',
  },
  {
    key: 'vendor',
    title: 'Vendor',
  },
  {
    key: 'tags',
    title: 'Tags',
  },
  {
    key: 'createdAt',
    title: 'Created at',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'updatedAt',
    title: 'Updated at',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'state',
    align: 'end',
    title: 'Release state',
  },
]

export const mediaHeaders: any = [
  {
    key: 'slug',
    title: 'Slug',
  },
  {
    key: 'info.category',
    align: 'end',
    title: 'Category',
  },
]

export const imageHeaders: any = [
  {
    key: 'slug',
    title: 'Slug',
  },
  {
    key: 'info.content',
    align: 'end',
    title: 'Comment',
  },
]

export const slideshowHeaders: any = [
  {
    key: 'id',
    title: 'ID',
  },
]

export const contentIcons: any = {
  audio: 'mdi-volume-high',
  video: 'mdi-video-box',
  slideshow: 'mdi-image-multiple-outline',
}

export const contentTypes: { title: string; value: string }[] = [
  {
    title: 'Audio',
    value: 'audio',
  },
  {
    title: 'Video',
    value: 'video',
  },
  {
    title: 'Slideshow',
    value: 'slideshow',
  },
]

export const contentCategories: { title: string; value: string }[] = [
  {
    title: 'Meditate',
    value: 'meditation',
  },
  {
    title: 'Breath',
    value: 'breathwork',
  },
  {
    title: 'Sleep',
    value: 'sleep',
  },
  {
    title: 'Learn',
    value: 'edu',
  },
]
