<template>
  <v-dialog v-if="isOpen" v-model="isOpen" width="800">
    <v-card>
      <v-card-title>Edit insights using the formula</v-card-title>

      <v-card-text>
        <v-alert
          v-if="!!activeProject"
          class="mb-8"
          type="info"
          text="Only showing insights belonging to the active project and already added insights"
        />
        <v-row>
          <v-col cols="12">
            <v-select v-model="category" :items="insightCategories" label="Filter insights by category" />

            <v-autocomplete
              v-model="selectedInsights"
              chips
              multiple
              return-object
              auto-select-first
              label="Insights using this formula"
              placeholder="No insights using this formula"
              no-data-text="No additional insights in this project"
              :items="insightItems"
            >
              <template v-if="category !== 'all'" #prepend-item>
                <v-list-item title="Select All" @click="toggleSelectAll()">
                  <template #prepend>
                    <v-checkbox :model-value="selectAllInsights" :indeterminate="!selectAllInsights" />
                  </template>
                </v-list-item>

                <v-divider />
              </template>

              <template #chip="{ item, index }">
                <v-chip closable @click:close="selectedInsights.splice(index, 1)">
                  {{ item.title }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-alert v-if="inEvals && insightsWithOtherSpotlight.length > 0" class="mb-4" color="warning">
          The following insights already have a spotlight category set:

          <div v-for="insight in insightsWithOtherSpotlight" :key="insight.id">{{ insight.id.toUpperCase() }}</div>
        </v-alert>

        <v-row v-if="!!insightsWithoutProject.length" class="mt-6">
          <v-col cols="12" class="px-6">
            <v-autocomplete
              v-model="insightsProject"
              chips
              return-object
              item-value="id"
              item-title="name"
              placeholder="No project selected"
              label="Assign insights without project to"
              no-data-text="No matching project in formula projects"
              :items="existingProjects"
            >
              <template v-if="!!insightsProject" #chip="{ item }">
                <v-chip closable @click:close="insightsProject = null">
                  {{ existingProjects.find((p) => p.id === item.raw.id).name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="(isOpen = false), (category = 'all')" />

        <v-btn text="Save" color="primary" :loading="isSaving" :disabled="!hasChanges" @click="save()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { insightCategories } from '#views/insights/constants'

  import { AppStore, FormulasStore, InsightsStore, ProjectsStore } from '#stores'

  import { Insight } from '#types'

  @Component({})
  class InsightsDialog extends Vue {
    public title = ''
    public formulaId = ''

    public isOpen = false
    public isSaving = false

    public category = 'all'

    public insights: any[] = []

    public insightsProject: any = null

    public selectedInsights: any[] = []
    public formulaInsights: Insight[] = []

    private readonly appStore = new AppStore()
    private readonly formulasStore = new FormulasStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly insightsStore = new InsightsStore()

    public get inEvals() {
      return this.appStore.inEvals
    }

    public get inRules() {
      return this.appStore.inRules
    }

    public get insightCategories() {
      return [{ title: 'All categories', value: 'all' }, ...insightCategories]
    }

    public get insightItems() {
      if (this.category === 'all') {
        return this.insights.map((insight) => ({ title: insight.id, value: insight.id }))
      } else {
        return this.insights
          .filter((insight) => insight.category === this.category)
          .map((insight) => ({ title: insight.id, value: insight.id }))
      }
    }

    public get selectAllInsights() {
      return (
        this.insightItems.length > 0 &&
        this.insightItems.every((item) => this.selectedInsights.some((selected) => selected.value === item.value))
      )
    }

    public get hasChanges() {
      return (
        !!this.insightsProject ||
        JSON.stringify(this.selectedInsights) !=
          JSON.stringify(this.formulaInsights.map((rule) => ({ text: rule.id, value: rule.id })))
      )
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get existingProjects() {
      return this.projectsStore.projects
    }

    public get insightsWithoutProject() {
      return this.insights.filter((i) => !i.project && this.selectedInsights.find((s) => s.value === i.id))
    }

    public get insightsWithOtherSpotlight() {
      return this.insights.filter(
        (i) => i.spotlight && i.spotlight !== this.formulaId && this.selectedInsights.find((s) => s.value === i.id),
      )
    }

    @Watch('activeProject', { immediate: true })
    protected async onActiveProjectChanged() {
      this.insights = await this.formulasStore.fetchFormulaInsights(this.activeProject?.id)
    }

    public async open(formulaInsights: Insight[], formulaId: string, title: string) {
      this.isOpen = true

      this.title = title
      this.formulaId = formulaId
      this.formulaInsights = formulaInsights

      this.insightsProject = null

      this.selectedInsights = formulaInsights.map((f) => ({ title: f.id, value: f.id }))
    }

    public toggleSelectAll() {
      if (this.selectAllInsights) {
        const insightValuesSet = new Set(this.insightItems.map((item) => item.value))

        this.selectedInsights = this.selectedInsights.filter((insight) => !insightValuesSet.has(insight.value))
      } else {
        const selectedInsightValuesSet = new Set(this.selectedInsights.map((insight) => insight.value))

        const insightsToAdd = this.insightItems.filter((item) => !selectedInsightValuesSet.has(item.value))

        this.selectedInsights = [...this.selectedInsights, ...insightsToAdd]
      }
    }

    public async save() {
      this.isSaving = true

      const add = this.selectedInsights
        .filter((i) => !this.formulaInsights.map((f) => f.id).includes(i.value))
        .map((i) => i.value)

      const remove = this.formulaInsights
        .filter((f) => !this.selectedInsights.map((item) => item.value).includes(f.id))
        .map((f) => f.id)

      for (const insight of this.insights) {
        if (this.inRules) {
          if (add.includes(insight.id) || remove.includes(insight.id)) {
            // Remove possible duplicates
            insight.rules = [...new Set(insight.rules)]

            if (add.includes(insight.id) && !insight.rules.includes(this.formulaId)) {
              insight.rules.push(this.formulaId)
            }

            if (remove.includes(insight.id)) {
              insight.rules.splice(insight.rules.indexOf(this.formulaId), 1)
            }

            const author = this.appStore.author

            author.comment = 'Condition rules updated'

            await this.insightsStore.updateInsight({
              author,
              id: insight.id,
              rules: insight.rules,
            })
          }
        } else {
          if (add.includes(insight.id)) {
            const author = this.appStore.author

            author.comment = 'Spotlight category updated'

            await this.insightsStore.updateInsight({
              author,
              id: insight.id,
              spotlight: this.formulaId,
            })
          } else if (remove.includes(insight.id)) {
            const author = this.appStore.author

            author.comment = 'Spotlight category removed'

            await this.insightsStore.updateInsight({
              author,
              id: insight.id,
              spotlight: '',
            })
          }
        }
      }

      await this.formulasStore.updateFormula({
        id: this.formulaId,
        author: { ...this.appStore.author, comment: 'Updated insights using the formula' },
        insights: this.selectedInsights.map((i) => i.value).sort((a, b) => a.localeCompare(b)),
      })

      if (this.insightsProject && this.insightsWithoutProject.length) {
        await this.insightsStore.updateProjects({
          project: this.insightsProject,
          insights: this.insightsWithoutProject,
        })
      }

      this.isSaving = false
      this.isOpen = false
      this.category = 'all'
    }
  }

  export default toNative(InsightsDialog)
</script>

<style lang="scss" scoped>
  :deep(.v-autocomplete:not(.v-input--is-focused).v-select--chips input) {
    max-height: 25px !important;
  }
</style>
