<template>
  <v-dialog v-model="isOpen" width="800" :presistent="isReplacing">
    <v-card :loading="isLoading || isReplacing">
      <v-card-title class="d-flex flex-column">
        <h5 class="mb-4 text-h5">Find and replace {{ searchResults.length ? `(${searchResults.length})` : '' }}</h5>

        <v-row>
          <v-col>
            <v-text-field v-model="searchText" label="Search text" :disabled="isReplacing" />
          </v-col>

          <v-col>
            <v-text-field v-model="replaceText" label="Replace text" :disabled="isReplacing" />
          </v-col>
        </v-row>

        <v-row v-if="searchMatches.length" class="mt-0 mb-n3">
          <v-col cols="6">
            <span class="text-button ml-4 text-black">There are matches also with these words:</span>
          </v-col>

          <v-col cols="6">
            <v-chip
              v-for="(match, index) in searchMatches"
              :key="index"
              class="mr-2"
              :text="match"
              :disabled="isReplacing"
              @click="searchText = match"
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text style="min-height: 400px; max-height: 400px">
        <div v-if="!searchText">
          Run search and replace to any exact matching word or sentence from all the messages of the currently listed
          insights. The search is case sensitive, but any words found with case insensitive matching will be shown as
          search suggestions.
          <br />
          <br />
          Please notice that if any messages are edited by somebody else while this search and replace dialog is open
          the changes will be overwritten.
        </div>

        <v-row v-for="(result, index) in searchResults" :key="index">
          <v-col cols="12" class="mt-n4 mb-n6">
            <div class="text-overline">{{ result.message.id }}</div>
          </v-col>

          <v-col cols="6">
            <div class="backdrop">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="highlights" v-html="highlightText(result.translatable.text, searchText)" />
            </div>
          </v-col>

          <v-col cols="6">
            <div class="backdrop">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="highlights" v-html="highlightText(result.translatable.text, searchText, replaceText)" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <template v-if="searchResults.length === 100">
          <v-icon color="warning" class="mr-2">mdi-alert</v-icon>

          <span class="text-warning">Search limit reached, there may be more results after the replace</span>
        </template>

        <v-spacer />

        <v-btn text="Cancel" :disabled="isReplacing" @click="isOpen = false" />
        <v-btn
          text="Replace"
          color="primary"
          :disabled="isLoading || isReplacing || !searchText || !replaceText || !searchResults.length"
          @click="runReplace(searchResults, searchText, replaceText)"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { uniq } from 'lodash-es'

  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { collection, doc, getDocs, getFirestore, setDoc } from 'firebase/firestore'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { AppStore } from '#stores'

  import { Insight } from '#types'

  @Component({})
  class FindReplace extends Vue {
    @Prop() public insights!: Insight[]
    @Prop() public insightsPath!: string

    @Model() public isOpen!: boolean

    public searchText = ''
    public replaceText = ''

    public isLoading = false
    public isReplacing = false

    public messagesCache: any = {}

    public searchResults: any[] = []
    public searchMatches: string[] = []

    private readonly appStore = new AppStore()

    @Watch('isOpen')
    protected isOpenChanged() {
      if (!this.isOpen) {
        this.searchText = ''
        this.replaceText = ''

        this.messagesCache = {}

        this.searchResults = []
        this.searchMatches = []
      }
    }

    @Watch('insights')
    protected insightsChanged() {
      this.updateResults(this.searchText)
    }

    @Watch('searchText')
    protected searchTextChanged() {
      this.updateResults(this.searchText)
    }

    public highlightText(text: string, search: string, replace?: string) {
      return text.replace(new RegExp(`(^|\\s)${search}($|\\s)`, 'g'), `$1<mark>${replace || search}</mark>$2`)
    }

    public async runReplace(results: any[], search: string, replace: string) {
      this.isReplacing = true

      const messagesToSave: any = {}

      const author = { ...this.appStore.author }

      author.comment = `Replaced '${search}' with '${replace}'`

      for (const result of results) {
        result.translatable.text = result.translatable.text.replace(new RegExp(`(^|\\s)${search}($|\\s)`, 'g'), replace)

        messagesToSave[result.message.id] = { insight: result.insight, message: result.message }
      }

      for (const changes of Object.values(messagesToSave) as any[]) {
        await setDoc(
          doc(getFirestore(), `${this.insightsPath}/${changes.insight.id}/messages/${changes.message.id}`),
          {
            author: author,
            facets: changes.message.facets,
          },
          { merge: true },
        )
      }

      this.isReplacing = false

      if (results.length === 100) {
        this.updateResults(this.searchText)
      } else {
        this.isOpen = false
      }
    }

    @Debounce(500)
    private async updateResults(search: string) {
      if (search && search === this.searchText) {
        this.isLoading = true

        this.searchResults = []
        this.searchMatches = []

        const matchText = new RegExp(`(^|\\s)${search}($|\\s)`, 'g')
        const matchTextFinder = new RegExp(`(^|\\s)${search}($|\\s)`, 'ig')

        // Load messages for each insight and cache them
        for (const insight of this.insights) {
          const messages =
            this.messagesCache[insight.id] ||
            (await getDocs(collection(getFirestore(), `${this.insightsPath}/${insight.id}/messages`)))

          this.messagesCache[insight.id] = messages

          for (const message of messages.docs) {
            const messageData = message.data()

            // Find all translatable texts and check if they match the search
            if (messageData?.facets?.view?.components) {
              for (const c of messageData.facets.view.components) {
                for (const key of Object.keys(c)) {
                  // Stop at 100 results so this dont take ages to finish
                  if (search !== this.searchText || this.searchResults.length === 100) {
                    return
                  }

                  if (typeof c[key] == 'object' && c[key].translationId && c[key].text) {
                    // If not template text then add it to the search results
                    if (c[key].text.match(matchText) && !c[key].translationId.startsWith('template-')) {
                      this.searchResults.push({
                        insight: insight,
                        message: messageData,
                        translatable: c[key],
                      })
                    }

                    // If case insensitive matches then add them to the other matches
                    if (c[key].text.match(matchTextFinder) && !c[key].translationId.startsWith('template-')) {
                      this.searchMatches = uniq(
                        this.searchMatches.concat(c[key].text.match(matchTextFinder)[0].trim()),
                      ).filter((m) => m !== this.searchText)
                    }
                  }
                }
              }
            }
          }
        }
      }

      this.isLoading = false
    }
  }

  export default toNative(FindReplace)
</script>

<style lang="scss" scoped>
  .backdrop {
    overflow: visible;
    line-height: 21px;
    padding: 4px 8px;
    max-width: 100%;
    font-size: 14px;
    letter-spacing: normal;
    background: #efefef;
    border-radius: 4px;
  }

  .highlights {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  :deep(mark) {
    border: 1px solid transparent;
    background-color: yellow;

    mark {
      border: 0px solid transparent;

      .wrapper {
        border: 1px solid darkgrey !important;
      }
    }
  }
</style>
