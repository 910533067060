<template>
  <v-dialog v-model="isOpen" width="800">
    <v-card v-if="isOpen">
      <v-card-title>Create a new template</v-card-title>

      <v-card-text>
        <div class="d-flex flex-row align-top">
          <IconPicker class="mr-4" :icon="icon" @set-icon="icon = $event" />

          <v-text-field v-model="name" label="Name" :rules="[uniqName]" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="isOpen = false" />

        <v-btn
          text="Create"
          color="primary"
          :disabled="!icon || !name || uniqName() !== true"
          @click="createTemplate()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { v4 as uuid } from 'uuid'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'

  import { AppStore, TemplatesStore } from '#stores'

  @Component({})
  class CreateTemplateDialog extends Vue {
    @Prop() public templatesPath!: string

    public name = ''
    public icon = ''

    public isOpen = false

    private readonly appStore = new AppStore()
    private readonly templatesStore = new TemplatesStore()

    public get templates() {
      return this.templatesStore.templates
    }

    public open() {
      this.isOpen = true
    }

    public async createTemplate() {
      const id = uuid()

      await setDoc(doc(getFirestore(), `${this.templatesPath}/${id}`), {
        id,
        name: this.name,
        icon: this.icon,
        allowedPages: [],
        allowedFacets: [],
        allowedComponents: [],
        requiredComponents: [],
        facets: {
          view: null,
          notification: null,
          home: null,
        },
        author: this.appStore.author,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      })

      this.isOpen = false

      this.$router.push(`templates/${id}`)
    }

    public uniqName() {
      return this.name && this.templates.find((t) => t.name === this.name)
        ? 'Name must be unique, template with this name already exists'
        : true
    }
  }

  export default toNative(CreateTemplateDialog)
</script>
