<template>
  <div class="pa-2">
    <div class="body-2">{{ component.title.text }}</div>
    <v-sparkline type="trend" color="white" :model-value="[0, 0, 0, 0, 0, 0, 0]" :padding="2" :line-width="3" />
    <v-sparkline type="bar" class="mt-n2" color="white" :model-value="mockChartData" :padding="2" :line-width="3" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentIdealBedtimeChart } from '#types'

  @Component({})
  class IdealBedtimeChartPreview extends Vue {
    @Prop() public component!: UIComponentIdealBedtimeChart

    public get mockChartData() {
      return [3, 3, 2, 2, 1, 2, 3]
    }
  }

  export default toNative(IdealBedtimeChartPreview)
</script>
