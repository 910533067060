<template>
  <div>
    <v-btn
      v-if="!loading && !!stat && statsData"
      class="body-2"
      style="font-weight: inherit"
      :style="disabled ? 'pointer-events: none' : ''"
      @click="emitOpen()"
    >
      <span v-if="stat === 'count'" class="pr-2" style="text-transform: none">
        {{ label }}
        <span :style="!!label ? 'font-weight: bold' : ''">~{{ count }}</span>
        {{ unit }}
      </span>
      <span v-else-if="stat === 'average'" class="pr-2" style="text-transform: none">
        {{ label }}
        <span :style="!!label ? 'font-weight: bold' : ''">~{{ average }}</span>
        {{ unit }}
      </span>
      <span v-else class="pr-2" style="text-transform: none">
        {{ label }}
        <span :style="!!label ? 'font-weight: bold' : ''">{{ stat }}</span>
        {{ unit }}
      </span>

      <template v-if="!textonly">
        <span v-if="!warnings.length && trendValue > 0"><v-icon color="green">mdi-trending-up</v-icon></span>
        <span v-if="!warnings.length && trendValue === 0" color="blue">
          <v-icon>mdi-trending-neutral</v-icon>
        </span>
        <span v-if="!warnings.length && trendValue < 0"><v-icon color="red">mdi-trending-down</v-icon></span>
      </template>

      <span v-if="warnings.length > 0"><v-icon color="blue" class="mt-n1">mdi-alert-circle</v-icon></span>
    </v-btn>

    <v-btn v-if="!loading && (!stat || !statsData)" style="pointer-events: none; font-weight: inherit">
      <span class="pr-2" style="text-transform: none">No stats</span>

      <v-icon v-if="!textonly" color="black">mdi-minus</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
  import { sum } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AmplitudeStats, StatsWarning } from '#types'

  @Component({})
  class StatisticsChip extends Vue {
    @Prop() public stat!: string
    @Prop() public unit!: string
    @Prop() public label!: string

    @Prop({ type: Boolean }) public loading!: boolean
    @Prop({ type: Boolean }) public disabled!: boolean
    @Prop({ type: Boolean }) public textonly!: boolean

    @Prop({ default: () => [] }) public warnings!: StatsWarning[]

    @Prop({ default: undefined }) public statsData?: AmplitudeStats

    @Emit('open')
    public emitOpen() {
      return true
    }

    public count = 0
    public average = 0
    public trendValue = 0

    @Watch('statsData', { immediate: true })
    protected onStatsChanged(val: boolean) {
      if (val) {
        this.updateCount()
        this.updateAverage()
        this.updateTrendValue()
      }
    }

    private updateCount() {
      this.count = this.statsData?.series ? Math.max(...this.statsData.series) : 0
    }

    private updateAverage() {
      this.average = this.statsData?.series ? this.averageValue(this.statsData.series) : 0
    }

    private updateTrendValue() {
      this.trendValue = 0

      if (this.statsData?.series) {
        const indexes = Array(this.statsData.series.length)
          .fill(0)
          .map((_, index) => index + 1)

        // Let's skip the last value from the array since it might be still change
        const slope = this.linearRegression(
          this.statsData.series.slice(0, this.statsData.series.length - 1),
          indexes.slice(0, indexes.length - 1),
        )

        this.trendValue = slope > 0 ? 1 : slope < 0 ? -1 : 0
      }
    }

    private averageValue(dataSet: number[]): number {
      if (dataSet.length === 0) {
        return 0
      }
      return Math.round(sum(dataSet) / dataSet.length)
    }

    // Based on: https://stackoverflow.com/questions/6195335/linear-regression-in-javascript/31566791#31566791
    private linearRegression(y: number[], x: number[]): number {
      const n = y.length
      let sum_x = 0
      let sum_y = 0
      let sum_xy = 0
      let sum_xx = 0
      // let sum_yy = 0

      for (let i = 0; i < y.length; i++) {
        sum_x += x[i]
        sum_y += y[i]
        sum_xy += x[i] * y[i]
        sum_xx += x[i] * x[i]
        // sum_yy += y[i] * y[i]
      }

      return (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x)
    }
  }

  export default toNative(StatisticsChip)
</script>
