<template>
  <div v-if="message.media">
    <div v-for="media in message.media" :key="media.id" class="py-2 text-center">
      {{ media.type }}: {{ media.slug }}
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Message } from '#types'

  @Component({})
  class MediaObjectPreview extends Vue {
    @Prop() public message!: Message
  }

  export default toNative(MediaObjectPreview)
</script>
