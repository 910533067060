<template>
  <div />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentSleepClock } from '#types'

  @Component({})
  class SleepClock extends Vue {
    @Prop() public data!: UIComponentSleepClock
  }

  export default toNative(SleepClock)
</script>
