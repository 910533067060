<template>
  <div v-if="searchResults.length > 1 && !disabled" class="d-flex flex-column pa-0">
    <v-divider />

    <div class="px-4 py-4">
      <div
        class="d-flex flex-row align-center space-between"
        style="justify-content: space-between; text-transform: uppercase"
      >
        <div style="white-space: nowrap; display: block; width: 35%">
          <v-btn
            v-if="activeIndex > 0"
            size="small"
            class="font-weight-light"
            prepend-icon="mdi-arrow-left-thin"
            style="width: 100%; justify-content: start"
            :text="searchResults[activeIndex - 1].id.toUpperCase()"
            @click="selectInsight(searchResults[activeIndex - 1].id)"
          />
        </div>

        <div class="d-flex justify-center align-center">
          <template v-if="!searchActive">
            <v-btn
              v-if="!searchActive"
              size="small"
              class="font-weight-light"
              prepend-icon="mdi-magnify"
              :text="activeIndex + 1 + ' / ' + searchResults.length"
              @click="searchActive = true"
            />

            <v-tooltip text="Copy IDs to clipboard">
              <template #activator="{ props }">
                <v-icon
                  v-bind="props"
                  size="small"
                  icon="mdi-content-copy"
                  @click.stop="useClipboard.copy(searchResultIds.join('\n'))"
                />
              </template>
            </v-tooltip>
          </template>

          <v-combobox
            v-else
            autofocus
            class="my-n2"
            hide-details
            item-title="id"
            item-value="id"
            density="compact"
            :items="searchResults"
            :model-value="insightId"
            :menu-props="{ location: 'top center', offset: 8 }"
            @blur="searchActive = false"
            @update:search="searchText = $event"
            @update:model-value="selectedInsight($event)"
          />
        </div>

        <div style="white-space: nowrap; display: block; width: 35%">
          <v-btn
            v-if="activeIndex < searchResults.length - 1"
            size="small"
            class="font-weight-light"
            append-icon="mdi-arrow-right-thin"
            style="width: 100%; justify-content: end"
            :text="searchResults[activeIndex + 1].id.toUpperCase()"
            @click="selectInsight(searchResults[activeIndex + 1].id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { useClipboard } from '@vueuse/core'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AppStore, InsightsStore } from '#stores'

  import { Insight } from '#types'

  @Component({})
  class InsightsBar extends Vue {
    @Prop() public disabled!: boolean

    @Prop() public insightId!: string

    public searchText = ''

    public searchActive = false

    public readonly useClipboard = useClipboard()

    protected readonly appStore = new AppStore()

    protected readonly insightsStore = new InsightsStore()

    public get rootPath() {
      return this.appStore.route[1]
    }

    public get activeIndex() {
      return this.searchResults.findIndex((r) => r.id === this.insightId)
    }

    public get searchResults() {
      return this.insightsStore.searchResults.length ? this.insightsStore.searchResults : this.insightsStore.allInsights
    }

    public get searchResultIds() {
      return this.searchResults.map((item: Insight) => item.id)
    }

    @Watch('insightId')
    protected insightIdChanged() {
      this.searchText = this.searchResults.find((r) => r.id === this.insightId)?.id || ''
    }

    public selectInsight(id: string) {
      this.searchActive = false

      this.$router.push(`/${this.rootPath}/${id}`)
    }

    public selectedInsight(insight: any) {
      if (insight && typeof insight === 'object') {
        this.selectInsight(insight.id)
      }
    }
  }

  export default toNative(InsightsBar)
</script>

<style lang="scss" scoped>
  :deep(.v-btn__content) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
