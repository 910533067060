<template>
  <div>
    <div class="my-6">
      <div class="text-body-2 my-6">Copy condition from another message:</div>
      <v-select
        v-model="copyFromMessage"
        clearable
        :items="otherMessages"
        placeholder="Do not copy condition for this message"
        no-data-text="No other messages to copy from"
      />

      <template v-if="!copyFromMessage">
        <v-divider class="mt-4 mb-8" />

        <div class="text-body-2 my-6">Only show this message with selected languages:</div>
        <v-select
          v-model="selectedLanguages"
          placeholder="Show this message for all app languages"
          :items="locales.filter((item) => item.value != '')"
          multiple
        />

        <div class="text-body-2 my-6">Only show this message with selected feature flags:</div>
        <v-select
          v-model="selectedFeatures"
          placeholder="Show this message regardless of feature flags"
          :items="flags.filter((item) => item.value != '')"
          multiple
        />

        <div class="text-body-2 my-6">Only show this message for selected app flavors:</div>
        <v-select
          v-model="selectedFlavors"
          placeholder="Show this message regardless of the app flavor"
          :items="flavors.filter((item) => item.value != '')"
          multiple
        />

        <template v-if="predefinedConditions.length">
          <v-divider class="mt-4 mb-8" />

          <div class="text-body-2 my-6">Visibility based on components:</div>

          <v-select
            v-model="selectedComponents"
            :items="predefinedConditions.map((c) => ({ text: c.description, value: c.condition }))"
            multiple
          />
        </template>

        <v-divider class="mt-4 mb-8" />

        <Statement v-model="condition" label="Condition preview" simplify readonly />

        <v-checkbox v-model="hide" label="Hide message instead of showing (revert the condition behavior)" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { appFlavors, appLocales } from '#views/messages/constants'

  import { componentConditions } from '#views/messages/components/constants'

  import { Message } from '#types'

  import { simplifyStatement } from '#statement'

  @Component({})
  class MessageConditions extends Vue {
    @Prop() public message!: Message
    @Prop() public messages!: Message[]

    public hide = false

    public condition = ''

    public flavors = appFlavors
    public locales = appLocales

    public copyFromMessage: string | null = null

    public selectedFeatures: any[] = []
    public selectedFlavors: any[] = []
    public selectedLanguages: any[] = []
    public selectedComponents: any[] = []

    public flags = [
      { title: 'Select feature flag', value: '' },
      {
        title: 'Bedtime guidance content',
        value: 'bedtime_guidance_content',
      },
    ]

    public get otherMessages() {
      return this.messages
        .map((item, index) => ({ title: `${index + 1}: ${item.id}`, value: item.id }))
        .filter((item) => item.value != this.message.id)
    }

    public get predefinedConditions() {
      return (this.message?.facets?.view?.components || [])
        .filter(
          (c) =>
            c[(componentConditions[c.type as keyof typeof componentConditions]?.key || 'type') as keyof typeof c] ===
            componentConditions[c.type as keyof typeof componentConditions]?.value,
        )
        .map((c) => componentConditions[c.type as keyof typeof componentConditions])
    }

    @Watch('hide')
    protected onHideChanged() {
      this.updateConditions()
    }

    @Watch('condition')
    protected onUserChanged() {
      this.message.condition = this.condition
    }

    @Watch('copyFromMessage')
    protected copyFromMessageChanged() {
      this.updateConditions()
    }

    @Watch('selectedFlavors')
    protected selectedFlavorsChanged() {
      this.updateConditions()
    }

    @Watch('selectedFeatures')
    protected selectedFeaturesChanged() {
      this.updateConditions()
    }

    @Watch('selectedLanguages')
    protected selectedLanguagesChanged() {
      this.updateConditions()
    }

    @Watch('selectedComponents')
    protected selectedComponentsChanged() {
      this.updateConditions()
    }

    public mounted() {
      this.copyFromMessage = this.message.conditionSource || null

      if (this.message && 'condition' in this.message) {
        const condition = String(this.message.condition)

        this.selectedFeatures = condition
          .split(' && ')
          .filter((condition) => /app.feature_enabled/gi.test(condition))
          .flatMap((condition) => {
            if (/\|\|/gi.test(condition)) {
              return condition.split(' || ').map((item: any) => item.match(/app.feature_enabled\('([a-z_]+)'\)/)[1])
            } else if (condition !== null) {
              let match = condition.match(/app.feature_enabled\('([a-z_]+)'\)/)
              return match ? match[1] : ''
            }
          })
          .map((condition) => this.flags.find((item) => item.value == condition))
          .map((item) => item?.value)

        this.selectedFlavors = condition
          .split(' && ')
          .filter((condition) => /app.flavor/gi.test(condition))
          .flatMap((condition) => {
            if (/\|\|/gi.test(condition)) {
              return condition.split(' || ').map((item: any) => item.match(/app.flavor === '([a-z_]+)'/)[1])
            } else if (condition !== null) {
              let match = condition.match(/app.flavor === '([a-z_]+)'/)
              return match ? match[1] : ''
            }
          })
          .map((condition) => appFlavors.find((item) => item.value == condition))
          .map((item) => item?.value)

        this.selectedLanguages = condition
          .split(' && ')
          .filter((condition) => /app.isoLanguage/gi.test(condition))
          .flatMap((condition) => {
            if (/\|\|/gi.test(condition)) {
              return condition.split(' || ').map((item: any) => item.match(/app.isoLanguage === '([a-z_]+)'/)[1])
            } else if (condition !== null) {
              let match = condition.match(/app.isoLanguage === '([a-z_]+)'/)
              return match ? match[1] : ''
            }
          })
          .map((condition) => appLocales.find((item) => item.value == condition))
          .map((item) => item?.value)

        this.selectedComponents = this.predefinedConditions
          .map((c) => c.condition)
          .filter((c) => condition.includes(`${c}`))

        this.hide = condition.startsWith('!')

        this.condition = condition
      }
    }

    public async updateConditions() {
      let condition: any = []

      if (this.selectedFeatures.length) {
        condition.push(
          `(${this.selectedFeatures
            .filter((val) => val.length)
            .map((val) => `app.feature_enabled('${val}')`)
            .join(' || ')})`,
        )
      }

      if (this.selectedFlavors.length) {
        condition.push(
          `(${this.selectedFlavors
            .filter((val) => val.length)
            .map((val) => `app.flavor === '${val}'`)
            .join(' || ')})`,
        )
      }

      if (this.selectedLanguages.length) {
        condition.push(
          `(${this.selectedLanguages
            .filter((val) => val.length)
            .map((val) => `app.isoLanguage === '${val}'`)
            .join(' || ')})`,
        )
      }

      if (this.selectedComponents.length) {
        condition.push(`${this.selectedComponents.join(' && ')}`)
      }

      condition = condition.join(' && ')

      if (this.hide) {
        condition = `!(${condition})`
      }

      if (this.copyFromMessage) {
        this.message.conditionSource = this.copyFromMessage
      } else {
        this.message.conditionSource = undefined

        delete this.message.conditionSource
      }

      const result = (await simplifyStatement(condition)).value

      this.condition = result
    }
  }

  export default toNative(MessageConditions)
</script>
