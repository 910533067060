import { JsonSchema } from '@jouzen/outo-apps-toolkit'

import {
  MobileApp_Language,
  MobileDevice_Os,
  Ring_Capability,
  Ring_HardwareType,
  Service_Environment,
  User_SubscriptionState,
} from '@jouzen/control-api/predicates'

export const cloudEnvs = [
  { title: 'Sandbox', value: 'test' },
  { title: 'Staging', value: 'stage' },
  { title: 'Release', value: 'prod' },
]

export const userHeaders: any = [
  {
    title: 'UID',
    value: 'userUid',
    cellClass: 'caption text-no-wrap px-3',
  },
  {
    title: 'Added',
    value: 'createdAt',
    cellClass: 'caption text-right text-no-wrap px-3',
    align: 'end',
  },
]

// Components use custom sort functions so update them if headers changes
export const segmentHeaders: any = [
  {
    key: 'data-table-expand',
  },
  {
    key: 'metadata.name',
    title: 'Name',
    class: 'text-primary',
  },
  {
    key: 'expression.type',
    title: 'Type',
    class: 'text-primary',
  },
  {
    key: 'expression',
    title: 'Expressions',
    class: 'text-primary',
  },
  {
    key: 'metadata',
    title: 'Rollouts',
    class: 'text-primary',
  },
  {
    key: 'metadata.informative.labels.color',
    title: 'Category',
    class: 'text-primary',
    align: 'end',
  },
]

export const segmentCategories = [
  { title: 'Default', value: 'blue' },
  { title: 'Draft', value: 'grey' },
  { title: 'Proposal', value: 'yellow' },
  { title: 'Complex', value: 'amber' },
  { title: 'Dangerous', value: 'red' },
  { title: 'User groups', value: 'indigo' },
  { title: 'Oura groups', value: 'pink' },
  { title: 'Android OS', value: 'teal' },
  { title: 'Apple / iOS', value: 'cyan' },
  { title: 'Sandbox apps', value: 'green' },
  { title: 'Staging apps', value: 'purple' },
  // Black needs to have space in the end since it
  // does not have lighten or darken variants!
  { title: 'Release apps', value: 'black ' },
]

export const segmentConfigTypes = [
  { value: 'user-labels', title: 'User group label' },
  { value: 'predicates', title: 'Custom predicates' },
]

export const criteriaEnumTypes: any = {
  mobileDeviceOs: [
    { value: MobileDevice_Os.IOS, title: 'iOS' },
    { value: MobileDevice_Os.ANDROID, title: 'Android' },
  ],
  mobileAppLanguage: [
    { value: MobileApp_Language.EN, title: 'en' },
    { value: MobileApp_Language.DA, title: 'da' },
    { value: MobileApp_Language.DE, title: 'de' },
    { value: MobileApp_Language.ES, title: 'es' },
    { value: MobileApp_Language.FI, title: 'fi' },
    { value: MobileApp_Language.FR, title: 'fr' },
    { value: MobileApp_Language.NB, title: 'nb' },
    { value: MobileApp_Language.IT, title: 'it' },
    { value: MobileApp_Language.JA, title: 'ja' },
    { value: MobileApp_Language.SV, title: 'sv' },
    { value: MobileApp_Language.OTHER, title: 'Other' },
  ],
  ringCapability: [
    { value: Ring_Capability.CAP_BACKGROUND_DFU, title: 'Background DFU' },
    { value: Ring_Capability.CAP_RESEARCH_DATA, title: 'Research data' },
    { value: Ring_Capability.CAP_DAYTIME_HR, title: 'Daytime HR' },
    { value: Ring_Capability.CAP_EXERCISE_HR, title: 'Exercise HR' },
    { value: Ring_Capability.CAP_SPO2, title: 'SPO2' },
    { value: Ring_Capability.CAP_BUNDLING, title: 'Bundling' },
    { value: Ring_Capability.CAP_ENCRYPTED_API, title: 'Encrypted API' },
    { value: Ring_Capability.CAP_TAP_TO_TAG, title: 'Tap to tag' },
    { value: Ring_Capability.CAP_RESTING_HR, title: 'Resting HR' },
    { value: Ring_Capability.CAP_APP_AUTH, title: 'App auth' },
    { value: Ring_Capability.CAP_BLE_MODE, title: 'BLE mode' },
    { value: Ring_Capability.CAP_REAL_STEPS, title: 'Real steps' },
    { value: Ring_Capability.CAP_EXPERIMENTAL, title: 'Experimental' },
    { value: Ring_Capability.CAP_CVA_PPG_SAMPLER, title: 'CVA PPG sampler' },
  ],
  ringHardwareType: [
    { value: Ring_HardwareType.GEN1, title: 'Gen 1' },
    { value: Ring_HardwareType.GEN2, title: 'Gen 2' },
    { value: Ring_HardwareType.GEN2M, title: ' Gen 2M' },
    { value: Ring_HardwareType.GEN2X, title: 'Gen 3' },
    { value: Ring_HardwareType.OREO, title: 'Oreo' },
  ],
  subscriptionState: [
    { value: User_SubscriptionState.PENDING, title: 'Pending' },
    { value: User_SubscriptionState.TRIAL, title: 'Trial' },
    { value: User_SubscriptionState.MEMBER, title: 'Member' },
    { value: User_SubscriptionState.EXPIRED, title: 'Expired' },
    { value: User_SubscriptionState.LIFETIME, title: 'Lifetime' },
  ],
  serviceEnvironment: [
    { value: Service_Environment.SANDBOX, title: 'Sandbox' },
    { value: Service_Environment.STAGING, title: 'Staging' },
    { value: Service_Environment.PROD, title: 'Release' },
  ],
}

export const criteriaOperators: any = {
  stringMatch: [
    { value: 'equals', title: 'Equal to', default: '' },
    { value: 'startsWith', title: 'Starts with', default: '' },
    { value: 'endsWith', title: 'Ends with', default: '' },
    { value: 'regexp', title: 'Regexp', default: '' },
    { value: 'equalsAnyOf', title: 'Equal to any of:', default: '' },
    { value: 'isPresent', title: 'Is present / is set', default: true },
  ],
  stringMatchS: [
    { value: 'equals', title: 'Equal to', default: '' },
    { value: 'equalsAnyOf', title: 'Equal to any of', default: '' },
    { value: 'isPresent', title: 'Is present', default: true },
  ],
  integerMatch: [
    { value: 'equals', title: 'Equal to', default: '' },
    { value: 'lessThan', title: 'Less than', default: '' },
    { value: 'lessThanOrEqual', title: 'Less than / equal to', default: '' },
    { value: 'greaterThan', title: 'Greater than', default: '' },
    { value: 'greaterThanOrEqual', title: 'Greater than / equal to', default: '' },
    { value: 'isPresent', title: 'Is present / is set', default: true },
  ],
  versionMatch: [
    { value: 'min', title: 'Min version', default: 'version' },
    { value: 'max', title: 'Max version', default: 'version' },
    { value: 'isPresent', title: 'Is present / is set', default: true },
  ],
  timestampMatch: [
    { value: 'before', title: 'Before', datetime: Date.now() },
    { value: 'after', title: 'After or on', datetime: Date.now() },
    { value: 'withinPast', title: 'Within past', duration: 7 * 24 * 60 * 60 * 1000 },
    { value: 'withinFuture', title: 'Within next', duration: 7 * 24 * 60 * 60 * 1000 },
    { value: 'isPresent', title: 'Is present / is set', default: true },
  ],
  capabilityMatch: [
    { value: 'capability', title: 'Capability', default: Ring_Capability.CAP_BACKGROUND_DFU, type: 'ringCapability' },
    { value: 'version', title: 'Version', default: 'isPresent / is set', type: 'integerMatch' },
  ],
}

export const criteriaPredicates: any = {
  default: [
    //{ header: 'User', divider: true },
    {
      value: 'user-disabled',
      title: 'USER',
      props: { disabled: true, class: 'text-overline' },
    },
    { value: 'user-userUid', title: 'User UID is', type: 'stringMatch' },
    { value: 'user-emailVerified', title: 'Has verified email', type: 'booleanMatch' },
    { value: 'user-hasLabelThat', title: 'Has a specific label', type: 'stringMatch' },
    { value: 'user-hipaaEnabled', title: 'Has HIPAA association', type: 'booleanMatch' },
    { value: 'user-registrationDate', title: 'Registration date is', type: 'timestampMatch' },
    { value: 'user-subscriptionActive', title: 'Subscription is active', type: 'booleanMatch' },
    { value: 'user-subscriptionState', title: 'Subscription state is', type: 'subscriptionState' },
    { value: 'user-subscriptionStart', title: 'Subscription start is', type: 'timestampMatch' },
    { value: 'user-subscriptionEnd', title: 'Subscription end is', type: 'timestampMatch' },
    {
      value: 'user-countryOfResidence',
      title: 'Country of residence is',
      type: 'stringMatchS',
    },

    //{ header: 'Client', divider: true },
    { value: 'client-disabled', title: 'Client', props: { disabled: true, class: 'text-overline' } },
    { value: 'client-device-uid', title: 'Mobile device uid is', type: 'stringMatch' },
    { value: 'client-device-model', title: 'Mobile device model is', type: 'stringMatch' },
    { value: 'client-device-os', title: 'Mobile device OS is', type: 'mobileDeviceOs' },
    { value: 'client-device-osVersion', title: 'Mobile device OS version is', type: 'stringMatch' },
    { value: 'client-mobileApp-flavor', title: 'Mobile app flavor is', type: 'stringMatch' },
    { value: 'client-mobileApp-version', title: 'Mobile app version is', type: 'versionMatch' },
    { value: 'client-mobileApp-language', title: 'Mobile app language is', type: 'mobileAppLanguage' },
    { value: 'client-mobileApp-ecoreVersion', title: 'Mobile app ecore version is', type: 'versionMatch' },
    { value: 'client-mobileApp-insightsVersion', title: 'Mobile app insights version is', type: 'versionMatch' },
    { value: 'client-primaryRing-macAddress', title: 'Primary ring MAC address is', type: 'stringMatch' },
    { value: 'client-primaryRing-apiVersion', title: 'Primary ring API version is', type: 'versionMatch' },
    {
      value: 'client-primaryRing-bootloaderVersion',
      title: 'Primary ring bootloader version is',
      type: 'versionMatch',
    },
    { value: 'client-primaryRing-bluetoothVersion', title: 'Primary ring bluetooth version is', type: 'versionMatch' },
    { value: 'client-primaryRing-firmwareVersion', title: 'Primary ring firmware version is', type: 'versionMatch' },
    { value: 'client-primaryRing-hardwareType', title: 'Primary ring hardware type is', type: 'ringHardwareType' },
    // { value: 'client-primaryRing-hardwareVersion', title: 'Primary ring hardware version is', type: 'stringMatch' },
    { value: 'client-primaryRing-capability', title: 'Primary ring has capability', type: 'capabilityMatch' },
    { value: 'client-primaryRing-serialNumber', title: 'Primary ring serial number is', type: 'stringMatch' },
    { value: 'client-primaryRing-size', title: 'Primary ring size is', type: 'integerMatch' },
    /*{ value: 'client-anyRing-macAddress', title: 'Any ring MAC address is', type: 'stringMatch' },
    { value: 'client-anyRing-apiVersion', title: 'Any ring API version is', type: 'versionMatch' },
    { value: 'client-anyRing-bootloaderVersion', title: 'Any ring bootloader version is', type: 'versionMatch' },
    { value: 'client-anyRing-bluetoothVersion', title: 'Any ring bluetooth version is', type: 'versionMatch' },
    { value: 'client-anyRing-firmwareVersion', title: 'Any ring firmware version is', type: 'versionMatch' },
    { value: 'client-anyRing-hardwareType', title: 'Any ring hardware type is', type: 'versionMatch' },
    // { value: 'client-anyRing-hardwareVersion', title: 'Any ring hardware version is', type: 'stringMatch' },
    { value: 'client-anyRing-capability', title: 'Any ring has capability', type: 'capabilityMatch' },
    { value: 'client-anyRing-serialNumber', title: 'Any ring serial number is', type: 'stringMatch' },
    { value: 'client-anyRing-size', title: 'Any ring size is', type: 'integerMatch' },*/
    {
      value: 'client-locationCountryCode',
      title: 'Location country is',
      type: 'stringMatchS',
    },

    //{ header: 'Service', divider: true },
    { value: 'service-disabled', title: 'Service', props: { disabled: true, class: 'text-overline' } },
    { value: 'service-environment', title: 'Cloud service environment is', type: 'serviceEnvironment' },
    { value: 'service-serviceType', title: 'Cloud service type is', type: 'stringMatch' },
    { value: 'service-serviceName', title: 'Cloud service name is', type: 'stringMatch' },
  ],
  inverted: [
    //{ header: 'User', divider: true },
    { value: 'user-disabled', title: 'User', props: { disabled: true, class: 'text-overline' } },
    { value: 'user-userUid', title: 'User UID is not', type: 'stringMatch' },
    { value: 'user-emailVerified', title: 'Has unverified email', type: 'booleanMatch' },
    { value: 'user-hasLabelThat', title: 'Does not have a label', type: 'stringMatch' },
    { value: 'user-hipaaEnabled', title: 'Has no HIPAA association', type: 'booleanMatch' },
    { value: 'user-registrationDate', title: 'Registration date is not', type: 'timestampMatch' },
    { value: 'user-subscriptionActive', title: 'Subscription is inactive', type: 'booleanMatch' },
    { value: 'user-subscriptionState', title: 'Subscription state is not', type: 'subscriptionState' },
    { value: 'user-subscriptionStart', title: 'Subscription start is not', type: 'timestampMatch' },
    { value: 'user-subscriptionEnd', title: 'Subscription end is not', type: 'timestampMatch' },
    {
      value: 'user-countryOfResidence',
      title: 'Country of residence is not',
      type: 'stringMatchS',
    },

    //{ header: 'Client', divider: true },
    { value: 'client-disabled', title: 'Client', props: { disabled: true, class: 'text-overline' } },
    { value: 'client-device-uid', title: 'Mobile device uid is not', type: 'stringMatch' },
    { value: 'client-device-model', title: 'Mobile device model is not', type: 'stringMatch' },
    { value: 'client-device-os', title: 'Mobile device OS is not', type: 'mobileDeviceOs' },
    { value: 'client-device-osVersion', title: 'Mobile device OS version is not', type: 'stringMatch' },
    { value: 'client-mobileApp-flavor', title: 'Mobile app flavor is not', type: 'stringMatch' },
    { value: 'client-mobileApp-version', title: 'Mobile app version is not', type: 'versionMatch' },
    { value: 'client-mobileApp-language', title: 'Mobile app language is not', type: 'mobileAppLanguage' },
    { value: 'client-mobileApp-ecoreVersion', title: 'Mobile app ecore version is not', type: 'versionMatch' },
    { value: 'client-mobileApp-insightsVersion', title: 'Mobile app insights version is not', type: 'versionMatch' },
    { value: 'client-primaryRing-macAddress', title: 'Primary ring MAC address is not', type: 'stringMatch' },
    { value: 'client-primaryRing-apiVersion', title: 'Primary ring API version is not', type: 'versionMatch' },
    {
      value: 'client-primaryRing-bootloaderVersion',
      title: 'Primary ring bootloader version is not',
      type: 'versionMatch',
    },
    {
      value: 'client-primaryRing-bluetoothVersion',
      title: 'Primary ring bluetooth version is not',
      type: 'versionMatch',
    },
    {
      value: 'client-primaryRing-firmwareVersion',
      title: 'Primary ring firmware version is not',
      type: 'versionMatch',
    },
    {
      value: 'client-primaryRing-hardwareType',
      title: 'Primary ring hardware type is not',
      type: 'ringHardwareType',
    },
    // { value: 'client-primaryRing-hardwareVersion', title: 'Primary ring hardware version is not', type: 'stringMatch' },
    { value: 'client-primaryRing-capability', title: 'Primary ring has no capability', type: 'capabilityMatch' },
    { value: 'client-primaryRing-serialNumber', title: 'Primary ring serial number is not', type: 'stringMatch' },
    { value: 'client-primaryRing-size', title: 'Primary ring size is not', type: 'integerMatch' },
    /* { value: 'client-anyRing-macAddress', title: 'Any ring MAC address is not', type: 'stringMatch' },
    { value: 'client-anyRing-apiVersion', title: 'Any ring API version is not', type: 'versionMatch' },
    { value: 'client-anyRing-bootloaderVersion', title: 'Any ring bootloader version is not', type: 'versionMatch' },
    { value: 'client-anyRing-bluetoothVersion', title: 'Any ring bluetooth version is not', type: 'versionMatch' },
    { value: 'client-anyRing-firmwareVersion', title: 'Any ring firmware version is not', type: 'versionMatch' },
    { value: 'client-anyRing-hardwareType', title: 'Any ring hardware type is not', type: 'versionMatch' },
    // { value: 'client-anyRing-hardwareVersion', title: 'Any ring hardware version is not', type: 'stringMatch' },
    { value: 'client-anyRing-capability', title: 'Any ring has no capability', type: 'capabilityMatch' },
    { value: 'client-anyRing-serialNumber', title: 'Any ring serial number is not', type: 'stringMatch' },
    { value: 'client-anyRing-size', title: 'Any ring size is not', type: 'integerMatch' }, */
    {
      value: 'client-locationCountryCode',
      title: 'Location country is not',
      type: 'stringMatchCC',
    },

    //{ header: 'Service', divider: true },
    { value: 'service-disabled', title: 'Service', props: { disabled: true, class: 'text-overline' } },
    { value: 'service-environment', title: 'Cloud service environment is not', type: 'serviceEnvironment' },
    { value: 'service-serviceType', title: 'Cloud service type is not', type: 'stringMatch' },
    { value: 'service-serviceName', title: 'Cloud service name is not', type: 'stringMatch' },
  ],
}

export const labelUsersSchema: JsonSchema = {
  userUid: {
    key: 'userUid',
    type: 'string',
    mandatory: true,
    title: 'User UID or Email',
    variants: ['email', 'uid', 'uuid', 'id'],
    description: 'Emails are automatically converted to UUID, emails without corresponding UUID are discarded.',
  },
}
