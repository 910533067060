import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { Unsubscribe, collection, collectionGroup, getFirestore, onSnapshot, query, where } from 'firebase/firestore'

let messageCountUnsubscribes: Unsubscribe[] = []

let unsubscribeTemplates: Unsubscribe | undefined = undefined

@Store
export class TemplatesStore extends Pinia {
  public loading = true

  public templates: any[] = []

  public get components() {
    return this.templates.filter((t) => t.allowedPages?.includes('components'))
  }

  public async subscribeToTemplates() {
    if (!unsubscribeTemplates) {
      this.loading = true

      unsubscribeTemplates = onSnapshot(query(collection(getFirestore(), '/templates')), (snap) => {
        const templates: any[] = snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))

        this.templates = sortBy(templates, [(t) => t.updatedAt || '']).reverse()

        this.templates.forEach((doc, i) => {
          messageCountUnsubscribes[i] = onSnapshot(
            query(collectionGroup(getFirestore(), `messages`), where('template', '==', doc.id)),
            (snap) => {
              this.templates = this.templates.map((template) =>
                template.id == doc.id ? { ...template, messageCount: snap.docs.length } : template,
              )
            },
          )
        })

        this.loading = false
      })
    }
  }
  public async unsubscribeFromTemplates() {
    if (unsubscribeTemplates) {
      unsubscribeTemplates()

      unsubscribeTemplates = undefined

      messageCountUnsubscribes.forEach((unsubscribe) => unsubscribe())

      messageCountUnsubscribes = []
    }
  }
}
