<template>
  <div>
    <div>{{ getDeltaCategoryValue(component.category) }}</div>
    <div>+0</div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { deltaCategoryItems } from '#views/messages/constants'

  import { UIComponentDelta } from '#types'

  @Component({})
  class DeltaPreview extends Vue {
    @Prop() public component!: UIComponentDelta

    public getDeltaCategoryValue(key: string) {
      const settingItem = deltaCategoryItems.find((item) => item.value === key)

      return settingItem?.title
    }
  }

  export default toNative(DeltaPreview)
</script>
