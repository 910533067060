<template>
  <div>
    <div v-for="option in component.options" :key="option.id" class="pa-2 body-2 d-flex justify-start">
      <v-icon class="pr-2" color="white">mdi-checkbox-blank-outline</v-icon>
      <span>{{ option.name.text }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentQuestionnaire } from '#types'

  @Component({})
  class QuestionnairePreview extends Vue {
    @Prop() public component!: UIComponentQuestionnaire
  }

  export default toNative(QuestionnairePreview)
</script>
