<template>
  <v-navigation-drawer
    width="700"
    order="0"
    location="end"
    elevation="0"
    disable-resize-watcher
    :model-value="isOpen"
    :temporary="$vuetify.display.mdAndDown"
  >
    <v-card>
      <v-card-text>
        <v-card-title class="headline">{{ 'Edit contributors' }}</v-card-title>
        <v-card-text>
          <p>
            This page allows you to define custom contributors and their values. All 'NOT' listed contributors use value
            of 77, which is also the default priority value. Next version will use values from user as default. (77 is
            the value that produces smallest priority)
          </p>
          <v-row class="d-flex flew-row align-center justify-start">
            <v-col>
              <v-switch
                v-model="toggleContributorsEnabled"
                label="Enable custom contributors"
                color="primary"
                hide-details
              />
            </v-col>
            <v-spacer />
          </v-row>
          <p>To update values, toggle the switch off and on.</p>
        </v-card-text>

        <v-data-table
          v-model="selected"
          :items="storedContributors"
          :headers="contributorHeaders"
          hide-default-footer
          scrollable
          class="elevation-1"
        >
          <template #item.value="{ item }">
            <input
              v-model="item.value"
              style="background-color: lightyellow"
              type="text"
              step="1"
              min="1"
              max="100"
              name="new value"
              :placeholder="item.contributor.toString()"
            />
          </template>
          <template #item.actions="{ item }">
            <v-btn icon class="mx-0" @click="deleteItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <v-container>
          <v-row class="d-flex align center">
            <v-col>
              <v-select v-model="contributorSelection" :items="contributorOptions" label="Contributor" />
            </v-col>
            <v-col>
              <v-text-field v-model.trim="inputs.value" persistent-hint label="Score value" placeholder="1 - 100" />
            </v-col>
            <v-col>
              <v-btn @click="addToContributorList()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="saveToLocalStorage()">Save values locally</v-btn>
        <v-btn color="red" @click="closePanel()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { contributorDialogHeaders, contributorList } from '#views/priorities/constants'

  import { ContributorScore } from '#types'

  @Component({})
  class ContributorsPanel extends Vue {
    @Prop() public storedContributors!: ContributorScore[]
    @Prop() public storedEnableContributorsToggle!: boolean
    @Emit('updateSavedContributors')
    public updateSavedContributors(customContributors: ContributorScore[]) {
      return customContributors
    }
    @Emit('toggleEnableContributors')
    public toggleEnableContributors(toggleContributorsEnabled: boolean) {
      return toggleContributorsEnabled
    }

    public toggleContributorsEnabled = this.getStoredContributorsToggleValue()
    public customContributors = this.getSavedContributors()
    public isOpen = false
    public action = ''
    public contributorSelection = ''
    public inputs: any = { contributor: '', value: '77' }
    public item: ContributorScore | null = null
    public selected: any[] = []
    public contributorHeaders = contributorDialogHeaders
    public contributorOptions: any[] = this.getContributorOptions()

    public open() {
      this.getContributorOptions()
      this.isOpen = true
    }

    public closePanel() {
      if (!this.contributorSuitable()) {
        return
      }
      const customContributors = this.storedContributors
      this.updateSavedContributors(customContributors)
      this.toggleEnableContributors(this.toggleContributorsEnabled)
      this.isOpen = false
    }

    @Watch('toggleContributorsEnabled')
    public ToggleContributorsEnabledChanged() {
      localStorage.setItem('UserDefinedContributorsToggle', JSON.stringify(this.toggleContributorsEnabled))
      this.toggleEnableContributors(this.toggleContributorsEnabled)
    }

    public getSavedContributors(): any[] {
      return this.storedContributors
    }

    public getStoredContributorsToggleValue(): boolean {
      return this.storedEnableContributorsToggle
    }

    public saveToLocalStorage() {
      if (!this.contributorSuitable()) {
        return
      }
      localStorage.setItem('UserDefinedContributors', JSON.stringify(this.storedContributors))
      this.closePanel()
    }

    public addToContributorList() {
      if (this.contributorSelection === '') {
        return
      }
      let contributors: ContributorScore[] = this.customContributors
      contributors.push({ contributor: this.contributorSelection, value: this.inputs.value })
      this.customContributors = contributors
      this.updateSavedContributors(this.customContributors)
      this.contributorOptions = this.getContributorOptions()
      this.contributorSelection = ''
    }

    public deleteItem(item: any) {
      const index = this.storedContributors.indexOf(item)
      if (index > -1) {
        this.storedContributors.splice(index, 1)
      }
      this.updateSavedContributors(this.storedContributors)
    }

    private getContributorOptions() {
      const customContributors = this.storedContributors.map((item) => item.contributor)
      const remainingList = contributorList.filter((item) => !customContributors.includes(item.value))
      return remainingList
    }

    private contributorSuitable() {
      const contributors: ContributorScore[] = this.customContributors
      for (const key in contributors) {
        if (contributors[key].value.toString() === '') {
          return false
        }
      }
      return true
    }
  }

  export default toNative(ContributorsPanel)
</script>

<style lang="scss" scoped>
  .message {
    width: 320px;
  }
</style>
