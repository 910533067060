<template>
  <v-select v-model="measurementValue" variant="underlined" :items="measurementItems" label="Measurement" />
  <v-switch
    v-model="useCumulative"
    class="mt-0"
    label="Use cumulative daily values"
    :disabled="!cumulativeMeasurementItems.includes(measurementValue)"
  />

  <TranslatableTitle
    v-if="!titleDisabled"
    comment="chart title"
    :data="data"
    :prefix="id"
    :items="templateTitles"
    :loading="loadingTitles"
    @change-title="data.title = $event"
  />

  <v-switch v-model="titleDisabled" label="Disable chart title" @click="disableTitle()" />

  <v-row dense>
    <v-col>
      <v-select v-model="data.presentation" variant="underlined" :items="presentationItems" label="Presentation" />
    </v-col>
    <v-col>
      <v-select v-model="data.mood" variant="underlined" :items="moodItems" label="Mood" />
    </v-col>
  </v-row>
  <v-row dense>
    <v-col>
      <v-select v-model="data.interval" variant="underlined" :items="intervalItems" label="Interval" />
    </v-col>
    <v-col>
      <v-text-field v-model.number="data.length" variant="underlined" type="number" label="Length" />
    </v-col>
    <v-col>
      <v-text-field v-model.number="data.offset" variant="underlined" type="number" label="Offset" />
    </v-col>
    <v-col>
      <v-select v-model="data.average_line" variant="underlined" :items="averageItems" label="Average line" />
    </v-col>
    <v-col>
      <v-select v-model="data.hide_x_axis" variant="underlined" :items="hideXAxisItems" label="Hide x axis" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { sortBy } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { cumulativeMeasurements, measurements } from '#views/messages/constants'

  import { createDefaultTranslatable } from '#views/messages/utilities'

  import { Template, Translatable, UIComponentChart } from '#types'

  @Component({})
  class Chart extends Vue {
    @Prop() public id!: string
    @Prop() public data!: UIComponentChart
    @Prop() public template!: Template | null | undefined

    public titleDisabled = false
    public loadingTitles = false

    public templateTitles: Translatable[] = []

    public title: Translatable | null | undefined = null

    private unsubscribe: Unsubscribe | undefined = undefined

    public get moodItems() {
      return sortBy(
        [
          { value: 'good', title: 'Good' },
          { value: 'steady', title: 'Steady' },
          { value: 'bad', title: 'Bad' },
        ],
        'title',
      )
    }

    public get hideXAxisItems() {
      return [
        { value: false, title: 'false' },
        { value: true, title: 'true' },
      ]
    }

    public get averageItems() {
      return sortBy(
        [
          { value: 'none', title: 'None' },
          { value: 'all', title: 'All' },
          { value: 'period', title: 'Period' },
        ],
        'title',
      )
    }

    public get intervalItems() {
      return sortBy(
        [
          { value: 'daily', title: 'Daily' },
          { value: 'weekly', title: 'Weekly' },
          { value: 'monthly', title: 'Monthly' },
        ],
        'title',
      )
    }

    public get measurementItems() {
      return measurements
    }

    public get presentationItems() {
      return sortBy(
        [
          { value: 'line', title: 'Line' },
          { value: 'bar', title: 'Bar' },
        ],
        'title',
      )
    }

    public get cumulativeMeasurementItems() {
      return cumulativeMeasurements
    }

    public get useCumulative() {
      return this.data.measurement.startsWith('daily_')
    }

    public set useCumulative(value: boolean) {
      this.data.measurement = (value ? 'daily_' : '') + this.measurementValue
    }

    public get measurementValue() {
      return this.data.measurement.replace(/daily_/g, '')
    }

    public set measurementValue(value: string) {
      this.data.measurement = value
    }

    public created() {
      this.title = this.data.title

      if (!this.title) {
        this.titleDisabled = true
      }

      if (!this.template) {
        this.loadingTitles = true

        this.unsubscribe = onSnapshot(query(collection(getFirestore(), `/templates`)), (snap) => {
          if (snap?.docs) {
            const templates = snap.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))

            this.loadingTitles = false

            this.templateTitles = templates
              .filter((t: any) => (t.allowedPages || []).includes('components'))
              .reduce(
                (titles: Translatable[], template: any) => [
                  ...titles,
                  ...(template.facets?.view?.components || [])
                    .map((c: any) => ({ ...c.title, templateId: template.id }))
                    .filter((t: any) => t?.translationId?.startsWith('template-component')),
                ],
                [],
              )
          }
        })
      }
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }
    }

    public disableTitle() {
      if (this.data.title) {
        delete this.data.title
      } else {
        this.data.title = createDefaultTranslatable(this.id, 'chart title')
      }
    }
  }

  export default toNative(Chart)
</script>
