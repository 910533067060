<template>
  <div class="d-flex flex-column flex-sm-row my-4">
    <v-tooltip top max-width="350px">
      <template #activator="{ props }">
        <div class="mt-2 ml-n4" v-bind="props">
          <v-icon class="mr-2">mdi-information-outline</v-icon>
          <span style="vertical-align: middle">What are {{ messageName }}s?</span>
        </div>
      </template>
      <span v-if="messageName === 'slide'">
        Slides are the actual views shown in the app. The slides are shown in this order by the application.
      </span>
      <span v-else>
        Messages are the actual views shown in the app. If there are several for insight the one to be shown is selected
        randomly.
      </span>
    </v-tooltip>

    <v-spacer />

    <v-btn
      prepend-icon="mdi-note-text-outline"
      class="mr-2"
      color="primary"
      :text="`Notes (${notesCount})`"
      @click.stop="openNotesDialog()"
    />

    <v-btn
      v-if="!disableEdit && (canUserEdit || isInsightsEditor)"
      prepend-icon="mdi-swap-horizontal"
      class="mr-2"
      color="primary"
      :text="`Re-order ${messageName}s`"
      @click.stop="openReorderMessageDialog()"
    />
    <v-btn
      v-if="!disableEdit && (canUserEdit || isInsightsEditor)"
      prepend-icon="mdi-plus"
      color="primary"
      class="mr-n4"
      :text="`Create new ${messageName}`"
      @click.stop="create()"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  @Component({})
  class MessageActions extends Vue {
    @Prop() public notesCount!: string
    @Prop() public messageName!: string
    @Prop() public disableEdit!: boolean

    private appStore = new AppStore()

    public get canUserEdit() {
      return this.appStore.canUserEdit
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    @Emit('openNotesDialog')
    public openNotesDialog() {
      return true
    }

    @Emit('openReorderMessageDialog')
    public openReorderMessageDialog() {
      return true
    }
    @Emit('create')
    public create() {
      return true
    }
  }

  export default toNative(MessageActions)
</script>
