<template>
  <div class="d-flex flex-column align-stretch justify-stretch" style="width: 100%">
    <v-hover>
      <template #default="{ isHovering, props }">
        <div v-bind="props" style="position: relative; height: 100%; width: 100%">
          <v-lazy transition="fade-transition" class="d-flex align-center" style="height: 100%">
            <v-img :src="imageUrl!" aspect-ratio="1" class="grey lighten-2">
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-row>
              </template>
            </v-img>
          </v-lazy>

          <div
            v-if="!!thumbUrl"
            style="
              position: absolute;
              bottom: 12px;
              right: 12px;
              outline: 2px solid rgba(255, 255, 255, 0.5);
              border: 2px solid rgba(0, 0, 0, 0.8);
              border-radius: 8px;
              min-width: 40px;
              min-height: 40px;
              background-color: white;
              z-index: 1;
              overflow: hidden;
            "
            :style="isHovering ? 'visibility: hidden' : ''"
          >
            <v-lazy transition="fade-transition" class="d-flex align-center">
              <v-img :src="thumbUrl" contain style="min-width: 40px; max-width: 40px; max-height: 40px" />
            </v-lazy>
          </div>
        </div>

        <div v-if="!noText" style="align-self-end text-transform: capitalize; min-height: 40px">
          {{ capitalize(slug.replace(`${category}-`, '').replace(/-/g, ' ')) }}
        </div>
      </template>
    </v-hover>
  </div>
</template>

<script lang="ts">
  import { capitalize } from 'lodash'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { MediaStore } from '#stores'

  @Component({})
  class ImageObject extends Vue {
    @Prop() public slug!: string
    @Prop() public urls!: string

    @Prop() public loading!: boolean
    @Prop() public category!: string

    @Prop({ type: Boolean }) public noText!: boolean

    public imageUrl: string | null = null
    public thumbUrl: string | null = null

    public readonly capitalize = capitalize

    private readonly mediaStore = new MediaStore()

    @Watch('slug')
    protected onSlugChanged() {
      this.getUrl()
    }

    @Watch('loading')
    protected onLoadingChanged() {
      this.getUrl()
    }

    public mounted() {
      this.getUrl()
    }

    public async getUrl() {
      if (this.urls) {
        this.imageUrl = this.urls[0] || null
        this.thumbUrl = this.urls[1] || null
      } else {
        const response = await this.mediaStore.getMediaInfo({ slug: this.slug, type: 'image' })

        const variants = (response?.data?.info?.variants || []).reverse()

        const imageIdx = variants.findIndex((v: any) => v.info.rendition === 'original')
        const thumbIdx = variants.findIndex((v: any) => v.info.rendition === 'thumbnail')

        this.imageUrl = variants[imageIdx]?.media_file?.url || null
        this.thumbUrl = variants[thumbIdx]?.media_file?.url || null
      }
    }
  }

  export default toNative(ImageObject)
</script>
