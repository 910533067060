<template>
  <div>
    <v-icon color="white" size="x-large">mdi-toggle-switch-off-outline</v-icon>
    <span>{{ getSettingTitleForKey(component.setting) }}</span>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { settingItems } from '#views/messages/constants'

  import { UIComponentSetting } from '#types'

  @Component({})
  class SettingPreview extends Vue {
    @Prop() public component!: UIComponentSetting

    public getSettingTitleForKey(key: string) {
      const settingItem = settingItems.find((item) => item.value === key)

      return settingItem?.title
    }
  }

  export default toNative(SettingPreview)
</script>
