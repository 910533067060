<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="diff-container" v-html="htmlDiff" />
</template>

<script lang="ts">
  import * as Diff2Html from 'diff2html'
  import { ColorSchemeType } from 'diff2html/lib/types'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Prop, Setup, Vue, toNative } from 'vue-facing-decorator'

  @Component({})
  class DiffView extends Vue {
    @Prop() private diff!: string

    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    public get htmlDiff() {
      const colorScheme = this.theme?.global?.current?.value?.dark ? ColorSchemeType.DARK : ColorSchemeType.LIGHT

      return Diff2Html.html(this.diff || '', { drawFileList: false, colorScheme: colorScheme })
    }
  }

  export default toNative(DiffView)
</script>

<style lang="scss" scoped>
  .diff-container {
    /** This fixes an issue where the line numbers of diffs would scroll. */
    position: relative;
  }
</style>
