<template>
  <v-app-bar :title="'Rollouts / Content (' + otaRollouts.length + ')'">
    <v-spacer />

    <v-tooltip text="Re-trigger GitHub action">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-restart-alert" :disabled="isReleasing || !project" @click="reTriggerGithub()" />
      </template>
    </v-tooltip>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">OTA rollouts allow safe release of new contents and insights</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isOTAContentAdmin">
            Your content admin rights allow you to create new rollouts and elevate existing rollouts
          </template>
          <template v-else>You can only view ota rollouts, apply for content admin rights in the IT portal</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          color="primary"
          text="Create new release"
          :disabled="!project || !isOTAContentAdmin"
          @click="release = 'confirm'"
        />
      </v-col>
    </v-row>

    <v-alert v-if="project?.state === 'Blocked'" type="warning" class="my-8">
      <v-row align="center">
        <v-col class="grow">
          <p>{{ `Project status: ${project.state} - ${project.status}` }}</p>
        </v-col>
      </v-row>
    </v-alert>

    <OtaRollouts />
  </v-container>

  <ChangesDialog v-if="release === 'confirm'" @cancel="release = ''" @confirm="releaseChanges($event)" />

  <ReleaseDialog
    v-if="release === 'creating'"
    :release-content="changes"
    @cancel="release = ''"
    @released="monitorRelease()"
  />
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore, ProjectsStore, RolloutsStore } from '#stores'

  @Component({})
  class RolloutsView extends Vue {
    public release = ''

    public changes = {}

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly rolloutsStore = new RolloutsStore()

    public get actions() {
      return this.rolloutsStore.actions
    }

    public get project() {
      return this.projectsStore.project
    }

    public get projects() {
      return this.projectsStore.projects
    }

    public get isReleasing() {
      return this.rolloutsStore.releasing
    }

    public get otaRollouts() {
      return this.rolloutsStore.rollouts
    }

    public get isOTAContentAdmin() {
      return this.appStore.isOTAContentAdmin
    }

    public created() {
      this.rolloutsStore.listOtaRollouts()
    }

    public monitorRelease() {
      this.release = ''

      this.rolloutsStore.releasing = true
    }

    public releaseChanges(changes: any) {
      this.changes = changes
      this.release = 'creating'
    }

    public async reTriggerGithub() {
      const actions = await this.rolloutsStore.checkGitHubStatuses()

      if (!actions.length) {
        this.rolloutsStore.releasing = true

        await this.rolloutsStore.experimentalOtaRollout({
          project: this.project?.id,
        })
      }
    }
  }

  export default toNative(RolloutsView)
</script>
