<template>
  <v-alert v-if="!!feature && getContextList(feature)[0] === MetadataContext.UNAUTHENTICATED_DEVICE" color="error">
    The feature below is targeting unauthenticated users and therefore does not support all criterias!
  </v-alert>

  <v-row>
    <v-col class="py-0 pt-4" style="position: relative">
      <v-text-field
        v-if="!!feature && !!override"
        readonly
        label="Flag"
        :model-value="`${getProjectKey(feature)}/${feature.metadata?.name}`.toUpperCase()"
      />
      <v-autocomplete v-else label="Flag" :items="selectableFeatures" @update:model-value="emitSelect($event)" />
    </v-col>

    <v-col v-if="!!references" style="max-width: 24px; padding: 0" class="px-5 pt-8">
      <v-tooltip top>
        <template #activator="{ props }">
          <v-btn
            class="mt-n3 ml-n7"
            color="grey-darken-2"
            icon="mdi-delete"
            v-bind="props"
            :disabled="
              !!override?.metadata?.informative?.labels?.rollout ||
              !!override?.metadata?.informative?.additionalData?.createdAt
            "
            @click="emitDelete()"
          />
        </template>
        <span style="white-space: nowrap">Delete feature override</span>
      </v-tooltip>
    </v-col>
  </v-row>

  <v-alert
    v-if="feature?.parameters?.slice(!!override?.metadata?.informative?.labels?.rollout ? 1 : 0).length === 0"
    color="info"
  >
    Flag does not have any extra parameters to override
  </v-alert>

  <template v-if="feature && override">
    <v-row
      v-for="parameter in feature?.parameters
        ?.slice(!!override.metadata?.informative?.labels?.rollout ? 1 : 0)
        .filter((p) => !p.deprecated) ?? []"
      :key="parameter.name"
      class="my-0 mb-n3"
    >
      <v-col class="px-0 ml-10 py-0">
        <v-text-field v-model="parameter.name" density="compact" label="Parameter" readonly />
      </v-col>
      <v-col class="py-0">
        <v-select
          v-if="parameter.type?.oneOf?.$case === 'enum'"
          density="compact"
          :model-value="getAndAddParamValue(parameter)"
          :label="isDefaultValue(parameter) ? 'Value (default)' : 'Value (changed)'"
          :items="parameter.type.oneOf?.enum?.values"
          @update:model-value="transformParamValue(parameter, $event)"
        />
        <v-text-field
          v-else-if="parameter.type?.oneOf?.$case === 'boolean'"
          readonly
          density="compact"
          :model-value="getAndAddParamValue(parameter)"
          :label="isDefaultValue(parameter) ? 'Value (default)' : 'Value (changed)'"
          @click="transformParamValue(parameter, !(override.parameters as any)[parameter.name].oneOf.boolean)"
        />
        <v-text-field
          v-else-if="parameter.type?.oneOf?.$case === 'duration'"
          type="number"
          density="compact"
          :model-value="getAndAddParamValue(parameter)"
          :label="isDefaultValue(parameter) ? 'Value (default)' : 'Value (changed)'"
          @update:model-value="transformParamValue(parameter, $event)"
        />
        <v-text-field
          v-else-if="parameter.type?.oneOf?.$case === 'fixedPoint'"
          density="compact"
          :model-value="fractionNumbers[parameter.name] || getAndAddParamValue(parameter)"
          :label="isDefaultValue(parameter) ? 'Value (default)' : 'Value (changed)'"
          @update:model-value="fractionNumbers[parameter.name] = $event"
          @update:focused="$event ? null : transformParamValue(parameter, fractionNumbers[parameter.name])"
        />
        <v-text-field
          v-else
          density="compact"
          :model-value="getAndAddParamValue(parameter)"
          :label="isDefaultValue(parameter) ? 'Value (default)' : 'Value (changed)'"
          @update:model-value="transformParamValue(parameter, $event)"
        />
      </v-col>
    </v-row>
  </template>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Context } from '@jouzen/control-api/metadata'

  import { fixedPointToString, stringToFixedPoint } from '#views/features/utilities'
  import { getProjectKey } from '#views/projects/utilities'

  import { FeaturesStore } from '#stores'

  import { Feature, Override, Reference } from '#types'

  @Component({})
  class RolloutParams extends Vue {
    @Prop() public feature!: Feature
    @Prop() public override!: Override

    @Prop() public references!: Reference[]

    public fractionNumbers: any = {}

    public readonly MetadataContext = Context

    public readonly getProjectKey = getProjectKey

    private readonly featuresStore = new FeaturesStore()

    public get selectableFeatures() {
      console.log(this.references, this.feature)
      const features = this.featuresStore.features
        .filter(
          (f) =>
            (f.metadata?.name !== this.feature?.metadata?.name || getProjectKey(f) !== getProjectKey(this.feature)) &&
            !this.references.find(
              (r) => r.feature?.metadata?.name === f.metadata?.name && getProjectKey(r.feature) === getProjectKey(f),
            ) &&
            !(
              (f.metadata?.contextSpec?.oneOf?.$case === 'contexts' &&
                f.metadata?.contextSpec?.oneOf?.contexts?.contexts) ||
              []
            ).includes(Context.UNAUTHENTICATED_DEVICE),
        )
        .map((f) => ({
          title: (getProjectKey(f) + '/' + f.metadata?.name).toUpperCase(),
          value: getProjectKey(f) + '/' + f.metadata?.name,
        }))

      const ownFeatures: any[] = features
        .filter((f) => f.value.startsWith(getProjectKey(this.feature) + '/'))
        .sort((a, b) => (a.title < b.title ? -1 : 1))

      const otherFeatures: any[] = features
        .filter((f) => !f.value.startsWith(getProjectKey(this.feature) + '/'))
        .sort((a, b) => (a.title < b.title ? -1 : 1))

      return ([] as any[]).concat(
        { value: 'features-in-the-project', title: 'Features in the project', props: { disabled: true } },
        ownFeatures,
        { value: 'features-in-other-projects', title: 'Features in other projects', props: { disabled: true } },
        otherFeatures,
      )
    }

    @Emit('delete')
    public emitDelete() {
      return
    }

    @Emit('select')
    public emitSelect(id: string) {
      return id
    }

    public getContextList(feature: Feature) {
      return feature?.metadata?.contextSpec?.oneOf?.$case === 'contexts'
        ? feature.metadata.contextSpec.oneOf[feature.metadata.contextSpec.oneOf!.$case]?.contexts || []
        : []
    }

    public isDefaultValue(parameter: any) {
      const override = (this.override.parameters as any)[parameter.name]

      return (
        !override ||
        parameter.type.oneOf[parameter.type.oneOf.$case].defaultValue === override.oneOf[override.oneOf.$case]
      )
    }

    public getAndAddParamValue(parameter: any) {
      const overrideParam = (this.override.parameters as any)[parameter.name]

      if (!overrideParam) {
        this.override.parameters[parameter.name] = {
          oneOf: {
            $case: parameter.type!.oneOf!.$case,
            [parameter.type!.oneOf!.$case]: (parameter.type!.oneOf! as any)[parameter.type!.oneOf!.$case].defaultValue,
          } as any,
        }
      }

      const type = this.override.parameters[parameter.name].oneOf!.$case

      const value = (this.override.parameters[parameter.name].oneOf as any)[type]

      switch (type) {
        case 'duration':
          return value.seconds
        case 'fixedPoint':
          return fixedPointToString(value)

        default:
          return value
      }
    }

    public transformParamValue(parameter: any, value: any) {
      const overrideParam = (this.override.parameters as any)[parameter.name]

      switch (overrideParam.oneOf.$case) {
        case 'boolean':
          overrideParam.oneOf[overrideParam.oneOf.$case] = value === 'true' ? true : value === 'false' ? false : value
          break
        case 'integer':
          overrideParam.oneOf[overrideParam.oneOf.$case] = parseInt(value) == value ? parseInt(value) : value
          break
        case 'duration':
          overrideParam.oneOf[overrideParam.oneOf.$case] = { seconds: value, nanos: 0 }
          break
        case 'fixedPoint': {
          overrideParam.oneOf[overrideParam.oneOf.$case] = stringToFixedPoint(value, 6, true)

          delete this.fractionNumbers[parameter.name]
          break
        }
        default:
          overrideParam.oneOf[overrideParam.oneOf.$case] = value
      }
    }
  }

  export default toNative(RolloutParams)
</script>
