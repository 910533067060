<template>
  <v-row align="center" style="border-bottom: 1px solid #eee">
    <v-col style="text-transform: capitalize" cols="6">
      <span>{{ content.title.text }}</span>
    </v-col>

    <v-col align="right">
      <audio v-if="preview" ref="audioPlayerRef" controls>
        <source :src="src || ''" :type="contentType || ''" />
      </audio>

      <span v-else class="caption">{{ slug }}</span>
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { MediaStore } from '#stores'

  @Component({})
  class AudioObject extends Vue {
    @Prop() public slug!: string
    @Prop() public content!: any
    @Prop() public preview!: boolean
    @Prop() public category!: string
    @Prop() public rendition!: string

    @Prop() public noText!: boolean

    public declare $refs: {
      audioPlayerRef: HTMLAudioElement
    }
    public loaded = false

    public src: string | null = null
    public contentType: string | null = null

    private mediaStore = new MediaStore()

    @Watch('preview')
    protected onPreviewChanged() {
      if (this.$refs.audioPlayerRef) {
        this.$refs.audioPlayerRef!.load()
      }
    }

    @Watch('slug')
    protected onSlugChanged() {
      this.getUrl()
    }

    @Watch('loaded')
    protected onLoadedChanged() {
      setTimeout(() => {
        this.$refs.audioPlayerRef!.load()
      }, 10)
    }

    public mounted() {
      this.getUrl()
    }

    private async getUrl() {
      const response = await this.mediaStore.getMediaInfo({ slug: this.slug, type: 'audio' })

      const variants = response?.data?.info?.variants || []

      this.src = variants[0]?.media_file?.url
      this.contentType = variants[0]?.media_file?.content_type
    }
  }

  export default toNative(AudioObject)
</script>
