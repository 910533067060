import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

import { Project } from '#types'

let unsubscribeProjects: Unsubscribe | undefined = undefined

@Store
export class ProjectsStore extends Pinia {
  public project: any = null

  public projects: any[] = []

  public async setProject(project: Project | null) {
    this.project = project

    localStorage.OuraProject = project?.id || ''
  }

  public async updateProject(project: Project) {
    const updateKey = project.createdAt ? 'updatedAt' : 'createdAt'

    const updatedProject = {
      ...project,
      [updateKey]: serverTimestamp(),
    }
    await setDoc(doc(getFirestore(), `/projects/${project.id}`), updatedProject, { merge: true })
  }

  public async resetChangesAtTimestamp(project: any) {
    const change = {
      changesAt: project?.releasedAt || project?.createdAt || '',
    }
    await updateDoc(doc(getFirestore(), `/projects/${project.id}`), change)
  }

  public async subscribeToProjects() {
    if (!unsubscribeProjects) {
      unsubscribeProjects = onSnapshot(query(collection(getFirestore(), '/projects')), (snap) => {
        const projects: any = sortBy(
          snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })),
          [(o: any) => o.name || ''],
        )

        const project = projects.find((p: Project) => p.id === localStorage.OuraProject)

        if (project) {
          this.project = project
        }

        this.projects = projects
      })
    }
  }
  public async unsubscribeFromProjects() {
    this.project = null

    this.projects = []

    if (unsubscribeProjects) {
      unsubscribeProjects()

      unsubscribeProjects = undefined
    }
  }
}
