<template>
  <div>
    <div class="d-flex">
      <span>Option #{{ index + 1 }}</span>
      <v-spacer />
      <v-btn icon="mdi-delete" @click="deleteOption()" />
    </div>
    <v-text-field v-model="data.mnemonic" label="Mnemonic" />
    <Translatable :data="data.name">
      <v-text-field v-model="data.name.text" spellcheck="true" lang="en" label="Name" />
    </Translatable>
    <Statement v-if="!inWorkspace" v-model="data.condition" simplify />
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  import { UIComponentQuestionnaireOption } from '#types'

  @Component({})
  class Questionnaire extends Vue {
    @Prop() public data!: UIComponentQuestionnaireOption
    @Prop() public index!: number

    @Emit('delete')
    public deleteOption() {
      return this.index
    }

    private appStore = new AppStore()

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }
  }

  export default toNative(Questionnaire)
</script>
