import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

@Store
export class LabelsStore extends Pinia {
  public loading = false
  public waiting = false

  public env = ''
  public label = ''

  public cache: any = {}

  public users: any[] = []
  public labels: any[] = []

  public invalidUsers: any[] = []

  public get hasInvalidUsers() {
    return this.invalidUsers.length > 0
  }

  public resetLabelUsers() {
    this.env = ''
    this.label = ''

    this.cache = {}

    this.users = []
  }

  public clearInvalidUsers() {
    this.invalidUsers = []
  }

  /*
   * Load user labels for current environment into the store
   */
  public async loadLabels() {
    this.labels = []

    this.loading = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'listAllLabelsUsersViaOutoApi')({})

    this.labels = response?.data?.labels || []

    this.loading = false
  }

  /*
   * List and return label users from all / given environments
   */
  public async listLabelUsers(env: string, label: string) {
    this.waiting = true

    let users: any[] = []

    const cloudEnv = import.meta.env.VITE_CLOUD_ENV

    const functions = getFunctions(getApp(), 'europe-west1')

    const cloudEnvs = ['test', 'stage', 'prod'].splice(0, ['test', 'stage', 'prod'].indexOf(cloudEnv) + 1)

    for (const e of env ? [cloudEnv === 'dev' ? 'dev' : env] : cloudEnvs.length ? cloudEnvs : [cloudEnv]) {
      try {
        const response: any = await httpsCallable(functions, 'listAllLabelsUsersViaOutoApi')({ env: e, label })

        users = users.concat((response?.data?.users || []).map((u: any) => ({ ...u, env: e })))
      } catch (_error) {
        // Error can happen if label has not been created yet
      }
    }

    users = sortBy(users || [], (o) => o.createdAt).reverse()

    this.waiting = false

    return users
  }

  /*
   * Load label users from the given environment into the store
   */
  public async loadLabelUsers(env: string, label: string) {
    if (label !== this.label) {
      this.cache = {}
    }

    env = import.meta.env.VITE_CLOUD_ENV === 'dev' ? 'dev' : env

    if (this.cache[env] && this.label && label === this.label) {
      this.env = env

      this.users = this.cache[env]
    } else {
      this.users = []

      this.env = env
      this.label = label

      if (label) {
        this.loading = true

        await this.loadEnvLabelUsers(env, label)

        this.loading = false
      }

      return this.users
    }
  }

  /*
   * Save label users for all environments from the store cache
   */
  public async saveLabelUsers(env: string, label: string) {
    this.loading = true

    this.invalidUsers = []

    if (env === 'all' && this.label && label === this.label) {
      for (const [env, users] of Object.entries(this.cache)) {
        await this.saveEnvLabelUsers(
          env,
          label,
          (users as any[]).map((u) => u.userUid),
        )
      }
    } else if (env === this.env && this.label && label === this.label) {
      env = import.meta.env.VITE_CLOUD_ENV === 'dev' ? 'dev' : env

      await this.saveEnvLabelUsers(
        env,
        label,
        this.users.map((u) => u.userUid),
      )
    }

    this.loading = false

    this.loadLabels()
  }

  /*
   * Convert emails to uids and check that the accounts exists
   */
  public async convertEmailsToUids(env: string, emails: string[]) {
    this.waiting = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'fetchUidsForEmailsViaOutoApi')({ env, emails })

    this.waiting = false

    return response?.data?.uids || []
  }

  /*
   * Helper label users loading function with caching support
   */
  private async loadEnvLabelUsers(env: string, label: string) {
    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'listAllLabelsUsersViaOutoApi')({ env, label })

    if (label === this.label) {
      this.cache[env] = sortBy(response?.data?.users || [], (o) => o.createdAt).reverse()

      if (env === this.env) {
        this.users = this.cache[env]
      }
    }
  }

  /*
   * Helper label users storing function with caching support
   */
  private async saveEnvLabelUsers(env: string, label: string, users: string[]) {
    const response: any = await httpsCallable(
      getFunctions(getApp(), 'europe-west1'),
      'modifyLabelUsersViaOutoApi',
    )({ env, label, users })

    if (response?.data?.users) {
      this.invalidUsers = this.invalidUsers.concat(
        response.data.users.filter((user: any) => user.status === 'not_found').map((user: any) => user.userUid),
      )
    }

    await this.loadEnvLabelUsers(env, label)

    return response?.data?.users || []
  }
}
