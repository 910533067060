<template>
  <div class="pa-2">
    <div class="pb-1 body-2">{{ component.title.text }}</div>

    <span color="gray-lighten-1">Chart</span>

    <v-sparkline type="trend" color="white" :model-value="mockChartData" :padding="2" :line-width="3" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentSleepStages } from '#types'

  @Component({})
  class SleepStagesPreview extends Vue {
    @Prop() public name!: string
    @Prop() public component!: UIComponentSleepStages

    public get mockChartData() {
      return [3, 3, 0, 0, 0, 2, 2, 3, 3]
    }
  }

  export default toNative(SleepStagesPreview)
</script>
