import { JobCreateParameters, KeyCreateParameters, Translation } from 'phrase-js'

/**
 * Data to send to the cloud function that will get translations from the given project for keys it finds with the provided query
 * See possible query parmeters at https://developers.phrase.com/api/#get-/projects/-project_id-/keys
 */
export type GetTranslations = {
  project: PhraseProjects
  keysQuery: string
}

/**
 * Data to be sent to the cloud function that will create a new translation job to the given project
 */
export type NewTranslationJob = {
  project: PhraseProjects
  data: JobCreateParameters
}

/**
 * Data to be sent to the cloud function that will create a new translation key to the given project
 */
export type NewTranslationKey = {
  project: PhraseProjects
  data: KeyCreateParameters
}

/**
 * Translation template returned from the cloud function that fetches release note templates from Phrase
 */
export type TranslationKeyTemplate = Translation & { keyName: string }

export enum PhraseProjects {
  RELEASE = 'release',
  CONTENTS = 'contents',
  INSIGHTS = 'insights',
  TIPTHEMES = 'tipthemes',
  SLIDESHOWS = 'slideshows',
}
