import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

export const cloudFunction: <T>(functionName: string, data: any) => Promise<T | undefined> = async (
  functionName: string,
  data: any,
) => {
  try {
    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, functionName)(data)

    return response.data
  } catch (_error) {
    return undefined
  }
}
