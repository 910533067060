<template>
  <div class="px-2">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="white--text" v-html="component.contents.text" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentRichText } from '#types'

  @Component({})
  class RichTextPreview extends Vue {
    @Prop() public name!: string
    @Prop() public component!: UIComponentRichText
  }

  export default toNative(RichTextPreview)
</script>

<style lang="scss">
  ul,
  ol {
    text-align: left;
  }

  // Scoped styles don't work with the editor classes.
  .white--text {
    p {
      margin-bottom: 16px;
    }

    node-heading,
    node-title-m {
      color: white;
      font-size: 1.35em;
      font-weight: bold;
    }
    node-heading-secondary,
    node-title-s {
      color: white;
      font-size: 1;
      font-weight: bold;
    }
    node-body,
    node-paragraph-m {
      color: white;
      font-size: 1em;
      line-height: 150%;
    }
    node-body-small,
    node-paragraph-s {
      color: white;
      font-size: 0.75em;
      line-height: 150%;
    }
    node-subtitle,
    node-label-xs {
      color: white;
      font-size: 0.75em;
      line-height: 115%;
    }
    node-label-m {
      color: white;
      font-size: 0.9em;
      line-height: 115%;
    }
    node-label-l {
      color: white;
      font-size: 1em;
      line-height: 115%;
    }
    node-headline-s {
      color: white;
      font-size: 1.55em;
      font-weight: 300;
      line-height: 115%;
    }
    node-headline-m {
      color: white;
      font-size: 2em;
      font-weight: 400;
      line-height: 115%;
    }
  }
</style>
