import { sortBy } from 'lodash-es'

import ActivityIntensity from '#views/messages/components/ActivityIntensity.vue'
import ActivityIntensityPreview from '#views/messages/components/ActivityIntensityPreview.vue'
import BadgeReward from '#views/messages/components/BadgeReward.vue'
import BadgeRewardPreview from '#views/messages/components/BadgeRewardPreview.vue'
import Button from '#views/messages/components/Button.vue'
import ButtonPreview from '#views/messages/components/ButtonPreview.vue'
import Chart from '#views/messages/components/Chart.vue'
import ChartPreview from '#views/messages/components/ChartPreview.vue'
import ContentRecommendation from '#views/messages/components/ContentRecommendation.vue'
import ContentRecommendationPreview from '#views/messages/components/ContentRecommendationPreview.vue'
import Contributor from '#views/messages/components/Contributor.vue'
import ContributorPreview from '#views/messages/components/ContributorPreview.vue'
import Delta from '#views/messages/components/Delta.vue'
import DeltaPreview from '#views/messages/components/DeltaPreview.vue'
import Hint from '#views/messages/components/Hint.vue'
import HintPreview from '#views/messages/components/HintPreview.vue'
import IdealBedtimeChart from '#views/messages/components/IdealBedtimeChart.vue'
import IdealBedtimeChartPreview from '#views/messages/components/IdealBedtimeChartPreview.vue'
import Label from '#views/messages/components/Label.vue'
import MeasurementStack from '#views/messages/components/MeasurementStack.vue'
import NightTimeHeartRate from '#views/messages/components/NightTimeHeartRate.vue'
import NightTimeHeartRatePreview from '#views/messages/components/NightTimeHeartRatePreview.vue'
import NightTimeHeartRateVariability from '#views/messages/components/NightTimeHeartRateVariability.vue'
import NightTimeHeartRateVariabilityPreview from '#views/messages/components/NightTimeHeartRateVariabilityPreview.vue'
import Paragraph from '#views/messages/components/Paragraph.vue'
import ParagraphPreview from '#views/messages/components/ParagraphPreview.vue'
import Picture from '#views/messages/components/Picture.vue'
import PicturePreview from '#views/messages/components/PicturePreview.vue'
import PreviewComponent from '#views/messages/components/PreviewComponent.vue'
import Questionnaire from '#views/messages/components/Questionnaire.vue'
import QuestionnairePreview from '#views/messages/components/QuestionnairePreview.vue'
import RichText from '#views/messages/components/RichText.vue'
import RichTextPreview from '#views/messages/components/RichTextPreview.vue'
import Score from '#views/messages/components/Score.vue'
import ScorePreview from '#views/messages/components/ScorePreview.vue'
import Setting from '#views/messages/components/Setting.vue'
import SettingPreview from '#views/messages/components/SettingPreview.vue'
import SleepClock from '#views/messages/components/SleepClock.vue'
import SleepClockPreview from '#views/messages/components/SleepClockPreview.vue'
import SleepStages from '#views/messages/components/SleepStages.vue'
import SleepStagesPreview from '#views/messages/components/SleepStagesPreview.vue'
import Title from '#views/messages/components/Title.vue'
import TitlePreview from '#views/messages/components/TitlePreview.vue'
import HomeFacet from '#views/messages/facets/HomeFacet.vue'
import NotificationFacet from '#views/messages/facets/NotificationFacet.vue'
import ViewFacet from '#views/messages/facets/ViewFacet.vue'

import { ComponentMapping, SelectType, UIComponentType } from '#types'

export const mediaTypes = [
  { title: 'Video', value: 'video' },
  { title: 'Slideshow', value: 'slideshow' },
]

export const messageHomeFacetActions = sortBy([
  { value: 'cta_primary', title: 'Primary' },
  { value: 'cta_secondary', title: 'Secondary' },
])

export const messageHomeFacetDisplayStyles = sortBy([
  { value: 'inferred', title: 'Inferred' },
  { value: 'action_card', title: 'Action card' },
])

export const messageIcons = sortBy(
  [
    { value: 'telephone', title: 'Telephone' },
    { value: 'message', title: 'Message' },
    { value: 'email', title: 'Email' },
  ],
  'title',
)

export const messageBackgroundImages = sortBy(
  [
    { value: 'activity', title: 'Activity' },
    { value: 'activity_blurred', title: 'Activity (Blurred)' },
    { value: 'bedtime', title: 'Bedtime' },
    { value: 'bedtime_blurred', title: 'Bedtime (Blurred)' },
    { value: 'hrm_guidance', title: 'HRM Guidance' },
    { value: 'readiness_excellent', title: 'Readiness: Excellent' },
    {
      value: 'readiness_excellent_blurred',
      title: 'Readiness: Excellent (Blurred)',
    },
    { value: 'readiness_good', title: 'Readiness: Good' },
    { value: 'readiness_good_blurred', title: 'Readiness: Good (Blurred)' },
    { value: 'readiness_good_2', title: 'Readiness: Good #2' },
    { value: 'readiness_good_2_blurred', title: 'Readiness: Good #2 (Blurred)' },
    { value: 'readiness_pay_attention', title: 'Readiness: Pay Attention' },
    {
      value: 'readiness_pay_attention_blurred',
      title: 'Readiness: Pay Attention (Blurred)',
    },
    { value: 'readiness_pay_attention_2', title: 'Readiness: Pay Attention #2' },
    {
      value: 'readiness_pay_attention_2_blurred',
      title: 'Readiness: Pay Attention #2 (Blurred)',
    },
    { value: 'sleep_excellent', title: 'Sleep: Excellent' },
    { value: 'sleep_excellent_blurred', title: 'Sleep: Excellent (Blurred)' },
    { value: 'sleep_good', title: 'Sleep: Good' },
    { value: 'sleep_good_blurred', title: 'Sleep: Good (Blurred)' },
    { value: 'sleep_pay_attention', title: 'Sleep: Pay Attention' },
    {
      value: 'sleep_pay_attention_blurred',
      title: 'Sleep: Pay Attention (Blurred)',
    },
    { value: 'snippet', title: 'Snippet' },
    { value: 'snippet_blurred', title: 'Snippet (Blurred)' },
    { value: 'snippet_introducing_oura', title: 'Snippet: Introducing Oura' },
    {
      value: 'snippet_introducing_moment',
      title: 'Snippet: Introducing Moment',
    },
    { value: 'snippet_weekly_report', title: 'Snippet: Weekly Report' },
    { value: 'snippet_stress_slideshow', title: 'Snippet: Stress Slideshow' },
    { value: 'snippet_light_sleep_slideshow', title: 'Snippet: Light Sleep Slideshow' },
    {
      value: 'ring_hero_gen2_balance_silver',
      title: 'Ring Hero (Gen2: Balance Silver)',
    },
    {
      value: 'ring_hero_gen3_heritage_silver',
      title: 'Ring Hero (Gen3: Heritage Silver)',
    },

    {
      value: 'valentines_default',
      title: 'Valentines default',
    },
    {
      value: 'valentines_temp_elevated',
      title: 'Valentines temp elevated',
    },
    {
      value: 'valentines_rhr_elevated',
      title: 'Valentines RHS elevated',
    },

    {
      value: 'april_fools_optimal',
      title: 'April fools optimal',
    },
    {
      value: 'april_fools_good',
      title: 'April fools good',
    },
    {
      value: 'april_fools_bad',
      title: 'April fools bad',
    },
  ],
  'title',
)

export const messageBackgroundAnimations = sortBy(
  [
    {
      title: 'Superpower Main Cover',
      value: 'superpower_main_cover',
    },
    {
      title: 'Superpower Most Improved',
      value: 'superpower_most_improved',
    },
    {
      title: 'Superpower Kryptonite',
      value: 'superpower_kryptonite',
    },
    {
      title: 'Superpower Superpowers',
      value: 'superpower_superpowers',
    },
    {
      title: 'Superpower Total Sleep',
      value: 'superpower_total_sleep',
    },
    {
      title: 'Superpower Summary Cover',
      value: 'superpower_summary_cover',
    },
  ],
  'title',
)

export const measurements = sortBy(
  [
    { value: 'sleep.score', title: 'Sleep: Score' },
    { value: 'sleep.hr_min', title: 'Sleep: Heart Rate (Min)' },
    { value: 'sleep.hr_max', title: 'Sleep: Heart Rate (Max)' },
    { value: 'sleep.hr_avg', title: 'Sleep: Heart Rate (Avg)' },
    { value: 'sleep.total', title: 'Sleep: Total' },
    { value: 'sleep.awake', title: 'Sleep: Awake' },
    { value: 'sleep.deep', title: 'Sleep: Deep' },
    { value: 'sleep.rem', title: 'Sleep: REM' },
    { value: 'sleep.light', title: 'Sleep: Light' },
    { value: 'sleep.phases', title: 'Sleep: Phases' },
    { value: 'sleep.time_in_bed', title: 'Sleep: Time In Bed' },
    { value: 'sleep.bedtime_start_offset', title: 'Sleep: Bedtime Start' },
    { value: 'sleep.bedtime_end_offset', title: 'Sleep: Bedtime End' },
    {
      value: 'sleep.bedtime_end_time_zone',
      title: 'Sleep: Bedtime End Time Zone',
    },
    { value: 'sleep.efficiency', title: 'Sleep: Efficiency' },

    {
      value: 'sleep.contributors.total_sleep',
      title: 'Sleep Contributor: Total Sleep',
    },
    {
      value: 'sleep.contributors.efficiency',
      title: 'Sleep Contributor: Efficiency',
    },
    {
      value: 'sleep.contributors.restfulness',
      title: 'Sleep Contributor: Restfulness',
    },
    {
      value: 'sleep.contributors.rem_sleep',
      title: 'Sleep Contributor: REM Sleep',
    },
    {
      value: 'sleep.contributors.deep_sleep',
      title: 'Sleep Contributor: Deep Sleep',
    },
    { value: 'sleep.contributors.latency', title: 'Sleep Contributor: Latency' },
    { value: 'sleep.contributors.timing', title: 'Sleep Contributor: Timing' },

    { value: 'activity.score', title: 'Activity: Score' },
    { value: 'activity.cal_total', title: 'Activity: Calories (Total)' },
    { value: 'activity.cal_active', title: 'Activity: Calories (Active)' },
    { value: 'activity.cal_target', title: 'Activity: Calories (Target)' },
    { value: 'activity.steps', title: 'Activity: Steps' },
    { value: 'activity.goal_completion', title: 'Activity: Goal Completion' },
    { value: 'activity.inactive', title: 'Activity: Inactive Time' },
    { value: 'activity.low', title: 'Activity: Low' },
    { value: 'activity.medium', title: 'Activity: Medium' },
    { value: 'activity.high', title: 'Activity: High' },
    {
      value: 'activity.inactivity_alerts',
      title: 'Activity: Inactivity Alerts',
    },
    { value: 'activity.target_m', title: 'Activity: Target (Kilometers)' },
    {
      value: 'activity.walk_eq_m',
      title: 'Activity: Walking Equivalency (Kilometers)',
    },

    {
      value: 'activity.contributors.stay_active',
      title: 'Activity Contributor: Stay Active',
    },
    {
      value: 'activity.contributors.move_every_hour',
      title: 'Activity Contributor: Move Every Hour',
    },
    {
      value: 'activity.contributors.meet_daily_targets',
      title: 'Activity Contributor: Meet Daily Targets',
    },
    {
      value: 'activity.contributors.training_frequency',
      title: 'Activity Contributor: Training Frequency',
    },
    {
      value: 'activity.contributors.training_volume',
      title: 'Activity Contributor: Training Volume',
    },
    {
      value: 'activity.contributors.recovery_time',
      title: 'Activity Contributor: Recovery Time',
    },

    { value: 'readiness.score', title: 'Readiness: Score' },
    {
      value: 'readiness.body_temperature',
      title: 'Readiness: Body Temperature',
    },
    {
      value: 'readiness.body_temperature_trend',
      title: 'Readiness: Body Temperature Trend',
    },
    { value: 'readiness.hrv_min', title: 'Readiness: HRV (Min)' },
    { value: 'readiness.hrv_max', title: 'Readiness: HRV (Max)' },
    { value: 'readiness.hrv_avg', title: 'Readiness: HRV (Avg)' },
    {
      value: 'readiness.respiratory_rate',
      title: 'Readiness: Respiratory Rate',
    },

    {
      value: 'readiness.contributors.previous_night',
      title: 'Readiness Contributor: Previous Night',
    },
    {
      value: 'readiness.contributors.sleep_balance',
      title: 'Readiness Contributor: Sleep Balance',
    },
    {
      value: 'readiness.contributors.previous_day_activity',
      title: 'Readiness Contributor: Previous Day Activity',
    },
    {
      value: 'readiness.contributors.activity_balance',
      title: 'Readiness Contributor: Activity Balance',
    },
    {
      value: 'readiness.contributors.body_temperature',
      title: 'Readiness Contributor: Body Temperature',
    },
    {
      value: 'readiness.contributors.resting_heart_rate',
      title: 'Readiness Contributor: Resting Heart Rate',
    },
    {
      value: 'readiness.contributors.recovery_index',
      title: 'Readiness Contributor: Recovery Index',
    },
  ],
  'title',
)

export const cumulativeMeasurements = [
  'sleep.score',
  'sleep.total',
  'sleep.awake',
  'sleep.deep',
  'sleep.rem',
  'sleep.light',
  'sleep.phases',
  'sleep.time_in_bed',

  'sleep.contributors.total_sleep',
  'sleep.contributors.efficiency',
  'sleep.contributors.restfulness',
  'sleep.contributors.rem_sleep',
  'sleep.contributors.deep_sleep',
  'sleep.contributors.latency',
  'sleep.contributors.timing',

  'readiness.score',
  'readiness.body_temperature',
  'readiness.body_temperature_trend',

  'readiness.contributors.previous_night',
  'readiness.contributors.sleep_balance',
  'readiness.contributors.previous_day_activity',
  'readiness.contributors.activity_balance',
  'readiness.contributors.body_temperature',
  'readiness.contributors.resting_heart_rate',
  'readiness.contributors.recovery_index',
]

export const facets = [
  {
    key: 'home',
    name: 'Home',
    component: HomeFacet,
    preview: 'HomeFacetPreview',
    info: 'This creates a card to home view.',
  },
  {
    key: 'view',
    name: 'View',
    component: ViewFacet,
    preview: 'ViewFacetPreview',
    info: 'View facet is taking one screen and is scrollable. The added components will be shown in the order they are added here. This view can be accessed from home through a separate card.',
  },
  {
    key: 'notification',
    name: 'Notification',
    component: NotificationFacet,
    preview: 'NotificationFacetPreview',
    info: 'Define the content for the push notification. Logic for sending this push notification lives in the application code.',
  },
]

export const nativeMessages = [
  {
    title: 'Superpowers summary report',
    value: 'native_summary_report',
  },
]

export const facetTabs = [
  {
    key: 'view',
    name: 'View',
  },
  { key: 'home', name: 'Home' },
  {
    key: 'notification',
    name: 'Notification',
  },
  { key: 'media', name: 'Media' },
]

export const settingItems: SelectType[] = sortBy(
  [
    {
      value: 'battery_level_notifications',
      title: 'Notifications: Battery Level',
    },
    {
      value: 'inactivity_alert_notifications',
      title: 'Notifications: Inactivity Alerts',
    },
    {
      value: 'activity_progress_notifications',
      title: 'Notifications: Activity Progress',
    },
    { value: 'snippet_notifications', title: 'Notifications: Snippets' },
    { value: 'bedtime_notifications', title: 'Notifications: Bedtime' },
  ],
  'title',
)

export const deltaCategoryItems: SelectType[] = [
  { value: 'sleep_score', title: 'Sleep score' },
  { value: 'readiness_score', title: 'Readiness score' },
  { value: 'total_sleep_time', title: 'Total sleep time' },
]

export const scoreCategoryItems: SelectType[] = sortBy(
  [
    { value: 'activity', title: 'Activity' },
    { value: 'readiness', title: 'Readiness' },
    { value: 'sleep', title: 'Sleep' },
  ],
  'title',
)

export const deprecatedComponents: string[] = ['label', 'paragraph', 'title']

export const replaceableTitles: { [key: string]: string } = {
  activity_intensity: 'Daily movement graph',
  delta: 'Nap Detection delta',
  setting: 'Notification toggle',
}

export const componentMapping: ComponentMapping[] = [
  {
    type: UIComponentType.activityIntensity,
    component: ActivityIntensity,
    previewComponent: ActivityIntensityPreview,
    icon: 'mdi-poll',
  },
  { type: UIComponentType.button, component: Button, previewComponent: ButtonPreview, icon: 'mdi-card-outline' },
  { type: UIComponentType.chart, component: Chart, previewComponent: ChartPreview, icon: 'mdi-chart-timeline-variant' },
  {
    type: UIComponentType.contributor,
    component: Contributor,
    previewComponent: ContributorPreview,
    icon: 'mdi-percent',
  },
  { type: UIComponentType.hint, component: Hint, previewComponent: HintPreview, icon: 'mdi-information-variant' },
  {
    type: UIComponentType.idealBedtimeChart,
    component: IdealBedtimeChart,
    previewComponent: IdealBedtimeChartPreview,
    icon: 'mdi-chart-bar',
  },
  { type: UIComponentType.label, component: Label, previewComponent: PreviewComponent, icon: 'mdi-card-outline' },
  {
    type: UIComponentType.measurementStack,
    component: MeasurementStack,
    previewComponent: PreviewComponent,
    icon: 'mdi-card-outline',
  },
  {
    type: UIComponentType.nightTimeHeartRate,
    component: NightTimeHeartRate,
    previewComponent: NightTimeHeartRatePreview,
    icon: 'mdi-chart-timeline-variant',
  },
  {
    type: UIComponentType.nightTimeHRV,
    component: NightTimeHeartRateVariability,
    previewComponent: NightTimeHeartRateVariabilityPreview,
    icon: 'mdi-chart-timeline-variant',
  },
  { type: UIComponentType.paragraph, component: Paragraph, previewComponent: ParagraphPreview, icon: 'mdi-text' },
  {
    type: UIComponentType.questionnaire,
    component: Questionnaire,
    previewComponent: QuestionnairePreview,
    icon: 'mdi-checkbox-multiple-marked-outline',
  },
  { type: UIComponentType.richText, component: RichText, previewComponent: RichTextPreview, icon: 'mdi-text' },
  { type: UIComponentType.score, component: Score, previewComponent: ScorePreview, icon: 'mdi-percent' },
  {
    type: UIComponentType.setting,
    component: Setting,
    previewComponent: SettingPreview,
    icon: 'mdi-toggle-switch-off-outline',
  },
  {
    type: UIComponentType.sleepClock,
    component: SleepClock,
    previewComponent: SleepClockPreview,
    icon: 'mdi-clock-time-eleven',
  },
  {
    type: UIComponentType.sleepStages,
    component: SleepStages,
    previewComponent: SleepStagesPreview,
    icon: 'mdi-chart-timeline-variant',
  },
  { type: UIComponentType.title, component: Title, previewComponent: TitlePreview, icon: 'mdi-format-text' },
  {
    type: UIComponentType.delta,
    component: Delta,
    previewComponent: DeltaPreview,
    icon: 'mdi-card-outline',
  },
  {
    type: UIComponentType.picture,
    component: Picture,
    previewComponent: PicturePreview,
    icon: 'mdi-image',
    category: 'slideshow',
  },
  {
    type: UIComponentType.badgeReward,
    component: BadgeReward,
    previewComponent: BadgeRewardPreview,
    icon: 'mdi-multimedia',
  },
  {
    type: UIComponentType.contentRecommendation,
    component: ContentRecommendation,
    previewComponent: ContentRecommendationPreview,
    icon: 'mdi-multimedia',
  },
]

export const appFlavors = [{ value: 'experimental', title: 'Experimental' }]

export const appLocales = [
  { value: '', title: 'Select language' },
  { value: 'en', title: 'English' },
  { value: 'da', title: 'Danish' },
  { value: 'de', title: 'Deutch' },
  { value: 'es', title: 'Spanish' },
  { value: 'fi', title: 'Finnish' },
  { value: 'fr', title: 'French' },
  { value: 'it', title: 'Italian' },
  { value: 'ja', title: 'Japanese' },
  { value: 'nb', title: 'Norwegian' },
  { value: 'sv', title: 'Swedish' },
]
