<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-dialog v-model="isOpen" scrollable width="1000" @click:outside="close()">
    <v-card>
      <v-card-title class="d-flex flex-row">
        <span class="primary--text">Select Slideshow</span>
      </v-card-title>

      <v-card-text class="mt-4 mb-n2">
        <v-row>
          <v-col cols="7">
            <v-text-field v-model="textFilter" label="Filter slideshows" />

            <div style="min-height: 300px; max-height: 300px; overflow: auto">
              <v-data-table
                v-model="selectedItems"
                class="mt-n3"
                disable-pagination
                hide-default-footer
                single-select
                :headers="headers"
                :items="newSlideshows"
                :loading="!allSlideshows.length"
                :no-data-text="!allSlideshows.length ? 'Loading slideshows...' : 'No new slideshows'"
                @click:row="(_event: any, row: any) => slideshowSelected(row.item)"
              />
            </div>
          </v-col>

          <v-col
            cols="5"
            class="d-flex flex-column align-center fill-height px-8"
            style="min-height: 400px; max-height: 400px; overflow: auto"
          >
            <div
              class="white"
              style="
                position: relative;
                z-index: 10;
                border-radius: 8px;
                font-size: 12px;
                width: 100%;
                text-align: center;
              "
            >
              Preview
            </div>

            <div
              v-if="!slideshow"
              class="my-4 grey"
              style="font-size: 40px; text-align: center; opacity: 0.3; padding: 150px 25px; min-width: 100%"
            >
              {{ loading ? 'Loading...' : 'No file selected' }}
            </div>
            <div v-else style="max-width: 300px">
              <ViewFacetPreview contain :message="slideshow && slideshow.message" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex flex-row align-center" style="overflow: hidden">
        <v-spacer />

        <v-btn text="Cancel" @click="close()" />

        <v-btn text="Confirm" color="primary" :disabled="!slideshow" @click="confirmSelect()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getDocs, getFirestore, onSnapshot, orderBy, query } from 'firebase/firestore'

  import { contentCategories, contentIcons, slideshowHeaders } from '#views/contents/constants'

  @Component({})
  class SelectSlideshowDialog extends Vue {
    @Prop({ default: () => [] }) public vendors!: any[]
    @Prop({ default: () => [] }) public contents!: any[]

    @Emit('confirm')
    public confirm(data: any) {
      return data
    }

    public isOpen = false
    public loading = false

    public textFilter = ''

    public slideshow: any = null

    public allSlideshows: any[] = []
    public selectedItems: any[] = []

    public icons = contentIcons
    public headers = slideshowHeaders
    public categories = contentCategories

    private unsubscribe: Unsubscribe | undefined = undefined

    public get newSlideshows() {
      return this.allSlideshows.filter(
        (s: any) => s.id.includes(this.textFilter) && !this.contents?.find((c: any) => c.media === s.id),
      )
    }

    public mounted() {
      this.unsubscribe = onSnapshot(query(collection(getFirestore(), 'slideshows')), (snap) => {
        this.allSlideshows = snap.docs
          .map((doc) => ({
            id: doc.id,
            state: doc.data().state,
          }))
          .filter((s) => s.state !== 'draft')
      })
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }
    }

    public open() {
      this.isOpen = true
    }

    public close() {
      this.slideshow = null

      this.textFilter = ''

      this.selectedItems = []

      this.isOpen = false
    }

    public async confirmSelect() {
      this.confirm({
        slug: this.slideshow.id,
        media_type: 'slideshow',
      })

      this.close()
    }

    public async slideshowSelected(item: any) {
      this.loading = true

      const messages = await getDocs(
        query(collection(getFirestore(), `slideshows/${item.id}/messages`), orderBy('order')),
      )

      this.slideshow = { ...item, message: messages.docs[0].data() }

      this.loading = false
    }
  }

  export default toNative(SelectSlideshowDialog)
</script>
