<template>
  <v-dialog v-model="isOpen" max-width="800px">
    <v-card>
      <v-card-title class="headline">Add new media item</v-card-title>

      <v-card-text>
        <v-row v-if="mediaItem" class="mx-4">
          <v-col cols="3">
            <v-select v-model="mediaItem.type" :items="mediaTypes" label="Type" :autofocus="true" />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="mediaItem.slug" :label="label" :hint="hintText" />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="mediaItem.duration"
              type="number"
              label="Duration"
              hint="Duration in seconds"
              suffix="seconds"
              :rules="[(v: number) => v >= 0]"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn text="Cancel" color="primary" @click="onCancelClicked()" />
        <v-btn text="Add" color="primary" :disabled="isOkButtonDisabled" @click="onOkClicked()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { mediaTypes } from '#views/messages/constants'

  import { createMediaObjectItem } from '#views/messages/utilities'

  import { MediaItemType } from '#types'

  import { isIdentifierValid, isMediaIdentifierValid } from '#utilities'

  @Component({})
  class CreateNewMediaItemDialog extends Vue {
    @Prop() public messageId!: string

    @Emit('addMediaItem')
    public addMediaItem(item: any) {
      return item
    }

    public isOpen = false

    public mediaItem: MediaItemType | null = null

    public get mediaTypes() {
      return mediaTypes
    }

    public get hintText() {
      return this.mediaItem?.type === 'video'
        ? "Media slug may contain only lowercase letters, numbers and '-' should be used to separate words."
        : "ID may contain only lowercase letters, numbers and '_' should be used to separate words."
    }

    public get label() {
      return this.mediaItem?.type === 'video' ? 'Media slug' : 'Slideshow ID'
    }

    public get isOkButtonDisabled() {
      return !this.validateMediaItem() || !this.passesIdentifierCheck()
    }

    public passesIdentifierCheck() {
      const validatorFn = this.mediaItem?.type === 'video' ? isMediaIdentifierValid : isIdentifierValid

      return validatorFn(this.mediaItem?.slug ?? '')
    }

    public validateMediaItem() {
      return Boolean(this.mediaItem?.slug && this.mediaItem?.type && this.mediaItem?.duration > 0)
    }

    public open() {
      this.mediaItem = createMediaObjectItem()

      this.isOpen = true
    }

    public onOkClicked() {
      const newMediaItem = {
        ...this.mediaItem,
        id: `${this.messageId}:media:${this.mediaItem?.id}`,
        slug: `${this.mediaItem?.slug}`,
      }

      this.addMediaItem(newMediaItem)

      this.mediaItem = null
      this.isOpen = false
    }

    public onCancelClicked() {
      this.mediaItem = null
      this.isOpen = false
    }
  }

  export default toNative(CreateNewMediaItemDialog)
</script>
