<template>
  <v-timeline-item :dot-color="stepColor(nextStep, isComplete, ReleaseStepStates.ROLLOUT)">
    <h2 class="d-flex flex-start">Review process</h2>

    <template v-if="isActive">
      <template
        v-if="
          selectedBuild.state === ReleaseStates.PREPARE_FOR_SUBMISSION && (selectedBuild?.rolloutPercentage ?? 0) <= 0
        "
      >
        <v-btn
          rounded
          class="mt-4"
          color="primary"
          variant="outlined"
          text="Submit build for review"
          append-icon="mdi-arrow-right"
          :disabled="!isAppReleaseAdmin"
          @click="submitForReview(selectedBuild)"
        />
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.WAITING_FOR_REVIEW">
        <v-icon>mdi-clock-time-four-outline</v-icon>

        <p>Waiting for review</p>
      </template>

      <template v-else-if="selectedBuild.state === ReleaseStates.PENDING_FOR_RELEASE">
        <v-icon>mdi-check-circle</v-icon>

        <p v-if="selectedBuild.platform === 'ios'">Public release rollout may be started from App Store</p>

        <p v-if="selectedBuild.platform === 'android'">
          Staged rollout in progress. Current rollout percentage: {{ selectedBuild.rolloutPercentage }}%
        </p>
      </template>
    </template>
  </v-timeline-item>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { changeReviewStatus, stepColor } from '#views/releases/utilities'

  import { AppStore } from '#stores'

  import { AppBuild, ReleaseStates, ReleaseStepStates } from '#types'

  @Component({})
  class StepReleaseState extends Vue {
    @Prop({ required: true }) public nextStep!: number

    @Prop({ default: false }) public isComplete!: boolean

    @Prop({ required: true }) public selectedBuild!: AppBuild

    public readonly stepColor = stepColor

    public readonly ReleaseStates = ReleaseStates

    public readonly ReleaseStepStates = ReleaseStepStates

    private readonly appStore = new AppStore()

    public get isActive() {
      return this.nextStep === ReleaseStepStates.ROLLOUT
    }

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin
    }

    public async submitForReview(build: AppBuild) {
      const { version, versionId, buildVersion, buildId, platform } = build

      if (!version || !buildVersion || !platform || !buildId) {
        return
      }

      const confirm = await this.$confirm(
        'Submit build for review?',
        `Platform: ${platform === 'ios' ? 'iOS' : 'Android'} | Version: ${version} | Build: ${buildVersion}`,
      )

      if (confirm) {
        await changeReviewStatus('submit', {
          buildId,
          buildVersion,
          version,
          versionId,
          platform,
        })
      }
    }
  }

  export default toNative(StepReleaseState)
</script>
