<template>
  <div>
    <div class="d-flex">
      <v-select v-model="data.style" :items="styleItems" label="Category" />
      <v-checkbox v-model="data.javascript" class="ml-4" label="Requires dynamic JavaScript evaluation" />
    </div>

    <Translatable :data="data.text">
      <v-textarea
        v-model="data.text.text"
        spellcheck="true"
        lang="en"
        :error-messages="textError"
        label="Text"
        rows="1"
        auto-grow
      />
    </Translatable>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { styleItems } from '#views/messages/components/constants'

  import { UIComponentLabel } from '#types'

  import { evaluateStatement } from '#statement'

  @Component({})
  class Label extends Vue {
    @Prop() public data!: UIComponentLabel

    public textError = ''

    public get styleItems() {
      return styleItems
    }

    @Watch('data', { deep: true })
    protected onDataChanged(next: UIComponentLabel) {
      if (next.javascript) {
        const result = evaluateStatement(next.text.text, 'string')
        this.textError = result.success
          ? ''
          : result
              .message(
                // TODO fix this ugliness: Send save button status to MessageEditor component!
                this.$parent!.$parent!.$parent!.$parent!.$parent!.$parent!.$parent!.$parent!.$parent! as any,
              )
              .updateSaveChanges(result.success)
      } else {
        this.textError = ''
      }
    }
  }

  export default toNative(Label)
</script>
