<template>
  <v-row>
    <v-col cols="10">
      <v-select
        v-if="inTemplates"
        v-model="data.reward"
        label="Reward type"
        :items="rewardTypes"
        @update:model-value="createDefaultBadges()"
      />
      <v-select
        v-else
        v-model="data.template"
        label="Reward template"
        :items="rewardTemplates"
        item-title="template.name"
        item-value="template.id"
        @update:model-value="selectRewardTemplate($event)"
      />
    </v-col>
    <v-col cols="2">
      <v-text-field v-model.number="data.count" label="Rewards to show" />
    </v-col>
  </v-row>

  <div v-if="!inTemplates && data.template" class="caption orange--text" style="position: relative; z-index: 10">
    Note: To view or edit the badges you need to view / edit the
    <router-link :to="'/templates/' + data.template">
      <a link>template</a>
    </router-link>
    .
  </div>

  <v-expansion-panels v-if="data.badges.length && inTemplates" v-model="badge" flat>
    <v-expansion-panel v-for="(item, index) in data.badges" :key="item.id" class="my-0">
      <v-expansion-panel-title class="overline py-0">
        Badge: {{ item.id }}
        <v-spacer />
        <v-btn icon="mdi-delete" class="mr-4" style="flex: unset" @click="deleteBadge(index)" />
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <component :is="badgeComponent('picture')" :id="data.id" :data="item.components[0]" :images="images" />

        <component :is="badgeComponent('rich_text')" :id="data.id" :data="item.components[1]" />

        <component :is="badgeComponent('rich_text')" :id="data.id" :data="item.components[2]" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
  import { capitalize, uniqBy } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { componentMapping } from '#views/messages/constants'

  import { rewardBadges, rewardTypes } from '#views/messages/components/constants'

  import { createUIComponentDefaultData } from '#views/messages/components/utilities'

  import { AppStore } from '#stores'

  import { UIComponentBadgeReward, UIComponentPicture, UIComponentRichText, UIComponentType } from '#types'

  @Component({ inheritAttrs: false })
  class BadgeReward extends Vue {
    @Prop() public id!: string
    @Prop() public data!: UIComponentBadgeReward

    public badge = 0

    public rewardTypes = rewardTypes

    public rewardTemplates: any[] = []

    private appStore = new AppStore()

    private unsubscribe: Unsubscribe | undefined = undefined

    public get images() {
      return uniqBy(
        rewardBadges.map((b) => ({
          text: capitalize(`${this.data.reward}_${b}`).replace(/_/g, ' '),
          value: `badge_${this.data.reward}_${b}`,
        })),
        (b) => b.value,
      )
    }

    public get inTemplates() {
      return this.appStore.inTemplates
    }

    public mounted() {
      if (this.inTemplates && !this.data.badges.length) {
        this.createDefaultBadges()
      }

      if (!this.inTemplates) {
        this.unsubscribe = onSnapshot(query(collection(getFirestore(), `/templates`)), (snap) => {
          if (snap?.docs) {
            const templates = snap.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))

            this.rewardTemplates = templates
              .filter((t: any) => (t.allowedPages || []).includes('components'))
              .reduce(
                (rt: any[], template: any) => [
                  ...rt,
                  ...(template.facets?.view?.components || [])
                    .filter((c: any) => c.type === this.data.type)
                    .map((c: any) => ({ template, component: c })),
                ],
                [],
              )

            this.updateTemplateBadges()
          }
        })
      }
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }
    }

    public deleteBadge(index: number) {
      this.$confirm('Delete badge?').then((confirmed) => {
        if (confirmed) {
          this.data.badges.splice(index, 1)
        }
      })
    }

    public badgeComponent(type: string) {
      return componentMapping.find((c) => c.type === type)!.component
    }

    public createDefaultBadges() {
      const badges: any[] = []

      for (const badge of rewardBadges) {
        const types = this.data.reward === 'improver' ? [''] : ['optimal', 'pay_attention']

        for (const type of types) {
          const id = [this.data.reward, badge, type].filter(Boolean).join('_')

          const oldIndex = this.data.badges.findIndex((b) => b.id.includes(id.split('_').slice(1).join('_')))

          if (oldIndex >= 0) {
            badges.push({ ...this.data.badges[oldIndex], id })
          } else {
            badges.push({
              id,
              components: [
                createUIComponentDefaultData(UIComponentType.picture, `${this.id}:view`) as UIComponentPicture,
                createUIComponentDefaultData(UIComponentType.richText, `${this.id}:view`) as UIComponentRichText,
                createUIComponentDefaultData(UIComponentType.richText, `${this.id}:view`) as UIComponentRichText,
              ],
            })

            badges[badges.length - 1].components[1].contents.text = `<node-title-m><p>${capitalize(
              badge.replace(/_/g, ' '),
            )}</p></node-title-m>`
          }

          badges[badges.length - 1].components[0].image = `badge_${this.data.reward}_${badge}`
        }
      }

      this.data.badges = badges
    }

    public updateTemplateBadges() {
      if (this.data.template) {
        const data = this.rewardTemplates.find((t) => t.template.id === this.data.template)

        this.data.badges = data.component.badges
      }
    }

    public selectRewardTemplate(template: string) {
      const data = this.rewardTemplates.find((t) => t.template.id === template)

      this.data.count = data.component.count
      this.data.reward = data.component.reward
      this.data.badges = data.component.badges
    }
  }

  export default toNative(BadgeReward)
</script>
