<template>
  <div class="full-width">
    <div v-for="(link, index) in links" :key="index">
      <div class="mx-4">
        <v-row dense>
          <v-col cols="2">
            <v-select v-model="link.type" :items="linkTypes" label="Type" />
          </v-col>

          <v-col cols="10" class="d-flex align-center">
            <v-text-field v-model="link.value" label="Link" />

            <v-menu v-if="link.type === 'web'" offset-y>
              <template #activator="{ props }">
                <v-btn class="ml-4" v-bind="props">
                  <v-icon>mdi-translate</v-icon>
                  +
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="locale in appLocales(link)" :key="locale" @click="addVariant(index, locale)">
                  <v-list-item-title>{{ locale }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn icon="mdi-delete" class="ml-4" @click="deleteLink(index)" />
          </v-col>
        </v-row>

        <template v-if="link.type === 'web' && link.variants">
          <v-row v-for="(variant, varIndex) in Object.keys(link.variants)" :key="varIndex" class="my-n4" dense>
            <v-col cols="2" class="d-flex align-center justify-center">{{ variant.toUpperCase() }}</v-col>

            <v-col cols="10" class="d-flex align-center">
              <v-text-field v-model="(link.variants as any)[variant]" />

              <v-btn icon="mdi-close" class="ml-4" @click="deleteVariant(varIndex, variant)" />
            </v-col>
          </v-row>
        </template>

        <v-row dense>
          <v-col cols="12">
            <Translatable :data="link.title">
              <v-text-field v-model="link.title.text" spellcheck="true" lang="en" label="Title" />
            </Translatable>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mb-4" />
    </div>
    <div class="mt-6">
      <v-btn text="+ Add link" variant="outlined" color="primary" @click="createNewLinksItem()" />
    </div>
  </div>
</template>

<script lang="ts">
  import { linkTypes } from './constants'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { LinksItemType } from '#types'

  @Component({})
  class LinksObject extends Vue {
    @Prop() public links!: LinksItemType[]

    @Emit('createNewLinksItem')
    public createNewLinksItem() {
      return true
    }

    public linkTypes = linkTypes

    protected locale = 'any'

    public deleteLink(index: number) {
      this.links!.splice(index, 1)
    }

    public appLocales(link: any) {
      return ['da', 'de', 'es', 'fi', 'fr', 'it', 'ja', 'nb', 'sv'].filter(
        (l) => !link.variants || link.variants[l] === undefined,
      )
    }

    public addVariant(index: number, locale: string) {
      const link = (this.links || [])[index]

      const variants: any = link.variants || {}

      variants[locale] = ''

      link.variants = variants

      this.links[index] = link
    }

    public deleteVariant(index: number, locale: string) {
      const link = (this.links || [])[index]

      delete (link.variants as any)[locale]

      this.links[index] = link
    }
  }

  export default toNative(LinksObject)
</script>
