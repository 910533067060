<template>
  <ReleaseNotesDialog v-model:open="showReleaseNotes" v-model:tab="defaultTab" :type="type" />

  <v-sheet class="mb-12">
    <v-row>
      <v-col class="d-flex align-center">
        <h5 class="text-h5 text-medium-emphasis pa-4">{{ title }}</h5>

        <v-spacer />

        <v-btn
          rounded="1"
          variant="outlined"
          text="Release notes"
          color="grey-darken-1"
          style="min-width: 200px"
          @click="showReleaseNotes = true"
        />
      </v-col>
    </v-row>

    <v-card class="my-2 bg-background d-flex flex-row">
      <v-progress-circular v-if="isLoading" indeterminate />

      <template v-if="!isLoading">
        <v-row dense>
          <v-col cols="12">
            <v-card-item>
              <v-row dense>
                <v-col cols="1" />
                <v-col><div class="text-p font-weight-light">Build</div></v-col>
                <v-col><div class="text-p font-weight-light">Version</div></v-col>
                <v-col><div class="text-p font-weight-light">Released</div></v-col>
                <v-col><div class="text-p font-weight-light">Session crash free rate</div></v-col>
                <v-col class="text-p font-weight-light text-right">Release state</v-col>
              </v-row>

              <template v-for="release in releases" :key="release.buildVersion">
                <v-row v-if="release.buildVersion" dense class="d-flex align-center">
                  <v-col cols="1" class="text-left">
                    <v-icon size="small" class="mx-4">{{ platformIcon(release.platform) }}</v-icon>
                  </v-col>

                  <v-col>
                    <p class="d-flex aling-baseline pa-0 font-weight-medium">
                      {{ release.buildVersion }}
                    </p>
                  </v-col>

                  <v-col>
                    <p class="d-flex aling-baseline pa-0 font-weight-medium">
                      {{ release.version || '-' }}
                    </p>
                  </v-col>

                  <v-col>
                    <p class="d-flex aling-baseline pa-0 font-weight-medium">
                      {{ release?.createdAt ? $dayjs(release?.createdAt?.toDate()).format('MMM DD') : '–' }}
                    </p>
                  </v-col>

                  <v-col>
                    <v-progress-circular v-if="!(release.buildVersion in releaseCrashFreeRates)" indeterminate />

                    <p
                      v-else-if="releaseCrashFreeRates[release.buildVersion]"
                      class="d-flex aling-baseline pa-0 font-weight-medium"
                    >
                      <v-tooltip location="bottom">
                        <template #activator="{ props }">
                          <span v-if="!isLoading" v-bind="props" class="font-weight-bold">
                            {{ releaseCrashFreeRates[release.buildVersion] }}
                          </span>
                        </template>

                        <span>
                          Session crash free rate is the percentage of sessions that did not crash. A rate of 99.5 % or
                          higher is considered acceptable.
                        </span>
                      </v-tooltip>

                      <v-tooltip>
                        <template #activator="{ props }">
                          <v-chip
                            v-bind="props"
                            link
                            variant="text"
                            target="_blank"
                            rel="noopener noreferrer"
                            :href="sentryLink(release)"
                          >
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-chip>
                        </template>

                        <span>View Sentry dashboard</span>
                      </v-tooltip>
                    </p>

                    <p v-else class="d-flex aling-baseline pa-0 font-weight-medium">–</p>
                  </v-col>

                  <v-col>
                    <p class="d-flex aling-baseline pa-0 font-weight-medium justify-end">
                      Active
                      <span v-if="release.platform === 'android'">
                        ({{ release?.rolloutPercentage ? release.rolloutPercentage * 100 : 'Unknown' }}%)
                      </span>
                    </p>

                    <v-btn
                      v-if="canIncreaseRollout(release)"
                      color="success"
                      :text="`Increase rollout to ${nextRolloutPercentage(release) * 100}%`"
                      @click="increaseRolloutPercentage(release, nextRolloutPercentage(release))"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-card-item>
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-sheet>
</template>

<script lang="ts">
  import { adjustAndroidRollout, getReleaseCrashFreeRateFromSentry } from './utilities'

  import { capitalize } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AppBuild, AppPlatform, PlayStoreReleaseStatuses, ReleaseTypes, SentryProjects } from '#types/releases'

  @Component({})
  class PublicRelease extends Vue {
    @Prop({ required: true }) public type!: ReleaseTypes

    @Prop({ required: true }) public isLoading!: boolean

    @Prop({ required: true }) public releases!: AppBuild[]

    public releaseCrashFreeRates: { [k: string]: string | undefined } = {}

    public SentryProjects = SentryProjects

    public showReleaseNotes = false

    public defaultTab = 'android'

    @Watch('releases')
    public releasesChanged() {
      this.releases.forEach(async (release) => {
        this.releaseCrashFreeRates[release.buildVersion] = await getReleaseCrashFreeRateFromSentry(release)
      })
    }

    public get title() {
      return `${capitalize(this.type)}s`
    }

    public canIncreaseRollout(release: AppBuild) {
      return (
        release.platform === 'android' &&
        release.rolloutPercentage !== 1 &&
        this.type === ReleaseTypes.CURRENT_PUBLIC_RELEASE
      )
    }

    public sentryLink = (release: AppBuild) => {
      const sentryProject = release.platform === 'android' ? SentryProjects.ANDROID : SentryProjects.IOS

      return `https://ouraring-ltd.sentry.io/releases/${release.version}/?project=${sentryProject}`
    }

    public platformIcon(platform: AppPlatform) {
      if (['android', 'ios'].includes(platform) === false) {
        return 'mdi-question-mark-circle'
      }

      return platform === 'android' ? 'mdi-android' : 'mdi-apple'
    }

    public nextRolloutPercentage(build: AppBuild) {
      if (!build.rolloutPercentage || build.rolloutPercentage < 0.25) {
        return 0.25
      } else if (build.rolloutPercentage < 0.99) {
        return 0.99
      } else {
        return 1
      }
    }

    public async increaseRolloutPercentage(build: AppBuild, rolloutPercentage: number) {
      const { buildVersion, version, platform } = build

      if (!version || !buildVersion || platform !== 'android') {
        return
      }

      const confirm = await this.$confirm(
        `Increase rollout percentage to ${rolloutPercentage * 100}%?`,
        `Android | Version: ${version} | Build: ${buildVersion}`,
      )

      if (confirm) {
        const action =
          rolloutPercentage === 1 ? PlayStoreReleaseStatuses.COMPLETED : PlayStoreReleaseStatuses.IN_PROGRESS

        await adjustAndroidRollout(action, { buildVersion, rolloutPercentage })
      }
    }
  }

  export default toNative(PublicRelease)
</script>
