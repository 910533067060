<template>
  <v-dialog v-model="isOpen" width="1200" :persistent="isDownloading">
    <v-card :loading="isLoadingPreview">
      <v-card-title class="d-flex flex-column">
        <h5 class="mb-4 text-h5">Export insights</h5>

        <v-select v-model="exportType" label="File type" :items="exportTypes" />
      </v-card-title>

      <v-card-text style="min-height: 400px; max-height: 400px">
        <pre v-if="!isLoadingPreview" style="font-family: monospace; font-size: 0.8em"
          >{{ insightDataPreview }}{{ insightIds.length > previewLength ? '\n...more data not shown in preview' : '' }}
          </pre
        >
      </v-card-text>

      <v-card-actions>
        <v-btn text="Cancel" @click="isOpen = false" />

        <v-btn
          color="primary"
          :text="`Download ${insightIds.length} insights`"
          :loading="isDownloading"
          @click="downloadInsights()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { InsightExportType, insightExportPreviewLength, insightExportTypes } from '#views/insights/constants'

  import { FormulasStore, InsightsStore } from '#stores'

  import { InsightCollection } from '#types'

  @Component({})
  class ExportInsights extends Vue {
    @Prop() public insightIds!: string[]
    @Prop() public insightCollection!: InsightCollection

    @Model() public isOpen!: boolean

    public isDownloading = false
    public isLoadingPreview = false

    public previewInsightData: any[] = []

    public exportType: InsightExportType = insightExportTypes[0]

    public readonly exportTypes = insightExportTypes
    public readonly previewLength = insightExportPreviewLength

    private readonly insightsStore = new InsightsStore()
    private readonly formulasStore = new FormulasStore()

    private get fileType() {
      if (this.exportType === 'All data (JSON)') {
        return 'JSON'
      } else {
        return 'CSV'
      }
    }

    public get insightDataPreview() {
      return this.parseInsightDataToString(this.previewInsightData)
    }

    @Watch('isOpen')
    protected async isOpenChanged() {
      if (this.isOpen) {
        await this.fetchInsightsDataPreview()
      } else {
        this.exportType = this.exportTypes[0]
      }
    }

    public async downloadInsights() {
      this.isDownloading = true

      const restOfInsightData = await this.fetchInsightData(this.insightIds.slice(this.previewLength))
      const insightData = [...this.previewInsightData, ...restOfInsightData]
      const dataString = this.parseInsightDataToString(insightData)

      const filetype = this.fileType === 'CSV' ? 'text/csv' : 'application/json'
      const fileNameExtension = this.fileType === 'CSV' ? 'csv' : 'json'
      const blob = new Blob([dataString], { type: `${filetype};charset=utf-8` })

      const a = window.document.createElement('a')

      a.href = window.URL.createObjectURL(blob)
      a.download = `insights.${fileNameExtension}`

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)

      this.isDownloading = false
    }

    public async fetchInsightsDataPreview() {
      this.isLoadingPreview = true

      this.previewInsightData = await this.fetchInsightData(this.insightIds.slice(0, this.previewLength))

      this.isLoadingPreview = false
    }

    private async fetchInsightData(insightIds: string[]) {
      if (insightIds.length === 0) {
        return []
      }

      const data = {
        insightIds,
        insightConfigData: true,
        insightCollection: this.insightCollection,
      }

      const response = await this.insightsStore.exportInsights(data)

      for (const insight of response.insights) {
        const ruleIds = insight?.content?.rules || []

        if (ruleIds.length > 0) {
          const rules = await this.formulasStore.fetchRules(ruleIds)

          insight.content.rules = rules.map((rule) => rule.title)
        }
      }

      return response.insights
    }

    private parseInsightDataToString(data: any[]) {
      switch (this.exportType) {
        case 'All data (JSON)':
          return JSON.stringify(data, null, 2).trim()
        case 'Insight messages (CSV)':
          return this.parseInsightDataToMessages(data)
        case 'Insight settings (CSV)':
          return this.parseInsightDataToSettings(data)
        default:
          return ''
      }
    }

    private parseInsightDataToSettings(data: any[]) {
      const rows: string[] = ['insight_id;condition;priority;rule_names']

      data.forEach((insight) => {
        const { id, condition, priority, rules = [], rule } = insight?.content || {}

        const rulesToExport = rule ? [...rules, 'Custom rule'] : rules

        rows.push(`${id};${condition};${priority};${rulesToExport.join(', ')}`)
      })

      return rows.join('\n')
    }

    private parseInsightDataToMessages(data: any[]) {
      const rows: string[] = ['insight_id;message_id;component_id;text']

      data.forEach((insight) => {
        const insightId = insight?.content?.id

        insight?.content?.messages.forEach((message: any) => {
          const messageId = message?.id

          message?.facets?.view?.components.forEach((component: any) => {
            let textContent = null

            const { id: componentId, text, title, contents } = component

            if (text || text === '') {
              textContent = text
            } else if (title || title === '') {
              textContent = title
            } else if (contents || contents === '') {
              textContent = contents
            }

            if (textContent !== null) {
              rows.push(`${insightId};${messageId};${componentId};${textContent}`)
            }
          })
        })
      })
      return rows.join('\n')
    }
  }

  export default toNative(ExportInsights)
</script>
