export const formulaColors = [
  'blue',
  'green',
  'yellow',
  'red',
  'orange',
  'cyan',
  'lime',
  'teal',
  'amber',
  'pink',
  'indigo',
  'purple',
  'brown',
]

export const insightFormulaCategories: any = {
  blue: {
    title: 'Resting and sleep',
    value: 'resting_sleep',
  },
  green: {
    title: 'Readiness states',
    value: 'readiness_states',
  },
  yellow: {
    title: 'Activity level',
    value: 'activity_level',
  },
  red: {
    title: 'Body temperature',
    value: 'body_temperature',
  },
  orange: {
    title: 'Metabolic',
    value: 'metabolic',
  },
  cyan: {
    title: 'Napping times',
    value: 'napping_times',
  },
  lime: {
    title: 'Bedtime and wake-up',
    value: 'bedtime_wakeup',
  },
  teal: {
    title: 'Stress and resilience',
    value: 'stress_resilience',
  },
  amber: {
    title: 'Workouts and AAD',
    value: 'workouts_aad',
  },
  pink: {
    title: 'Reproductive health',
    value: 'reproductive_health',
  },
  indigo: {
    title: 'Tagging and notes',
    value: 'tagging_notes',
  },
  purple: {
    title: 'Status and goals',
    value: 'status_goals',
  },
  brown: {
    title: 'Other and drafts',
    value: 'other',
  },
}

export const spotlightFormulaCategories: any = {
  lime: {
    title: 'Bedtime',
    value: 'bedtime',
  },
  blue: {
    title: 'Sleep',
    value: 'sleep',
  },
  green: {
    title: 'Readiness',
    value: 'readiness',
  },
  yellow: {
    title: 'Activity goal',
    value: 'activity_goal',
  },
  red: {
    title: 'Pregnancy',
    value: 'pregnancy',
  },
  orange: {
    title: 'Chronotype',
    value: 'chronotype',
  },
  cyan: {
    title: 'Cycle phases',
    value: 'cycle_phases',
  },
  teal: {
    title: 'Vascular age',
    value: 'vascular_age',
  },
  amber: {
    title: 'Symptom Radar',
    value: 'symptom_radar',
  },
  pink: {
    title: 'Daytime stress',
    value: 'daytime_stress',
  },
  indigo: {
    title: 'Daytime stress evening',
    value: 'daytime_stress_evening',
  },
  purple: {
    title: 'Resilience',
    value: 'resilience',
  },
  brown: {
    title: 'Other and drafts',
    value: 'other',
  },
}
