export const noteTexts: { [key: string]: { title: string; single: string } } = {
  note: {
    title: 'Notes',
    single: 'note',
  },
  comment: {
    title: 'Comments',
    single: 'comment',
  },
}
