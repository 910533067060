import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import { Unsubscribe, collection, doc, getDoc, getFirestore, onSnapshot, query } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { ProjectsStore } from '#stores'

import { createZipAndDownload } from '#utilities'

let unsubscribeContents: Unsubscribe | undefined = undefined

@Store
export class ContentsStore extends Pinia {
  public loading = false

  public allContents: any[] = []

  public get contents() {
    const project = new ProjectsStore().project

    return this.allContents.filter((r) => !project || project.id === r.project)
  }

  public get audios() {
    return this.allContents.filter((c) => c.type === 'audio')
  }

  public get videos() {
    return this.allContents.filter((c) => c.type === 'video')
  }

  public get slideshows() {
    return this.allContents.filter((c) => c.type === 'slideshow')
  }

  public async exportContents(data: any) {
    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'exportContentsFromFirestore')(data)

    return response.data
  }

  public async downloadContents(data: any) {
    const exportResult = await this.exportContents(data)

    const latestRelease = await getDoc(doc(getFirestore(), '/rollouts/contents'))

    await createZipAndDownload(
      'Explore',
      {
        version: latestRelease.data()?.releasedVersion + '-export',
        entries: exportResult.contents.map((c: any) => c.content),
        collections: exportResult.featured.map((f: any) => f.content),
      },
      exportResult.translations,
    )

    return exportResult
  }

  public async subscribeToContents() {
    if (!unsubscribeContents) {
      this.loading = true

      unsubscribeContents = onSnapshot(query(collection(getFirestore(), '/contents')), (snap) => {
        const contents: any[] = snap.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }) as any)
          .filter((c) => !!c.updatedAt)

        this.allContents = sortBy(contents, [(c) => c.updatedAt || '']).reverse()
      })

      this.loading = false
    }
  }

  public async unsubscribeFromContents() {
    if (unsubscribeContents) {
      unsubscribeContents()

      unsubscribeContents = undefined
    }
  }
}
