<template>
  <v-chip
    class="ma-2"
    :disabled="disabled || readonly"
    :style="{
      color: stateTextColor(stateKey),
      background: stateBackgroundColor(stateKey),
      ...(readonly ? { opacity: '1.0' } : {}),
      ...(dangerous ? { border: '4px solid red' } : {}),
    }"
    style="font-size: 12px; min-width: 110px"
    @click.stop="select(stateKey)"
  >
    {{ (label ? label : stateKey).toUpperCase() }}
  </v-chip>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { insightStates, messageStates, reviewStates, workspaceStates } from '#views/insights/constants'

  import { AppStore } from '#stores'

  import { InsightStateType, StateItem } from '#types'

  @Component({})
  class StateChip extends Vue {
    @Prop() public label?: string

    @Prop({ default: 'draft' }) public stateKey!: InsightStateType

    @Prop({ default: false, type: Boolean }) public readonly!: boolean
    @Prop({ default: false, type: Boolean }) public disabled!: boolean
    @Prop({ default: false, type: Boolean }) public dangerous!: boolean

    private appStore = new AppStore()

    public get states() {
      if (this.inWorkspace) {
        return workspaceStates
      } else if (this.inApprovals) {
        return reviewStates
      } else if (this.inMessages) {
        return messageStates
      } else {
        return insightStates
      }
    }

    private get inApprovals() {
      return this.appStore.inApprovals
    }

    private get inMessages() {
      return this.appStore.inMessages
    }

    private get inWorkspace() {
      return this.appStore.inWorkspace
    }

    @Emit('select')
    public select(stateKey: InsightStateType) {
      return stateKey
    }

    public stateTextColor(state: InsightStateType): string {
      const stateItem = this.findStateItemForState(state)
      return stateItem ? stateItem.textColor : 'black'
    }

    public stateBackgroundColor(state: InsightStateType): string {
      const stateItem = this.findStateItemForState(state)
      return stateItem ? stateItem.color : 'grey'
    }

    private findStateItemForState(state: InsightStateType): StateItem | undefined {
      return this.states.find((stateItem: StateItem) => stateItem.value === state)
    }
  }

  export default toNative(StateChip)
</script>

<style lang="scss">
  .v-chip {
    .v-chip__content {
      margin: auto !important;
    }
  }
</style>
