<template>
  <v-footer order="1" class="d-flex flex-column pa-0">
    <div class="d-flex flex-column w-100">
      <slot />

      <v-divider />

      <v-progress-linear v-if="loading" absolute bottom indeterminate />

      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-row align-center pa-2">
            <v-tooltip v-if="!inWorkspace" bottom>
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  :icon="!!error ? 'mdi-alert-outline' : 'mdi-history'"
                  :color="!!error ? 'error' : 'grey'"
                  class="mr-0"
                  @click="openHistoryDialog()"
                />
              </template>

              <span>View history</span>
            </v-tooltip>

            <div v-if="!!error" class="ml-2">
              <span class="text-caption">{{ error }}</span>
            </div>

            <div v-else class="text-caption text-medium-emphasis pl-2">
              <div v-if="hasChanges" class="d-flex flex-row">You have unsaved changes.</div>

              <div v-if="!inWorkspace">
                <span class="pb-0 pr-1">Last edit by {{ (author && author.email) || 'script / system' }}:</span>

                <span v-if="author && edited">
                  {{
                    edited.toLocaleString('default', {
                      hour: 'numeric',
                      minute: 'numeric',
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })
                  }}
                  --
                  {{ author && author.comment }}
                </span>
                <span v-else>Just created by you and not saved yet</span>
              </div>

              <div v-if="inWorkspace && insightState" class="d-flex flex-row">
                <span v-if="insightState === 'created'">
                  You haven't yet sent your insight to review. You can do it from the status badge in top right.
                </span>
                <span v-else-if="insightState === 'review'">Your insight is in review, please wait for comments.</span>
                <span v-else-if="insightState === 'commented'">
                  You have comments from insights admin. Go and check those from the status badge in top right.
                </span>
                <span v-else-if="insightState === 'approved'">
                  <v-icon>mdi-party-popper</v-icon>
                  Your insight has been approved. Good job!
                </span>
              </div>
            </div>

            <v-spacer />

            <v-btn
              v-if="hasChanges"
              text="Save"
              color="primary"
              :disabled="!!error || loading || disabled"
              @click="save()"
            />

            <template v-else-if="dataSource && dataSource.createdAt">
              <v-btn v-if="action" color="primary" :text="action" :disabled="loading" @click="execute()" />

              <v-btn
                v-if="releasable"
                text="Publish"
                color="success"
                :disabled="!!error || loading || disabled"
                @click="release()"
              />
              <v-btn v-else-if="deletable" text="Delete" color="error" :disabled="loading" @click="confirmDelete()" />
            </template>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-footer>
</template>

<script lang="ts">
  import { isEqual } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  import { Author, Formula, Insight, Message, Template } from '#types'

  @Component({})
  class SaveChanges extends Vue {
    @Prop() public error!: string
    @Prop() public action!: string

    @Prop() public current!: any
    @Prop() public original!: any
    @Prop() public template!: Template

    @Prop() public author?: Author = undefined
    @Prop() public dataSource?: Formula | Insight | Message = undefined

    @Prop({ default: false, type: Boolean }) public loading!: boolean
    @Prop({ default: false, type: Boolean }) public disabled!: boolean
    @Prop({ default: false, type: Boolean }) public deletable!: boolean
    @Prop({ default: false, type: Boolean }) public releasable!: boolean

    @Prop({ default: '(No edit history)' }) public edited?: Date = undefined

    @Emit('save')
    public save() {
      return true
    }

    @Emit('delete')
    public delete() {
      return true
    }

    @Emit('release')
    public release() {
      return true
    }

    @Emit('execute')
    public execute() {
      return this.action.replace(/ /g, '-')
    }

    @Emit('confirmDelete')
    public confirmDelete() {
      return true
    }

    @Emit('openHistoryDialog')
    public openHistoryDialog() {
      return true
    }

    public hasChanges = false

    private readonly appStore = new AppStore()

    private readonly env = import.meta.env.VITE_APP_ENV

    public get saveClass() {
      return this.env === 'release' ? 'no-footer' : ''
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get insightState() {
      return (this.dataSource as any).state
    }

    @Watch('current', { deep: true })
    protected onCurrentChanged(value: any) {
      this.hasChanges = !isEqual(this.original, value)
    }

    @Watch('original', { deep: true })
    protected onOriginalChanged(value: any) {
      this.hasChanges = !isEqual(this.current, value)
    }
  }

  export default toNative(SaveChanges)
</script>
