export const editListHeaders: any = [
  {
    title: 'Insight ID',
    key: 'id',
    class: 'blue--text',
  },
  {
    title: 'Priority function',
    key: 'priority',
    class: 'blue--text',
  },
  {
    title: 'Category',
    key: 'category',
    class: 'blue--text',
  },
  {
    title: 'Group ID',
    key: 'group_id',
    class: 'blue--text',
  },
  {
    title: 'Tags',
    key: 'tags',
    class: 'blue--text',
    cellClass: 'wrap-text',
  },
  {
    title: 'Min interval',
    key: 'minimum_interval',
    class: 'blue--text',
    cellClass: 'text-no-wrap',
  },
  {
    title: 'Min category interval',
    key: 'minimum_category_interval',
    class: 'blue--text',
    cellClass: 'text-no-wrap',
  },
  {
    title: 'Release state',
    key: 'state',
    class: 'blue--text',
  },
]

export const simListHeaders = [
  {
    title: 'Insight ID',
    key: 'id',
    class: 'blue--text',
  },
  {
    title: 'Priority function',
    key: 'priority',
    class: 'blue--text',
  },
  {
    title: 'Default priority output',
    key: 'default_output',
    class: 'blue--text',
  },
  {
    title: 'Changed priority output',
    key: 'priority_output',
    class: 'blue--text',
  },
  {
    title: 'Position',
    key: 'position',
    class: 'blue--text',
  },
  {
    title: 'New position',
    key: 'new_position',
    class: 'blue--text',
  },
  {
    title: 'Position change',
    key: 'position_change',
    class: 'blue--text',
  },
]

export const contributorList: { title: string; value: string }[] = [
  { title: 'previous_night.score', value: 'readiness.contributors.previous_night.score' },
  { title: 'body_temperature.score', value: 'readiness.contributors.body_temperature.score' },
  { title: 'sleep_regularity.score', value: 'readiness.contributors.sleep_regularity.score' },
  { title: 'activity_balance.score', value: 'readiness.contributors.activity_balance.score' },
  { title: 'hrv_balance.score', value: 'readiness.contributors.hrv_balance.score' },
  { title: 'previous_day_activity.score', value: 'readiness.contributors.previous_day_activity.score' },
  { title: 'recovery_index.score', value: 'readiness.contributors.recovery_index.score' },
  { title: 'resting_heart_rate.score', value: 'readiness.contributors.resting_heart_rate.score' },
  { title: 'sleep_balance.score', value: 'readiness.contributors.sleep_balance.score' },

  { title: 'exercise.score', value: 'activity.contributors.exercise.score' },
  { title: 'meet_daily_targets.score', value: 'activity.contributors.meet_daily_targets.score' },
  { title: 'inactivity.score', value: 'activity.contributors.inactivity.score' },
]

export const priorityWeights: { weight: string; value: number }[] = [
  { weight: 'WEIGHT_RECOVERY_LAST_NIGHT', value: 0.1 },
  { weight: 'WEIGHT_RECOVERY_SLEEP_BALANCE', value: 0.15 },
  { weight: 'WEIGHT_RECOVERY_LAST_DAY', value: 0.1 },
  { weight: 'WEIGHT_RECOVERY_ACTIVITY_BALANCE', value: 0.1 },
  { weight: 'WEIGHT_RECOVERY_RESTING_HR', value: 0.25 },
  { weight: 'WEIGHT_RECOVERY_RESTING_HR_TIME', value: 0.1 },
  { weight: 'WEIGHT_RECOVERY_TEMP', value: 0.25 },
  { weight: 'WEIGHT_RECOVERY_SCORE', value: 1.0 },
  { weight: 'WEIGHT_RECOVERY_HRV_BALANCE', value: 0.25 },
  { weight: 'WEIGHT_RECOVERY_SLEEP_REGULARITY', value: 0.2 },
  { weight: 'WEIGHT_ACT_7D_ADHERENCE', value: 0.25 },
  { weight: 'WEIGHT_ACT_24H_INACTIVITY', value: 0.15 },
  { weight: 'WEIGHT_ACT_ALERT_COUNT', value: 0.1 },
  { weight: 'WEIGHT_ACT_7D_EXE_COUNT', value: 0.1 },
  { weight: 'WEIGHT_ACT_7D_EXERCISE', value: 0.15 },
  { weight: 'WEIGHT_ACT_7D_REST_COUNT', value: 0.1 },
  { weight: 'WEIGHT_ACT_7D_REST', value: 0.15 },
]

export const contributorDialogHeaders = [
  { title: 'Contributor', key: 'contributor' },
  { title: 'Value', key: 'value' },
  { title: '', key: 'actions', width: 5 },
]

export const weightDialogHeaders = [
  { title: 'Weight', key: 'weight' },
  { title: 'Value', key: 'value' },
  { title: 'New value', key: 'new_value' },
  { title: '', key: 'actions' },
]

export const templateContributors: { contributor: string; value: number }[] = [
  {
    contributor: 'readiness.contributors.previous_night.score',
    value: 77,
  },
  {
    contributor: 'readiness.contributors.body_temperature.score',
    value: 77,
  },
]

export const insightGroups: { text: string; value: string }[] = [
  { value: 'introducing_oura', text: 'Introducing Oura' },
  { value: 'bedtime', text: 'Bedtime' },
  { value: 'recovery_mode', text: 'Recovery Mode' },
  { value: 'rest_mode', text: 'Rest Mode' },
  { value: 'bedtime', text: 'Bedtime' },
  { value: 'weekly_summary', text: 'Weekly Summary' },
]

export const priorityCategories: { text: string; value: string }[] = [
  { value: 'snippet', text: 'Snippet' },
  { value: 'sleep', text: 'Sleep' },
  { value: 'activity', text: 'Activity' },
  { value: 'readiness', text: 'Readiness' },
  { value: 'anniversary', text: 'Anniversary' },
  { value: 'aad_reward', text: 'AAD Reward' },
  { value: 'ctp_reward', text: 'CTP Reward' },
  { value: 'workout_hr_reward', text: 'Workout HR Reward' },
  { value: 'hrm', text: 'Health Risk Management' },
  { value: 'dhr_daytime', text: 'Daytime HR mini insight' },
  { value: 'dhr_nighttime', text: 'Nighttime HR mini insight' },
  { value: 'dhr_restorative_time', text: 'Restorative Time mini insight' },
  { value: 'cycle_phases', text: 'Cycle phases' },
  { value: 'other', text: 'Other' },
]
