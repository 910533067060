<template>
  <div>
    <Translatable :data="data.contents">
      <v-textarea v-model="data.contents.text" spellcheck="true" lang="en" label="Contents" rows="1" auto-grow />
    </Translatable>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentParagraph } from '#types'

  @Component({})
  class Paragraph extends Vue {
    @Prop() public data!: UIComponentParagraph
  }

  export default toNative(Paragraph)
</script>
