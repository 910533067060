<template>
  <div class="pt-4">
    <div class="mb-4 d-flex align-center">
      <span class="text-primary text-h5">Components</span>

      <v-spacer />

      <v-switch v-model="all" hide-details label="Expand all" class="flex-rewerse flex-grow-0" />
    </div>

    <v-expansion-panels v-model="panel" multiple>
      <draggable :list="components">
        <ComponentContainer
          v-for="(c, index) in components"
          :key="c.id"
          :data="c"
          :component-name="componentName(c.type)"
          :component-icon="componentIcon(c.type)"
          class="mb-7"
          @delete="emitDelete(index)"
          @remove-info="removeInfo(index)"
        >
          <template v-if="!inTemplates">
            <v-alert v-if="c.info" variant="text" type="info" :text="c.info" />
          </template>

          <div
            v-else
            style="border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 3px; background: rgba(0, 0, 0, 0.02)"
            class="py-3 px-5 mb-3"
          >
            <v-text-field
              v-model="c.info"
              label="Info shown for the user related to this component"
              color="black"
              class="black--text"
            />

            <v-icon icon="mdi-information-outline" size="small" />

            <small>
              This variant="text" is shown to user when component is displayed for the first time. When user updates
              component in message editor this variant="text" will be removed.
            </small>
          </div>

          <component
            :is="componentMappingForType(c.type).component"
            :id="componentId(c.type)"
            :data="c"
            :message="message"
            :template="!!template"
          />
        </ComponentContainer>
      </draggable>
    </v-expansion-panels>

    <v-select
      v-model="selectedComponent"
      prepend-icon="mdi-plus"
      variant="underlined"
      :items="componentItems"
      label="Select a component to add"
      class="mt-8"
      :disabled="!componentItems.length"
      @update:model-value="addComponent($event)"
    />
  </div>
</template>

<script lang="ts">
  import { lowerCase, sortBy, upperFirst } from 'lodash-es'

  import { VueDraggableNext } from 'vue-draggable-next'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { componentMapping, deprecatedComponents, replaceableTitles } from '#views/messages/constants'

  import { createUIComponentDefaultData } from '#views/messages/components/utilities'

  import { AppStore } from '#stores'

  import { ComponentMapping, Insight, Message, Template, UIComponentDefault, UIComponentType } from '#types'

  @Component({
    components: {
      draggable: VueDraggableNext,
    },
  })
  class ComponentsList extends Vue {
    @Prop() public insight!: Insight
    @Prop() public message!: Message
    @Prop() public template!: Template

    @Prop({ default: [] }) public components!: UIComponentDefault<UIComponentType>[]
    @Prop({ default: () => [] }) public allowedComponents!: string[]
    @Prop({ default: () => [] }) public requiredComponents!: string[]

    public all = false

    public panel: number[] = []

    public selectedComponent = null

    private appStore = new AppStore()

    public get inTemplates() {
      return this.appStore.inTemplates
    }

    public get componentItems() {
      return sortBy(
        componentMapping
          .filter((c) => !this.insight || !c.category || c.category === this.insight.category)
          .filter((c) => this.allowedComponents.length == 0 || this.allowedComponents?.includes(c.type))
          .filter((c) => !deprecatedComponents.includes(c.type))
          .map((c) => ({
            title:
              this.componentName(c.type) +
              (this.requiredComponents.length > 0 && this.requiredComponents.includes(c.type) ? ' (required)' : ''),
            value: c.type,
          })),
        'title',
      )
    }

    @Watch('all')
    protected onAllChange(val: any) {
      this.panel = val ? [...Array(this.components.length).keys()].map((k, i) => i) : []
    }

    @Emit('delete')
    public emitDelete(index: number) {
      return index
    }

    @Emit('removeInfo')
    public removeInfo(index: number) {
      return index
    }

    public created() {
      if (this.$route.hash == '#expand') {
        this.all = true
      }
    }

    public componentId(type: string) {
      return this.template ? `template-component-${type}` : `${this.message.id}`
    }

    public componentName(type: string) {
      // Ticket IPRODS-325. We just want to change display name in component list.
      if (replaceableTitles[type]) {
        return replaceableTitles[type]
      }
      return (
        upperFirst(lowerCase(type.replace(/_/g, ' '))) + (deprecatedComponents.includes(type) ? ' (deprecated)' : '')
      )
    }

    public componentIcon(type: string) {
      const component = componentMapping.find((c) => c.type === type)
      if (component) {
        return component.icon
      }
      console.error(`Unknown component type ${type}`)
      return undefined
    }

    public componentMappingForType(type: string): ComponentMapping {
      const component = componentMapping.find((c) => c.type === type)

      if (component) {
        return component
      }

      console.error(`Unknown component type ${type}`)

      return {} as any
    }

    public addComponent(type: UIComponentType | null) {
      if (type) {
        const data = createUIComponentDefaultData(type, this.componentId(type) + ':view')

        if (data) {
          this.components.push(data)
        }

        this.panel.push(this.components.length - 1)

        this.selectedComponent = null
      }
    }
  }

  export default toNative(ComponentsList)
</script>

<style lang="scss" scoped>
  .v-expansion-panels {
    width: 100%;
    display: block;
  }

  :deep(.sortable-ghost) {
    border: 1px solid #efefef;

    & > button {
      border: 2px solid rgb(var(--v-theme-primary));
    }
  }
</style>
