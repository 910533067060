import { ReleaseTiers } from '#types/releases'

export const appHeaders: any = [
  {
    key: 'data-table-group',
    title: 'Platform',
    width: 80,
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'tests',
    title: 'Tests',
    width: 30,
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'build.version',
    title: 'Build',
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'version',
    title: 'Version',
    cellClass: 'text-no-wrap',
    width: 90,
    sortable: false,
  },
  {
    key: 'insightsEngine',
    title: 'Insights engine',
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'insightsContent',
    title: 'Insights content',
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'ecore',
    title: 'Ecore',
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'age',
    title: 'Age',
    cellClass: 'text-no-wrap',
    sortable: false,
  },
  {
    key: 'status',
    title: 'Release state',
    cellClass: 'text-no-wrap',
    width: 200,
    sortable: false,
  },
]

export const buildsHeaders: any = [
  {
    key: 'attributes.version',
    title: 'Version',
    cellClass: 'text-no-wrap',
  },

  {
    key: 'attributes.uploadedDate',
    title: 'Uploaded',
    align: 'end',
    cellClass: 'text-no-wrap',
  },
]

export const releaseTierMeaning: { [key in ReleaseTiers]: string } = {
  [ReleaseTiers.UNKNOWN]: 'Unknown tier',
  [ReleaseTiers.PRIORITY_GROUP_TESTING]: 'Priority group testing',
  [ReleaseTiers.INTERNAL_TESTING]: 'Internal testing',
  [ReleaseTiers.SELECTED_EXTERNAL_TESTING]: 'Selected external testing',
  [ReleaseTiers.FULL_EXTERNAL_TESTING]: 'External testing',
  [ReleaseTiers.PRODUCTION]: 'Production',
} as const

export const releaseProjectJobTemplates = {
  dev: '8730c70d64f1071c15c033b262962d94',
  prod: 'cccda1aca863204e0b80b221b4d75209',
}
