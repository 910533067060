<template>
  <template v-if="overrides">
    <template v-for="(o, index) in overrides" :key="o.metadata?.uid">
      <tr
        class="expanded-row"
        @click="
          $router.push(
            `/features/${getProjectKey(feature)}/${feature.metadata?.name}/rollouts/advanced/` +
              `${o.metadata?.uid}/${o?.metadata?.informative?.labels?.rollout ? 0 : index}`,
          )
        "
      >
        <template v-for="(header, i) in rolloutsHeaders.slice(0, 4)" :key="header.key">
          <v-hover #default="{ props, isHovering }" :disabled="index === 0 && i < 3">
            <td
              v-if="index === 0 || i === 3"
              v-bind="props"
              style="vertical-align: top"
              :class="
                overrides.length === 1 ? 'py-2' : index === 0 ? 'pt-2' : index === overrides.length - 1 ? 'pb-2' : ''
              "
              :width="i < 3 ? header.width || 'auto' : 'auto'"
              :style="
                (overrides.length === 1 && i < 3
                  ? ''
                  : index === 0 && i < 3
                    ? 'border-bottom: none;'
                    : 'cursor: pointer;') +
                ' ' +
                (i === 0 ? ' border-left: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));' : '') +
                (i === 3 ? ' border-right: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));' : '') +
                (index === 0 ? ' border-top: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));' : '') +
                (index === 0 ? ' border-bottom: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));' : '')
              "
              :rowspan="index === 0 && i < 3 ? overrides.length : 1"
              :colspan="i === 3 ? 3 : 1"
            >
              <template v-if="header.key === 'name' && index === 0">
                <div class="py-4 pr-4" style="max-width: 500px">
                  <div
                    v-if="!overrides[0]?.metadata?.informative?.labels.rollout"
                    class="pb-4"
                    style="word-wrap: break-word"
                  >
                    {{ overrides[0]?.metadata?.informative?.description }}
                  </div>

                  <div v-else class="pb-4" style="word-wrap: break-word">
                    Hint: You can access default rollouts more easily from the rollouts stepper bar at the top of this
                    page and that is what you should do in normal situations.
                    <br />
                    <br />
                    Note: Editing through this advanced view enables more advanced controls and editing options also for
                    the default rollouts, but bare in mind that you should only take advantage of this when really
                    needed to avoid any configuration mistakes.
                  </div>

                  <div
                    v-if="
                      overrides[0]?.metadata?.informative?.referenceUrls &&
                      overrides[0]?.metadata?.informative?.referenceUrls[0]
                    "
                  >
                    <a :href="overrides[0]?.metadata?.informative?.referenceUrls[0]" class="text-info" target="_blank">
                      More info
                    </a>
                  </div>
                </div>
              </template>

              <template v-if="header.key === 'features' && index === 0">
                <div class="text-break py-4" style="text-transform: uppercase">
                  {{ getProjectKey(feature) }}/
                  {{ feature.metadata?.name }}
                </div>

                <template v-for="linkedFeature in features" :key="linkedFeature">
                  <div
                    class="text-info text-break pb-4"
                    style="cursor: pointer"
                    @click.stop="$router.push('/features/' + linkedFeature + '/rollouts/advanced/' + o.metadata?.uid)"
                  >
                    {{ linkedFeature.toUpperCase() }}
                  </div>
                </template>
              </template>

              <template v-if="header.key === 'type'">
                <div class="d-flex flex-column justify-space-between py-4" style="height: 100%">
                  <div class="d-flex flex-row" style="max-width: 100%">
                    <div class="text-no-wrap font-weight-bold pr-6" style="min-width: 200px">
                      {{ rolloutName(o) }}
                    </div>

                    <div class="flex-shrink-1">
                      <span
                        v-if="o.metadata?.informative?.labels.template.startsWith('percentage')"
                        class="text-truncate text-no-wrap"
                      >
                        Step {{ (activeStageIndex(o) || 0) + 1 }}/{{
                          (o.rolloutOneOf?.$case === 'rollout' && o.rolloutOneOf.rollout.stages.length) || 1
                        }}: Feature
                        {{
                          rolloutStageStateToJSON(activeStage(o)!.state!).replace('CURRENT', 'COMPLETED') ===
                          'COMPLETED'
                            ? 'is'
                            : 'will be'
                        }}
                        <span
                          :class="
                            featureOverrideState(o, true) === 'DISABLED' ? 'text-red-darken-2' : 'text-green-darken-2'
                          "
                        >
                          {{ featureOverrideState(o, true).toLowerCase() }}

                          for
                          {{ fixedPointToString(activeStage(o)!.percentile) }}%
                        </span>
                        of targeted users
                      </span>
                      <span v-else class="text-truncate text-no-wrap">
                        Feature is
                        <span
                          :class="
                            featureOverrideState(o, true) === 'DISABLED' ? 'text-red-darken-2' : 'text-green-darken-2'
                          "
                        >
                          {{
                            overrideRolloutStatus(feature, 'disable') === 'INACTIVE' &&
                            featureOverrideState(o, true) !== 'DISABLED'
                              ? 'enable'
                              : 'disable'
                          }}{{
                            rolloutStageStateToJSON(activeStage(o)!.state!).replace('CURRENT', 'COMPLETED') ===
                            'COMPLETED'
                              ? 'd'
                              : ''
                          }}
                          for
                          <span
                            v-if="
                              o.criteria?.oneOf?.$case === 'and' &&
                              o.criteria?.oneOf?.and?.expressions[0].oneOf?.$case === 'predicate' &&
                              o.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate.oneOf?.$case === 'user' &&
                              o.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate.oneOf.user.oneOf?.$case ===
                                'range'
                            "
                          >
                            {{
                              fixedPointToString(
                                o.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate.oneOf.user.oneOf.range
                                  .lowerBound,
                              )
                            }}% -
                            {{
                              fixedPointToString(
                                o.criteria?.oneOf?.and?.expressions[0].oneOf?.predicate.oneOf.user.oneOf.range
                                  .upperBound,
                              )
                            }}%
                          </span>
                          of targeted users
                        </span>
                      </span>
                    </div>

                    <v-spacer />

                    <div class="ml-12 mt-n4 flex-grow-0">
                      <v-tooltip text="View / edit rollout" location="start">
                        <template #activator="{ props: tooltipProps }">
                          <v-btn v-bind="tooltipProps" icon="mdi-chevron-right" />
                        </template>
                      </v-tooltip>
                    </div>
                  </div>

                  <div
                    class="pr-5"
                    :class="index === overrides.length - 1 ? 'mt-n1' : 'my-n1'"
                    style="transition: opacity 0.5s ease-in-out"
                    :style="{ opacity: isHovering ? 1 : 0 }"
                  >
                    <span class="text-subtitle-2 font-weight-light text-grey-darken-1">
                      Last edited by
                      {{
                        o.metadata?.informative?.additionalData?.updatedBy ||
                        o.metadata?.informative?.additionalData?.createdBy ||
                        'system / unknown user'
                      }}
                    </span>
                    <span class="text-subtitle-2 font-weight-light text-grey-darken-1" style="float: right">
                      {{
                        $dayjs(
                          o.metadata?.informative?.additionalData?.updatedAt ||
                            o.metadata?.informative?.additionalData?.createdAt,
                        ).format('HH:mm - DD MMM YYYY z')
                      }}
                    </span>
                  </div>
                </div>
              </template>
            </td>
          </v-hover>
        </template>
      </tr>
    </template>
  </template>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import {
    Rollout_Stage_StageState,
    Rollout_Stage_StageType,
    rollout_Stage_StageStateToJSON,
  } from '@jouzen/control-api/rollout'

  import { rolloutsHeaders, stageColors, statusColors } from '#views/features/constants'

  import { featureOverrideState, fixedPointToString, overrideRolloutStatus } from '#views/features/utilities'
  import { getProjectKey } from '#views/projects/utilities'

  import { Feature, Override } from '#types'

  @Component({})
  class RolloutDrawer extends Vue {
    @Prop() public feature!: Feature
    @Prop() public features!: string[]

    @Prop() public overrides!: Override[]

    public readonly stageColors = stageColors
    public readonly statusColors = statusColors
    public readonly rolloutsHeaders = rolloutsHeaders

    public readonly stageTypes = Rollout_Stage_StageType
    public readonly stageStates = Rollout_Stage_StageState

    public readonly getProjectKey = getProjectKey

    public readonly fixedPointToString = fixedPointToString

    public readonly featureOverrideState = featureOverrideState
    public readonly overrideRolloutStatus = overrideRolloutStatus

    public readonly rolloutStageStateToJSON = rollout_Stage_StageStateToJSON

    public rolloutName(override: Override) {
      switch (override.metadata?.informative?.labels.template) {
        case 'percentage-sandbox':
          return 'Sandbox apps'
        case 'percentage-staging':
          return 'Staging app'
        case 'percentage-release':
          return 'Release app'
        case 'percentage-ouranians':
          return 'Ouranians only'
        case 'percentage-experimental':
          return 'Experimental app'
        case 'percentage-release-ios':
          return 'Release iOS app'
        case 'percentage-release-android':
          return 'Release Android app'
        default:
          return override.metadata?.informative?.displayName || 'Unknown'
      }
    }

    public activeStage(override: Override) {
      return override?.rolloutOneOf?.$case === 'rollout'
        ? override.rolloutOneOf.rollout.stages.find((s) => s.state === Rollout_Stage_StageState.CURRENT) ||
            override.rolloutOneOf.rollout.stages[0]
        : null
    }

    public activeStageIndex(override: Override) {
      return override?.rolloutOneOf?.$case === 'rollout'
        ? Math.max(
            0,
            override.rolloutOneOf.rollout.stages.findIndex((s) => s.state === Rollout_Stage_StageState.CURRENT),
          )
        : null
    }
  }

  export default toNative(RolloutDrawer)
</script>
