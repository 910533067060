<template>
  <div class="pa-2 heading-6">
    {{ component.title.text }}
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentTitle } from '#types'

  @Component({})
  class TitlePreview extends Vue {
    @Prop() public component!: UIComponentTitle
  }

  export default toNative(TitlePreview)
</script>
