<template>
  <v-textarea
    :model-value="modelValue"
    :error-messages="statementErrors(modelValue, type)"
    :label="label"
    :rows="rows || 1"
    :readonly="readonly"
    spellcheck="false"
    auto-grow
    class="mb-0"
    @blur="simplifyRule()"
    @click="click()"
    @update:model-value="valueChanged($event)"
  />
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { evaluateStatement, simplifyStatement } from '#statement'

  @Component({})
  class Statement extends Vue {
    @Prop() public rows!: number

    @Prop() public modelValue!: string

    @Prop({ type: Boolean }) public readonly!: boolean
    @Prop({ type: Boolean }) public required!: boolean
    @Prop({ type: Boolean }) public simplify!: boolean

    @Prop({ default: 'boolean' }) public type!: string
    @Prop({ default: 'Condition' }) public label!: string

    @Emit('click')
    public click() {
      return true
    }

    @Emit('update:modelValue')
    public updateModelValue(value: any) {
      return value
    }

    @Emit('updateSaveChanges')
    public updateSaveChanges(success: boolean) {
      return success
    }

    private useSimplify = false

    public async simplifyRule() {
      if (this.simplify) {
        let response = await simplifyStatement(this.modelValue)

        if (response.success) {
          if (response.value.includes('||')) {
            response.value = `(${response.value})`
          }

          if (this.useSimplify && response.success) {
            this.updateModelValue(response.value)
          }
        }
      }
    }

    public valueChanged(event: any) {
      this.useSimplify = true

      this.updateModelValue(event)
    }

    public statementErrors(statement: string | null, type: string) {
      if (!this.required && (!statement || statement.length === 0)) {
        return ''
      }

      const result = evaluateStatement(statement ?? '', type, '')

      this.updateSaveChanges(result.success)

      return result.success ? '' : result.message
    }
  }

  export default toNative(Statement)
</script>

<style lang="scss" scoped>
  .simplify {
    position: relative;
    top: -10px;
    transform: scale(0.8);
  }
</style>
