import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  collectionGroup,
  doc,
  getFirestore,
  onSnapshot,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'

import { createDefaultInsight } from '#views/insights/utilities'

import { AppStore } from '#stores'

import { Insight, Notification } from '#types'

let unsubscribeReviews: Unsubscribe | undefined = undefined
let unsubscribeNotifications: Unsubscribe | undefined = undefined

@Store
export class ApprovalsStore extends Pinia {
  public loading = true

  public insights: Insight[] = []

  public notifications: any[] = []

  public activeInsight: string | null = null

  public async closeNotification(insight: string) {
    const appStore = new AppStore()

    const batch = writeBatch(getFirestore())

    batch.update(doc(getFirestore(), `users/${appStore.uuid}/notifications/${insight}`), {
      type: 'insight',
      status: 'closed',
    })

    await batch.commit()
  }

  public async subscribeToReviews() {
    if (!unsubscribeReviews) {
      this.loading = true

      unsubscribeReviews = onSnapshot(
        query(collectionGroup(getFirestore(), 'insights'), where('state', 'in', ['review', 'commented'])),
        (snap) => {
          const insights = snap.docs.map((doc) => ({
            ...createDefaultInsight(),
            id: doc.id,
            ...doc.data(),
          }))

          this.insights = sortBy(insights, ['updatedAt']).reverse()

          this.loading = false
        },
      )
    }
  }

  public async unsubscribeFromReviews() {
    if (unsubscribeReviews) {
      unsubscribeReviews()
      unsubscribeReviews = undefined
    }
  }

  public async subscribeToNotifications() {
    if (!unsubscribeNotifications) {
      const appStore = new AppStore()

      unsubscribeNotifications = onSnapshot(
        query(collection(getFirestore(), `users/${appStore.user!.uid}/notifications`), where('type', '==', 'review')),
        (snap) => {
          const batch = writeBatch(getFirestore())

          const lastLoginAt = new Date(+JSON.parse(JSON.stringify(appStore.user)).lastLoginAt)

          const notifications: Notification[] = snap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))

          const changedInsights = this.insights.filter(
            (i) =>
              (i.updatedAt ? +i.updatedAt.toDate() : i.createdAt ? +i.createdAt.toDate() : +new Date()) > +lastLoginAt,
          )

          // Add notification for insights moved to review after previous login
          for (const review of changedInsights) {
            if (!notifications.find((n) => n.id === review.id) && review.author?.email != appStore.user!.email) {
              batch.set(doc(getFirestore(), `users/${appStore.user!.uid}/notifications/${review.id}`), {
                status: 'open',
                type: 'review',
              })
            }
          }

          // Close notifications for insights moved from review after previous login
          for (const notification of notifications) {
            if (notification.id && !this.insights.find((i) => i.id === notification.id)) {
              notification.status = 'closed'

              batch.set(
                doc(getFirestore(), `users/${appStore.user!.uid}/notifications/${notification.id}`),
                notification,
              )
            }
          }

          this.notifications = notifications.filter((n) => (n as Notification).status == 'open')

          batch.commit()
        },
      )
    }
  }
  public async unsubscribeFromNotifications() {
    if (unsubscribeNotifications) {
      unsubscribeNotifications()
      unsubscribeNotifications = undefined
    }
  }
}
