<template>
  <div>
    <TranslatableTitle
      comment="contributor title"
      :data="data"
      :prefix="id"
      :items="templateTitles"
      :loading="loadingTitles"
      @change-title="data.title = $event"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { Template, Translatable, UIComponentNightTimeHeartRateVariability } from '#types'

  @Component({})
  class NightTimeHeartRateVariability extends Vue {
    @Prop() public id!: string
    @Prop() public data!: UIComponentNightTimeHeartRateVariability
    @Prop() public template!: Template | null | undefined

    public loadingTitles = false

    public templateTitles: Translatable[] = []

    private unsubscribe: Unsubscribe | undefined = undefined

    public created() {
      if (!this.template) {
        this.loadingTitles = true

        this.unsubscribe = onSnapshot(query(collection(getFirestore(), `/templates`)), (snap) => {
          if (snap?.docs) {
            const templates = snap.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))

            this.loadingTitles = false

            this.templateTitles = templates
              .filter((t: any) => (t.allowedPages || []).includes('components'))
              .reduce(
                (titles: Translatable[], template: any) => [
                  ...titles,
                  ...(template.facets?.view?.components || [])
                    .map((c: any) => ({ ...c.title, templateId: template.id }))
                    .filter((t: any) => t?.translationId?.startsWith('template-component')),
                ],
                [],
              )
          }
        })
      }
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }
    }
  }

  export default toNative(NightTimeHeartRateVariability)
</script>
