<template>
  <div class="d-flex align-start">
    <div class="flex-grow-1">
      <slot />
    </div>

    <v-btn icon="mdi-translate" variant="plain" class="mt-1 ml-4" @click="showsTranslation = !showsTranslation" />
  </div>

  <div v-show="showsTranslation">
    <v-text-field v-model="data.comment" label="Translation comment" />

    <v-row dense>
      <v-col cols="12">
        <v-text-field readonly :model-value="data.translationId" label="Translation identifier" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Translatable } from '#types'

  @Component({ inheritAttrs: false })
  class TranslatableText extends Vue {
    @Prop() public data!: Translatable

    public showsTranslation = false
  }

  export default toNative(TranslatableText)
</script>
