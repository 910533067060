<template>
  <Translatable :data="data.text">
    <v-textarea v-model="data.text.text" spellcheck="true" lang="en" label="Text" rows="1" auto-grow />
  </Translatable>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentHint } from '#types'

  @Component({})
  class Hint extends Vue {
    @Prop() public data!: UIComponentHint
  }

  export default toNative(Hint)
</script>
