<template>
  <div>
    <div v-for="media in message.media" :key="media.id">
      <div class="d-flex mx-4">
        <v-text-field :model-value="media.slug" readonly label="Slug" />
        <v-spacer />
        <v-select :model-value="media.type" readonly :items="mediaTypes" label="Type" />
      </div>
    </div>
    <div class="mt-6">
      <v-btn text="+ Add media" color="primary" variant="outlined" @click="openMediaItemDialog()" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { mediaTypes } from '#views/messages/constants'

  import { Message } from '#types'

  @Component({})
  class MediaObject extends Vue {
    @Prop() public message!: Message

    @Emit('openMediaItemDialog')
    public openMediaItemDialog() {
      return true
    }

    public get mediaTypes() {
      return mediaTypes
    }
  }

  export default toNative(MediaObject)
</script>
