import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import {
  Unsubscribe,
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { createDefaultInsight } from '#views/insights/utilities'

import { ProjectsStore } from '#stores'

import { Insight } from '#types'

let unsubscribeTipthemes: Unsubscribe | undefined = undefined

@Store
export class TipthemesStore extends Pinia {
  public loading = true

  public allInsights: Insight[] = []

  public searchResults: any[] = []
  public searchQueryParams: string = ''

  public activeInsight: string | null = null

  public get insights() {
    const project = new ProjectsStore().project

    return this.allInsights.filter((r) => !project || project.id === r.project)
  }

  public async updateTiptheme(tiptheme: any) {
    tiptheme.updatedAt = serverTimestamp()

    return await updateDoc(doc(getFirestore(), `/tipthemes/${tiptheme.id}`), tiptheme)
  }

  public async exportTipthemes(data: any) {
    const functions = getFunctions(getApp(), 'europe-west1')

    data.insightCollection = 'tipthemes'

    const response: any = await httpsCallable(functions, 'exportInsightsFromFirestore')(data)

    response.data.tipthemes = response.data.insights

    delete response.data.insights

    return response.data
  }

  public async subscribeToTipthemes() {
    if (!unsubscribeTipthemes) {
      this.loading = true

      unsubscribeTipthemes = onSnapshot(query(collection(getFirestore(), `/tipthemes`)), (snap) => {
        const insights = snap.docs.map((doc) => ({
          ...createDefaultInsight(),
          id: doc.id,
          ...doc.data(),
        }))

        this.allInsights = sortBy(insights, [(o) => o.updatedAt || '']).reverse()

        this.loading = false
      })
    }
  }
  public async unsubscribeFromTipthemes() {
    if (unsubscribeTipthemes) {
      unsubscribeTipthemes()

      unsubscribeTipthemes = undefined
    }
  }
}
