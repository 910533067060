<template>
  <v-dialog v-if="isOpen" v-model="isOpen" width="1000">
    <v-card>
      <v-card-title>Clicked, viewed and generated by platform</v-card-title>

      <v-card-text>
        <div class="text-overline">{{ insightId.toUpperCase() }}</div>

        <v-row>
          <v-col>
            <v-text-field v-model="dateFrom" hide-details="auto" hint="format YYYY-MM-DD" label="From date" />
          </v-col>

          <v-col>
            <v-text-field v-model="dateTo" hide-details="auto" hint="format YYYY-MM-DD" label="To date" />
          </v-col>

          <v-col class="d-flex" cols="3">
            <v-btn class="align-self-center" text="Update date range" @click="renderChart()" />
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col>
            <v-progress-circular v-if="isLoading" indeterminate />
            <p v-if="!isLoading && noStats">No statistics to show</p>
          </v-col>
        </v-row>
      </v-card-text>

      <div ref="chartContainer" class="chart mt-4 mb-n4" style="height: 500px; position: relative" />

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="isOpen = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'

  import * as am4core from '@amcharts/amcharts4/core'

  import * as am4charts from '@amcharts/amcharts4/charts'

  import am4themes_animated from '@amcharts/amcharts4/themes/animated'

  import { convertInsightStatisticsToChartData } from '#views/insights/utilities'

  import { InsightsStore } from '#stores'

  import { InsightChartData } from '#types/insights'

  am4core.useTheme(am4themes_animated as any)

  @Component({})
  class InsightChart extends Vue {
    @Prop({ required: true }) public insightId!: string

    public dateTo = ''
    public dateFrom = ''

    public isOpen = false
    public noStats = false
    public isLoading = false

    public chart: am4charts.XYChart | null = null

    public header = 'Clicks and views by platform'

    public insightStats: undefined | { data: [] } = undefined

    private preSelectedLine?: 'clicked' | 'viewed' | 'generated'

    protected readonly insightsStore = new InsightsStore()

    @Ref() private readonly chartContainer: HTMLElement | null = null

    public beforeUnmount() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }
    }

    public async open(preSelectedLine?: 'clicked' | 'viewed' | 'generated') {
      this.dateTo = this.$dayjs(new Date()).format('YYYY-MM-DD')
      this.dateFrom = this.$dayjs(new Date()).subtract(7, 'day').format('YYYY-MM-DD')

      this.preSelectedLine = preSelectedLine

      this.isOpen = true
    }

    public updated() {
      if (this.isOpen) {
        setTimeout(this.renderChart, 500)
      }
    }

    public async renderChart() {
      try {
        this.isLoading = true

        const results = await this.insightsStore.fetchStats({
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          insightId: this.insightId,
        })

        const chartData = convertInsightStatisticsToChartData(results)

        this.chart = this.createChart(chartData)

        this.noStats = false
      } catch (_error) {
        this.noStats = true
      } finally {
        this.isLoading = false
      }
    }

    private createChart(chartData: InsightChartData[]) {
      const chart = am4core.create(this.chartContainer!, am4charts.XYChart)

      chart.data = chartData

      chart.logo.height = -10000

      chart.legend = new am4charts.Legend()

      chart.yAxes.push(new am4charts.ValueAxis())

      const categoryAxis = chart.xAxes.push(new am4charts.DateAxis())

      // Create series
      const series = Object.keys(chartData[0]).filter((key) => key !== 'date')

      series.forEach((seriesName) => {
        const lineSeries = chart.series.push(new am4charts.LineSeries())

        lineSeries.name = seriesName
        lineSeries.xAxis = categoryAxis
        lineSeries.smoothing = 'monotoneX'
        lineSeries.strokeWidth = 5

        lineSeries.dataFields.dateX = 'date'
        lineSeries.dataFields.valueY = seriesName

        if (this.preSelectedLine && !seriesName.includes(this.preSelectedLine)) {
          lineSeries.hidden = true
        }

        const segments = lineSeries.segments.template

        segments.interactionsEnabled = true

        const bullets = lineSeries.bullets.push(new am4charts.CircleBullet())

        bullets.circle.stroke = am4core.color('#fff')
        bullets.circle.strokeWidth = 2

        bullets.tooltipText = `[bold]{name}[/] - {dateX}\n{valueY}`
      })

      return chart
    }
  }

  export default toNative(InsightChart)
</script>
