<template>
  <div style="position: relative">
    <div class="backdrop">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="highlights" v-html="text" />
    </div>

    <v-textarea
      ref="statementTextarea"
      v-resize="reCalculateHeight"
      auto-grow
      rows="1"
      spellcheck="false"
      :label="label"
      :readonly="readonly"
      :error-messages="statementErrors(value, type)"
      :model-value="highlightText || value"
      @update:model-value="input($event)"
    />
  </div>
</template>

<script lang="ts">
  import { ComponentPublicInstance } from 'vue'

  import { Component, Emit, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { evaluateStatement } from '#statement'

  @Component({})
  class StatementPreview extends Vue {
    @Prop() public value!: string

    @Prop() public highlight!: string | string[]

    @Prop() public highlightText!: string
    @Prop() public highlightColor!: string | string[]
    @Prop() public highlightTooltip!: string | string[]

    @Prop({ type: Boolean }) public error!: boolean
    @Prop({ type: Boolean }) public readonly!: boolean

    @Prop({ default: 'boolean' }) public type!: string
    @Prop({ default: 'Condition' }) public label!: string

    @Ref() private readonly statementTextarea: ComponentPublicInstance | null = null

    @Emit('input')
    public input(value: string) {
      return value
    }

    @Emit('update:error')
    public updateError(error: boolean) {
      return error
    }

    @Emit('updateSaveChanges')
    public updateSaveChanges(value: boolean) {
      return value
    }

    public text = ''

    private textareaWidth = 0

    @Watch('value')
    protected onValueChanged() {
      this.onHighlightChanged()
    }

    @Watch('highlight')
    protected onHighlightChanged() {
      if (this.highlight && this.highlightText) {
        this.text = ''

        const highlights = !Array.isArray(this.highlight) ? [this.highlight] : this.highlight
        const highlightTexts = !Array.isArray(this.highlightText) ? [this.highlightText] : this.highlightText
        const highlightColors = !Array.isArray(this.highlightColor) ? [this.highlightColor] : this.highlightColor
        const highlightTooltip = !Array.isArray(this.highlightTooltip) ? [this.highlightTooltip] : this.highlightTooltip

        highlightTexts.forEach((text, level) => {
          text = text.replace(/\n$/g, '\n\n')

          highlights.forEach((value, index) => {
            if (value) {
              text = text
                .split(value.trim())
                .join(
                  `<mark class="level-${level} bg-${highlightColors[index] || highlightColors[0]}"><span class="tooltip">${
                    highlightTooltip[index] || ''
                  }</span><span class="wrapper">${value.trim()}</span></mark>`,
                )
            }
          })

          this.text += text
        })
      } else {
        this.text = this.highlightText
      }

      setTimeout(() => {
        const elements: any = this.$el.querySelectorAll('mark') || []

        elements.forEach((e: any) => {
          e.addEventListener('mouseover', (event: any) => {
            event.currentTarget.classList.add('active')
            event.stopPropagation()
            event.preventDefault()
          })
          e.addEventListener('mouseout', (event: any) => {
            event.currentTarget.classList.remove('active')
          })
        })
      }, 0)
    }

    @Watch('highlightText')
    protected onHighlightTextChanged() {
      this.onHighlightChanged()
    }

    @Watch('highlightColor')
    protected onHighlightColorChanged() {
      this.onHighlightChanged()
    }

    public mounted() {
      this.onHighlightChanged()
    }

    public statementErrors(statement: string | null, type: string) {
      const result = evaluateStatement(statement ?? '', type)

      this.updateError(!result.success)
      this.updateSaveChanges(result.success)

      return result.success ? '' : result.message
    }

    public reCalculateHeight() {
      const textareaWidth = this.statementTextarea!.$el.offsetWidth

      if (textareaWidth !== this.textareaWidth) {
        this.textareaWidth = textareaWidth
      }
    }
  }

  export default toNative(StatementPreview)
</script>

<style lang="scss" scoped>
  .backdrop {
    position: absolute;
    overflow: visible;
    top: 24px;
    left: 16px;
    right: 16px;
    line-height: 21px;
    max-width: 100%;
    font-size: 14px;
    letter-spacing: normal;
  }

  .highlights {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  :deep(mark) {
    border: 1px solid transparent;
    background-color: yellow;

    mark {
      border: 0px solid transparent;

      .wrapper {
        border: 1px solid darkgrey !important;
      }
    }

    &.active {
      & > .wrapper {
        outline: 2px solid pink;
      }

      & > .tooltip {
        visibility: visible;
        opacity: 0.5;
      }
    }

    &.level-1,
    &.level-2 {
      outline: 1px solid rgba(150, 150, 150, 0.5) !important;
    }
  }

  :deep(.tooltip) {
    visibility: hidden;
    width: auto;
    background-color: #ddd;
    color: #000;
    text-align: center;
    padding: 2px 8px;
    border-radius: 3px;
    white-space: nowrap;
    opacity: 0;

    position: absolute;
    z-index: 1;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;

    transition: opacity 0.3s;
  }

  :deep(textarea) {
    color: transparent !important;
    font-size: 14px;
    letter-spacing: normal;
  }

  :deep(.v-textarea) {
    pointer-events: none;
  }
</style>
