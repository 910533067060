<template>
  <div>
    <v-select
      v-model="data.measurements"
      :items="measurementItems"
      label="Measurements"
      multiple
      chips
      small-chips
      deletable-chips
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { measurements } from '#views/messages/constants'

  import { UIComponentMeasurementStack } from '#types'

  @Component({})
  class MeasurementStack extends Vue {
    @Prop() public data!: UIComponentMeasurementStack

    public get measurementItems() {
      return measurements
    }
  }

  export default toNative(MeasurementStack)
</script>
