<template>
  <v-dialog v-if="isOpen" v-model="isOpen" width="1000">
    <v-card>
      <v-card-title class="headline">
        {{ title }} / {{ unit }}

        <v-spacer />

        <StatisticsChip
          stat="average"
          label="Average"
          :unit="'/ ' + unit"
          :loading="loading"
          :warnings="warnings"
          :stats-data="statsData"
        />
      </v-card-title>

      <v-card-text>
        <div class="overline ml-1 mt-4">{{ header }}</div>

        <div ref="chartContainer" class="chart mt-4 mb-n4" style="height: 500px; position: relative" />

        <div v-if="warnings.length > 0">
          <v-divider />
          <p>
            <v-icon color="blue" class="pr-2">mdi-comment-alert</v-icon>
            <b>Warnings:</b>
          </p>
          <p v-for="warning in warnings" :key="warning.name">
            {{ warning.label }}
          </p>
          <v-divider />
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn text="Close" @click="isOpen = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { keyBy, values } from 'lodash-es'

  import { Component, Prop, Ref, Vue, toNative } from 'vue-facing-decorator'

  import * as am4core from '@amcharts/amcharts4/core'

  import * as am4charts from '@amcharts/amcharts4/charts'

  import am4themes_animated from '@amcharts/amcharts4/themes/animated'

  import { AmplitudeStats, StatsWarning } from '#types'

  am4core.useTheme(am4themes_animated as any)

  type ChartDataType = {
    name: string
    date: string
    actual?: number
    prediction?: number
    /* generated?: number
    generatedPrediction?: number */
  }

  @Component({})
  class StatisticsChart extends Vue {
    @Prop() public unit!: string

    @Prop() public title!: string
    @Prop() public header!: string

    @Prop() public guides!: any[]
    @Prop() public labels!: string[]

    @Prop({ type: Boolean }) public loading!: boolean

    @Prop({ default: () => [] }) public warnings!: StatsWarning[]
    @Prop({ default: undefined }) public statsData?: AmplitudeStats

    public isOpen: boolean = false

    public chart: am4charts.XYChart | null = null

    @Ref() private readonly chartContainer: HTMLElement | null = null

    public open() {
      this.isOpen = true
    }

    public updated() {
      if (this.isOpen) {
        setTimeout(this.renderChart, 500)
      }
    }

    public beforeUnmount() {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }
    }

    private renderChart() {
      if (this.statsData) {
        let chart = am4core.create(this.chartContainer!, am4charts.XYChart)

        chart.logo.height = -10000

        const data =
          this.statsData.xValues.map((stat, index) => {
            let result: ChartDataType = { date: stat, name: stat }

            if (index >= this.statsData!.xValues.length - 2) {
              result['prediction'] = this.statsData!.series[index]
            }

            if (index < this.statsData!.xValues.length - 1) {
              result['actual'] = this.statsData!.series[index]
            }

            return result
          }) || []

        chart.data = values(keyBy(data, 'date'))

        /*  const generated =
        this.stats.generated?.xValues.map((stat, index) => {
          let result: ChartDataType = { date: stat, name: stat }
          if (index >= this.stats.generated.xValues.length - 2) {
            result['generatedPrediction'] = this.stats.generated.series[index]
          }
          if (index < this.stats.generated.xValues.length - 1) {
            result['generated'] = this.stats.generated.series[index]
          }
          return result
        }) || []

        chart.data = values(merge(keyBy(viewed, 'date'), keyBy(generated, 'date')))
      */

        const dateAxis = new am4charts.DateAxis()

        chart.xAxes.push(dateAxis)
        chart.yAxes.push(new am4charts.ValueAxis())

        function createGuide(label: string, value: number) {
          const range = dateAxis.axisRanges.create()

          range.value = value
          range.grid.stroke = am4core.color('#396478')
          range.grid.strokeWidth = 2
          range.grid.strokeOpacity = 0.5
          //range.grid.strokeDasharray = '5 3'

          range.label.tooltip = new am4core.Tooltip()
          range.label.opacity = 0.5
          range.label.valign = 'middle'
          range.label.inside = true
          range.label.truncate = true
          range.label.text = label
          range.label.tooltipText = label
          range.label.disabled = false
          range.label.rotation = 90
          range.label.fill = am4core.color('#396478')
        }

        function createSeries(field: string, name: string, zindex: number, color: string, dashed?: boolean) {
          const series = chart.series.push(new am4charts.LineSeries())
          series.dataFields.valueY = field
          series.dataFields.dateX = 'date'

          series.fill = am4core.color(color)
          series.stroke = am4core.color(color)
          series.name = name
          series.tooltipText = '{dateX}: [b]{valueY}[/]'
          series.strokeWidth = 2
          series.zIndex = zindex

          series.legendSettings.labelText = name
          series.legendSettings.itemLabelText = name

          if (dashed) {
            series.strokeDasharray = '5 3'
          }

          chart.cursor = new am4charts.XYCursor()

          let bullet = series.bullets.push(new am4charts.CircleBullet())

          bullet.circle.stroke = am4core.color('#fff')
          bullet.circle.strokeWidth = 1

          return series
        }

        if (this.guides) {
          this.guides.forEach((guide) => {
            createGuide(guide.label, guide.value)
          })
        }

        createSeries('actual', this.labels[0], 2, '#2196F3')
        createSeries('prediction', this.labels[1], 1, '#64B5F6', true)

        /*createSeries('generated', 'Generated messages', 2, '#009688')
      createSeries('generatedPrediction', 'Generated messages current week', 1, '#4DB6AC', true) */

        chart.legend = new am4charts.Legend()

        this.chart = chart
      }
    }
  }

  export default toNative(StatisticsChart)
</script>
