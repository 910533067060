<template>
  <v-dialog v-model="isOpen" scrollable width="1200">
    <v-card :loading="isLoading">
      <v-card-title class="headline">History</v-card-title>

      <v-card-text>
        <template v-if="isLoading">
          <div />
        </template>
        <template v-else-if="changes.length">
          <div v-if="!isReview" class="mb-6">
            There are {{ changes.length }} changes total made for this content, see the full history below.
          </div>
          <div v-else class="mb-6">
            There are {{ changes.length }} changes total made for this content since the last publish, see the changes
            below.
          </div>
          <span v-for="(change, index) in changes" :key="index">
            <v-divider class="mb-5" />

            <div class="d-flex flex-row align-baseline">
              <div v-if="change.author">
                <span class="overline">{{ change.author.displayName || change.author.email || 'Unknown author' }}</span>
              </div>
              <div v-else>
                <span class="overline">Unknown author (maybe a script?)</span>
              </div>

              <v-spacer />

              <span class="oberline">
                {{
                  change.timestamp.toDate().toLocaleString('default', {
                    hour: 'numeric',
                    minute: 'numeric',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })
                }}
              </span>
            </div>

            <blockquote v-if="change.author" class="blockquote">
              {{ change.author.comment }}
            </blockquote>
            <DiffView :diff="change.diff" />
          </span>
        </template>
        <template v-else>
          <div class="mt-6">There are no changes.</div>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="isOpen = false" />

        <v-btn v-if="isReview" text="Publish" color="success" @click="publish()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, mixins, toNative } from 'vue-facing-decorator'

  import { JsonDiff } from '@jouzen/outo-apps-toolkit'

  import { HistoryStore } from '#stores'

  import { ChangeSet } from '#types/history'

  @Component({})
  class HistoryDialog extends mixins(JsonDiff) {
    public isOpen = false
    public isReview = false
    public isLoading = false

    public changes: ChangeSet[] = []

    private historyStore = new HistoryStore()

    @Emit('publish')
    public publish() {
      this.isOpen = false

      return true
    }

    public open(path: string, name: string, since?: Date) {
      this.isOpen = true

      this.isReview = !!since

      this.getHistory(`${path}/history`, name, since)
    }

    public async getHistory(path: string, name: string, since?: Date) {
      this.changes = []

      this.isLoading = true

      this.isReview = !!since

      this.changes = await this.historyStore.changes(path, name, since)

      this.isLoading = false
    }
  }

  export default toNative(HistoryDialog)
</script>
