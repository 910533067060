import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

import { createDefaultInsight } from '#views/insights/utilities'

import { Insight } from '#types'

let unsubscribeWorkspace: Unsubscribe | undefined = undefined

@Store
export class WorkspaceStore extends Pinia {
  public loading = true

  public insights: Insight[] = []

  public searchResults: any[] = []
  public searchQueryParams: string = ''

  public activeInsight: string | null = null

  public async subscribeToWorkspace(userId: string) {
    if (!unsubscribeWorkspace) {
      this.loading = true

      unsubscribeWorkspace = onSnapshot(query(collection(getFirestore(), `/users/${userId}/insights`)), (snap) => {
        const insights = snap.docs.map((doc) => ({
          ...createDefaultInsight(),
          id: doc.id,
          ...doc.data(),
        }))

        this.insights = sortBy(insights, [(o) => o.updatedAt || '']).reverse()

        this.loading = false
      })
    }
  }
  public async unsubscribeFromWorkspace() {
    if (unsubscribeWorkspace) {
      unsubscribeWorkspace()

      unsubscribeWorkspace = undefined
    }
  }
}
