<template>
  <v-dialog v-model="isOpen" max-width="1000px">
    <v-card>
      <v-card-title>{{ texts.title }}</v-card-title>

      <v-divider />

      <v-card-text class="mt-n8">
        <div class="text-pre-wrap">
          <div class="d-flex flex-column">
            <template v-if="noteObject">
              <NoteList :parent-path="`${collectionPath}/${noteObject.metadata?.name}`" :texts="texts" />
            </template>
            <template v-else-if="insight && messages && messages.length">
              <div v-for="message in messages" :key="message.id" class="d-flex flex-row">
                <MessageItem :insight="insight" :message="message" class="message pa-3" />

                <NoteList :texts="texts" :parent-path="`${collectionPath}/${message.id}`" />
              </div>
            </template>
            <template v-else>
              <NoteList :parent-path="`${collectionPath}/${noteId}`" />
            </template>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Close" @click="close()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { noteTexts } from '#views/comments/constants'

  import { Criteria, Feature, Insight, Message } from '#types'

  @Component({})
  class NotesDialog extends Vue {
    public isOpen = false

    public noteId: string = ''

    public texts: any = noteTexts['note']

    public insight: Insight | null = null
    public messages: Message[] | null = null

    public collectionPath: string | null = null

    public noteObject: Feature | Criteria | null = null

    public open({
      noteId,
      noteTitle,
      insight,
      messages,
      noteObject,
      collectionPath,
    }: {
      noteId?: string
      noteTitle?: string
      insight?: Insight
      messages?: Message[]
      collectionPath: string
      noteObject?: Feature | Criteria
    }) {
      if (noteObject && collectionPath) {
        this.noteObject = { ...noteObject }

        this.texts = noteTexts['comment']
      } else if (messages && collectionPath) {
        this.insight = insight ?? null
        this.messages = messages ?? null

        this.texts = noteTexts['note']
      }

      this.noteId = noteId ?? ''

      if (noteTitle) {
        this.texts.title = `Notes for "${noteTitle}"`
      }

      this.collectionPath = collectionPath

      this.isOpen = true
    }

    public close() {
      this.isOpen = false
    }
  }

  export default toNative(NotesDialog)
</script>

<style lang="scss" scoped>
  .message {
    width: 320px;
  }
</style>
