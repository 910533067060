<template>
  <div class="pa-2 heading-6">
    <v-icon class="pr-1" color="white">mdi-alert-circle</v-icon>
    {{ component.text.text }}
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentHint } from '#types'

  @Component({})
  class HintPreview extends Vue {
    @Prop() public component!: UIComponentHint
  }

  export default toNative(HintPreview)
</script>
