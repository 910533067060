<template>
  <v-navigation-drawer
    order="0"
    width="450"
    location="end"
    elevation="0"
    style="right: 0"
    disable-resize-watcher
    :model-value="isOpen"
    :temporary="$vuetify.display.mdAndDown"
    @update:model-value="!$event && close()"
  >
    <v-card v-if="!!edited" class="d-flex flex-column fill-height">
      <v-card-title class="d-flex flex-row shrink">
        <span>{{ !original?.metadata?.changeRecord?.createdAt ? 'Create feature flag' : 'Edit feature flag' }}</span>

        <v-spacer />

        <v-btn icon color="primary" class="mt-n2 mr-n4" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text id="feature-panel" class="fill-height" style="overflow: auto">
        <div id="feature-panel-top" />

        <FeatureEditor :edited="edited" :original="original" @validate="validateFeature($event)" />

        <div id="feature-panel-bottom" />
      </v-card-text>

      <v-card-actions class="d-flex flex-row flex-shrink-1 align-center justify-center">
        <v-btn
          class="my-4"
          color="primary"
          :text="!original?.metadata?.changeRecord?.createdAt ? 'Create' : 'Update'"
          :disabled="!isValid || isLoading || !isProjectEditor(edited)"
          @click="updateFeature()"
        />
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { cloneDeep, isEqual } from 'lodash-es'

  import { useGoTo } from 'vuetify'

  import { Component, Emit, Prop, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Project, project_StaticIdFromJSON } from '@jouzen/control-api/metadata'

  import { getProjectKey, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore, ProjectsStore } from '#stores'

  import { Feature } from '#types'

  @Component({})
  class FeaturePanel extends Vue {
    @Prop() public feature!: Feature

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public isOpen = false
    public isValid = false

    public edited: Feature | null = null
    public original: Feature | null = null

    public readonly isProjectEditor = isProjectEditor

    protected readonly appStore = new AppStore()

    protected readonly projectsStore = new ProjectsStore()
    protected readonly featuresStore = new FeaturesStore()

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get existingProjects() {
      return this.projectsStore.projects
    }

    @Emit('open')
    public open() {
      this.initPanel()

      this.isOpen = true

      this.appStore.navDrawer = 'feature'

      return
    }

    @Emit('close')
    public close() {
      this.appStore.closeNavDrawer('feature')

      this.isOpen = false

      return
    }

    @Emit('change')
    public emitDataChange() {
      return this.edited
    }

    @Watch('appStore.navDrawer', { immediate: true })
    protected navDrawerChanged() {
      if (this.appStore.navDrawer === 'feature') {
        this.open()
      } else {
        this.close()
      }
    }

    public initPanel() {
      console.info('Editing', this.feature)

      this.original = this.feature

      this.edited = cloneDeep(this.original)

      if (!this.original.metadata?.changeRecord?.createdAt) {
        if (!this.edited?.metadata?.informative?.labels?.contact) {
          this.edited.metadata!.informative!.labels.contact = this.appStore.email || ''
        }

        if (this.activeProject && this.activeProject.sections.features) {
          const projectId = this.existingProjects.find((p) => p.id === this.activeProject.id).key || 'testing_waltari'

          const staticProject = project_StaticIdFromJSON(projectId.toUpperCase()) || -1

          this.edited!.metadata!.project =
            staticProject === -1
              ? ({ oneOf: { $case: 'external', external: projectId } } as Project)
              : ({ oneOf: { $case: 'apiStatic', apiStatic: staticProject } } as Project)

          if (!this.edited?.metadata?.informative?.labels?.project) {
            this.edited.metadata!.informative!.labels.project = this.activeProject.id
          }
        }
      }

      this.goTo('#feature-panel-top', { container: '#feature-panel' })

      this.emitDataChange()

      return this.edited
    }

    public async updateFeature() {
      if (!this.edited?.metadata?.changeRecord?.createdAt) {
        const project = this.existingProjects.find(
          (p: any) => p.id === this.edited?.metadata?.informative?.labels?.project,
        )

        const objectId = `${getProjectKey(this.edited!)}/${this.edited!.metadata!.name}`

        const objectName = `${getProjectKey(this.edited!)}/${this.edited!.metadata!.name}`.toUpperCase()

        this.$confirm(
          'Is the name and project correct?',
          'These can not be changed later so please make sure everything is correct!' +
            '<div>' +
            `<div class="text-overline mt-4">ID</div><div class="text-right">${objectId}</div>` +
            `<div class="text-overline mt-4">Name</div><div class="text-right">${objectName}</div>` +
            `<div class="text-overline mt-4">Project</div><div class="text-right">${project.name}</div>` +
            '</div>',
          { width: 640 },
        ).then(async (confirmed) => {
          if (confirmed && this.edited) {
            await this.featuresStore.updateFeature(this.edited)

            this.$router.push(`/features/${getProjectKey(this.edited!)}/${this.edited?.metadata?.name}`)

            this.close()
          }
        })
      } else {
        await this.featuresStore.updateFeature(this.edited)

        this.close()
      }
    }

    public validateFeature(feature: Feature) {
      if (feature && !isEqual(this.edited, this.original)) {
        let isValid = true

        const checkEmptyValues = function (_key: string, value: any) {
          if (typeof value == 'string') {
            isValid = value ? isValid : false
          } else if (value && typeof value == 'object') {
            Object.keys(value).forEach((key) => {
              if (!['labels', 'owners', 'displayName'].includes(key)) {
                checkEmptyValues(key, value[key])
              }
            })
          }
        }

        Object.keys(feature).forEach((key) => {
          const value: any = feature[key as keyof typeof feature]

          if (['metadata', 'overrides', 'parameters'].includes(key)) {
            checkEmptyValues(key, value)
          }
        })

        this.isValid = isValid
      } else {
        this.isValid = false
      }
    }
  }

  export default toNative(FeaturePanel)
</script>

<style lang="scss" scoped>
  :deep(.parameters) {
    .v-select,
    .v-text-field {
      input,
      label,
      .v-select__selection-text {
        font-size: 14px !important;
      }
    }
  }
</style>
