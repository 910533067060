import { sortBy } from 'lodash-es'

export const styleItems = sortBy(
  [
    { value: 'header', title: 'Header' },
    { value: 'title', title: 'Title' },
    { value: 'body', title: 'Body' },
    { value: 'subtitle', title: 'Subtitle' },
    { value: 'subtitle_italic', title: 'Subtitle (Italic)' },
  ],
  'text',
)

export const rewardTypes = [
  {
    title: 'Superpower',
    value: 'superpower',
  },
  {
    title: 'Kryptonite',
    value: 'kryptonite',
  },
  {
    title: 'Improver',
    value: 'improver',
  },
]

export const rewardBadges = [
  'resting_heart_rate',
  'hrv_balance',
  'recovery_index',
  'sleep',
  'sleep_balance',
  'previous_day_activity',
  'activity_balance',
  'total_sleep',
  'efficiency',
  'restfulness',
  'rem_sleep',
  'deep_sleep',
  'latency',
  'timing',
  'stay_active',
  'meet_daily_goals',
  'training_volume',
  'recovery_time',
  'lowest_heart_rate',
  'hrv_average',
  'walking_equivalency',
  'steps',
]

export const cumulativeContributors = [
  'sleep.total',
  'sleep.efficiency',
  'sleep.tranquility',
  'sleep.rem',
  'sleep.deep',
  'sleep.timing',
  'readiness.previous_night',
  'readiness.sleep_balance',
  'readiness.resting_heart_rate',
  'readiness.body_temperature',
  'readiness.hrv_balance',
]

export const componentConditions = {
  badge_reward: {
    key: 'reward',
    value: 'improver',
    condition: 'halfiversary(0).has_improver',
    description: 'Only show if there is an improver',
  },
}
