<template>
  <div class="pa-4">
    <div class="d-flex flex-row align-top">
      <IconPicker class="mr-6" :icon="template.icon" @set-icon="setIcon($event)" />

      <v-text-field v-model="template.name" class="mb-0" label="Name" />
    </div>

    <v-textarea v-model="template.info" label="Info text for the messages / pages / slides" />

    <v-divider class="my-2" />

    <div class="text-h5 mb-4">Settings</div>

    <v-row>
      <v-col cols="6">
        <v-select v-model="selectedPage" label="Available in" :items="templateTypes" />
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          v-model="selectedCategory"
          auto-select-first
          clearable
          label="Category"
          :disabled="selectedPage === 'components'"
          :items="categoryItems"
        />
      </v-col>
    </v-row>

    <v-divider class="my-2" />

    <div class="text-h5 mb-4">Allowed facets</div>

    <v-row>
      <v-col v-for="facet in templateFacets" :key="facet.value" cols="12" sm="3" md="3">
        <v-checkbox :key="facet.value" v-model="selectedFacets" multiple :label="facet.title" :value="facet.value" />
      </v-col>
    </v-row>

    <AllowedComponents
      id="allowedComponents"
      title="Select allowed components"
      :selected-components="allowedComponents"
      @components-changed="componentsChanged($event)"
    />

    <AllowedComponents
      id="requiredComponents"
      title="Select required components"
      :allowed-components="allowedComponents"
      :selected-components="requiredComponents"
      @components-changed="componentsChanged($event)"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { insightCategories, slideshowCategories, tipthemesCategories } from '#views/insights/constants'
  import { templateFacets, templateTypes } from '#views/templates/constants'

  import { createHomeFacet, createNotificationFacet, createViewFacet } from '#views/messages/utilities'

  import { Category, MessageFacets, Template } from '#types'

  @Component({})
  class TemplateSettings extends Vue {
    @Prop() public template!: Template

    @Prop({ default: () => [] }) public allowedPages!: string[]
    @Prop({ default: () => [] }) public allowedFacets!: string[]

    @Prop({ default: () => [] }) public allowedComponents!: string[]
    @Prop({ default: () => [] }) public requiredComponents!: string[]

    public selectedPage: string = ''
    public selectedFacets: string[] = []
    public selectedCategory: string = ''

    public readonly templateTypes = templateTypes
    public readonly templateFacets = templateFacets

    public get categoryItems() {
      let items: Category[] = []
      if (this.selectedPage === 'slideshows') {
        items = slideshowCategories
      } else if (this.selectedPage === 'tipthemes') {
        items = tipthemesCategories
      } else {
        items = insightCategories
      }
      return items
    }

    @Watch('selectedCategory')
    protected selectedCategoryChanged() {
      this.template.category = this.selectedCategory
    }

    @Watch('selectedPage')
    protected selectedPageChanged() {
      this.template.allowedPages = this.selectedPage ? [this.selectedPage] : []
    }

    @Watch('selectedFacets')
    protected selectedFacetsChanged() {
      this.template.allowedFacets = this.selectedFacets

      this.templateFacets.forEach((facet) => {
        if (!this.selectedFacets.includes(facet.value)) {
          this.deleteFacet(facet.value as keyof MessageFacets)
        }
      })

      this.selectedFacets.forEach((key) => {
        if (!this.template.facets[key as keyof MessageFacets]) {
          this.createFacet(key as keyof MessageFacets)
        }
      })
    }

    @Emit('componentsChanged')
    public componentsChanged(component: any) {
      return component
    }

    public mounted() {
      // Initialize the selectedPage with a default value to ensure proper page selection
      this.selectedPage = this.allowedPages ? this.allowedPages[0] : ''
      this.selectedFacets = this.allowedFacets

      this.selectedCategory = this.template?.category ?? ''
    }

    public setIcon(icon: string) {
      this.template.icon = icon
    }

    private deleteFacet(key: keyof MessageFacets) {
      this.template.facets[key] = null
    }

    private createFacet(key: keyof MessageFacets) {
      let data: any = null

      switch (key) {
        case 'home':
          data = createHomeFacet(this.template!.id)
          break
        case 'view':
          data = createViewFacet(this.template!.id)
          break
        case 'notification':
          data = createNotificationFacet(this.template!.id)
          break
      }

      this.template.facets[key] = data
    }
  }

  export default toNative(TemplateSettings)
</script>
