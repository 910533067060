<template>
  <v-dialog v-if="rules && insight && isOpen" v-model="isOpen" width="1200">
    <v-card>
      <v-card-title>
        {{ !!title ? 'Insight rules / condition' : 'Edit rules / condition' }}
      </v-card-title>

      <v-card-text class="d-flex flex-column">
        <div v-if="title" class="text-overline my-4">{{ title }}</div>

        <v-col class="d-flex" style="min-height: 600px">
          <v-col md="4" cols="12" class="pr-8 flex-shrink-1" style="overflow: auto">
            <div v-if="editRule">
              <template v-for="(rule, index) in insight.rules" :key="rule">
                <!-- TODO: Change to chip when the coloring works correctly -->
                <div :class="`rounded-lg d-flex pa-2 bg-${findRule(rule)?.color}-lighten-3`">
                  <p>{{ findRule(rule)?.title }}</p>

                  <v-spacer />

                  <v-icon
                    icon="mdi-trash-can-outline"
                    style="cursor: pointer; font-size: 18px"
                    class="align-self-center ml-2"
                    @click="removeRule(rule)"
                  />
                </div>

                <p v-if="index < insight.rules.length - 1 || insight.rule" :key="`and${rule}`" class="my-2 text-center">
                  AND
                </p>
              </template>

              <v-chip v-if="insight.rule" label text="Insight specific rule" class="mb-2" style="min-width: 100%" />
            </div>

            <div v-else>
              <v-text-field v-model="ruleFilter" autofocus persistent-placeholder placeholder="Filter rules..." />

              <div
                v-for="rule in rules.filter(
                  (r) =>
                    !insight.rules.includes(r.id) &&
                    (!ruleFilter || r.title.toLowerCase().includes(ruleFilter.toLowerCase())),
                )"
                :key="rule.id"
                style="cursor: pointer"
                :class="'rounded-lg mb-2 d-flex pa-2 bg-' + rule.color + '-lighten-3'"
                @click="appendRule(rule)"
              >
                <p class="text-truncate">{{ rule.title }}</p>
              </div>

              <div
                v-if="editedRule"
                style="cursor: pointer"
                :class="'rounded-lg mb-2 d-flex pa-1 bg-' + editedRule.color + '-lighten-3'"
                @click="appendRule(editedRule)"
              >
                <p class="text-truncate">{{ editedRule.title || '(No title given)' }}</p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="8" style="border-left: 1px solid lightgrey" class="pl-8 flex-grow-1">
            <StatementPreview
              readonly
              label="Condition preview"
              :rows="14"
              :value="getCondition(false)"
              :highlight="sortedList.map((r) => r.condition)"
              :highlight-text="getCondition(true)"
              :highlight-color="sortedList.map((r) => r.color + '-lighten-5')"
              :highlight-tooltip="sortedList.map((r) => r.title)"
            />

            <Statement v-model="insight.rule" simplify label="Insight specific rule" :rows="3" />
          </v-col>
        </v-col>
      </v-card-text>

      <v-card-actions>
        <div v-if="!title" class="col-4">
          <v-btn v-if="editRule" size="small" color="primary" text="Add existing rule" @click="editRule = false" />

          <v-btn v-else size="small" color="primary" text="Cancel rule adding" @click="editRule = true" />
        </div>

        <v-spacer />

        <v-btn text="Close" @click="isOpen = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { orderBy } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { FormulasStore } from '#stores'

  import { Formula, Insight } from '#types'

  @Component({})
  class EditRuleDialog extends Vue {
    @Prop() public rules!: Formula[]
    @Prop() public insight!: Insight

    public title = ''

    public isOpen = false
    public editRule = true

    public ruleFilter = ''

    public sortedList: Formula[] = []

    public editedRule: Formula | null = null

    private readonly formulasStore = new FormulasStore()

    @Watch('rules', { immediate: true })
    protected onRulesChanged() {
      this.cancel()

      if (this.rules && this.rules.length) {
        this.sortedList = orderBy(this.rules, [(o) => o.condition.length], 'desc')
      }
    }

    public open(insightId?: string) {
      this.editRule = true

      this.title = insightId || ''

      this.isOpen = true
    }

    public cancel() {
      this.editedRule = null
    }

    public findRule(id: string) {
      return this.rules?.find((r) => r.id === id)
    }

    public appendRule(rule: Formula | null) {
      this.insight!.rules!.push(rule!.id)

      this.editRule = true
    }

    public removeRule(rule: string) {
      this.insight!.rules = this.insight?.rules?.filter((r) => r !== rule) || []
    }

    public getCondition(split: boolean) {
      const rules = [
        this.insight.rules?.map((r) => this.findRule(r)?.condition.trim()).join(' && '),
        this.insight.rules?.length && this.insight.rule ? ' && ' : '',
        this.insight.rule || '',
      ]

      return split ? rules.filter(Boolean) : rules.join('')
    }
  }

  export default toNative(EditRuleDialog)
</script>

<style lang="scss" scoped>
  :deep(.v-chip) {
    .v-chip__content {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      min-width: 100%;
      padding: 6px 12px 6px 0;
      text-overflow: ellipsis;

      .v-chip__close {
        position: absolute;
        top: 7px;
        right: 10px;
        width: 24px;
      }
    }
  }
</style>
