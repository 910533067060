<template>
  <v-app-bar>
    <v-app-bar-title>
      <v-menu v-model="viewMenu" offset="18">
        <template #activator="{ props }">
          <span v-bind="props" style="cursor: pointer">
            Formulas / {{ $route.meta && $route.meta.title }} ({{ formulasList.length }})
          </span>

          <v-icon>mdi-triangle-small-down</v-icon>
        </template>

        <v-list>
          <v-list-item to="/rules" title="Insights" prepend-icon="mdi-lightbulb-on-outline" :disabled="inRules" />
          <v-list-item to="/evals" title="Spotlights" prepend-icon="mdi-lightbulb-spot" :disabled="inEvals" />
        </v-list>
      </v-menu>
    </v-app-bar-title>

    <v-spacer />

    <v-text-field
      v-model="routeParams.filterText"
      clearable
      class="mr-2"
      style="max-width: 500px"
      prepend-icon="mdi-magnify"
      :placeholder="'Type to filter formulas...'"
      @click:clear="routeParams.filterText = ''"
    />

    <v-menu v-model="filterMenu" offset="8" min-width="230" max-height="500" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-filter-variant-plus"
          :active="!!routeParams.filterType"
          :color="props.value ? 'primary' : ''"
        />
      </template>

      <v-list>
        <v-list-subheader>Select additional filter</v-list-subheader>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="true">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Formula type" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item v-for="color in formulaColors" :key="color" dense @click="routeParams.filterType = color">
              <template #prepend>
                <div
                  :class="'bg-' + color + '-lighten-3 mr-4'"
                  style="border-radius: 50%; min-width: 24px; max-width: 24px; min-height: 24px"
                />
              </template>

              <v-list-item-title>{{ formulaCategories[color].title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider />

        <v-list-item
          prepend-icon="mdi-close-circle-outline"
          title="Clear filter"
          :disabled="!routeParams.filterType"
          @click="(routeParams.filterType = ''), (filterMenu = false)"
        />
      </v-list>
    </v-menu>

    <v-menu offset="8">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          :disabled="inEvals"
          :color="props.value ? 'primary' : ''"
          :icon="routeParams.sortOrder === 'alpha' ? 'mdi-sort-alphabetical-ascending' : 'mdi-sort-numeric-descending'"
        />
      </template>

      <v-list>
        <v-list-subheader>Sort order for the list</v-list-subheader>

        <v-list-item
          title="Category and title (asc)"
          prepend-icon="mdi-sort-alphabetical-ascending"
          :disabled="routeParams.sortOrder === 'alpha'"
          @click="routeParams.sortOrder = 'alpha'"
        />

        <v-list-item
          title="Condition count / length (desc)"
          prepend-icon="mdi-sort-numeric-descending"
          :disabled="routeParams.sortOrder === 'count'"
          @click="routeParams.sortOrder = 'count'"
        />
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container class="d-flex flex-column flex-grow-1 flex-shrink-1">
    <v-row class="flex-grow-0">
      <v-col cols="12" md="9">
        <div v-if="inRules" class="text-h5 font-weight-light">Insight rules define when an insights are shown</div>
        <div v-else class="text-h5 font-weight-light">Spotlight evaluations define insights to highlight</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isInsightsEditor">
            Your insights editor rights allow you to view and edit all formulas
          </template>
          <template v-else>You can only view formulas, apply for editor rights in the IT portal</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn text="Create formula" color="primary" :disabled="!isInsightsEditor" @click="createFormula()" />
      </v-col>
    </v-row>

    <v-row v-if="isLoading" align="center" class="flex-grow-1">
      <v-col class="text-center">
        <v-progress-circular class="mt-n12" size="96" color="primary" indeterminate />
      </v-col>
    </v-row>

    <v-row v-else class="d-flex flex-grow-1 flex-shrink-1">
      <v-col cols="12" md="4">
        <span v-if="filteredAndSortedFormulas.length === 0" class="font-weight-light">
          No formulas match the selected filters/project
        </span>
        <div
          id="formulas-list"
          style="overflow: auto; padding-right: 8px; margin-bottom: -30px; max-height: calc(100vh - 365px)"
        >
          <div
            v-for="(r, i) in filteredAndSortedFormulas"
            :id="'item-' + i"
            :key="r.id"
            ref="formulaItemRef"
            style="cursor: pointer"
            class="text-truncate mb-3 px-4 py-2"
            :class="'bg-' + r.color + (editedFormula?.id === r.id ? '-lighten-1 elevation-3' : '-lighten-4')"
            @click="selectFormula(r)"
          >
            {{ r.title || 'New unnamed formula' }}
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="8">
        <div
          style="
            overflow: auto;
            padding-right: 8px;
            margin-right: -8px;
            margin-bottom: -30px;
            max-height: calc(100vh - 365px);
          "
        >
          <CreateFormula
            v-if="!!editedFormula"
            :formula="editedFormula"
            :formulas-path="formulasPath"
            @formula-insights="formulaInsights = $event"
            @migration-insights="selectedInsights = $event"
            @update-save-changes="enableSaveChanges = $event"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>

  <SaveChanges
    v-if="!!editedFormula"
    :error="
      !editedFormula.createdAt
        ? ''
        : editedFormula.project
          ? hasProjectlessInsights
            ? 'Some insights are not assigned to any project'
            : ''
          : 'Formula is not assigned to any project, please assign to a project to be able to save changes'
    "
    :action="editedFormula.condition && selectedInsights.length ? 'migrate' : 'duplicate'"
    :author="author"
    :edited="edited"
    :loading="isLoading"
    :deletable="allowDelete"
    :releasable="allowRelease"
    :current="editedFormula"
    :original="originalFormula"
    :data-source="editedFormula"
    :disabled="
      hasDuplicateTitle ||
      hasProjectlessInsights ||
      !enableSaveChanges ||
      !isInsightsEditor ||
      !editedFormula.title ||
      !editedFormula.project ||
      !editedFormula.description ||
      (inEvals && !editedFormula.order)
    "
    @save="openCommitDialog()"
    @release="openReviewDialog()"
    @confirm-delete="deleteFormula()"
    @execute="$event === 'migrate' ? (commitDialog = true) : duplicateSelectedFormula()"
    @open-history-dialog="openHistoryDialog()"
  />

  <v-dialog v-if="editedFormula && commitDialog" v-model="commitDialog" width="800" :persistent="isMigrating">
    <v-card :loading="isMigrating">
      <v-card-title>Confirm migration</v-card-title>

      <v-card-text>
        <div class="py-2">
          Migration will be run for {{ selectedInsights.length }} insights. Changes will be saved with the following
          commit message:
        </div>

        <v-text-field readonly label="Commit message" :model-value="'Migrated to rule: ' + editedFormula.title" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text="Cancel" :disabled="isMigrating" @click="commitDialog = false" />

        <v-btn text="Confirm" color="primary" :disabled="isMigrating" @click="runMigrateSelectedInsights()" />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <CommitDialog
    ref="commitDialogRef"
    @saved="updateFormulaInsights($event.id)"
    @created="$router.push(`/${rootPath}/${$event.id}`)"
  />

  <HistoryDialog ref="historyDialogRef" @publish="publishFormula()" />
</template>

<script lang="ts">
  import slug from 'slug'

  import { orderBy } from 'lodash-es'

  import { useGoTo } from 'vuetify'

  import { Component, Prop, Ref, Setup, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { serverTimestamp } from 'firebase/firestore'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import { formulaColors, insightFormulaCategories, spotlightFormulaCategories } from '#views/formulas/constants'

  import { createDefaultFormula } from '#views/formulas/utilities'

  import { AppStore, FormulasStore, InsightsStore, ProjectsStore } from '#stores'

  import { Author, Dialog, Formula } from '#types'

  @Component({})
  class FormulasView extends mixins(RouteParams) {
    @Prop() public formulaId!: string

    @Prop() public formulasPath!: string

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public routeParams = {
      sortOrder: 'alpha',
      filterText: '',
      filterType: '',
    }

    public viewMenu = false
    public filterMenu = false
    public isMigrating = false
    public commitDialog = false
    public enableSaveChanges = false

    public formulaInsights: any[] = []
    public selectedInsights: any[] = []

    public author: Author | null = null
    public edited: string | undefined = ''

    public editedFormula: Formula | null = null
    public originalFormula: Formula | null = null

    public readonly formulaColors = formulaColors

    protected readonly appStore = new AppStore()
    protected readonly formulasStore = new FormulasStore()
    protected readonly insightsStore = new InsightsStore()
    protected readonly projectsStore = new ProjectsStore()

    @Ref() protected readonly commitDialogRef: Dialog | null = null
    @Ref() protected readonly historyDialogRef: Dialog | null = null

    @Ref() protected readonly formulaItemRef: HTMLElement[] | null = null

    public get inEvals() {
      return this.appStore.inEvals
    }

    public get inRules() {
      return this.appStore.inRules
    }

    public get rootPath() {
      return this.appStore.route[1]
    }

    public get isLoading() {
      return this.formulasStore.loading
    }

    public get allowDelete() {
      return !this.formulaInsights.length
    }

    public get allowRelease() {
      return (
        this.originalFormula &&
        (!this.originalFormula.releasedAt ||
          (this.originalFormula.updatedAt &&
            this.originalFormula.releasedAt?.toDate() < this.originalFormula.updatedAt?.toDate()))
      )
    }

    public get formulasList() {
      return this.formulasStore.formulas
    }

    public get insightsList() {
      return this.insightsStore.allInsights
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get formulaCategories() {
      return this.inRules ? insightFormulaCategories : spotlightFormulaCategories
    }

    public get hasDuplicateTitle() {
      return this.editedFormula?.createdAt
        ? false
        : this.formulasList.some((r) => r.title.toLowerCase() === this.editedFormula?.title?.toLowerCase())
    }

    public get hasProjectlessInsights() {
      return this.formulaInsights.some((i) => !i.project)
    }

    public get filteredAndSortedFormulas() {
      const formulas = this.formulasList.filter(
        (r) =>
          (!this.routeParams.filterText ||
            r.title.toLowerCase().includes(this.routeParams.filterText.toLowerCase()) ||
            r.condition.includes(this.routeParams.filterText)) &&
          (!this.routeParams.filterType || r.color === this.routeParams.filterType),
      )

      const sortedFormulas = this.inEvals
        ? orderBy(formulas, [(o) => o.order, (o) => o.title], ['asc', 'asc'])
        : this.routeParams.sortOrder === 'alpha'
          ? orderBy(formulas, [(o) => formulaColors.indexOf(o.color), (o) => o.title], ['asc', 'asc'])
          : orderBy(formulas, [(o) => o.condition.split('&&').length, (o) => o.condition.length], ['desc', 'desc'])

      return this.editedFormula && !this.editedFormula.createdAt
        ? [this.editedFormula].concat(sortedFormulas)
        : sortedFormulas
    }

    @Watch('formulaId', { immediate: true })
    protected formulaIdChanged() {
      this.formulasListChanged()
    }

    @Watch('formulasList', { immediate: true })
    protected formulasListChanged() {
      this.editedFormula = null

      for (const rule of this.formulasList) {
        if (this.formulaId && rule.id == this.formulaId) {
          this.editedFormula = { ...rule }
        }
      }

      if (!this.editedFormula && this.formulasList.length) {
        this.editedFormula = { ...this.filteredAndSortedFormulas[0] }

        this.$router.replace(`/${this.rootPath}/${this.editedFormula!.id}`)
      }

      this.originalFormula = this.formulasList.find((r) => r.id === this.formulaId)
    }

    @Watch('editedFormula', { immediate: true })
    protected async editedFormulaChanged() {
      if (this.editedFormula) {
        const latest = await this.formulasStore.fetchLatestHistory(this.editedFormula.id)

        this.author = latest?.author || null
        this.edited = latest?.edited || null

        this.filteredAndSortedFormulasChanged()
      }
    }

    @Watch('filteredAndSortedFormulas', { immediate: true })
    protected async filteredAndSortedFormulasChanged() {
      const index = this.filteredAndSortedFormulas.findIndex((r) => r.id === this.editedFormula?.id)

      if (index > -1 && this.formulaItemRef && this.formulaItemRef[index]) {
        window.setTimeout(() => this.goTo('#item-' + index, { container: '#formulas-list' }), 10)
      }
    }

    public selectFormula(formula: any) {
      this.editedFormula = formula ? { ...formula } : null

      if (formula?.id) {
        this.$router.push(`/${this.rootPath}/${formula.id}`)
      }
    }

    public createFormula() {
      if (!this.editedFormula || this.editedFormula.createdAt) {
        this.originalFormula = null

        this.editedFormula = createDefaultFormula(this.activeProject?.id)

        if (this.inEvals) {
          this.editedFormula!.type = 'change'
          this.editedFormula!.order = this.formulasList.length + 1
        }
      }
    }

    public deleteFormula() {
      if (this.editedFormula?.id) {
        this.$confirm('Are you sure you want to delete this formula?', this.editedFormula.title).then((confirmed) => {
          if (confirmed) {
            const index = this.filteredAndSortedFormulas.findIndex((r) => r.id === this.editedFormula!.id)

            this.formulasStore.deleteFormula(this.editedFormula!.id)

            this.formulasStore.unpublishFormula(this.editedFormula!.id)

            this.selectFormula(
              this.filteredAndSortedFormulas[index] || this.filteredAndSortedFormulas[index - 1] || null,
            )
          }
        })
      }
    }

    public publishFormula() {
      this.formulasStore.publishFormula(this.originalFormula)

      if (this.inEvals && this.originalFormula?.insights) {
        for (const insight of this.originalFormula.insights) {
          this.insightsStore.publishInsight({
            id: insight,
            spotlight: this.originalFormula!.id,
          })
        }
      }
    }

    public openCommitDialog() {
      if (this.inEvals && !this.editedFormula?.createdAt) {
        this.editedFormula!.id = slug(this.editedFormula!.title, '_')
      }

      this.commitDialogRef!.open(
        this.editedFormula!.id,
        `${this.formulasPath}/${this.editedFormula!.id}`,
        this.editedFormula,
        this.originalFormula,
      )
    }

    public openReviewDialog() {
      this.historyDialogRef!.open(
        `${this.formulasPath}/${this.originalFormula!.id}`,
        this.originalFormula!.title,
        this.originalFormula!.releasedAt?.toDate() || this.originalFormula!.createdAt?.toDate(),
      )
    }

    public openHistoryDialog() {
      this.historyDialogRef!.open(`${this.formulasPath}/${this.editedFormula!.id}`, this.editedFormula!.title)
    }

    public duplicateSelectedFormula() {
      if (this.inRules) {
        this.editedFormula = {
          ...createDefaultFormula(),
          title: `${this.editedFormula!.title} (Copy)`,
          project: this.editedFormula!.project,
          condition: this.editedFormula!.condition,
          description: this.editedFormula!.description,
        }
      } else {
        this.editedFormula = {
          ...createDefaultFormula(),
          type: this.editedFormula!.type,
          title: `${this.editedFormula!.title} (Copy)`,
          project: this.editedFormula!.project,
          category: this.editedFormula!.category,
          condition: this.editedFormula!.condition,
          description: this.editedFormula!.description,
        }

        this.editedFormula!.order = this.formulasList.length + 1
      }
    }

    public async updateFormulaInsights(rule: string) {
      if (this.inRules) {
        const updatedConditions: any[] = []

        const formulaInsights = this.insightsList.filter((insight) => insight.rules?.includes(rule))

        const response = await this.insightsStore.exportInsights({
          insightIds: formulaInsights.map((i) => i.id),
        })

        for (const insight of response.insights) {
          const newCondition = insight.content.condition
          const oldCondition = formulaInsights.find((i) => i.id === insight.id)?.condition || ''

          if (oldCondition !== newCondition) {
            updatedConditions.push({
              id: insight.content.id,
              condition: newCondition,
            })
          }
        }

        if (updatedConditions.length) {
          await this.insightsStore.updateConditions({
            rule: this.editedFormula,
            insights: updatedConditions,
            displayName: 'formulas',
          })
        }
      }
    }

    public async runMigrateSelectedInsights() {
      this.isMigrating = true
      this.commitDialog = false

      const author = this.appStore.author

      author.comment = 'Migrated to rule: ' + this.editedFormula!.title

      for (const insight of this.selectedInsights) {
        await this.insightsStore.updateInsight({
          author,
          id: insight.id,
          rule: insight.newRule,
          rules: [...insight.rules, this.editedFormula!.id],
          updatedAt: serverTimestamp(),
        })
      }

      this.isMigrating = false
    }
  }

  export default toNative(FormulasView)
</script>
