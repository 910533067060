<template>
  <div class="fill-height text-left">
    <template v-if="message.facets.view && message.facets.view.components.length">
      <div class="d-flex flex-column pa-1 fill-height">
        <div class="card grow" :class="[category, preview ? 'preview' : '']">
          <div v-if="category === 'slideshow'" class="text-left">
            <v-icon class="close mb-4" color="white">mdi-close</v-icon>
          </div>

          <div :class="[contain ? 'contain' : '']" style="color: white">
            <div v-if="scoreCategories.includes(category)">
              <div class="pb-6 body-2" style="font-size: 16px">
                <b>{{ title }}</b>
              </div>
              <div style="position: relative; overflow: hidden">
                <v-progress-circular
                  :style="'margin-bottom: -' + (preview ? '75' : '100') + 'px'"
                  :size="preview ? '150' : '200'"
                  :indeterminate="false"
                  width="6"
                  bg-color="white"
                  color="grey-lighten-1"
                  value="90"
                />
                <strong
                  style="
                    position: absolute;
                    left: 50%;
                    bottom: 5px;
                    font-size: 2.5em;
                    transform: translateX(-50%);
                    line-height: 1;
                  "
                >
                  80
                </strong>
              </div>

              <strong class="pt-4" style="font-size: 12px"><b>Good</b></strong>
            </div>

            <div v-for="component in components" :key="component.id">
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <div v-bind="props">
                    <component
                      :is="componentMappingForType(component.type).previewComponent"
                      :id="message.id"
                      :name="component.type"
                      :preview="preview"
                      :category="category"
                      :component="component"
                    />
                  </div>
                </template>
                <span>{{ component.type }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center text-white pa-4">No view facet added!</div>
    </template>
  </div>
</template>

<script lang="ts">
  import { startCase } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { componentMapping } from '#views/messages/constants'

  import { ComponentMapping, Message } from '#types'

  @Component({})
  class ViewFacetPreview extends Vue {
    @Prop() public message!: Message
    @Prop() public category!: string

    @Prop() public contain!: boolean
    @Prop() public preview!: boolean
    @Prop() public simulate!: boolean

    public scoreCategories = ['activity', 'readiness', 'sleep']

    public get title() {
      return startCase(this.category)
    }

    public get components() {
      return this.message?.facets?.view?.components || []
    }

    public componentMappingForType(type: string): ComponentMapping {
      const component = componentMapping.find((c) => c.type === type)

      if (component) {
        return component
      }

      console.error(`Unknown component type ${this.message?.id} ${type}`)

      return {} as any
    }
  }

  export default toNative(ViewFacetPreview)
</script>

<style lang="scss" scoped>
  .card {
    background: rgba(36, 71, 123, 0.8);
    border-radius: 12px;
    padding: 0.5em 0.25em;
    font-size: 16px;
    text-align: center;

    &.preview {
      font-size: 12px;
    }
  }

  .contain {
    overflow: auto;
    max-height: 330px;
  }

  .slideshow {
    border-radius: 4px;
    text-align: left;
  }
</style>
