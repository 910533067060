<template>
  <StatusHeader :feature="feature" :override="activeOverride" :rollout-view="rolloutView" />
  <v-card>
    <StatusConfig
      v-if="activeOverride"
      :feature="feature"
      :override="activeOverride"
      :overrides="overrides"
      :references="references"
      :rollout-view="rolloutView"
      :preview-only="isLoading || previewOnly"
    />

    <v-card-text class="d-flex flex-column">
      <v-progress-circular
        v-if="isLoading || !activeOverride"
        class="justify-self-center align-self-center ma-16"
        :size="96"
      />

      <template v-else>
        <div class="d-flex flex-row align-center">
          <div class="text-primary text-h6 font-weight-medium pa-4 mb-n2">Rollout plan and configuration</div>

          <v-spacer />

          <v-tooltip
            location="start"
            :text="
              'Rollout last edited by ' +
              (activeOverride.metadata?.informative?.additionalData?.updatedBy ||
                activeOverride.metadata?.informative?.additionalData?.createdBy ||
                'system / unknown user') +
              ' - ' +
              $dayjs(
                activeOverride.metadata?.informative?.additionalData?.updatedAt ||
                  activeOverride.metadata?.informative?.additionalData?.createdAt,
              ).format('HH:mm on DD MMM YYYY')
            "
          >
            <template #activator="{ props }">
              <v-btn v-bind="props" class="mr-2" icon="mdi-clock-edit-outline" />
            </template>
          </v-tooltip>

          <v-tooltip location="start" text="Show past stages">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="mr-4"
                icon="mdi-calendar-start-outline"
                :active="routeParams.showHistory === 'true'"
                @click="routeParams.showHistory = routeParams.showHistory === 'false' ? 'true' : 'false'"
              />
            </template>
          </v-tooltip>

          <v-select
            v-model="routeParams.timeDisplay"
            hide-details
            label="Time display"
            density="compact"
            style="max-width: 230px"
            :items="timeOptions"
          />
        </div>

        <StatusStages
          :feature="feature"
          :override="activeOverride"
          :rollout-view="rolloutView"
          :time-display="routeParams.timeDisplay"
          :show-history="routeParams.showHistory === 'true'"
        />

        <StatusParams :feature="feature" :override="activeOverride" :rollout-view="rolloutView" />

        <StatusLabels
          :feature="feature"
          :override="activeOverride"
          :rollout-view="rolloutView"
          :time-display="routeParams.timeDisplay"
        />

        <div class="text-primary text-h6 font-weight-medium pa-4 mt-8">Users targeting criteria / segments</div>

        <CriteriaChart
          v-if="activeOverride && allCriterias.length > 0"
          class="ma-4"
          :height="500"
          :criteria="activeOverride.criteria"
          :criterias="allCriterias"
        />
      </template>
    </v-card-text>
  </v-card>

  <StatusControl
    v-if="activeOverride?.metadata?.informative?.labels?.rollout !== 'disable'"
    :feature="feature"
    :override="activeOverride"
    :overrides="overrides"
    :references="references"
    :rollout-view="rolloutView"
  />
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import { timeOptions } from '#views/features/constants'

  import { FeaturesStore, SegmentsStore } from '#stores'

  import { Feature, Override, Reference, RolloutTarget } from '#types'

  @Component({})
  class RolloutsInfo extends mixins(RouteParams) {
    @Prop() public active!: number

    @Prop() public feature!: Feature
    @Prop() public overrides!: Override[]
    @Prop() public references!: Reference[]

    @Prop() public previewOnly!: boolean

    @Prop() public rolloutView!: RolloutTarget

    public routeParams = {
      timeDisplay: 'local',
      showHistory: 'false',
    }

    public readonly timeOptions = timeOptions

    private readonly featuresStore = new FeaturesStore()
    private readonly segmentsStore = new SegmentsStore()

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get allCriterias() {
      return this.segmentsStore.criterias
    }

    public get activeOverride() {
      return this.overrides[this.active]
    }
  }

  export default toNative(RolloutsInfo)
</script>
