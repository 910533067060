import { measurements } from '../constants'
import { createDefaultTranslatable } from '../utilities'

import {
  UIComponentActivityIntensity,
  UIComponentBadgeReward,
  UIComponentButton,
  UIComponentChart,
  UIComponentContentRecommendation,
  UIComponentContributor,
  UIComponentDefault,
  UIComponentDefaultText,
  UIComponentDefaultTitle,
  UIComponentDelta,
  UIComponentHint,
  UIComponentIdealBedtimeChart,
  UIComponentLabel,
  UIComponentMeasurementStack,
  UIComponentNightTimeHeartRate,
  UIComponentNightTimeHeartRateVariability,
  UIComponentParagraph,
  UIComponentPicture,
  UIComponentQuestionnaire,
  UIComponentRichText,
  UIComponentScore,
  UIComponentSetting,
  UIComponentSleepClock,
  UIComponentSleepStages,
  UIComponentTitle,
  UIComponentType,
} from '#types'

import { prefixedId } from '#utilities'

const ComponentText = <UIComponentType>(
  type: UIComponentType,
  prefix: string,
): UIComponentDefault<UIComponentType> => ({
  type: type,
  id: prefixedId(prefix),
})

const UIComponentWithText = <UIComponentType>(
  type: UIComponentType,
  prefix: string,
  translationDefault: string,
): UIComponentDefaultText<UIComponentType> => ({
  ...ComponentText(type, prefix),
  text: createDefaultTranslatable(prefix, translationDefault),
})

const UIComponentWithTitle = <UIComponentType>(
  type: UIComponentType,
  prefix: string,
  translationDefault: string,
): UIComponentDefaultTitle<UIComponentType> => ({
  ...ComponentText(type, prefix),
  title: createDefaultTranslatable(prefix, translationDefault),
})

const UIComponentHintDefault = (prefix: string): UIComponentHint =>
  UIComponentWithText(UIComponentType.hint, prefix, 'hint text')

const UIComponentContributorDefault = (prefix: string): UIComponentContributor => ({
  ...UIComponentWithTitle(UIComponentType.contributor, prefix, 'contributor title'),
  contributor: 'activity.stay_active',
})

const UIComponentActivityIntensityDefault = (prefix: string): UIComponentActivityIntensity =>
  UIComponentWithTitle(UIComponentType.activityIntensity, prefix, 'activity intensity chart title')

const UIComponentTitleDefault = (prefix: string): UIComponentTitle =>
  UIComponentWithTitle(UIComponentType.title, prefix, 'title text')

const UIComponentChartDefault = (prefix: string): UIComponentChart => ({
  ...UIComponentWithTitle(UIComponentType.chart, prefix, 'chart title'),
  presentation: 'bar',
  mood: 'good',
  measurement: measurements[0].value,
  interval: 'daily',
  length: 7,
  average_line: 'all',
  offset: 0,
  hide_x_axis: false,
})

const UIComponentButtonDefault = (prefix: string): UIComponentButton => ({
  ...UIComponentWithTitle(UIComponentType.button, prefix, 'button title'),
  analytics_id: '',
  url: '',
  style: 'small_filled',
})

const UIComponentIdealBedtimeChartDefault = (prefix: string): UIComponentIdealBedtimeChart =>
  UIComponentWithTitle(UIComponentType.idealBedtimeChart, prefix, 'ideal bedtime chart title')

const UIComponentMeasurementStackDefault = (prefix: string): UIComponentMeasurementStack => ({
  ...ComponentText(UIComponentType.measurementStack, prefix),
  measurements: [],
})

const UIComponentLabelDefault = (prefix: string): UIComponentLabel => ({
  ...UIComponentWithText(UIComponentType.label, prefix, 'label text'),
  javascript: false,
  style: 'body',
})

const UIComponentNightTimeHeartRateDefault = (prefix: string): UIComponentNightTimeHeartRate =>
  UIComponentWithTitle(UIComponentType.nightTimeHeartRate, prefix, 'night time heart rate chart title')

const UIComponentNightTimeHeartRateVariabilityDefault = (prefix: string): UIComponentNightTimeHeartRateVariability =>
  UIComponentWithTitle(UIComponentType.nightTimeHRV, prefix, 'night time heart rate variability chart title')

const UIComponentParagraphDefault = (prefix: string): UIComponentParagraph => ({
  ...ComponentText(UIComponentType.paragraph, prefix),
  contents: createDefaultTranslatable(prefix, 'paragraph'),
})

const UIComponentQuestionnaireDefault = (prefix: string): UIComponentQuestionnaire => ({
  ...ComponentText(UIComponentType.questionnaire, prefix),
  mnemonic: '',
  select_min: 0,
  select_max: 0,
  options: [],
})

const UIComponentRichTextDefault = (prefix: string): UIComponentRichText => ({
  ...ComponentText(UIComponentType.richText, prefix),
  contents: {
    ...createDefaultTranslatable(prefix, 'rich text'),
    text: '<node-paragraph-m><p></p></node-paragraph-m>',
  },
  placeholders: [],
})

const UIComponentScoreDefault = (prefix: string): UIComponentScore => ({
  ...UIComponentWithText(UIComponentType.score, prefix, 'score title'),
  category: 'activity',
})

const UIComponentSettingDefault = (prefix: string): UIComponentSetting => ({
  ...ComponentText(UIComponentType.setting, prefix),
  setting: 'battery_level_notifications',
})

const UIComponentSleepClockDefault = (prefix: string): UIComponentSleepClock =>
  ComponentText(UIComponentType.sleepClock, prefix)

const UIComponentSleepStagesDefault = (prefix: string): UIComponentSleepStages => ({
  ...UIComponentWithTitle(UIComponentType.sleepStages, prefix, 'sleep stages chart title'),
  show_movement_graph: false,
})

const UIComponentDeltaDefault = (prefix: string): UIComponentDelta => ({
  ...ComponentText(UIComponentType.delta, prefix),
  category: 'sleep_score',
})

const UIComponentPictureDefault = (prefix: string): UIComponentPicture => ({
  ...ComponentText(UIComponentType.picture, prefix),
  image: '',
})

const UIComponentContentRecommendationDefault = (prefix: string): UIComponentContentRecommendation => ({
  ...ComponentText(UIComponentType.contentRecommendation, prefix),
  counter: '',
  contents: [
    {
      days: 1,
      text: createDefaultTranslatable(prefix, 'audio recommendation text'),
      content: '',
    },
  ],
})

const UIComponentBadgeRewardDefault = (prefix: string): UIComponentBadgeReward => ({
  ...ComponentText(UIComponentType.badgeReward, prefix),
  count: 1,
  reward: 'superpower',
  badges: [],
})

export function createUIComponentDefaultData(
  type: UIComponentType,
  prefix: string,
): UIComponentDefault<UIComponentType> | null {
  switch (type) {
    case UIComponentType.hint:
      return UIComponentHintDefault(prefix)
    case UIComponentType.idealBedtimeChart:
      return UIComponentIdealBedtimeChartDefault(prefix)
    case UIComponentType.label:
      return UIComponentLabelDefault(prefix)
    case UIComponentType.measurementStack:
      return UIComponentMeasurementStackDefault(prefix)
    case UIComponentType.nightTimeHRV:
      return UIComponentNightTimeHeartRateVariabilityDefault(prefix)
    case UIComponentType.nightTimeHeartRate:
      return UIComponentNightTimeHeartRateDefault(prefix)
    case UIComponentType.paragraph:
      return UIComponentParagraphDefault(prefix)
    case UIComponentType.questionnaire:
      return UIComponentQuestionnaireDefault(prefix)
    case UIComponentType.richText:
      return UIComponentRichTextDefault(prefix)
    case UIComponentType.score:
      return UIComponentScoreDefault(prefix)
    case UIComponentType.setting:
      return UIComponentSettingDefault(prefix)
    case UIComponentType.sleepClock:
      return UIComponentSleepClockDefault(prefix)
    case UIComponentType.sleepStages:
      return UIComponentSleepStagesDefault(prefix)
    case UIComponentType.title:
      return UIComponentTitleDefault(prefix)
    case UIComponentType.button:
      return UIComponentButtonDefault(prefix)
    case UIComponentType.contributor:
      return UIComponentContributorDefault(prefix)
    case UIComponentType.activityIntensity:
      return UIComponentActivityIntensityDefault(prefix)
    case UIComponentType.chart:
      return UIComponentChartDefault(prefix)
    case UIComponentType.delta:
      return UIComponentDeltaDefault(prefix)
    case UIComponentType.picture:
      return UIComponentPictureDefault(prefix)
    case UIComponentType.contentRecommendation:
      return UIComponentContentRecommendationDefault(prefix)
    case UIComponentType.badgeReward:
      return UIComponentBadgeRewardDefault(prefix)
    default:
      console.error('Unknown UI component type:', type)
      return null
  }
}
