import { Project, project_StaticIdFromJSON, project_StaticIdToJSON } from '@jouzen/control-api/metadata'

import { AppStore, ProjectsStore } from '#stores'

import { Criteria, Feature, Override } from '#types'

// Feature flag project getter helpers

export function isProjectEditor(data: Feature | Criteria | Override) {
  const appStore = new AppStore()
  const projectsStore = new ProjectsStore()

  // TODO: Remove any hack once metadata fixed in backend

  const project = projectsStore.projects.find((p) => p.id === data?.metadata?.informative?.labels?.project)

  return (
    appStore.isOnCallUser ||
    appStore.isWaltariAdmin ||
    (appStore.isAppFeatureAdmin &&
      (appStore.email === data?.metadata?.changeRecord?.createdBy ||
        (project?.editors || []).concat(project?.owner, project?.admins).includes(appStore.email)))
  )
}

export function getProjectKey(data: Feature | Criteria) {
  return data.metadata!.project!.oneOf!.$case === 'external'
    ? data.metadata!.project!.oneOf!.external || 'unknown'
    : project_StaticIdToJSON(data.metadata!.project!.oneOf!.apiStatic).toLowerCase()
}

export function getProjectName(data: Feature | Criteria | Override) {
  const projectsStore = new ProjectsStore()

  // TODO: Remove any hack once metadata fixed in backend

  return projectsStore.projects.find((p) => p.id === data?.metadata?.informative?.labels?.project)?.name
}

export function getProjectTeam(data: Feature | Criteria | Override) {
  const projectsStore = new ProjectsStore()

  // TODO: Remove any hack once metadata fixed in backend

  return projectsStore.projects.find((p) => p.id === data?.metadata?.informative?.labels?.project)?.team
}

// Feature flag project creation helpers

export function createProjectData(project: string) {
  const projectsStore = new ProjectsStore()

  const projectId = projectsStore.projects.find((p) => p.id === project)?.key || 'testing_waltari'

  const staticProject = project_StaticIdFromJSON(projectId.toUpperCase()) || -1

  return staticProject === -1
    ? ({ oneOf: { $case: 'external', external: projectId } } as Project)
    : ({ oneOf: { $case: 'apiStatic', apiStatic: staticProject } } as Project)
}
