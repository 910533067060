<template>
  <div class="pa-1">
    <template v-if="loading">
      <div class="text-center" style="margin-top: calc(50vh - 256px)">
        <v-progress-circular size="96" color="primary" indeterminate />
      </div>
    </template>
    <template v-else>
      <div v-if="!filteredInsights.length" class="text-h6 text-center font-weight-thin" style="margin-top: 20vh">
        No insights matching the filters, please refine your search.
      </div>

      <div
        v-for="insight in filteredInsights"
        v-else
        :key="insight.id"
        :class="activeInsight === insight.id ? 'mb-6' : 'mb-3'"
      >
        <div :ref="insight.id">
          <div v-intersect.once="onIntersect" :data-id="insight.id">
            <InsightDrawer
              v-if="intersected.includes(insight.id)"
              :store="store"
              :stats="stats"
              :insight="insight"
              :active="insight.id === activeInsight"
              :insights-path="insightsPath"
              @edit-state="editState(insight)"
              @edit-insight="editInsight(insight.id)"
              @show-notes="$router.push(`/${rootPath}/${insight.id}/notes`)"
            />
          </div>
        </div>

        <div v-if="!intersected.includes(insight.id)" :style="`min-height: 105px;`" @click="editInsight(insight.id)">
          <v-skeleton-loader type="list-item" />
        </div>
      </div>
    </template>

    <ChangeStateDialog ref="changeStateDialog" />
    <SendToReview ref="sendToReviewDialog" />
  </div>
</template>

<script lang="ts">
  import { compact, orderBy, uniqBy } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { calculateRatio } from '#views/insights/utilities'

  import { AppStore, ContentsStore, InsightsStore, SlideshowsStore, TipthemesStore, WorkspaceStore } from '#stores'

  import { Dialog, Insight } from '#types'

  @Component({})
  class InsightsList extends Vue {
    @Prop() public store!: string

    @Prop() public insightsPath!: string

    @Prop() public sortOrder?: string
    @Prop() public tagFilters?: string[]
    @Prop() public stateFilters?: string[]
    @Prop() public categoryFilters?: string[]
    @Prop() public insightIdFilters?: string[]

    public declare $refs: {
      changeStateDialog: Dialog
      sendToReviewDialog: Dialog
    }

    public filteredInsights: Insight[] = []
    public intersected: string[] = []

    private scrolled = false

    protected appStore = new AppStore()

    protected workspaceStore = new WorkspaceStore()

    protected contentsStore = new ContentsStore()
    protected insightsStore = new InsightsStore()
    protected tipthemesStore = new TipthemesStore()
    protected slideshowsStore = new SlideshowsStore()

    public get rootPath() {
      return this.appStore.route[1]
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    @Emit('updateInsightCount')
    public updateInsightCount() {
      return this.filteredInsights.length
    }

    @Watch('store')
    protected storeChanged() {
      this.updateFilteredInsights()
    }

    @Watch('insights')
    protected insightsUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('sortOrder')
    protected sortOrderUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('tagFilters')
    protected tagFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('stateFilters')
    protected stateFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('categoryFilters')
    protected categoryFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('insightIdFilters')
    protected insightIdFiltersUpdated() {
      this.updateFilteredInsights()
    }

    public get stats(): any {
      return this[`${this.store}Store` as keyof InsightsList].stats
    }

    public get insightsStatistics(): any {
      return this[`${this.store}Store` as keyof InsightsList].insightsStatistics
    }

    public get loading(): any {
      return this[`${this.store}Store` as keyof InsightsList].loading
    }

    public get insights(): any {
      return this[`${this.store}Store` as keyof InsightsList].insights
    }

    public get activeInsight(): any {
      return this[`${this.store}Store` as keyof InsightsList].activeInsight
    }

    public get searchResults(): any {
      return this[`${this.store}Store` as keyof InsightsList].searchResults
    }

    public updated() {
      if (this.activeInsight) {
        const element: any = this.$refs[this.activeInsight as keyof typeof this.$refs]

        if (element && !this.scrolled) {
          const top = element[0]?.offsetTop
          window.scrollTo(0, top - 165)
          this.scrolled = true
        }
      }
    }

    public async mounted() {
      this.updateFilteredInsights()
    }

    public beforeUnmount() {
      this[`${this.store}Store` as keyof InsightsList].activeInsight = null
    }

    public onIntersect(_intersecting: boolean, entries: any[]) {
      if (entries[0] && !this.intersected.includes(entries[0].target.getAttribute('data-id'))) {
        this.intersected.push(entries[0].target.getAttribute('data-id'))
      }
    }

    public editState(insight: Insight) {
      if (this.inWorkspace && insight.state === 'created') {
        this.$refs.sendToReviewDialog.open(insight.id, `${this.insightsPath}/${insight.id}`)
        /*} else if (this.inWorkspace && insight.state !== 'created') {
      this.$refs.messageDialog.open(insight, insight.author?.uid) */
      } else {
        this.$refs.changeStateDialog.open(insight, `${this.insightsPath}/${insight.id}`, 'Insight')
      }
    }

    public editInsight(id: string) {
      this[`${this.store}Store` as keyof InsightsList].activeInsight = id

      this.$router.push(`/${this.rootPath}/${id}`)
    }

    @Debounce(100)
    public updateFilteredInsights() {
      let insights = [...this.insights]

      // Apply filters
      insights = this.applyTagFilters(insights)
      insights = this.applyStateFilters(insights)
      insights = this.applyCategoryFilters(insights)
      insights = this.applyIdFilters(insights)

      // Remove duplicates
      insights = uniqBy(insights, 'id')

      // Add statistics to insights
      insights = this.addStatisticsToInsights(insights)

      // Sort insights
      insights = this.sortInsights(insights)

      // Update search results
      this[`${this.store}Store` as keyof InsightsList].searchResults = insights

      this.filteredInsights = insights

      this.updateInsightCount()
    }

    private applyTagFilters(insights: Insight[]): Insight[] {
      if (this.tagFilters?.length) {
        insights = insights.filter((insight) => insight.tags.some((t: string) => this.tagFilters!.includes(t)))
      }

      return insights
    }

    private applyStateFilters(insights: Insight[]): Insight[] {
      if (this.stateFilters?.length) {
        insights = insights.filter((insight) => insight.state && this.stateFilters!.includes(insight.state))
      } else {
        insights = insights.filter((insight) => insight.state && insight.state !== 'archived')
      }

      return insights
    }

    private applyCategoryFilters(insights: Insight[]): Insight[] {
      if (this.categoryFilters?.length) {
        insights = insights.filter((insight) => this.categoryFilters!.includes(insight.category))
      }

      return insights
    }

    private applyIdFilters(insights: Insight[]): Insight[] {
      if (this.insightIdFilters) {
        // We want to order the insights in the same order as the search results indicate
        insights = compact(this.insightIdFilters.map((id) => insights.find((insight) => insight.id === id)))
      }

      return insights
    }

    private addStatisticsToInsights(insights: Insight[]): Insight[] {
      if (this.insightsStatistics?.length > 0) {
        insights = insights.map((insight: Insight) => {
          const insightStats = this.insightsStatistics.find(
            (insightStat: any) => insightStat.insightName.toLowerCase() === insight.id,
          )

          return {
            ...insight,
            generated: insightStats ? insightStats?.generated : 0,
            viewed: insightStats ? insightStats.viewed : 0,
            clicked: insightStats ? insightStats.clicked : 0,
            viewedRatio: insightStats ? calculateRatio(insightStats.viewed, insightStats.generated) : 0,
            clickedRatio: insightStats ? calculateRatio(insightStats.clicked, insightStats.generated) : 0,
          }
        })
      }

      return insights
    }

    private sortInsights(insights: Insight[]): Insight[] {
      const sortOrderMap: { [key: string]: string } = {
        generated: 'generated',
        viewed: 'viewedRatio',
        clicked: 'clickedRatio',
        alphabetical: 'id',
      }

      const sortKey = this.sortOrder && sortOrderMap[this.sortOrder]

      return sortKey ? orderBy(insights, [sortKey], ['desc']) : insights
    }
  }

  export default toNative(InsightsList)
</script>
