<template>
  <v-card-text>
    <v-timeline align-top side="end" density="compact">
      <Note v-for="note in notes" :key="note.id" :note="note" />
      <v-timeline-item key="new-message" icon="mdi-account" width="100%" :dot-color="'blue'">
        <v-card elevation="2">
          <v-card-title>
            <div class="text-body-1 bold">Write a {{ (texts && texts.single) || 'note / response' }}:</div>
          </v-card-title>

          <v-card-text class="mb-n8">
            <v-textarea
              v-model="message"
              autofocus
              rows="2"
              placeholder="Write here..."
              :auto-grow="true"
              @keypress.ctrl.enter="submitNote()"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              text="Send"
              color="green"
              prepend-icon="mdi-send"
              :loading="isSending"
              :disabled="message.length === 0"
              @click="submitNote()"
            />
          </v-card-actions>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card-text>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import {
    Unsubscribe,
    collection,
    doc,
    getFirestore,
    onSnapshot,
    query,
    serverTimestamp,
    setDoc,
  } from 'firebase/firestore'

  import { AppStore } from '#stores'

  @Component({})
  class NoteList extends Vue {
    @Prop() public texts!: any
    @Prop() public parentPath!: string

    public notes: any[] = []

    public message = ''

    public isSending = false

    private appStore = new AppStore()

    private unsubscribe: Unsubscribe | undefined = undefined

    @Watch('parentPath')
    public parentPathChanged() {
      this.subscribeNotes()
    }

    public subscribeNotes() {
      this.unsubscribe = onSnapshot(query(collection(getFirestore(), `${this.parentPath}/notes`)), (snap) => {
        this.notes = snap.docs.map((doc) => ({
          ...doc.data(),
          date:
            doc.data().date !== null
              ? doc.data().date.toDate().toLocaleString('default', {
                  hour: 'numeric',
                  minute: 'numeric',
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })
              : '',
          message: `<p>${doc.data().message.replace(/\n/gi, '</p><p>')}</p>`,
        }))
      })
    }

    public created() {
      this.subscribeNotes()
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }
    }

    public async submitNote() {
      if (this.message) {
        this.isSending = true

        await setDoc(
          doc(getFirestore(), `${this.parentPath}`),
          {
            notes: this.notes.length + 1,
          },
          { merge: true },
        )

        await setDoc(doc(getFirestore(), `${this.parentPath}/notes/${+new Date()}`), {
          date: serverTimestamp(),
          author: this.appStore.author,
          message: this.message,
        })

        this.isSending = false

        this.message = ''
      }
    }
  }

  export default toNative(NoteList)
</script>
