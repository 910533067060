export const insightsMigrationMap: any = {
  'c765ea39-1db8-4e5c-9853-f3ef746a0e7f': 'reasearch_mode_generic',
  'd2dc0259-061a-4759-b97d-a9dd8bbbbfa7': 'onboarding_hrv_balance_old',
  '0b568687-3820-46da-8ce4-d029d41a57c9': 'sleep_score_good_catch_all',
  '25d4e006-1bd6-4f58-8038-b310c030eed6': 'readiness_score_optimal_catch_all',
  '9c06607d-e581-4839-84fc-980e78b17b77': 'total_sleep_good_readiness_bad',
  '1afa4ae9-b146-4be9-a836-1887fc21869b': 'onboarding_movement_during_sleep',

  'Recharge_intro_7d_rest_low_exercise_high': 'recharge_intro_7d_rest_low_exercise_high',
  'Recharge_intro_activity_target_changed_low': 'recharge_intro_activity_target_changed_low',
  'Recharge_intro_efficiency_bad_latency_high_once': 'recharge_intro_efficiency_bad_latency_high_once',
  'Recharge_intro_latency_high_triple': 'recharge_intro_latency_high_triple',
  'Recharge_intro_previous_day_bad_activity_too_score_good_once':
    'recharge_intro_previous_day_bad_activity_too_score_good_once',
  'Recharge_intro_previous_night_bad_score_bad_once': 'recharge_intro_previous_night_bad_score_bad_once',
  'Recharge_intro_readiness_bad': 'recharge_intro_readiness_bad',
  'Recharge_intro_rem_sleep_bad_activity_high_once': 'recharge_intro_rem_sleep_bad_activity_high_once',
  'Recharge_intro_restfulness_bad_evenly_distributed_once': 'recharge_intro_restfulness_bad_evenly_distributed_once',
  'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once':
    'recharge_intro_resting_hr_time_bad_score_bad_activity_low_once',
  'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once':
    'recharge_intro_resting_hr_time_bad_score_good_activity_high_once',
  'Recharge_intro_score_trending_down_already_bad': 'recharge_intro_score_trending_down_already_bad',
  'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once':
    'recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once',
}

export const messagesMigrationMap: any = {
  '24h_inactivity_low_7d_exercise_high_0': '24h_inactivity_low_7d_exercise_high:5975i9',
  '24h_inactivity_low_7d_exercise_high_1': '24h_inactivity_low_7d_exercise_high:g28nkx',
  '24h_inactivity_low_7d_exercise_high_2': '24h_inactivity_low_7d_exercise_high:quloma',
  '24h_inactivity_low_7d_exercise_high_3': '24h_inactivity_low_7d_exercise_high:z0ltfp',
  '6fef7ca4-061d-4e36-a81b-6c5fb6b9b870': 'activity_target_changed_readiness_high:7j7jv5',
  'Recharge_intro_rem_sleep_bad_activity_high_once_0': 'recharge_intro_rem_sleep_bad_activity_high_once:vn39br',
  'Recharge_intro_efficiency_bad_latency_high_once_0': 'recharge_intro_efficiency_bad_latency_high_once:fqbdtf',
  'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once_0':
    'recharge_intro_resting_hr_time_bad_score_good_activity_high_once:lmmw76',
  'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once_0':
    'recharge_intro_resting_hr_time_bad_score_bad_activity_low_once:68yasx',
  'Recharge_intro_latency_high_triple_0': 'recharge_intro_latency_high_triple:11pit3',
  'Recharge_intro_activity_target_changed_low_0': 'recharge_intro_activity_target_changed_low:ft3m50',
  'activity_target_changed_high_0': 'activity_target_changed_high:ssr3i4',
  'activity_target_changed_low_0': 'activity_target_changed_low:2sq609',
  'activity_target_changed_low_1': 'activity_target_changed_low:jg7uzm',
  'activity_balance_too_high_score_bad_once_0': 'activity_balance_too_high_score_bad_once:bi9vxl',
  'activity_balance_too_high_score_bad_once_2': 'activity_balance_too_high_score_bad_once:uivbdl',
  'activity_balance_too_high_score_bad_once_3': 'activity_balance_too_high_score_bad_once:fs7ub6',
  'Recharge_intro_7d_rest_low_exercise_high_0': 'recharge_intro_7d_rest_low_exercise_high:9igtb6',
  'Recharge_intro_score_trending_down_already_bad_0': 'recharge_intro_score_trending_down_already_bad:ji2v4n',
  'activity_balance_good_lowish_score_bad_once_0': 'activity_balance_good_lowish_score_bad_once:2zkn1q',
  'activity_balance_good_lowish_score_bad_once_2': 'activity_balance_good_lowish_score_bad_once:43na1y',
  'activity_balance_good_lowish_score_bad_once_3': 'activity_balance_good_lowish_score_bad_once:36yhk1',
  'activity_balance_good_score_bad_once_0': 'activity_balance_good_score_bad_once:g7604s',
  'activity_balance_good_score_bad_once_2': 'activity_balance_good_score_bad_once:83t7d7',
  'activity_balance_good_score_bad_once_3': 'activity_balance_good_score_bad_once:yposor',
  '7d_rest_low_exercise_high_0': '7d_rest_low_exercise_high:pkxc5k',
  '7d_rest_low_exercise_high_1': '7d_rest_low_exercise_high:m54162',
  '7d_rest_low_exercise_high_2': '7d_rest_low_exercise_high:aziebr',
  '7d_rest_low_exercise_high_3': '7d_rest_low_exercise_high:w7gbyd',
  '7d_rest_low_exercise_high_4': '7d_rest_low_exercise_high:e0kps7',
  'goal_healthy_sleep_balance_bad_0': 'goal_healthy_sleep_balance_bad:rthtcn',
  'goal_healthy_sleep_balance_good_0': 'goal_healthy_sleep_balance_good:cz6kko',
  'goal_healthy_sleep_balance_good_1': 'goal_healthy_sleep_balance_good:brcle1',
  'goal_healthy_resting_hr_time_bad_0': 'goal_healthy_resting_hr_time_bad:ti0tl0',
  'goal_healthy_resting_hr_bad_too_much_activity_0': 'goal_healthy_resting_hr_bad_too_much_activity:uiiwra',
  'goal_healthy_recovery_index_bad_0': 'goal_healthy_recovery_index_bad:b0jdvh',
  'goal_healthy_recovery_index_good_0': 'goal_healthy_recovery_index_good:dl6vn0',
  'goal_healthy_resting_hr_optimal_0': 'goal_healthy_resting_hr_optimal:rbf8ko',
  'goal_healthy_resting_hr_bad_not_much_activity_0': 'goal_healthy_resting_hr_bad_not_much_activity:0ky3tz',
  'goal_healthy_resting_hr_bad_0': 'goal_healthy_resting_hr_bad:xocxll',
  'goal_healthy_previous_day_good_score_good_0': 'goal_healthy_previous_day_good_score_good:eczkgs',
  'goal_healthy_previous_day_good_optimal_activity_0': 'goal_healthy_previous_day_good_optimal_activity:ch68fm',
  'goal_healthy_previous_night_bad_score_ok_0': 'goal_healthy_previous_night_bad_score_ok:3iq87m',
  'goal_healthy_previous_night_bad_score_ok_1': 'goal_healthy_previous_night_bad_score_ok:5hjpe9',
  'goal_productive_activity_balance_good_0': 'goal_productive_activity_balance_good:qy3j3c',
  'goal_productive_activity_balance_too_active_0': 'goal_productive_activity_balance_too_active:i7768q',
  'goal_healthy_activity_balance_good_0': 'goal_healthy_activity_balance_good:lez8tb',
  '43c8ea28-74d1-46f2-a0b5-88656b4511ea': 'goal_healthy_training_frequency_volume_optimal:chc6cf',
  '5e2c6c70-2a2c-47fc-bbf1-c48df85c9214': 'goal_productive_meet_daily_goals_optimal:cm6tce',
  '955a2f54-166f-48a0-afea-5fc608f9bea6': 'goal_healthy_previous_day_bad_inactivity:n75ivp',
  'goal_healthy_previous_day_bad_inactivity_0': 'goal_healthy_previous_day_bad_inactivity:18xsjk',
  'goal_healthy_sleep_balance_good_readiness_ok_0': 'goal_healthy_sleep_balance_good_readiness_ok:mb5sn7',
  '52eb5d42-fdbb-458d-995d-164e917b0b1e': 'goal_healthy_meet_daily_goals_bad:e1lijz',
  'd9eecd53-b6f3-4082-816a-68c1c416b4c3': 'goal_healthy_meet_daily_goals_bad:h8skrr',
  'goal_stress_readiness_bad_0': 'goal_stress_readiness_bad:nem140',
  'f7f01e1b-fd17-48e6-b2ba-6a9d4f46c222': 'goal_productive_meet_daily_goals_bad:axltc3',
  'goal_stress_previous_day_good_score_good_0': 'goal_stress_previous_day_good_score_good:i2zbjf',
  'goal_stress_recovery_index_good_0': 'goal_stress_recovery_index_good:cic3pv',
  'ob_activity_score_0': 'ob_activity_score:ty75w0',
  'nightly_score_0': 'nightly_score:bi636p',
  'nightly_score_attention_0': 'nightly_score_attention:gf8zde',
  'nightly_score_good_0': 'nightly_score_good:3usmh9',
  'nightly_score_bad_0': 'nightly_score_bad:cbbt9m',
  '12d1ba2e-32e8-4607-b51d-35c84200bd29': 'recovery_mode_body_temp_mediocre:it7y7n',
  'readiness_score_high_0': 'readiness_score_high:jfzu58',
  '1391674f-5ea1-4980-9ad2-ae211c86891f': 'readiness_good_record_high_activity:q7lcra',
  '238710bd-c53b-4559-b465-bbdc987ce12b': 'readiness_low_record_high_activity:eytshd',
  'readiness_great_1': 'readiness_great:i6jmn7',
  'readiness_great_2': 'readiness_great:a6rkdw',
  'readiness_great_3': 'readiness_great:rh135h',
  'readiness_great_4': 'readiness_great:v0llra',
  'restfulness_bad_during_second_half_once_0': 'restfulness_bad_during_second_half_once:ch5xk6',
  '61313bcb-2ee9-429f-8f61-99a0f2fa99cd': 'rest_mode_total_sleep_good:vlrmv7',
  'f2860f3d-8fdf-425a-811c-668dd3ea89c0': 'rest_mode_total_sleep_good:zcg5ru',
  '518c1c63-52cc-49d4-9ea7-913413d3086d': 'rest_mode_total_sleep_bad:5nldv6',
  'restfulness_bad_during_first_half_once_0': 'restfulness_bad_during_first_half_once:dcy3ga',
  '90c96f6d-84f3-489a-855d-2e34cac6e8d0': 'rest_mode_temp_high_score_good:hovieq',
  '063397b7-176a-4a66-a1be-acef300c6713': 'rest_mode_temp_midpoint_day_2_high_day_1:x2n7hp',
  'daa7cfdf-03c0-4eb6-94d0-43587507c3ca': 'rest_mode_temp_midpoint:avugjo',
  'b86c4c11-b1f0-46d4-9e12-43c168889735': 'rest_mode_temp_midpoint_day_2_yesterday_midpoint:zofad2',
  '4469f43a-c572-4097-9ad1-1df1d5bddf05': 'rest_mode_temp_high_day_5:h9uj7y',
  '5700091e-82ce-498a-932a-c20e221cb202': 'rest_mode_temp_high_day_5:y9amt0',
  '656d80cf-d1b2-4dd0-9adf-7494304d8ebf': 'rest_mode_temp_high_day_5:bye7th',
  '1cfb6a83-9a06-4b11-9c67-32c0bfa38027': 'rest_mode_temp_high_score_low_once:tyc6ir',
  '76201461-bac0-4899-b093-e1af99dd17c4': 'stay_active_optimal_7d_exercise_high:zvyc5w',
  'battery_exhausted_during_sleep_0': 'battery_exhausted_during_sleep:vsuv1m',
  'activity_target_0': 'activity_target:j7pdma',
  'b9488784-e88b-4699-84f4-256610d08e24': 'readiness_score_optimal_catch_all:j16er7',
  'activity_balance_too_low_score_bad_once_0': 'activity_balance_too_low_score_bad_once:mgsxvf',
  'activity_balance_too_low_score_bad_once_2': 'activity_balance_too_low_score_bad_once:1327zc',
  'activity_balance_too_low_score_bad_once_4': 'activity_balance_too_low_score_bad_once:l341qp',
  'activity_balance_too_low_score_bad_once_5': 'activity_balance_too_low_score_bad_once:yotyg6',
  'Recharge_intro_restfulness_bad_evenly_distributed_once_0':
    'recharge_intro_restfulness_bad_evenly_distributed_once:4we47x',
  '7d_adherence_high_0': '7d_adherence_high:vwhoi2',
  '7d_adherence_high_1': '7d_adherence_high:4uqa1i',
  '7d_adherence_high_2': '7d_adherence_high:tbkroa',
  '7d_adherence_high_3': '7d_adherence_high:uzixhr',
  'activity_target_back_to_normal_0': 'activity_target_back_to_normal:yqqlbo',
  '24h_inactivity_high_7d_exercise_low_0': '24h_inactivity_high_7d_exercise_low:0nnrm4',
  '24h_inactivity_high_7d_exercise_low_1': '24h_inactivity_high_7d_exercise_low:4w4lbm',
  '24h_inactivity_high_7d_exercise_low_2': '24h_inactivity_high_7d_exercise_low:sa64gl',
  'd21f0d47-e9d5-4421-b27b-ca7c6b277042': 'sleep_score_good_catch_all:pr4rxz',
  'Recharge_intro_readiness_bad_0': 'recharge_intro_readiness_bad:g5g45g',
  'activity_balance_too_low_score_good_once_0': 'activity_balance_too_low_score_good_once:m88hew',
  'activity_balance_too_low_score_good_once_2': 'activity_balance_too_low_score_good_once:lo0cc0',
  'activity_balance_too_high_score_good_once_0': 'activity_balance_too_high_score_good_once:4tz5fn',
  'activity_balance_too_high_score_good_once_2': 'activity_balance_too_high_score_good_once:bju7jb',
  'Recharge_intro_previous_day_bad_activity_too_score_good_once_0':
    'recharge_intro_previous_day_bad_activity_too_score_good_once:e94ki1',
  '6adbf197-f22d-47c9-a662-163860057759': 'total_sleep_good_readiness_bad:108ckc',
  'c15d9f85-42dc-423e-81b4-8333dbe82caf': 'goal_productive_stay_active_optimal:w7rtn8',
  'goal_stress_activity_balance_good_0': 'goal_stress_activity_balance_good:p03i4j',
  'goal_productive_sleep_balance_good_0': 'goal_productive_sleep_balance_good:if2ctp',
  '31bd8efe-a1b0-4473-aad3-f0690ca694bd': 'goal_stress_meet_daily_goals_bad:28c44t',
  '1e06a37f-711c-46b5-bfc1-36d4f3426374': 'recovery_mode_day_1_readiness_message:2quhr3',
  '9a38703b-031f-4120-bf00-c29d76085f05': 'recovery_mode_deep_sleep_bad:ys53zf',
  '99aeca18-331e-4d9e-b209-2b899d2667f7': 'recovery_mode_deep_sleep_good:d8xe8o',
  'ccb4a366-6fdc-4369-b312-ff41934d7f1d': 'recovery_mode_efficiency_good:k6fo5y',
  '7d_rest_high_exercise_high_0': '7d_rest_high_exercise_high:7a92x8',
  '24h_inactivity_high_7d_exercise_high_0': '24h_inactivity_high_7d_exercise_high:h8ziep',
  '24h_inactivity_high_7d_exercise_high_1': '24h_inactivity_high_7d_exercise_high:mm8q0g',
  '24h_inactivity_high_7d_exercise_high_2': '24h_inactivity_high_7d_exercise_high:c67163',
  '04b5318a-958f-46db-bc5b-54ebed528ab3': 'activity_target_changed_readiness_low:3xay8f',
  '7d_exercise_high_0': '7d_exercise_high:yx1lo3',
  '7d_exercise_high_1': '7d_exercise_high:py5ckn',
  'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0':
    'recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once:8xjnjv',
  'goal_productive_sleep_balance_bad_0': 'goal_productive_sleep_balance_bad:qqtvac',
  'goal_productive_sleep_balance_bad_1': 'goal_productive_sleep_balance_bad:v8fsix',
  'goal_productive_sleep_balance_bad_2': 'goal_productive_sleep_balance_bad:1on14m',
  '63b8d5ec-2c9c-4c0b-9afb-f8d014f50b6c': 'goal_productive_training_frequency_volume_low_readiness_ok:u6ttwu',
  'goal_stress_recovery_index_bad_0': 'goal_stress_recovery_index_bad:q3j2p5',
  'goal_productive_sleep_balance_good_readiness_ok_0': 'goal_productive_sleep_balance_good_readiness_ok:75zkq5',
  'goal_stress_readiness_score_trending_up_already_good_0':
    'goal_stress_readiness_score_trending_up_already_good:szrc5l',
  'goal_productive_resting_hr_time_bad_0': 'goal_productive_resting_hr_time_bad:xr2hw5',
  'goal_productive_resting_hr_time_bad_1': 'goal_productive_resting_hr_time_bad:y8kdqd',
  'goal_productive_resting_hr_time_bad_2': 'goal_productive_resting_hr_time_bad:xjmr4o',
  'goal_stress_resting_hr_time_bad_0': 'goal_stress_resting_hr_time_bad:pkh06m',
  'goal_productive_previous_night_bad_score_ok_0': 'goal_productive_previous_night_bad_score_ok:n3wqlx',
  '09aefb3f-f1aa-4876-9d68-b71ecd110e9a': 'goal_productive_stay_active_bad_7d_exercise_high:c20x9w',
  'goal_productive_previous_day_good_0': 'goal_productive_previous_day_good:uxhz3y',
  'goal_productive_previous_night_bad_0': 'goal_productive_previous_night_bad:t1wuj9',
  'goal_productive_previous_night_bad_1': 'goal_productive_previous_night_bad:7n5rcu',
  'goal_productive_previous_night_bad_2': 'goal_productive_previous_night_bad:9iyp42',
  'd6939fc9-a8cb-4cb8-a5a2-a1a627736ad5': 'recovery_mode_readiness_score_improving:5civit',
  'activity_balance_good_lowish_score_good_once_0': 'activity_balance_good_lowish_score_good_once:u4lzs0',
  'activity_balance_good_lowish_score_good_once_2': 'activity_balance_good_lowish_score_good_once:kq34ks',
  'activity_balance_good_lowish_score_good_once_3': 'activity_balance_good_lowish_score_good_once:taisfx',
  'activity_balance_good_lowish_score_good_once_4': 'activity_balance_good_lowish_score_good_once:52l2wg',
  '4e06a0fc-a5d3-44e7-94da-e6b4ff296059': 'reasearch_mode_generic:xeibhd',
  '0acfb670-c3b2-4b8e-86bd-67857279e1e5': 'goal_productive_previous_day_inactivity_high:t6iabn',
  'goal_productive_previous_day_inactivity_high_0': 'goal_productive_previous_day_inactivity_high:fsegke',
  'goal_productive_previous_night_good_0': 'goal_productive_previous_night_good:9et64f',
  'goal_stress_resting_hr_trend_bad_0': 'goal_stress_resting_hr_trend_bad:1ax73w',
  'f707b672-c64c-432c-9f7d-6edc3a80a15b': 'recovery_mode_readiness_good_heart_rate_good:qafb3k',
  '31b80c66-7785-4453-8ce3-7994fef55593': 'recovery_mode_previous_night_bad:agau9g',
  '679afde8-c029-47c2-8c47-7ba8f3a1ba73': 'recovery_mode_readiness_score_bad:qikdt0',
  '35de271a-7f28-4a76-a25e-9edab75a0464': 'recovery_mode_previous_day_good:9vt7ek',
  'latency_high_activity_high_once_0': 'latency_high_activity_high_once:xhbbor',
  'goal_stress_sleep_balance_good_0': 'goal_stress_sleep_balance_good:s0shuz',
  'latency_low_once_0': 'latency_low_once:kz3b7r',
  'high_daily_activity_0': 'high_daily_activity:ez7lih',
  'latency_good_once_0': 'latency_good_once:hz8p9o',
  'goal_stress_resting_hr_trend_optimal_0': 'goal_stress_resting_hr_trend_optimal:88vs9h',
  'e54c92c3-e5a1-4200-a119-145f34b9e46e': 'meet_daily_goals_bad:pqgm91',
  '9ade9659-85de-4f41-829c-245c0e4eeea4': 'recovery_mode_previous_night_good:ufm9te',
  '148859c2-481a-4a02-807f-70d8de366736': 'recovery_mode_efficiency_bad:h2sxmj',
  '6cd02eb1-22dc-484b-bb41-22abc2e60ce3': 'recovery_mode_hrv_balance_good:kw2dsi',
  '3bdbdeeb-362d-4fbf-a4a6-828ae6ed26a3': 'recovery_mode_previous_day_bad_high_activity:bhf7sv',
  'rem_sleep_bad_cum_activity_bad_once_0': 'rem_sleep_bad_cum_activity_bad_once:lk9tsz',
  'rem_sleep_bad_total_ok_once_0': 'rem_sleep_bad_total_ok_once:zyzc39',
  'rem_sleep_bad_total_ok_once_1': 'rem_sleep_bad_total_ok_once:q80qxm',
  'recovery_score_trending_up_already_good_0': 'recovery_score_trending_up_already_good:sdlb0e',
  'recovery_score_trending_up_already_good_2': 'recovery_score_trending_up_already_good:rvftjr',
  'recovery_score_trending_up_already_good_3': 'recovery_score_trending_up_already_good:dawkhx',
  'recovery_score_trending_up_already_good_4': 'recovery_score_trending_up_already_good:tqdv2h',
  'recovery_score_trending_up_already_good_5': 'recovery_score_trending_up_already_good:wgeoxb',
  'cdf7a673-cfe4-47b4-bebd-abde31e7d361': 'recovery_mode_total_sleep_improving:efqwa5',
  '10735a10-dda5-464b-8c7f-880dd8cee28a': 'recovery_mode_total_sleep_bad:tfosst',
  'rem_sleep_bad_total_bad_once_0': 'rem_sleep_bad_total_bad_once:tnwuv3',
  'rem_sleep_bad_total_bad_once_1': 'rem_sleep_bad_total_bad_once:oaojzh',
  'rem_sleep_good_total_bad_once_0': 'rem_sleep_good_total_bad_once:7qpv5n',
  'rem_sleep_good_total_good_once_0': 'rem_sleep_good_total_good_once:kiqyux',
  'rem_sleep_good_total_good_once_1': 'rem_sleep_good_total_good_once:emmq30',
  '43d246fa-da20-4d14-b591-816bf7305ccc': 'recovery_mode_timing_good:ks61ew',
  'rem_sleep_bad_activity_high_once_0': 'rem_sleep_bad_activity_high_once:g62r31',
  'recovery_score_trending_down_but_still_good_0': 'recovery_score_trending_down_but_still_good:xoeo4s',
  'recovery_score_trending_down_but_still_good_2': 'recovery_score_trending_down_but_still_good:jkv5pj',
  'recovery_score_trending_up_but_still_low_0': 'recovery_score_trending_up_but_still_low:txvup1',
  'recovery_score_trending_up_but_still_low_2': 'recovery_score_trending_up_but_still_low:vws48r',
  'f1daadf5-625b-4dc7-a949-73c36dbf5bf8': 'recovery_mode_temp_high:enp8c4',
  'decb4f97-234d-41df-97ac-59935a05855f': 'recovery_mode_resting_hr_improving:vpbiun',
  '4eb29d3c-627d-479c-ad2c-788fcc8e389e': 'stay_active_bad_7d_exercise_high:5vruvy',
  'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_short_once_0':
    'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_short_once:er26ex',
  'ada62ee9-fbfc-439a-bf06-56f600bf3c35': 'recovery_mode_restfulness_bad:rz5ufz',
  'fc761229-79bf-473b-b27f-4314da1626f7': 'rest_mode_elevated_resting_hr:78ne37',
  '17b8f108-fa83-4d69-9952-d9623dcf1ff9': 'recovery_mode_readiness_score_trending_down:5rqur7',
  'b5d453cb-3703-4d30-8ad6-154ad5e6ab83': 'recovery_mode_sleep_balance_improving:s5ckb6',
  'ob_activity_tracking_0': 'ob_activity_tracking:j6wh40',
  '03e868a2-6cc0-4b97-be7a-bea4e662014f': 'recovery_mode_resting_hr_good:nnudrm',
  'cf965242-d07c-485a-b3af-2ef91840c4b7': 'rest_mode_previous_night_good:kdrqrx',
  '288b966a-8acc-4993-adf7-067ef34415ee': 'recovery_mode_resting_hr_trending_down:ktbh2q',
  'b8b73e68-6d43-4b14-8b63-f33f478723f9': 'rest_mode_good_resting_hr:5u3axc',
  'c1ea06a2-688c-4315-a803-5731fd573a80': 'rest_mode_efficiency_good:hqwi27',
  'resting_hr_time_good_score_bad_activity_low_once_0': 'resting_hr_time_good_score_bad_activity_low_once:7nykqa',
  'resting_hr_time_good_score_bad_activity_low_once_2': 'resting_hr_time_good_score_bad_activity_low_once:xy5uyx',
  'resting_hr_time_bad_score_good_activity_high_once_0': 'resting_hr_time_bad_score_good_activity_high_once:jluc19',
  'resting_hr_time_bad_score_good_activity_high_once_2': 'resting_hr_time_bad_score_good_activity_high_once:xtyr54',
  'resting_hr_good_score_bad_once_0': 'resting_hr_good_score_bad_once:0ipz26',
  'resting_hr_good_score_bad_once_2': 'resting_hr_good_score_bad_once:0z7tuy',
  'resting_hr_good_score_bad_once_3': 'resting_hr_good_score_bad_once:7a2rj7',
  'resting_hr_time_good_score_bad_activity_high_once_0': 'resting_hr_time_good_score_bad_activity_high_once:rn6lwq',
  'resting_hr_time_good_score_bad_activity_high_once_3': 'resting_hr_time_good_score_bad_activity_high_once:47waik',
  'c6b854b4-3979-4c0e-8974-8509ea3a9c3d': 'rest_mode_deep_sleep_good:vu2i81',
  '7d9f0c6f-4efd-472d-abef-bdaa58e12006': 'rest_mode_day_1_sleep:5x01j2',
  '01de55f1-0d1b-4c93-be48-c2e92e46a937': 'rest_mode_previous_night_bad:meqxqq',
  '5c6f01b0-c87c-4ced-96ee-60a824724594': 'rest_mode_deep_sleep_bad:4i9x5q',
  'resting_hr_time_bad_score_bad_activity_low_once_0': 'resting_hr_time_bad_score_bad_activity_low_once:qneyr2',
  'resting_hr_time_bad_score_bad_activity_low_once_2': 'resting_hr_time_bad_score_bad_activity_low_once:kmifyp',
  'resting_hr_good_score_good_once_2': 'resting_hr_good_score_good_once:ffd8ur',
  'resting_hr_good_score_good_once_3': 'resting_hr_good_score_good_once:iq0zxp',
  '9ed6ce4d-97d1-41b9-a6a5-eb0d0ed2ccdb': 'rest_mode_day_1_readiness:pu5cpw',
  'b3f37751-e926-47a5-ae95-fd51a7b7a4f2': 'rest_mode_temp_high_day_2:bj9l1g',
  'b972bdf5-dada-4b10-bff2-26f99a57b0e0': 'rest_mode_temp_high_day_4:tfebtc',
  'b8c9ad2c-3e43-4ab6-bb96-60b1d05e917d': 'rest_mode_sleep_timing_good:u86ouh',
  'e60a5159-d92e-43c1-90e3-9c95f3b6d96b': 'rest_mode_temp_high_day_2_yesterday_midpoint:5bwvvc',
  '86fe0735-f805-46f3-9acf-88e4d43168ef': 'rest_mode_temp_high_day_3:jgsml8',
  '34f6ce42-822f-4028-9895-8598f0b871a0': 'rest_mode_restfulness_bad:zz74ul',
  '38b58e4c-e9af-439d-aa39-fcddee75c576': 'recovery_mode_resting_hr_bad:w1h3xd',
  'resting_hr_time_good_score_good_once_0': 'resting_hr_time_good_score_good_once:ulgwse',
  'resting_hr_time_good_score_good_once_2': 'resting_hr_time_good_score_good_once:kizf72',
  'resting_hr_time_good_score_good_once_3': 'resting_hr_time_good_score_good_once:541qmh',
  'resting_hr_time_good_score_good_once_4': 'resting_hr_time_good_score_good_once:7l3hhy',
  'resting_hr_time_good_score_good_once_5': 'resting_hr_time_good_score_good_once:aqkpws',
  'goal_stress_previous_night_bad_score_ok_0': 'goal_stress_previous_night_bad_score_ok:ohbju9',
  'daily_equal_km_above_your_average_0': 'daily_equal_km_above_your_average:j5qez6',
  '19360d20-7f0b-4830-b318-470f9afbeda2': 'rest_mode_recovery_index_good:30ibt4',
  '95ebc6a4-563b-4769-8dd6-25bf129db7e3': 'rest_mode_readiness_score_good:6kvtq5',
  'f4e64816-0f43-4f00-a177-d8e918bad3ed': 'rest_mode_readiness_score_good:c6i5lw',
  'daily_equal_km_0': 'daily_equal_km:6wgu80',
  'goal_athletic_previous_night_bad_readiness_score_ok_0': 'goal_athletic_previous_night_bad_readiness_score_ok:jpypm5',
  'goal_focus_readiness_ok_0': 'goal_focus_readiness_ok:2e2wsq',
  'goal_athletic_resting_hr_trend_bad_0': 'goal_athletic_resting_hr_trend_bad:b0vpy1',
  'efficiency_good_score_bad_once_0': 'efficiency_good_score_bad_once:wqp615',
  'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once_0':
    'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once:nkiwlk',
  'total_sleep_bad_midpoint_early_efficiency_good_once_0': 'total_sleep_bad_midpoint_early_efficiency_good_once:9i9bgv',
  'goal_focus_sleep_balance_good_0': 'goal_focus_sleep_balance_good:ntdgag',
  'goal_focus_recovery_score_trending_up_already_good_0': 'goal_focus_recovery_score_trending_up_already_good:4tz4tj',
  'a488a9ec-a2d8-4d88-af96-c6f5acd51f7d': 'temp_high_activity_high:ci87nv',
  'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once_0':
    'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once:hlzkg5',
  '68aded59-c8a6-4cb1-959a-14194dce6788': 'temp_high_day_2:b7r8o8',
  'tutorial_activity_target_0': 'tutorial_activity_target:wwej51',
  '7bd85869-c73c-4510-8af6-e4ef2a32e91b': 'temp_midpoint_day_2_yesterday_midpoint:1j2kkg',
  'goal_focus_sleep_balance_bad_previous_night_good_0': 'goal_focus_sleep_balance_bad_previous_night_good:chz1od',
  'a5409fda-9108-4a18-9100-5d42f365c508': 'goal_athletic_previous_day_inactivity_high:vp5zvb',
  'd0228acc-7be7-4be7-83af-a30c168a3404': 'temp_high_day_4:opt9hr',
  'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_long_once_0':
    'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_long_once:mfgz5y',
  'temp_high_score_low_once_0': 'temp_high_score_low_once:qr87pe',
  'temp_high_score_low_once_2': 'temp_high_score_low_once:32rw13',
  'b7abe895-08a2-4432-91ab-1efad05cf2bd': 'temp_midpoint_score_good:3a2qh4',
  '0b4907e6-9cd2-4b2e-908e-993edb743e4f': 'temp_high_previous_night_bad:g5w3ol',
  '9facbdf3-3051-450d-a4da-bbe829d50f20': 'temp_midpoint_score_mediocre:j4v52n',
  'da96a7ff-467e-4a4b-a18d-4cf7c8d9883f': 'temp_midpoint_day_2_yesterday_high:z7eqld',
  '7bfd1e71-745b-48b2-a712-00c3a29cd6e6': 'temp_high_score_mediocre:h304h7',
  'e5fa19fe-0b05-4bb4-b252-f2010ad62ef5': 'temp_high_score_mediocre:eg1ry3',
  '101d2f39-6351-46fa-98b6-d5236c15e1c8': 'temp_high_day_3:kgqonv',
  'goal_athletic_resting_hr_time_bad_score_bad_activity_low_0':
    'goal_athletic_resting_hr_time_bad_score_bad_activity_low:dfiziy',
  'b452cbbb-197e-4260-a7a7-59f44e1e4b81': 'temp_high_day_5:czdz4s',
  'be20df0b-e17a-4826-bbe4-b9d88bdd2083': 'temp_high_day_5:9gvzbz',
  'fba9f6d9-e371-424c-aff1-e9f08a4ec6cc': 'temp_high_day_5:wfu3n3',
  '5d41314f-4b7d-4e11-a0d5-c1ed38c584d8': 'temp_midpoint_score_low:dxrj6x',
  '1e38df3b-e693-41a0-bfa5-7f6ac9613654': 'temp_midpoint_day_2_high_day_1:j8u9re',
  'bbe13c64-2d85-46ee-accc-d30b890f4175': 'temp_high_resting_hr_high:mj9p88',
  'goal_athletic_previous_day_activity_good_0': 'goal_athletic_previous_day_activity_good:zfcf1e',
  'total_sleep_good_once_0': 'total_sleep_good_once:nvewap',
  'total_sleep_good_once_1': 'total_sleep_good_once:0rsu9z',
  'total_sleep_good_once_2': 'total_sleep_good_once:nel4cl',
  'total_sleep_good_once_3': 'total_sleep_good_once:itjf2f',
  'total_sleep_good_once_4': 'total_sleep_good_once:6z79e4',
  'total_sleep_good_once_5': 'total_sleep_good_once:ebmhcj',
  'total_sleep_good_once_6': 'total_sleep_good_once:wlegh5',
  'total_sleep_good_once_7': 'total_sleep_good_once:e4gjhl',
  'total_sleep_good_once_8': 'total_sleep_good_once:c79a84',
  'total_sleep_good_once_9': 'total_sleep_good_once:pg4j6z',
  'tutorial_activity_medium_activity_0': 'tutorial_activity_medium_activity:sfk609',
  'tutorial_activity_distance_0': 'tutorial_activity_distance:x16twe',
  'f7b6562b-71af-433f-87ef-5a092d3d2c3d': 'training_frequency_volume_low_readiness_ok:7pvjzs',
  'tutorial_activity_high_activity_0': 'tutorial_activity_high_activity:c6o5g9',
  '38daf14b-3374-418e-a3d8-1647b96f8a34': 'temp_midpoint_previous_night_bad:no3yy9',
  'efficiency_bad_activity_high_once_0': 'efficiency_bad_activity_high_once:9yiz4y',
  'efficiency_bad_activity_low_once_0': 'efficiency_bad_activity_low_once:34w8o9',
  '3db1b09a-f28b-4517-9441-57c2e489d5c4': 'goal_athletic_meet_daily_goals_optimal:xvva0v',
  'temp_high_score_high_once_0': 'temp_high_score_high_once:4jvr0u',
  'temp_high_score_high_once_2': 'temp_high_score_high_once:2tsegu',
  'temp_high_score_high_once_3': 'temp_high_score_high_once:znam6v',
  'temp_high_score_high_once_4': 'temp_high_score_high_once:hekr3g',
  'temp_high_score_high_once_5': 'temp_high_score_high_once:pcetgy',
  'tutorial_activity_score_0': 'tutorial_activity_score:s8ndw3',
  'tutorial_activity_act_cal_0': 'tutorial_activity_act_cal:wcjv56',
  'tutorial_activity_steps_0': 'tutorial_activity_steps:bguxoq',
  'tutorial_activity_breaking_up_inactive_time_0': 'tutorial_activity_breaking_up_inactive_time:p7qhfa',
  '13788b2f-6675-466b-bd2d-aa9263da069a': 'training_frequency_volume_high:0nzfr7',
  'tutorial_activity_low_activity_0': 'tutorial_activity_low_activity:29o81r',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once:t73i9x',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_0':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once:5c32sj',
  'tutorial_activity_inactive_time_0': 'tutorial_activity_inactive_time:66clx6',
  'total_sleep_bad_midpoint_late_efficiency_good_once_0': 'total_sleep_bad_midpoint_late_efficiency_good_once:kw0hql',
  'total_sleep_bad_midpoint_late_efficiency_good_once_1': 'total_sleep_bad_midpoint_late_efficiency_good_once:iutolt',
  '2b997dfd-e440-4908-95ea-ec07c34952d3': 'recovery_mode_hrv_balance_bad:nt3dpu',
  'ob_crown_0': 'ob_crown:n5qyx2',
  'ob_charging_icon_0': 'ob_charging_icon:37udy6',
  'ob_readiness_score_0': 'ob_readiness_score:ykp78v',
  'weekly_equal_km_low_0': 'weekly_equal_km_low:mxabk4',
  'tutorial_sleep_score_0': 'tutorial_sleep_score:hud6wi',
  'goal_focus_resting_hr_time_bad_0': 'goal_focus_resting_hr_time_bad:nvczpk',
  'goal_athletic_activity_balance_good_0': 'goal_athletic_activity_balance_good:3p5ht5',
  'other_bedtime_adherence_good_0': 'other_bedtime_adherence_good:a09xmj',
  'previous_night_bad_score_bad_once_0': 'previous_night_bad_score_bad_once:ojtgsz',
  'previous_night_bad_score_bad_once_2': 'previous_night_bad_score_bad_once:22hrde',
  'previous_night_bad_score_bad_once_3': 'previous_night_bad_score_bad_once:z1484s',
  'previous_night_bad_score_bad_once_4': 'previous_night_bad_score_bad_once:0kc3m9',
  'ob_respiratory_rate_0': 'ob_respiratory_rate:max8si',
  'ob_introducing_oura_0': 'ob_introducing_oura:nhatx5',
  'other_bedtime_adherence_poor_0': 'other_bedtime_adherence_poor:8oy5ox',
  'ob_oura_cloud_0': 'ob_oura_cloud:t0uf8o',
  'weekly_summary_0': 'weekly_summary:wjwtwn',
  'weekly_summary_1': 'weekly_summary:r5ycgs',
  'weekly_summary_2': 'weekly_summary:r807e7',
  'weekly_summary_3': 'weekly_summary:746gwn',
  'goal_focus_previous_night_bad_score_ok_0': 'goal_focus_previous_night_bad_score_ok:f4ythf',
  'goal_focus_sleep_balance_bad_0': 'goal_focus_sleep_balance_bad:2i2idy',
  'ob_moment_0': 'ob_moment:jejmyk',
  '50ab4070-b5d8-4369-96b1-7e8d994e1289': 'rest_mode_readiness_score_bad:ehzgex',
  '8fd43ecd-9806-4318-8f98-235b417e8a71': 'rest_mode_readiness_score_bad:6ubbjd',
  'other_bedtime_adherence_excellent_0': 'other_bedtime_adherence_excellent:c9m79q',
  'tutorial_sleep_rem_0': 'tutorial_sleep_rem:0zrbdg',
  'tutorial_sleep_heart_rate_0': 'tutorial_sleep_heart_rate:np5dil',
  'tutorial_sleep_deep_0': 'tutorial_sleep_deep:glvp5i',
  'tutorial_activity_view_0': 'tutorial_activity_view:e2zwjf',
  'c3b6961f-7ec2-47b3-9d38-992463fedbbf': 'onboarding_hrv_balance_old:xn21k3',
  'goal_athletic_readiness_score_ok_0': 'goal_athletic_readiness_score_ok:tx5v46',
  '4a9a9892-1f47-4eab-9248-2545f338e09e': 'goal_focus_stay_active_bad_7d_exercise_high:629njk',
  'a2ec1488-c50b-46c9-9710-7dbb3d367057': 'goal_focus_meet_daily_goals_optimal:jbqj2j',
  'tutorial_sleep_light_0': 'tutorial_sleep_light:j4sip4',
  'goal_focus_previous_night_bad_0': 'goal_focus_previous_night_bad:ht3iy3',
  'goal_creativity_recovery_index_good_0': 'goal_creativity_recovery_index_good:nsn5ej',
  'goal_creativity_sleep_balance_good_0': 'goal_creativity_sleep_balance_good:7tgrfi',
  'tutorial_sleep_latency_0': 'tutorial_sleep_latency:id45jo',
  'tutorial_sleep_efficiency_0': 'tutorial_sleep_efficiency:o8sqc0',
  'resting_hr_time_bad_score_bad_activity_high_once_0': 'resting_hr_time_bad_score_bad_activity_high_once:eguy95',
  'resting_hr_time_bad_score_bad_activity_high_once_2': 'resting_hr_time_bad_score_bad_activity_high_once:7oio8n',
  'resting_hr_time_bad_score_bad_activity_high_once_3': 'resting_hr_time_bad_score_bad_activity_high_once:xqfv4p',
  'resting_hr_time_bad_score_bad_activity_high_once_4': 'resting_hr_time_bad_score_bad_activity_high_once:fbg2bu',
  'first_weekly_summary_0': 'first_weekly_summary:gqymyj',
  'resting_hr_low_score_low_once_0': 'resting_hr_low_score_low_once:xkhcvv',
  'resting_hr_low_score_low_once_2': 'resting_hr_low_score_low_once:7q9dxd',
  'resting_hr_low_score_low_once_3': 'resting_hr_low_score_low_once:ykfc64',
  'sleep_balance_bad_once_score_good_2': 'sleep_balance_bad_once_score_good:ebbcgc',
  'sleep_balance_bad_once_score_good_3': 'sleep_balance_bad_once_score_good:couxbp',
  'sleep_balance_bad_once_score_good_4': 'sleep_balance_bad_once_score_good:4nl9cl',
  'score_trending_down_but_still_high_0': 'score_trending_down_but_still_high:gzsiu7',
  '518f2123-d94a-4217-a7d7-04a7dd8b05ea': 'rni_study:4887vf',
  'score_trending_up_already_good_0': 'score_trending_up_already_good:60hogl',
  'resting_hr_low_score_high_once_0': 'resting_hr_low_score_high_once:ldedpd',
  'resting_hr_low_score_high_once_2': 'resting_hr_low_score_high_once:1e19co',
  'score_trending_up_but_still_low_0': 'score_trending_up_but_still_low:x398x1',
  'goal_athletic_resting_hr_trend_optimal_0': 'goal_athletic_resting_hr_trend_optimal:igw5r1',
  'efficiency_good_score_ok_once_0': 'efficiency_good_score_ok_once:ye61l5',
  'goal_focus_activity_balance_good_score_good_0': 'goal_focus_activity_balance_good_score_good:akwu9q',
  'efficiency_bad_latency_high_once_0': 'efficiency_bad_latency_high_once:ds1qgp',
  'efficiency_bad_latency_high_once_1': 'efficiency_bad_latency_high_once:8asux6',
  'goal_creativity_sleep_balance_bad_0': 'goal_creativity_sleep_balance_bad:oh5qd1',
  'goal_creativity_readiness_bad_0': 'goal_creativity_readiness_bad:vzld8o',
  'goal_creativity_readiness_bad_1': 'goal_creativity_readiness_bad:y09xkv',
  'cf16450d-377c-4cce-a457-411d84c9137c': 'goal_focus_meet_daily_goals_bad:iiblcx',
  'goal_creativity_resting_hr_time_bad_0': 'goal_creativity_resting_hr_time_bad:89g1om',
  'goal_athletic_previous_day_bad_0': 'goal_athletic_previous_day_bad:8cjqce',
  'deabb04b-7e2b-4725-a020-b6cd77f65e84': 'goal_creativity_previous_day_high_inactivity:hpx0fv',
  'goal_creativity_previous_day_high_inactivity_0': 'goal_creativity_previous_day_high_inactivity:08d7bh',
  'goal_focus_previous_day_good_score_good_0': 'goal_focus_previous_day_good_score_good:9wl3pj',
  'goal_creativity_previous_night_good_readiness_good_0': 'goal_creativity_previous_night_good_readiness_good:7c2aqs',
  'goal_creativity_previous_day_good_0': 'goal_creativity_previous_day_good:u2edv6',
  'goal_creativity_previous_night_good_score_excellent_0': 'goal_creativity_previous_night_good_score_excellent:9eeduh',
  'dfdea2b5-5216-4a18-a19e-316ed631c49e': 'goal_athletic_training_frequency_volume_low_readiness_ok:dvs11p',
  'previous_day_good_and_active_score_good_once_0': 'previous_day_good_and_active_score_good_once:ynvz4m',
  'previous_day_good_and_active_score_good_once_1': 'previous_day_good_and_active_score_good_once:p8pvqz',
  'previous_day_good_and_active_score_good_once_3': 'previous_day_good_and_active_score_good_once:c0buw5',
  'previous_day_good_and_active_score_good_once_5': 'previous_day_good_and_active_score_good_once:8wz6w2',
  'previous_day_inactive_score_good_once_0': 'previous_day_inactive_score_good_once:gz4nm2',
  'previous_day_inactive_score_good_once_2': 'previous_day_inactive_score_good_once:koi6wh',
  'previous_day_inactive_score_good_once_3': 'previous_day_inactive_score_good_once:9oek7i',
  'previous_day_inactive_score_good_once_4': 'previous_day_inactive_score_good_once:z8xz9g',
  'ca5134db-0943-4567-b8ec-65ac163a412c': 'rest_mode_sleep_balance_good:5e68mz',
  'goal_creativity_previous_night_bad_score_ok_0': 'goal_creativity_previous_night_bad_score_ok:w8lal8',
  'goal_creativity_previous_night_bad_score_ok_1': 'goal_creativity_previous_night_bad_score_ok:t5eb32',
  '2cdc2348-7195-4272-a142-700cba4455ec': 'goal_creativity_meet_daily_goals_bad:woluou',
  'ea0571d6-7145-4708-98da-61d11189af11': 'goal_athletic_stay_active_optimal_exercise_high:01poz3',
  '65d52228-55aa-42b8-b608-501081364657': 'goal_athletic_training_frequency_volume_optimal:ki6h4f',
  'goal_athletic_sleep_balance_bad_activity_balance_bad_0':
    'goal_athletic_sleep_balance_bad_activity_balance_bad:61h7lw',
  'goal_athletic_sleep_trending_up_sleep_balance_ok_0': 'goal_athletic_sleep_trending_up_sleep_balance_ok:zx89il',
  'goal_athletic_sleep_balance_bad_previous_night_bad_0': 'goal_athletic_sleep_balance_bad_previous_night_bad:yp7m8d',
  'goal_creativity_activity_balance_good_0': 'goal_creativity_activity_balance_good:8eklj4',
  'goal_athletic_sleep_trending_down_sleep_balance_bad_0': 'goal_athletic_sleep_trending_down_sleep_balance_bad:dk05ki',
  'goal_athletic_sleep_balance_good_previous_night_good_0':
    'goal_athletic_sleep_balance_good_previous_night_good:zrvvt7',
  'weekly_equal_km_high_0': 'weekly_equal_km_high:qff1ah',
  'sleeptiming_early_once_0': 'sleeptiming_early_once:x1k44v',
  'sleeptiming_early_once_1': 'sleeptiming_early_once:f7ni12',
  'previous_day_good_activity_good_score_good_once_0': 'previous_day_good_activity_good_score_good_once:02v2s5',
  'previous_day_good_activity_good_score_good_once_2': 'previous_day_good_activity_good_score_good_once:eje0sn',
  'previous_day_good_activity_good_score_good_once_3': 'previous_day_good_activity_good_score_good_once:hyevrp',
  'previous_day_good_activity_good_score_good_once_4': 'previous_day_good_activity_good_score_good_once:3wgxrz',
  'previous_day_good_activity_good_score_good_once_5': 'previous_day_good_activity_good_score_good_once:e31t6q',
  'sleep_weekly_summary_bad_0': 'sleep_weekly_summary_bad:i27ubg',
  'd2a46b11-f133-4254-8735-8dc0efe9344a': 'recovery_mode_hrv_balance_improving:dav250',
  'sleep_weekly_summary_better_0': 'sleep_weekly_summary_better:t7ukm9',
  'sleep_weekly_summary_good_0': 'sleep_weekly_summary_good:qjhgnv',
  'activity_balance_good_score_good_once_0': 'activity_balance_good_score_good_once:ts3bvq',
  'activity_balance_good_score_good_once_2': 'activity_balance_good_score_good_once:dyzee9',
  'activity_balance_good_score_good_once_3': 'activity_balance_good_score_good_once:o5zopd',
  'activity_balance_good_score_good_once_4': 'activity_balance_good_score_good_once:t1r4og',
  'activity_balance_good_score_good_once_5': 'activity_balance_good_score_good_once:t453vp',
  'readiness_score_high_temp_midpoint_0': 'readiness_score_high_temp_midpoint:9dsnwc',
  'previous_day_bad_activity_too_score_good_once_0': 'previous_day_bad_activity_too_score_good_once:yjttep',
  'previous_day_bad_activity_too_score_good_once_2': 'previous_day_bad_activity_too_score_good_once:w8wfx9',
  'previous_day_bad_activity_too_score_good_once_3': 'previous_day_bad_activity_too_score_good_once:6283yb',
  'previous_day_bad_activity_too_score_good_once_4': 'previous_day_bad_activity_too_score_good_once:n2nlo7',
  'previous_day_bad_activity_too_score_good_once_5': 'previous_day_bad_activity_too_score_good_once:2vyj53',
  'readiness_ok_1': 'readiness_ok:mnfkch',
  'readiness_ok_2': 'readiness_ok:nicilp',
  'readiness_ok_3': 'readiness_ok:due1vx',
  'readiness_ok_4': 'readiness_ok:n5ewvl',
  'tutorial_sleep_total_0': 'tutorial_sleep_total:9ki39b',
  'deep_sleep_good_total_bad_once_0': 'deep_sleep_good_total_bad_once:4nvhu2',
  'e106058d-a4a6-49d7-b23d-066d1e678551': 'ucsf_illness_study_invite:1sow60',
  'sleep_balance_good_score_bad_once_0': 'sleep_balance_good_score_bad_once:ykvwbt',
  'sleep_balance_good_score_bad_once_2': 'sleep_balance_good_score_bad_once:9hs7t1',
  'sleep_balance_good_score_bad_once_3': 'sleep_balance_good_score_bad_once:wc0zkx',
  'previous_day_good_activity_good_score_bad_once_0': 'previous_day_good_activity_good_score_bad_once:3npty9',
  'previous_day_good_activity_good_score_bad_once_2': 'previous_day_good_activity_good_score_bad_once:qd2b6h',
  'previous_day_good_activity_good_score_bad_once_3': 'previous_day_good_activity_good_score_bad_once:jskvr8',
  'previous_day_good_activity_good_score_bad_once_4': 'previous_day_good_activity_good_score_bad_once:cwqh48',
  'previous_day_bad_activity_passive_score_bad_once_0': 'previous_day_bad_activity_passive_score_bad_once:nm8nx3',
  'previous_day_bad_activity_passive_score_bad_once_1': 'previous_day_bad_activity_passive_score_bad_once:u9byx1',
  'previous_day_bad_activity_passive_score_bad_once_2': 'previous_day_bad_activity_passive_score_bad_once:8fg3d7',
  'previous_day_bad_activity_passive_score_bad_once_3': 'previous_day_bad_activity_passive_score_bad_once:t8o7r9',
  'previous_day_bad_activity_passive_score_bad_once_5': 'previous_day_bad_activity_passive_score_bad_once:vrhx8h',
  'sleep_balance_good_score_good_once_0': 'sleep_balance_good_score_good_once:405a3c',
  'sleep_balance_good_score_good_once_2': 'sleep_balance_good_score_good_once:zs087m',
  'sleep_balance_bad_score_bad_once_0': 'sleep_balance_bad_score_bad_once:wnaqbb',
  'sleep_balance_bad_score_bad_once_2': 'sleep_balance_bad_score_bad_once:eb6vpq',
  'sleep_balance_bad_score_bad_once_3': 'sleep_balance_bad_score_bad_once:k1gj5h',
  'sleep_balance_bad_score_bad_once_4': 'sleep_balance_bad_score_bad_once:u1wzcg',
  'sleep_balance_bad_score_bad_once_5': 'sleep_balance_bad_score_bad_once:c3b4yp',
  'sleep_balance_bad_score_bad_once_6': 'sleep_balance_bad_score_bad_once:5ezupp',
  'goal_focus_sleep_balance_trend_good_0': 'goal_focus_sleep_balance_trend_good:q1gdc9',
  '7f674fd1-93c8-41e6-8b8c-b73511efa2f0': 'temp_midpoint_resting_hr_high:1rc2rz',
  'tutorial_activity_total_cal_0': 'tutorial_activity_total_cal:u718dd',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening_0':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening:98p80n',
  '7d_adherence_low_0': '7d_adherence_low:2n2p57',
  '7d_adherence_low_1': '7d_adherence_low:r3ebsz',
  '7d_adherence_low_2': '7d_adherence_low:hbyfqb',
  '7d_adherence_low_3': '7d_adherence_low:wshzgj',
  '7d_adherence_low_4': '7d_adherence_low:cl3e8w',
  '7d_adherence_low_5': '7d_adherence_low:7u9kr4',
  '24h_inactivity_low_7d_exercise_low_0': '24h_inactivity_low_7d_exercise_low:ykrndm',
  '24h_inactivity_low_7d_exercise_low_1': '24h_inactivity_low_7d_exercise_low:a9g8nf',
  '24h_inactivity_low_7d_exercise_low_2': '24h_inactivity_low_7d_exercise_low:msucf2',
  '24h_inactivity_low_7d_exercise_low_3': '24h_inactivity_low_7d_exercise_low:qqudld',
  'goal_athletic_resting_hr_trend_optimal_readiness_ok_0': 'goal_athletic_resting_hr_trend_optimal_readiness_ok:niku9p',
  'goal_stress_readiness_good_0': 'goal_stress_readiness_good:kkcjy0',
  'ob_sleep_score_1': 'ob_sleep_score:sfd05g',
  'previous_night_good_score_bad_once_0': 'previous_night_good_score_bad_once:f49p39',
  'previous_night_good_score_bad_once_2': 'previous_night_good_score_bad_once:x654ny',
  'previous_night_good_score_bad_once_3': 'previous_night_good_score_bad_once:i42uzw',
  'other_bedtime_no_ideal_day_0': 'other_bedtime_no_ideal_day:qtz924',
  '7d_exercise_low_0': '7d_exercise_low:jnaxb2',
  '7d_exercise_low_1': '7d_exercise_low:e9n5m1',
  '7d_exercise_low_2': '7d_exercise_low:vna7g8',
  '7d_exercise_low_3': '7d_exercise_low:vj6iwr',
  '7d_exercise_low_4': '7d_exercise_low:s11gxp',
  'other_bedtime_recent_ideal_day_0': 'other_bedtime_recent_ideal_day:pgh54g',
  'previous_night_good_score_good_once_0': 'previous_night_good_score_good_once:je7c2o',
  'previous_night_good_score_good_once_2': 'previous_night_good_score_good_once:fcull0',
  'previous_night_good_score_good_once_3': 'previous_night_good_score_good_once:es64za',
  'previous_night_good_score_good_once_4': 'previous_night_good_score_good_once:ts9ccm',
  'previous_night_good_score_good_once_5': 'previous_night_good_score_good_once:hyh050',
  'sleeptiming_good_score_bad_once_0': 'sleeptiming_good_score_bad_once:tclvzm',
  'snippet_ideal_bedtime_not_available_intro_0': 'snippet_ideal_bedtime_not_available_intro:mhev9m',
  'snippet_ideal_bedtime_available_0': 'snippet_ideal_bedtime_available:lc51hh',
  'sleeptiming_good_score_good_once_0': 'sleeptiming_good_score_good_once:9271d0',
  'sleeptiming_good_score_good_once_1': 'sleeptiming_good_score_good_once:b7is1h',
  'latency_high_triple_0': 'latency_high_triple:3btxt0',
  'latency_high_triple_1': 'latency_high_triple:cfkm9e',
  'latency_high_triple_2': 'latency_high_triple:dme6fd',
  'Recharge_intro_previous_night_bad_score_bad_once_0': 'recharge_intro_previous_night_bad_score_bad_once:cazli4',
  'deep_sleep_good_total_ok_once_0': 'deep_sleep_good_total_ok_once:jey4p8',
  'deep_sleep_good_total_ok_once_1': 'deep_sleep_good_total_ok_once:xb1c0i',
  'goal_creativity_readiness_stable_mediocre_0': 'goal_creativity_readiness_stable_mediocre:8lorgv',
  'goal_focus_previous_night_good_0': 'goal_focus_previous_night_good:2zy13w',
  'previous_day_too_active_score_bad_once_0': 'previous_day_too_active_score_bad_once:nhivr7',
  'previous_day_too_active_score_bad_once_2': 'previous_day_too_active_score_bad_once:3v3lpo',
  'previous_day_too_active_score_bad_once_3': 'previous_day_too_active_score_bad_once:013lox',
  'previous_day_too_active_score_bad_once_4': 'previous_day_too_active_score_bad_once:d8yzwl',
  'deep_sleep_bad_total_bad_once_0': 'deep_sleep_bad_total_bad_once:5okv7s',
  'deep_sleep_bad_total_ok_once_0': 'deep_sleep_bad_total_ok_once:uob5qa',
  'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once_0':
    'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once:5tf6aa',
  'efficiency_bad_time_in_bed_long_once_0': 'efficiency_bad_time_in_bed_long_once:umc2yw',
  'efficiency_bad_activity_normal_once_0': 'efficiency_bad_activity_normal_once:tltmcd',
  'efficiency_bad_activity_normal_once_1': 'efficiency_bad_activity_normal_once:e3c8nf',
  'restfulness_good_once_0': 'restfulness_good_once:z3gw8p',
  'ob_sleep_efficiency_0': 'ob_sleep_efficiency:aa9o0l',
  'ob_deep_sleep_0': 'ob_deep_sleep:l8828t',
  'ob_recovery_time_0': 'ob_recovery_time:pgf8ug',
  'ob_sleep_stages_0': 'ob_sleep_stages:5ye1xq',
  'ob_recovery_index_0': 'ob_recovery_index:1otejf',
  'ob_calorie_calculations_0': 'ob_calorie_calculations:0hyu0s',
  'ob_sleep_trends_0': 'ob_sleep_trends:m452a1',
  'ob_sleep_latency_0': 'ob_sleep_latency:5ggffy',
  'ob_ideal_bedtime_0': 'ob_ideal_bedtime:oiroxy',
  'other_user_profile_0': 'other_user_profile:346lmb',
  'other_user_profile_1': 'other_user_profile:buiaj4',
  'other_user_profile_2': 'other_user_profile:96f98u',
  'other_user_profile_3': 'other_user_profile:nfrqfx',
  'ob_sedentary_time_0': 'ob_sedentary_time:3zto8v',
  'ob_rem_sleep_0': 'ob_rem_sleep:b4wc77',
  '1afa4ae9-b146-4be9-a836-1887fc21869b': 'onboarding_movement_during_sleep:6z2r8r',
  'ob_body_temperature_0': 'ob_body_temperature:iql3h9',
  'ob_resting_heart_rate_0': 'ob_resting_heart_rate:2dydhq',
  'ob_steps_0': 'ob_steps:cgz0wz',
  'resting_hr_time_bad_score_good_activity_low_once_0': 'resting_hr_time_bad_score_good_activity_low_once:51tuib',
  'readiness_bad_1': 'readiness_bad:ntfrpq',
  'readiness_bad_2': 'readiness_bad:zal5cw',
  'readiness_bad_3': 'readiness_bad:9viupj',
  'readiness_bad_4': 'readiness_bad:fibof3',
  'readiness_bad_5': 'readiness_bad:v2eyfq',
  'other_bedtime_no_ideal_evening_0': 'other_bedtime_no_ideal_evening:j1sd15',
  'other_bedtime_recent_ideal_evening_0': 'other_bedtime_recent_ideal_evening:f9vm8f',
  'previous_night_bad_score_ok_once_0': 'previous_night_bad_score_ok_once:vpfob8',
  'previous_night_bad_score_ok_once_2': 'previous_night_bad_score_ok_once:1yp2dx',
  'previous_night_bad_score_ok_once_3': 'previous_night_bad_score_ok_once:hhggym',
  'sleeptiming_late_once_0': 'sleeptiming_late_once:fm848i',
  'sleeptiming_late_once_1': 'sleeptiming_late_once:mjbtl9',
  'restfulness_bad_evenly_distributed_once_0': 'restfulness_bad_evenly_distributed_once:4vyhbj',
  'restfulness_bad_evenly_distributed_once_1': 'restfulness_bad_evenly_distributed_once:0817xn',
  'score_trending_down_already_bad_0': 'score_trending_down_already_bad:y2tzi1',
  'score_trending_down_already_bad_1': 'score_trending_down_already_bad:7fh3n5',
  'resting_hr_high_score_high_once_0': 'resting_hr_high_score_high_once:8n140g',
  'resting_hr_high_score_high_once_2': 'resting_hr_high_score_high_once:hdj28w',
  'resting_hr_high_score_high_once_3': 'resting_hr_high_score_high_once:jbw7ks',
  'resting_hr_high_score_low_once_0': 'resting_hr_high_score_low_once:28v95o',
  'resting_hr_high_score_low_once_2': 'resting_hr_high_score_low_once:oqieoc',
  'resting_hr_high_score_low_once_3': 'resting_hr_high_score_low_once:r5v4ep',
}

export const translationsMigrationMap: any = {
  'Recharge_intro_efficiency_bad_latency_high_once_0:view:ttnbm3': 'Recharge_intro_efficiency_bad_latency_high_once',
  'Recharge_intro_efficiency_bad_latency_high_once_0:view:drt2vx': 'Recharge_intro_efficiency_bad_latency_high_once',
  'Recharge_intro_efficiency_bad_latency_high_once_0:view:2mrfhs': 'Recharge_intro_efficiency_bad_latency_high_once',
  'Recharge_intro_previous_day_bad_activity_too_score_good_once_0:view:2k9ept':
    'Recharge_intro_previous_day_bad_activity_too_score_good_once',
  'Recharge_intro_previous_day_bad_activity_too_score_good_once_0:view:0a9xcz':
    'Recharge_intro_previous_day_bad_activity_too_score_good_once',
  'Recharge_intro_previous_day_bad_activity_too_score_good_once_0:view:94c1o5':
    'Recharge_intro_previous_day_bad_activity_too_score_good_once',
  'Recharge_intro_latency_high_triple_0:view:zjax48': 'Recharge_intro_latency_high_triple',
  'Recharge_intro_latency_high_triple_0:view:jft2j9': 'Recharge_intro_latency_high_triple',
  'Recharge_intro_activity_target_changed_low_0:view:r60i9b': 'Recharge_intro_activity_target_changed_low',
  'Recharge_intro_activity_target_changed_low_0:view:gliebt': 'Recharge_intro_activity_target_changed_low',
  '6adbf197-f22d-47c9-a662-163860057759:view:95zlex': '9c06607d-e581-4839-84fc-980e78b17b77',
  '6adbf197-f22d-47c9-a662-163860057759:view:jj2y54': '9c06607d-e581-4839-84fc-980e78b17b77',
  'Recharge_intro_readiness_bad_0:view:ho2pui': 'Recharge_intro_readiness_bad',
  'Recharge_intro_readiness_bad_0:view:c142yk': 'Recharge_intro_readiness_bad',
  'Recharge_intro_7d_rest_low_exercise_high_0:view:yi0yr1': 'Recharge_intro_7d_rest_low_exercise_high',
  'Recharge_intro_7d_rest_low_exercise_high_0:view:u59a9m': 'Recharge_intro_7d_rest_low_exercise_high',
  'Recharge_intro_7d_rest_low_exercise_high_0:view:hfvdd0': 'Recharge_intro_7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_0:view:rom9at': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_0:view:nj3lm1': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_0:view:cmv5jr': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_1:view:jc9j23': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_1:view:jpfnbs': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_1:view:4mf19c': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_2:view:sxq1xr': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_2:view:e7n6k6': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_2:view:ojgmcc': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_3:view:2vri3c': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_3:view:rrj7eq': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_3:view:hzwco8': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_4:view:nviqm1': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_4:view:8ow19w': '7d_rest_low_exercise_high',
  '7d_rest_low_exercise_high_4:view:g1m43t': '7d_rest_low_exercise_high',
  '7d_rest_high_exercise_high_0:view:6nhdr0': '7d_rest_high_exercise_high',
  '7d_rest_high_exercise_high_0:view:dmhkmm': '7d_rest_high_exercise_high',
  '7d_rest_high_exercise_high_0:view:q8gvbg': '7d_rest_high_exercise_high',
  '7d_exercise_low_0:view:pdfbxo': '7d_exercise_low',
  '7d_exercise_low_0:view:8r1j42': '7d_exercise_low',
  '7d_exercise_low_1:view:z61utw': '7d_exercise_low',
  '7d_exercise_low_1:view:fec3lz': '7d_exercise_low',
  '7d_exercise_low_1:view:s4uu6j': '7d_exercise_low',
  '7d_exercise_low_2:view:0pusfu': '7d_exercise_low',
  '7d_exercise_low_2:view:u57ej4': '7d_exercise_low',
  '7d_exercise_low_3:view:tb54go': '7d_exercise_low',
  '7d_exercise_low_3:view:wdgxgd': '7d_exercise_low',
  '7d_exercise_low_3:view:x4waj1': '7d_exercise_low',
  '7d_exercise_low_4:view:lg3sxz': '7d_exercise_low',
  '7d_exercise_low_4:view:pjz6ip': '7d_exercise_low',
  '7d_adherence_high_0:view:l4ecrm': '7d_adherence_high',
  '7d_adherence_high_0:view:svt018': '7d_adherence_high',
  '7d_adherence_high_0:view:6gg29d': '7d_adherence_high',
  '7d_adherence_high_1:view:t5cr7v': '7d_adherence_high',
  '7d_adherence_high_1:view:hu1npw': '7d_adherence_high',
  '7d_adherence_high_1:view:oe6j1o': '7d_adherence_high',
  '7d_adherence_high_2:view:bj7hlj': '7d_adherence_high',
  '7d_adherence_high_2:view:nf7c2q': '7d_adherence_high',
  '7d_adherence_high_2:view:azh5y3': '7d_adherence_high',
  '7d_adherence_high_3:view:a2nzek': '7d_adherence_high',
  '7d_adherence_high_3:view:efdzbh': '7d_adherence_high',
  '7d_adherence_high_3:view:yqr4zc': '7d_adherence_high',
  '7d_adherence_low_0:view:de50r6': '7d_adherence_low',
  '7d_adherence_low_0:view:p7rq1k': '7d_adherence_low',
  '7d_adherence_low_0:view:2ifo2h': '7d_adherence_low',
  '7d_adherence_low_1:view:vql7xp': '7d_adherence_low',
  '7d_adherence_low_1:view:6wn182': '7d_adherence_low',
  '7d_adherence_low_1:view:8j5aur': '7d_adherence_low',
  '7d_adherence_low_2:view:0d9ebm': '7d_adherence_low',
  '7d_adherence_low_2:view:cykhlq': '7d_adherence_low',
  '7d_adherence_low_2:view:1xg2g1': '7d_adherence_low',
  '7d_adherence_low_3:view:ps5riq': '7d_adherence_low',
  '7d_adherence_low_3:view:efxs99': '7d_adherence_low',
  '7d_adherence_low_3:view:d3fmfn': '7d_adherence_low',
  '7d_adherence_low_4:view:19qgjg': '7d_adherence_low',
  '7d_adherence_low_4:view:ysp9f8': '7d_adherence_low',
  '7d_adherence_low_4:view:a3v44p': '7d_adherence_low',
  '7d_adherence_low_5:view:1o4obw': '7d_adherence_low',
  '7d_adherence_low_5:view:86k2jh': '7d_adherence_low',
  '7d_adherence_low_5:view:kzf7qw': '7d_adherence_low',
  'b9488784-e88b-4699-84f4-256610d08e24:view:zkt3lk': '25d4e006-1bd6-4f58-8038-b310c030eed6',
  'b9488784-e88b-4699-84f4-256610d08e24:view:f1ucnu': '25d4e006-1bd6-4f58-8038-b310c030eed6',
  '7d_exercise_high_0:view:nt4w7k': '7d_exercise_high',
  '7d_exercise_high_0:view:b9mu8z': '7d_exercise_high',
  '7d_exercise_high_1:view:f475a8': '7d_exercise_high',
  '7d_exercise_high_1:view:0v71t7': '7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_0:view:00c3s4': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_0:view:5fhzfr': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_0:view:jihyvh': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_1:view:v1ft5e': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_1:view:m46ni3': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_1:view:eormee': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_2:view:shut07': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_2:view:gn878a': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_3:view:9qroq2': '24h_inactivity_low_7d_exercise_high',
  '24h_inactivity_low_7d_exercise_high_3:view:k4eynk': '24h_inactivity_low_7d_exercise_high',
  '238710bd-c53b-4559-b465-bbdc987ce12b:view:o5fv86': 'readiness_low_record_high_activity',
  '238710bd-c53b-4559-b465-bbdc987ce12b:view:i6kyv2': 'readiness_low_record_high_activity',
  '24h_inactivity_high_7d_exercise_low_0:view:adwivw': '24h_inactivity_high_7d_exercise_low',
  '24h_inactivity_high_7d_exercise_low_0:view:o92498': '24h_inactivity_high_7d_exercise_low',
  '24h_inactivity_high_7d_exercise_low_1:view:yyw43z': '24h_inactivity_high_7d_exercise_low',
  '24h_inactivity_high_7d_exercise_low_1:view:phly65': '24h_inactivity_high_7d_exercise_low',
  '24h_inactivity_high_7d_exercise_low_1:view:dbnh4q': '24h_inactivity_high_7d_exercise_low',
  '24h_inactivity_high_7d_exercise_low_2:view:gvx5cr': '24h_inactivity_high_7d_exercise_low',
  '24h_inactivity_high_7d_exercise_low_2:view:6nrl5d': '24h_inactivity_high_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_0:view:2s7hqd': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_0:view:6hyalk': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_0:view:wqcef7': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_1:view:r9hykm': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_1:view:k1ylm3': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_2:view:btp9yy': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_2:view:m2g4mv': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_2:view:crxnix': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_3:view:xjcn77': '24h_inactivity_low_7d_exercise_low',
  '24h_inactivity_low_7d_exercise_low_3:view:e1y7it': '24h_inactivity_low_7d_exercise_low',
  'restfulness_bad_during_second_half_once_0:view:k7ekx3': 'restfulness_bad_during_second_half_once',
  'restfulness_bad_during_second_half_once_0:view:5l6db5': 'restfulness_bad_during_second_half_once',
  'restfulness_bad_during_second_half_once_0:view:0ttisx': 'restfulness_bad_during_second_half_once',
  '518c1c63-52cc-49d4-9ea7-913413d3086d:view:i00ugw': 'rest_mode_total_sleep_bad',
  '518c1c63-52cc-49d4-9ea7-913413d3086d:view:7oknmr': 'rest_mode_total_sleep_bad',
  '518c1c63-52cc-49d4-9ea7-913413d3086d:view:80it5b': 'rest_mode_total_sleep_bad',
  'nightly_score_bad_0:view:jk3g8g': 'nightly_score_bad',
  'nightly_score_bad_0:view:z7g9v7': 'nightly_score_bad',
  'nightly_score_bad_0:view:h9x2ru': 'nightly_score_bad',
  'goal_healthy_previous_day_good_optimal_activity_0:view:8c9g2x': 'goal_healthy_previous_day_good_optimal_activity',
  'goal_healthy_previous_day_good_optimal_activity_0:view:el1l8o': 'goal_healthy_previous_day_good_optimal_activity',
  'nightly_score_0:view:pmwobv': 'nightly_score',
  'nightly_score_0:view:7hfsmb': 'nightly_score',
  'nightly_score_0:view:un53g0': 'nightly_score',
  'ob_charging_icon_0:view:q0zuvr': 'ob_charging_icon',
  'ob_charging_icon_0:view:gxel50': 'ob_charging_icon',
  'ob_charging_icon_0:view:81jwb5': 'ob_charging_icon',
  'ob_charging_icon_0:view:z60kiz': 'ob_charging_icon',
  'ob_charging_icon_0:view:3y5dn1': 'ob_charging_icon',
  'ob_respiratory_rate_0:view:s51pqj': 'ob_respiratory_rate',
  'ob_respiratory_rate_0:view:76pf4v': 'ob_respiratory_rate',
  'ob_respiratory_rate_0:view:6wb2vp': 'ob_respiratory_rate',
  'ob_readiness_score_0:view:fj54ch': 'ob_readiness_score',
  'ob_readiness_score_0:view:xwh0z6': 'ob_readiness_score',
  'ob_readiness_score_0:view:5irmoa': 'ob_readiness_score',
  'ob_readiness_score_0:view:2t8n04': 'ob_readiness_score',
  '955a2f54-166f-48a0-afea-5fc608f9bea6:view:b9tepd': 'goal_healthy_previous_day_bad_inactivity',
  '955a2f54-166f-48a0-afea-5fc608f9bea6:view:d8lsh1': 'goal_healthy_previous_day_bad_inactivity',
  'goal_healthy_previous_day_bad_inactivity_0:view:bqm18t': 'goal_healthy_previous_day_bad_inactivity',
  'goal_healthy_previous_day_bad_inactivity_0:view:v1ndjz': 'goal_healthy_previous_day_bad_inactivity',
  'goal_healthy_previous_day_good_score_good_0:view:68g7ny': 'goal_healthy_previous_day_good_score_good',
  'goal_healthy_previous_day_good_score_good_0:view:djo9jm': 'goal_healthy_previous_day_good_score_good',
  'goal_productive_activity_balance_too_active_0:view:a65s4z': 'goal_productive_activity_balance_too_active',
  'goal_productive_activity_balance_too_active_0:view:xrz2xz': 'goal_productive_activity_balance_too_active',
  'goal_healthy_previous_night_bad_score_ok_0:view:s12lsd': 'goal_healthy_previous_night_bad_score_ok',
  'goal_healthy_previous_night_bad_score_ok_0:view:gen13m': 'goal_healthy_previous_night_bad_score_ok',
  'goal_healthy_previous_night_bad_score_ok_0:view:1qogfm': 'goal_healthy_previous_night_bad_score_ok',
  'goal_healthy_previous_night_bad_score_ok_1:view:avycj9': 'goal_healthy_previous_night_bad_score_ok',
  'goal_healthy_previous_night_bad_score_ok_1:view:d9ggih': 'goal_healthy_previous_night_bad_score_ok',
  'goal_healthy_previous_night_bad_score_ok_1:view:bsckie': 'goal_healthy_previous_night_bad_score_ok',
  '43c8ea28-74d1-46f2-a0b5-88656b4511ea:view:7ibnfk': 'goal_healthy_training_frequency_volume_optimal',
  '43c8ea28-74d1-46f2-a0b5-88656b4511ea:view:xfxdxk': 'goal_healthy_training_frequency_volume_optimal',
  'goal_healthy_activity_balance_good_0:view:7novfu': 'goal_healthy_activity_balance_good',
  'goal_healthy_activity_balance_good_0:view:vxbd8g': 'goal_healthy_activity_balance_good',
  'readiness_score_high_0:view:3c1z3m': 'readiness_score_high',
  'readiness_score_high_0:view:k47j2l': 'readiness_score_high',
  '1391674f-5ea1-4980-9ad2-ae211c86891f:view:7r77mk': 'readiness_good_record_high_activity',
  '1391674f-5ea1-4980-9ad2-ae211c86891f:view:n1imac': 'readiness_good_record_high_activity',
  '1391674f-5ea1-4980-9ad2-ae211c86891f:view:ejdl1j': 'readiness_good_record_high_activity',
  '9a38703b-031f-4120-bf00-c29d76085f05:view:j7eznp': 'recovery_mode_deep_sleep_bad',
  '9a38703b-031f-4120-bf00-c29d76085f05:view:unh00g': 'recovery_mode_deep_sleep_bad',
  '1e06a37f-711c-46b5-bfc1-36d4f3426374:view:ztnxxa': 'recovery_mode_day_1_readiness_message',
  '1e06a37f-711c-46b5-bfc1-36d4f3426374:view:pnbnjq': 'recovery_mode_day_1_readiness_message',
  'ob_crown_0:view:o3nc71': 'ob_crown',
  'ob_crown_0:view:pefegm': 'ob_crown',
  'ob_crown_0:view:hj8o7t': 'ob_crown',
  'ob_crown_0:view:nfjsep': 'ob_crown',
  'ob_activity_tracking_0:view:yacgis': 'ob_activity_tracking',
  'ob_activity_tracking_0:view:0axz4i': 'ob_activity_tracking',
  'ob_activity_tracking_0:view:k5vez5': 'ob_activity_tracking',
  'goal_productive_activity_balance_good_0:view:ve8av2': 'goal_productive_activity_balance_good',
  'goal_productive_activity_balance_good_0:view:ujww8z': 'goal_productive_activity_balance_good',
  'goal_productive_activity_balance_good_0:view:ixyb4i': 'goal_productive_activity_balance_good',
  'goal_healthy_sleep_balance_good_0:view:hwyzdb': 'goal_healthy_sleep_balance_good',
  'goal_healthy_sleep_balance_good_0:view:6ormvj': 'goal_healthy_sleep_balance_good',
  'goal_healthy_sleep_balance_good_1:view:h2dvxu': 'goal_healthy_sleep_balance_good',
  'goal_healthy_sleep_balance_good_1:view:u6mcq7': 'goal_healthy_sleep_balance_good',
  'goal_healthy_sleep_balance_bad_0:view:9vwcun': 'goal_healthy_sleep_balance_bad',
  'goal_healthy_sleep_balance_bad_0:view:pwre3k': 'goal_healthy_sleep_balance_bad',
  'goal_healthy_sleep_balance_good_readiness_ok_0:view:uui64a': 'goal_healthy_sleep_balance_good_readiness_ok',
  'goal_healthy_sleep_balance_good_readiness_ok_0:view:0cbk8a': 'goal_healthy_sleep_balance_good_readiness_ok',
  'goal_healthy_sleep_balance_good_readiness_ok_0:view:kkxvun': 'goal_healthy_sleep_balance_good_readiness_ok',
  'readiness_great_1:view:83nwxr': 'readiness_great',
  'readiness_great_1:view:vbr3wa': 'readiness_great',
  'readiness_great_2:view:lskyip': 'readiness_great',
  'readiness_great_2:view:2jnqy2': 'readiness_great',
  'readiness_great_3:view:zxto68': 'readiness_great',
  'readiness_great_3:view:f8c019': 'readiness_great',
  'readiness_great_4:view:9y613d': 'readiness_great',
  'readiness_great_4:view:dijyqe': 'readiness_great',
  '61313bcb-2ee9-429f-8f61-99a0f2fa99cd:view:9v4h0a': 'rest_mode_total_sleep_good',
  '61313bcb-2ee9-429f-8f61-99a0f2fa99cd:view:97v678': 'rest_mode_total_sleep_good',
  'f2860f3d-8fdf-425a-811c-668dd3ea89c0:view:6jx70w': 'rest_mode_total_sleep_good',
  'f2860f3d-8fdf-425a-811c-668dd3ea89c0:view:tlfne0': 'rest_mode_total_sleep_good',
  'b86c4c11-b1f0-46d4-9e12-43c168889735:view:hdpk2i': 'rest_mode_temp_midpoint_day_2_yesterday_midpoint',
  'b86c4c11-b1f0-46d4-9e12-43c168889735:view:kr95jh': 'rest_mode_temp_midpoint_day_2_yesterday_midpoint',
  'ob_moment_0:view:ew7zza': 'ob_moment',
  'ob_moment_0:view:j3xup3': 'ob_moment',
  'ob_moment_0:view:w7ds3y': 'ob_moment',
  'ob_moment_0:view:m8cxha': 'ob_moment',
  'nightly_score_attention_0:view:yq9n4y': 'nightly_score_attention',
  'nightly_score_attention_0:view:q4tycq': 'nightly_score_attention',
  'nightly_score_attention_0:view:2scrdy': 'nightly_score_attention',
  'ob_oura_cloud_0:view:g63r1s': 'ob_oura_cloud',
  'ob_oura_cloud_0:view:dey429': 'ob_oura_cloud',
  'ob_oura_cloud_0:view:bdimgs': 'ob_oura_cloud',
  'ob_activity_score_0:view:ad4h95': 'ob_activity_score',
  'ob_activity_score_0:view:llqxpp': 'ob_activity_score',
  'ob_activity_score_0:view:b1ml6e': 'ob_activity_score',
  '52eb5d42-fdbb-458d-995d-164e917b0b1e:view:16peba': 'goal_healthy_meet_daily_goals_bad',
  '52eb5d42-fdbb-458d-995d-164e917b0b1e:view:9yi4a8': 'goal_healthy_meet_daily_goals_bad',
  'd9eecd53-b6f3-4082-816a-68c1c416b4c3:view:v5rv71': 'goal_healthy_meet_daily_goals_bad',
  'd9eecd53-b6f3-4082-816a-68c1c416b4c3:view:6w0hn5': 'goal_healthy_meet_daily_goals_bad',
  'goal_healthy_resting_hr_time_bad_0:view:0d4s8u': 'goal_healthy_resting_hr_time_bad',
  'goal_healthy_resting_hr_time_bad_0:view:otw4il': 'goal_healthy_resting_hr_time_bad',
  'goal_healthy_resting_hr_time_bad_0:view:9mguag': 'goal_healthy_resting_hr_time_bad',
  'goal_healthy_resting_hr_optimal_0:view:ggo782': 'goal_healthy_resting_hr_optimal',
  'goal_healthy_resting_hr_optimal_0:view:77a6a0': 'goal_healthy_resting_hr_optimal',
  'goal_healthy_resting_hr_bad_not_much_activity_0:view:9g98iv': 'goal_healthy_resting_hr_bad_not_much_activity',
  'goal_healthy_resting_hr_bad_not_much_activity_0:view:5ouyij': 'goal_healthy_resting_hr_bad_not_much_activity',
  '24h_inactivity_high_7d_exercise_high_0:view:5yuaee': '24h_inactivity_high_7d_exercise_high',
  '24h_inactivity_high_7d_exercise_high_0:view:co48x7': '24h_inactivity_high_7d_exercise_high',
  '24h_inactivity_high_7d_exercise_high_0:view:f0reka': '24h_inactivity_high_7d_exercise_high',
  '24h_inactivity_high_7d_exercise_high_1:view:tfukfx': '24h_inactivity_high_7d_exercise_high',
  '24h_inactivity_high_7d_exercise_high_1:view:j74i72': '24h_inactivity_high_7d_exercise_high',
  '24h_inactivity_high_7d_exercise_high_2:view:vxlghv': '24h_inactivity_high_7d_exercise_high',
  '24h_inactivity_high_7d_exercise_high_2:view:1k6zps': '24h_inactivity_high_7d_exercise_high',
  '24h_inactivity_high_7d_exercise_high_2:view:5z3sco': '24h_inactivity_high_7d_exercise_high',
  'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0:view:06chdu':
    'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once',
  'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0:view:j24ds9':
    'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once',
  'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0:view:7rm64p':
    'Recharge_intro_total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once',
  'activity_balance_too_high_score_good_once_0:view:worxkv': 'activity_balance_too_high_score_good_once',
  'activity_balance_too_high_score_good_once_0:view:w2kbuf': 'activity_balance_too_high_score_good_once',
  'activity_balance_too_high_score_good_once_2:view:tcsgbj': 'activity_balance_too_high_score_good_once',
  'activity_balance_too_high_score_good_once_2:view:4ov9mf': 'activity_balance_too_high_score_good_once',
  'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once_0:view:xxt5du':
    'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once',
  'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once_0:view:u48pkm':
    'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once',
  'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once_0:view:7x0g7z':
    'Recharge_intro_resting_hr_time_bad_score_good_activity_high_once',
  'activity_balance_good_lowish_score_bad_once_0:view:fbdjoz': 'activity_balance_good_lowish_score_bad_once',
  'activity_balance_good_lowish_score_bad_once_0:view:dx5mew': 'activity_balance_good_lowish_score_bad_once',
  'activity_balance_good_lowish_score_bad_once_0:view:wq2mu4': 'activity_balance_good_lowish_score_bad_once',
  'activity_balance_good_lowish_score_bad_once_2:view:l19tro': 'activity_balance_good_lowish_score_bad_once',
  'activity_balance_good_lowish_score_bad_once_2:view:7400de': 'activity_balance_good_lowish_score_bad_once',
  'activity_balance_good_lowish_score_bad_once_3:view:7bvex0': 'activity_balance_good_lowish_score_bad_once',
  'activity_balance_good_lowish_score_bad_once_3:view:uywd1r': 'activity_balance_good_lowish_score_bad_once',
  'd21f0d47-e9d5-4421-b27b-ca7c6b277042:view:44x863': '0b568687-3820-46da-8ce4-d029d41a57c9',
  'd21f0d47-e9d5-4421-b27b-ca7c6b277042:view:qw8jb2': '0b568687-3820-46da-8ce4-d029d41a57c9',
  'activity_balance_too_low_score_bad_once_0:view:y1m5zt': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_low_score_bad_once_0:view:ezi7an': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_low_score_bad_once_2:view:imm390': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_low_score_bad_once_2:view:0doznq': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_low_score_bad_once_4:view:4dnfre': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_low_score_bad_once_4:view:7xkwh3': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_low_score_bad_once_5:view:o06uhy': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_low_score_bad_once_5:view:ro13p4': 'activity_balance_too_low_score_bad_once',
  'activity_balance_too_high_score_bad_once_0:view:gxcj5l': 'activity_balance_too_high_score_bad_once',
  'activity_balance_too_high_score_bad_once_0:view:znmdc5': 'activity_balance_too_high_score_bad_once',
  'activity_balance_too_high_score_bad_once_2:view:d0ibcl': 'activity_balance_too_high_score_bad_once',
  'activity_balance_too_high_score_bad_once_2:view:dagzrt': 'activity_balance_too_high_score_bad_once',
  'activity_balance_too_high_score_bad_once_3:view:5xk7zc': 'activity_balance_too_high_score_bad_once',
  'activity_balance_too_high_score_bad_once_3:view:i1ltb0': 'activity_balance_too_high_score_bad_once',
  'nightly_score_good_0:view:a2z1ek': 'nightly_score_good',
  'nightly_score_good_0:view:uplvcj': 'nightly_score_good',
  'nightly_score_good_0:view:bpokdv': 'nightly_score_good',
  'ob_introducing_oura_0:view:nqu1ct': 'ob_introducing_oura',
  'ob_introducing_oura_0:view:6hpngk': 'ob_introducing_oura',
  'ob_introducing_oura_0:view:m8sfgf': 'ob_introducing_oura',
  'ob_introducing_oura_0:view:r3balo': 'ob_introducing_oura',
  'ob_introducing_oura_0:view:23toct': 'ob_introducing_oura',
  'goal_healthy_resting_hr_bad_too_much_activity_0:view:uymare': 'goal_healthy_resting_hr_bad_too_much_activity',
  'goal_healthy_resting_hr_bad_too_much_activity_0:view:783mn7': 'goal_healthy_resting_hr_bad_too_much_activity',
  'goal_healthy_recovery_index_good_0:view:h2bo9c': 'goal_healthy_recovery_index_good',
  'goal_healthy_recovery_index_good_0:view:h2a5pf': 'goal_healthy_recovery_index_good',
  '5e2c6c70-2a2c-47fc-bbf1-c48df85c9214:view:r4x8iy': 'goal_productive_meet_daily_goals_optimal',
  '5e2c6c70-2a2c-47fc-bbf1-c48df85c9214:view:n7p01g': 'goal_productive_meet_daily_goals_optimal',
  'goal_healthy_resting_hr_bad_0:view:tosbuv': 'goal_healthy_resting_hr_bad',
  'goal_healthy_resting_hr_bad_0:view:msxvvy': 'goal_healthy_resting_hr_bad',
  'goal_stress_recovery_index_bad_0:view:21hm9l': 'goal_stress_recovery_index_bad',
  'goal_stress_recovery_index_bad_0:view:uphvee': 'goal_stress_recovery_index_bad',
  'goal_stress_recovery_index_bad_0:view:n9gwqp': 'goal_stress_recovery_index_bad',
  'goal_stress_recovery_index_good_0:view:usbv0k': 'goal_stress_recovery_index_good',
  'goal_stress_recovery_index_good_0:view:cxn4ye': 'goal_stress_recovery_index_good',
  'goal_stress_recovery_index_good_0:view:kz2uh5': 'goal_stress_recovery_index_good',
  'goal_stress_resting_hr_trend_bad_0:view:3xv333': 'goal_stress_resting_hr_trend_bad',
  'goal_stress_resting_hr_trend_bad_0:view:2oaeij': 'goal_stress_resting_hr_trend_bad',
  'goal_stress_resting_hr_trend_bad_0:view:xuuydv': 'goal_stress_resting_hr_trend_bad',
  'goal_healthy_recovery_index_bad_0:view:mieq1w': 'goal_healthy_recovery_index_bad',
  'goal_healthy_recovery_index_bad_0:view:y04xrm': 'goal_healthy_recovery_index_bad',
  'f7f01e1b-fd17-48e6-b2ba-6a9d4f46c222:view:au4td1': 'goal_productive_meet_daily_goals_bad',
  'f7f01e1b-fd17-48e6-b2ba-6a9d4f46c222:view:b9jo4c': 'goal_productive_meet_daily_goals_bad',
  'goal_stress_resting_hr_time_bad_0:view:tzfw69': 'goal_stress_resting_hr_time_bad',
  'goal_stress_resting_hr_time_bad_0:view:65esrd': 'goal_stress_resting_hr_time_bad',
  'goal_stress_resting_hr_time_bad_0:view:3islco': 'goal_stress_resting_hr_time_bad',
  'goal_stress_readiness_score_trending_up_already_good_0:view:orsk8x':
    'goal_stress_readiness_score_trending_up_already_good',
  'goal_stress_readiness_score_trending_up_already_good_0:view:04qqmg':
    'goal_stress_readiness_score_trending_up_already_good',
  '063397b7-176a-4a66-a1be-acef300c6713:view:kd6zhp': 'rest_mode_temp_midpoint_day_2_high_day_1',
  '063397b7-176a-4a66-a1be-acef300c6713:view:trc9v2': 'rest_mode_temp_midpoint_day_2_high_day_1',
  '1cfb6a83-9a06-4b11-9c67-32c0bfa38027:view:u8tzjm': 'rest_mode_temp_high_score_low_once',
  '1cfb6a83-9a06-4b11-9c67-32c0bfa38027:view:ia2bgh': 'rest_mode_temp_high_score_low_once',
  'daa7cfdf-03c0-4eb6-94d0-43587507c3ca:view:rlg62i': 'rest_mode_temp_midpoint',
  'daa7cfdf-03c0-4eb6-94d0-43587507c3ca:view:8775xe': 'rest_mode_temp_midpoint',
  'restfulness_bad_during_first_half_once_0:view:fqaj2c': 'restfulness_bad_during_first_half_once',
  'restfulness_bad_during_first_half_once_0:view:uagt5j': 'restfulness_bad_during_first_half_once',
  '4469f43a-c572-4097-9ad1-1df1d5bddf05:view:8x4r1g': 'rest_mode_temp_high_day_5',
  '4469f43a-c572-4097-9ad1-1df1d5bddf05:view:s7ed87': 'rest_mode_temp_high_day_5',
  '5700091e-82ce-498a-932a-c20e221cb202:view:shk0x4': 'rest_mode_temp_high_day_5',
  '5700091e-82ce-498a-932a-c20e221cb202:view:khai5n': 'rest_mode_temp_high_day_5',
  '656d80cf-d1b2-4dd0-9adf-7494304d8ebf:view:mhruet': 'rest_mode_temp_high_day_5',
  '656d80cf-d1b2-4dd0-9adf-7494304d8ebf:view:86996e': 'rest_mode_temp_high_day_5',
  '90c96f6d-84f3-489a-855d-2e34cac6e8d0:view:n741n7': 'rest_mode_temp_high_score_good',
  '90c96f6d-84f3-489a-855d-2e34cac6e8d0:view:d5vieq': 'rest_mode_temp_high_score_good',
  '12d1ba2e-32e8-4607-b51d-35c84200bd29:view:npoe7m': 'recovery_mode_body_temp_mediocre',
  '12d1ba2e-32e8-4607-b51d-35c84200bd29:view:b1xzw6': 'recovery_mode_body_temp_mediocre',
  'activity_balance_good_score_bad_once_0:view:o2lejx': 'activity_balance_good_score_bad_once',
  'activity_balance_good_score_bad_once_0:view:zvux6j': 'activity_balance_good_score_bad_once',
  'activity_balance_good_score_bad_once_2:view:c27ry3': 'activity_balance_good_score_bad_once',
  'activity_balance_good_score_bad_once_2:view:gukud3': 'activity_balance_good_score_bad_once',
  'activity_balance_good_score_bad_once_3:view:dl7rmn': 'activity_balance_good_score_bad_once',
  'activity_balance_good_score_bad_once_3:view:p427de': 'activity_balance_good_score_bad_once',
  '04b5318a-958f-46db-bc5b-54ebed528ab3:view:mw8vmn': 'activity_target_changed_readiness_low',
  '04b5318a-958f-46db-bc5b-54ebed528ab3:view:16sljz': 'activity_target_changed_readiness_low',
  '6fef7ca4-061d-4e36-a81b-6c5fb6b9b870:view:fwousp': 'activity_target_changed_readiness_high',
  '6fef7ca4-061d-4e36-a81b-6c5fb6b9b870:view:aky911': 'activity_target_changed_readiness_high',
  '4e06a0fc-a5d3-44e7-94da-e6b4ff296059:view:q3rknl': 'c765ea39-1db8-4e5c-9853-f3ef746a0e7f',
  '4e06a0fc-a5d3-44e7-94da-e6b4ff296059:view:goztz9': 'c765ea39-1db8-4e5c-9853-f3ef746a0e7f',
  '4e06a0fc-a5d3-44e7-94da-e6b4ff296059:view:x15hmc': 'c765ea39-1db8-4e5c-9853-f3ef746a0e7f',
  '4e06a0fc-a5d3-44e7-94da-e6b4ff296059:view:0alucb': 'c765ea39-1db8-4e5c-9853-f3ef746a0e7f',
  'activity_balance_too_low_score_good_once_0:view:aspkqi': 'activity_balance_too_low_score_good_once',
  'activity_balance_too_low_score_good_once_0:view:qsx1cv': 'activity_balance_too_low_score_good_once',
  'activity_balance_too_low_score_good_once_2:view:bog8bf': 'activity_balance_too_low_score_good_once',
  'activity_balance_too_low_score_good_once_2:view:d8dxiu': 'activity_balance_too_low_score_good_once',
  'd6939fc9-a8cb-4cb8-a5a2-a1a627736ad5:view:xzifx8': 'recovery_mode_readiness_score_improving',
  'd6939fc9-a8cb-4cb8-a5a2-a1a627736ad5:view:4rsti4': 'recovery_mode_readiness_score_improving',
  'd6939fc9-a8cb-4cb8-a5a2-a1a627736ad5:view:dctnqs': 'recovery_mode_readiness_score_improving',
  'activity_balance_good_score_good_once_0:view:ed3k4v': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_0:view:j6k9co': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_2:view:myor4s': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_2:view:aikz0l': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_3:view:ds6s1j': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_3:view:mqq2xn': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_4:view:xv0k75': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_4:view:dwbzim': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_5:view:bbs0ls': 'activity_balance_good_score_good_once',
  'activity_balance_good_score_good_once_5:view:byu0fh': 'activity_balance_good_score_good_once',
  'goal_stress_previous_day_good_score_good_0:view:dqni1a': 'goal_stress_previous_day_good_score_good',
  'goal_stress_previous_day_good_score_good_0:view:jy8ocr': 'goal_stress_previous_day_good_score_good',
  '63b8d5ec-2c9c-4c0b-9afb-f8d014f50b6c:view:3tnmss': 'goal_productive_training_frequency_volume_low_readiness_ok',
  '63b8d5ec-2c9c-4c0b-9afb-f8d014f50b6c:view:00e9wz': 'goal_productive_training_frequency_volume_low_readiness_ok',
  'goal_stress_readiness_bad_0:view:quc4xa': 'goal_stress_readiness_bad',
  'goal_stress_readiness_bad_0:view:btm440': 'goal_stress_readiness_bad',
  'goal_stress_readiness_bad_0:view:zhkzzv': 'goal_stress_readiness_bad',
  '31bd8efe-a1b0-4473-aad3-f0690ca694bd:view:5xf0qa': 'goal_stress_meet_daily_goals_bad',
  '31bd8efe-a1b0-4473-aad3-f0690ca694bd:view:o8fkbc': 'goal_stress_meet_daily_goals_bad',
  'goal_productive_previous_night_bad_score_ok_0:view:5a9xyc': 'goal_productive_previous_night_bad_score_ok',
  'goal_productive_previous_night_bad_score_ok_0:view:n57a9f': 'goal_productive_previous_night_bad_score_ok',
  'goal_productive_previous_night_bad_score_ok_0:view:r0jb8m': 'goal_productive_previous_night_bad_score_ok',
  'goal_stress_previous_night_bad_score_ok_0:view:b65egm': 'goal_stress_previous_night_bad_score_ok',
  'goal_stress_previous_night_bad_score_ok_0:view:081iws': 'goal_stress_previous_night_bad_score_ok',
  'goal_stress_previous_night_bad_score_ok_0:view:xzeeh7': 'goal_stress_previous_night_bad_score_ok',
  'goal_stress_activity_balance_good_0:view:mb40zg': 'goal_stress_activity_balance_good',
  'goal_stress_activity_balance_good_0:view:lc6dry': 'goal_stress_activity_balance_good',
  'goal_stress_activity_balance_good_0:view:95vddi': 'goal_stress_activity_balance_good',
  '679afde8-c029-47c2-8c47-7ba8f3a1ba73:view:173h2d': 'recovery_mode_readiness_score_bad',
  '679afde8-c029-47c2-8c47-7ba8f3a1ba73:view:teb89e': 'recovery_mode_readiness_score_bad',
  'f707b672-c64c-432c-9f7d-6edc3a80a15b:view:alj2p5': 'recovery_mode_readiness_good_heart_rate_good',
  'f707b672-c64c-432c-9f7d-6edc3a80a15b:view:vfydd8': 'recovery_mode_readiness_good_heart_rate_good',
  '31b80c66-7785-4453-8ce3-7994fef55593:view:xpwzbz': 'recovery_mode_previous_night_bad',
  '31b80c66-7785-4453-8ce3-7994fef55593:view:wzaccz': 'recovery_mode_previous_night_bad',
  '3bdbdeeb-362d-4fbf-a4a6-828ae6ed26a3:view:8okj90': 'recovery_mode_previous_day_bad_high_activity',
  '3bdbdeeb-362d-4fbf-a4a6-828ae6ed26a3:view:ch6zn4': 'recovery_mode_previous_day_bad_high_activity',
  '9ade9659-85de-4f41-829c-245c0e4eeea4:view:avoxmv': 'recovery_mode_previous_night_good',
  '9ade9659-85de-4f41-829c-245c0e4eeea4:view:tcvp09': 'recovery_mode_previous_night_good',
  '9ade9659-85de-4f41-829c-245c0e4eeea4:view:0pi7yv': 'recovery_mode_previous_night_good',
  '35de271a-7f28-4a76-a25e-9edab75a0464:view:fnrg6f': 'recovery_mode_previous_day_good',
  '35de271a-7f28-4a76-a25e-9edab75a0464:view:biuprf': 'recovery_mode_previous_day_good',
  '2b997dfd-e440-4908-95ea-ec07c34952d3:view:xwpkyr': 'recovery_mode_hrv_balance_bad',
  '2b997dfd-e440-4908-95ea-ec07c34952d3:view:6xk8mn': 'recovery_mode_hrv_balance_bad',
  '148859c2-481a-4a02-807f-70d8de366736:view:mo5n60': 'recovery_mode_efficiency_bad',
  '148859c2-481a-4a02-807f-70d8de366736:view:auc9n7': 'recovery_mode_efficiency_bad',
  '148859c2-481a-4a02-807f-70d8de366736:view:i9hy28': 'recovery_mode_efficiency_bad',
  'latency_high_activity_high_once_0:view:3yevsu': 'latency_high_activity_high_once',
  'latency_high_activity_high_once_0:view:pbibsi': 'latency_high_activity_high_once',
  'latency_high_activity_high_once_0:view:c2ss2y': 'latency_high_activity_high_once',
  'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once_0:view:vvk2jn':
    'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once',
  'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once_0:view:3etm7f':
    'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once',
  'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once_0:view:dxct6y':
    'Recharge_intro_resting_hr_time_bad_score_bad_activity_low_once',
  'goal_productive_previous_day_good_0:view:3blqyc': 'goal_productive_previous_day_good',
  'goal_productive_previous_day_good_0:view:hp9njn': 'goal_productive_previous_day_good',
  'goal_productive_previous_day_good_0:view:brfyo3': 'goal_productive_previous_day_good',
  'latency_good_once_0:view:or709m': 'latency_good_once',
  'latency_good_once_0:view:laozby': 'latency_good_once',
  'goal_productive_sleep_balance_good_0:view:68rk3r': 'goal_productive_sleep_balance_good',
  'goal_productive_sleep_balance_good_0:view:zbzd2d': 'goal_productive_sleep_balance_good',
  '0acfb670-c3b2-4b8e-86bd-67857279e1e5:view:k08d7l': 'goal_productive_previous_day_inactivity_high',
  '0acfb670-c3b2-4b8e-86bd-67857279e1e5:view:ur867d': 'goal_productive_previous_day_inactivity_high',
  'goal_productive_previous_day_inactivity_high_0:view:kpuywd': 'goal_productive_previous_day_inactivity_high',
  'goal_productive_previous_day_inactivity_high_0:view:r7jh73': 'goal_productive_previous_day_inactivity_high',
  'latency_low_once_0:view:2zzpj6': 'latency_low_once',
  'latency_low_once_0:view:ienuia': 'latency_low_once',
  'latency_low_once_0:view:0vsb98': 'latency_low_once',
  '09aefb3f-f1aa-4876-9d68-b71ecd110e9a:view:rpeliq': 'goal_productive_stay_active_bad_7d_exercise_high',
  '09aefb3f-f1aa-4876-9d68-b71ecd110e9a:view:op48e3': 'goal_productive_stay_active_bad_7d_exercise_high',
  '09aefb3f-f1aa-4876-9d68-b71ecd110e9a:view:t7tt6u': 'goal_productive_stay_active_bad_7d_exercise_high',
  'goal_productive_sleep_balance_good_readiness_ok_0:view:0xr1ko': 'goal_productive_sleep_balance_good_readiness_ok',
  'goal_productive_sleep_balance_good_readiness_ok_0:view:0ld19x': 'goal_productive_sleep_balance_good_readiness_ok',
  'goal_productive_sleep_balance_good_readiness_ok_0:view:6h7mh0': 'goal_productive_sleep_balance_good_readiness_ok',
  'high_daily_activity_0:view:lo2pwr': 'high_daily_activity',
  'high_daily_activity_0:view:cmw8jn': 'high_daily_activity',
  'c15d9f85-42dc-423e-81b4-8333dbe82caf:view:y4bnea': 'goal_productive_stay_active_optimal',
  'c15d9f85-42dc-423e-81b4-8333dbe82caf:view:pxr9pz': 'goal_productive_stay_active_optimal',
  'goal_productive_sleep_balance_bad_0:view:f0kh08': 'goal_productive_sleep_balance_bad',
  'goal_productive_sleep_balance_bad_0:view:ijajn8': 'goal_productive_sleep_balance_bad',
  'goal_productive_sleep_balance_bad_1:view:3hcanm': 'goal_productive_sleep_balance_bad',
  'goal_productive_sleep_balance_bad_1:view:o17mep': 'goal_productive_sleep_balance_bad',
  'goal_productive_sleep_balance_bad_1:view:8nt14p': 'goal_productive_sleep_balance_bad',
  'goal_productive_sleep_balance_bad_2:view:xy60cf': 'goal_productive_sleep_balance_bad',
  'goal_productive_sleep_balance_bad_2:view:ak6swp': 'goal_productive_sleep_balance_bad',
  'goal_stress_sleep_balance_good_0:view:tralp3': 'goal_stress_sleep_balance_good',
  'goal_stress_sleep_balance_good_0:view:y10b5l': 'goal_stress_sleep_balance_good',
  'goal_stress_sleep_balance_good_0:view:3v3qbm': 'goal_stress_sleep_balance_good',
  'rem_sleep_bad_cum_activity_bad_once_0:view:9wbnh6': 'rem_sleep_bad_cum_activity_bad_once',
  'rem_sleep_bad_cum_activity_bad_once_0:view:53tfd6': 'rem_sleep_bad_cum_activity_bad_once',
  'rem_sleep_bad_cum_activity_bad_once_0:view:qijzck': 'rem_sleep_bad_cum_activity_bad_once',
  'ccb4a366-6fdc-4369-b312-ff41934d7f1d:view:q8jbfm': 'recovery_mode_efficiency_good',
  'ccb4a366-6fdc-4369-b312-ff41934d7f1d:view:970b89': 'recovery_mode_efficiency_good',
  'rem_sleep_bad_total_bad_once_0:view:f3ye7s': 'rem_sleep_bad_total_bad_once',
  'rem_sleep_bad_total_bad_once_0:view:6hgu8v': 'rem_sleep_bad_total_bad_once',
  'rem_sleep_bad_total_bad_once_0:view:kl1dhm': 'rem_sleep_bad_total_bad_once',
  'rem_sleep_bad_total_bad_once_1:view:kbv7lo': 'rem_sleep_bad_total_bad_once',
  'rem_sleep_bad_total_bad_once_1:view:qjunm5': 'rem_sleep_bad_total_bad_once',
  'rem_sleep_bad_total_bad_once_1:view:die1jc': 'rem_sleep_bad_total_bad_once',
  'recovery_score_trending_up_but_still_low_0:view:2rfe29': 'recovery_score_trending_up_but_still_low',
  'recovery_score_trending_up_but_still_low_0:view:00xxis': 'recovery_score_trending_up_but_still_low',
  'recovery_score_trending_up_but_still_low_2:view:fu0fy8': 'recovery_score_trending_up_but_still_low',
  'recovery_score_trending_up_but_still_low_2:view:s4n3t8': 'recovery_score_trending_up_but_still_low',
  'b5d453cb-3703-4d30-8ad6-154ad5e6ab83:view:pqqafe': 'recovery_mode_sleep_balance_improving',
  'b5d453cb-3703-4d30-8ad6-154ad5e6ab83:view:1n0twu': 'recovery_mode_sleep_balance_improving',
  'rem_sleep_bad_activity_high_once_0:view:sz9al9': 'rem_sleep_bad_activity_high_once',
  'rem_sleep_bad_activity_high_once_0:view:3g5n2s': 'rem_sleep_bad_activity_high_once',
  'rem_sleep_bad_activity_high_once_0:view:kfw4d2': 'rem_sleep_bad_activity_high_once',
  'recovery_score_trending_down_but_still_good_0:view:7gppdg': 'recovery_score_trending_down_but_still_good',
  'recovery_score_trending_down_but_still_good_0:view:j97z8g': 'recovery_score_trending_down_but_still_good',
  'recovery_score_trending_down_but_still_good_2:view:5nczta': 'recovery_score_trending_down_but_still_good',
  'recovery_score_trending_down_but_still_good_2:view:2tz5rs': 'recovery_score_trending_down_but_still_good',
  '10735a10-dda5-464b-8c7f-880dd8cee28a:view:u7zoh1': 'recovery_mode_total_sleep_bad',
  '10735a10-dda5-464b-8c7f-880dd8cee28a:view:7deo1j': 'recovery_mode_total_sleep_bad',
  '43d246fa-da20-4d14-b591-816bf7305ccc:view:3ya4go': 'recovery_mode_timing_good',
  '43d246fa-da20-4d14-b591-816bf7305ccc:view:dzlymm': 'recovery_mode_timing_good',
  'cdf7a673-cfe4-47b4-bebd-abde31e7d361:view:134gjq': 'recovery_mode_total_sleep_improving',
  'cdf7a673-cfe4-47b4-bebd-abde31e7d361:view:p35c6b': 'recovery_mode_total_sleep_improving',
  'cdf7a673-cfe4-47b4-bebd-abde31e7d361:view:g5enjy': 'recovery_mode_total_sleep_improving',
  'recovery_score_trending_up_already_good_0:view:gs3vz6': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_0:view:oy0upu': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_2:view:nswpw3': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_2:view:l6123n': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_3:view:09dqsz': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_3:view:g4mq95': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_4:view:2olon1': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_4:view:qq24q7': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_5:view:5fkh3e': 'recovery_score_trending_up_already_good',
  'recovery_score_trending_up_already_good_5:view:jyxt94': 'recovery_score_trending_up_already_good',
  'resting_hr_good_score_good_once_2:view:u7syh6': 'resting_hr_good_score_good_once',
  'resting_hr_good_score_good_once_2:view:fytknf': 'resting_hr_good_score_good_once',
  'resting_hr_good_score_good_once_3:view:uo32ht': 'resting_hr_good_score_good_once',
  'resting_hr_good_score_good_once_3:view:rosy14': 'resting_hr_good_score_good_once',
  'resting_hr_good_score_good_once_3:view:zb30v8': 'resting_hr_good_score_good_once',
  'resting_hr_time_good_score_bad_activity_high_once_0:view:qoi25k':
    'resting_hr_time_good_score_bad_activity_high_once',
  'resting_hr_time_good_score_bad_activity_high_once_0:view:rmqq77':
    'resting_hr_time_good_score_bad_activity_high_once',
  'resting_hr_time_good_score_bad_activity_high_once_3:view:fcvsp9':
    'resting_hr_time_good_score_bad_activity_high_once',
  'resting_hr_time_good_score_bad_activity_high_once_3:view:3rud6k':
    'resting_hr_time_good_score_bad_activity_high_once',
  'resting_hr_good_score_bad_once_0:view:jykz6n': 'resting_hr_good_score_bad_once',
  'resting_hr_good_score_bad_once_0:view:g4d1mc': 'resting_hr_good_score_bad_once',
  'resting_hr_good_score_bad_once_2:view:8ee2qr': 'resting_hr_good_score_bad_once',
  'resting_hr_good_score_bad_once_2:view:s9sc6g': 'resting_hr_good_score_bad_once',
  'resting_hr_good_score_bad_once_3:view:sdppj9': 'resting_hr_good_score_bad_once',
  'resting_hr_good_score_bad_once_3:view:rqgi32': 'resting_hr_good_score_bad_once',
  'resting_hr_time_bad_score_bad_activity_low_once_0:view:vrb99d': 'resting_hr_time_bad_score_bad_activity_low_once',
  'resting_hr_time_bad_score_bad_activity_low_once_0:view:nyv7uc': 'resting_hr_time_bad_score_bad_activity_low_once',
  'resting_hr_time_bad_score_bad_activity_low_once_2:view:x349zt': 'resting_hr_time_bad_score_bad_activity_low_once',
  'resting_hr_time_bad_score_bad_activity_low_once_2:view:4bfa5h': 'resting_hr_time_bad_score_bad_activity_low_once',
  'resting_hr_low_score_high_once_0:view:4fp9oq': 'resting_hr_low_score_high_once',
  'resting_hr_low_score_high_once_0:view:lofn86': 'resting_hr_low_score_high_once',
  'resting_hr_low_score_high_once_0:view:6ooqhk': 'resting_hr_low_score_high_once',
  'resting_hr_low_score_high_once_2:view:1s01fn': 'resting_hr_low_score_high_once',
  'resting_hr_low_score_high_once_2:view:uwiopp': 'resting_hr_low_score_high_once',
  'resting_hr_low_score_high_once_2:view:r1hq1c': 'resting_hr_low_score_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_0:view:igqrsx': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_0:view:qjfozm': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_2:view:3ii57g': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_2:view:f4ywmz': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_3:view:3a8ykx': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_3:view:sv0jue': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_4:view:dm48cw': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_time_bad_score_bad_activity_high_once_4:view:rt0q43': 'resting_hr_time_bad_score_bad_activity_high_once',
  'resting_hr_low_score_low_once_0:view:xvtgfo': 'resting_hr_low_score_low_once',
  'resting_hr_low_score_low_once_0:view:f7fge4': 'resting_hr_low_score_low_once',
  'resting_hr_low_score_low_once_2:view:tg2mxb': 'resting_hr_low_score_low_once',
  'resting_hr_low_score_low_once_2:view:9jf72a': 'resting_hr_low_score_low_once',
  'resting_hr_low_score_low_once_3:view:h2uxyi': 'resting_hr_low_score_low_once',
  'resting_hr_low_score_low_once_3:view:q3kt6r': 'resting_hr_low_score_low_once',
  'Recharge_intro_restfulness_bad_evenly_distributed_once_0:view:jpp0a4':
    'Recharge_intro_restfulness_bad_evenly_distributed_once',
  'Recharge_intro_restfulness_bad_evenly_distributed_once_0:view:vtjrqv':
    'Recharge_intro_restfulness_bad_evenly_distributed_once',
  'goal_productive_previous_night_good_0:view:ylf54x': 'goal_productive_previous_night_good',
  'goal_productive_previous_night_good_0:view:92282k': 'goal_productive_previous_night_good',
  'decb4f97-234d-41df-97ac-59935a05855f:view:ocqv0a': 'recovery_mode_resting_hr_improving',
  'decb4f97-234d-41df-97ac-59935a05855f:view:l99m8b': 'recovery_mode_resting_hr_improving',
  'ada62ee9-fbfc-439a-bf06-56f600bf3c35:view:autsdh': 'recovery_mode_restfulness_bad',
  'ada62ee9-fbfc-439a-bf06-56f600bf3c35:view:ty4it7': 'recovery_mode_restfulness_bad',
  'ada62ee9-fbfc-439a-bf06-56f600bf3c35:view:tfkkgd': 'recovery_mode_restfulness_bad',
  '4eb29d3c-627d-479c-ad2c-788fcc8e389e:view:4yd0tk': 'stay_active_bad_7d_exercise_high',
  '4eb29d3c-627d-479c-ad2c-788fcc8e389e:view:8gru4v': 'stay_active_bad_7d_exercise_high',
  '4eb29d3c-627d-479c-ad2c-788fcc8e389e:view:c1vr12': 'stay_active_bad_7d_exercise_high',
  'sleep_balance_bad_score_bad_once_0:view:smz8z1': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_0:view:gl0cbz': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_2:view:0tsgkk': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_2:view:6bav25': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_3:view:6drj3r': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_3:view:q9mpyl': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_4:view:b1mzhz': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_4:view:taiu57': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_5:view:smpc8i': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_5:view:lg2za2': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_6:view:k5wn4h': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_score_bad_once_6:view:92558i': 'sleep_balance_bad_score_bad_once',
  'sleep_balance_bad_once_score_good_2:view:79j4tn': 'sleep_balance_bad_once_score_good',
  'sleep_balance_bad_once_score_good_2:view:okk390': 'sleep_balance_bad_once_score_good',
  'sleep_balance_bad_once_score_good_3:view:mjz9cu': 'sleep_balance_bad_once_score_good',
  'sleep_balance_bad_once_score_good_3:view:gtfwtj': 'sleep_balance_bad_once_score_good',
  'sleep_balance_bad_once_score_good_4:view:f5ztdv': 'sleep_balance_bad_once_score_good',
  'sleep_balance_bad_once_score_good_4:view:i5gdmt': 'sleep_balance_bad_once_score_good',
  'resting_hr_time_good_score_bad_activity_low_once_0:view:gglwg9': 'resting_hr_time_good_score_bad_activity_low_once',
  'resting_hr_time_good_score_bad_activity_low_once_0:view:jw8d5e': 'resting_hr_time_good_score_bad_activity_low_once',
  'resting_hr_time_good_score_bad_activity_low_once_2:view:tcpnm6': 'resting_hr_time_good_score_bad_activity_low_once',
  'resting_hr_time_good_score_bad_activity_low_once_2:view:2yiaiw': 'resting_hr_time_good_score_bad_activity_low_once',
  'e54c92c3-e5a1-4200-a119-145f34b9e46e:view:hvemud': 'meet_daily_goals_bad',
  'e54c92c3-e5a1-4200-a119-145f34b9e46e:view:xhbvg3': 'meet_daily_goals_bad',
  '17b8f108-fa83-4d69-9952-d9623dcf1ff9:view:2wlxws': 'recovery_mode_readiness_score_trending_down',
  '17b8f108-fa83-4d69-9952-d9623dcf1ff9:view:zz3k7l': 'recovery_mode_readiness_score_trending_down',
  '03e868a2-6cc0-4b97-be7a-bea4e662014f:view:3kfoim': 'recovery_mode_resting_hr_good',
  '03e868a2-6cc0-4b97-be7a-bea4e662014f:view:zk9hzj': 'recovery_mode_resting_hr_good',
  '288b966a-8acc-4993-adf7-067ef34415ee:view:dfu4q9': 'recovery_mode_resting_hr_trending_down',
  '288b966a-8acc-4993-adf7-067ef34415ee:view:a6ma1d': 'recovery_mode_resting_hr_trending_down',
  '288b966a-8acc-4993-adf7-067ef34415ee:view:u20car': 'recovery_mode_resting_hr_trending_down',
  '38b58e4c-e9af-439d-aa39-fcddee75c576:view:ja7akx': 'recovery_mode_resting_hr_bad',
  '38b58e4c-e9af-439d-aa39-fcddee75c576:view:l8607m': 'recovery_mode_resting_hr_bad',
  '38b58e4c-e9af-439d-aa39-fcddee75c576:view:zhq014': 'recovery_mode_resting_hr_bad',
  'rem_sleep_good_total_bad_once_0:view:a2m5wt': 'rem_sleep_good_total_bad_once',
  'rem_sleep_good_total_bad_once_0:view:f4buec': 'rem_sleep_good_total_bad_once',
  'rem_sleep_good_total_bad_once_0:view:o1xqzp': 'rem_sleep_good_total_bad_once',
  '01de55f1-0d1b-4c93-be48-c2e92e46a937:view:bu6824': 'rest_mode_previous_night_bad',
  '01de55f1-0d1b-4c93-be48-c2e92e46a937:view:9jff2n': 'rest_mode_previous_night_bad',
  'cf965242-d07c-485a-b3af-2ef91840c4b7:view:b5sokd': 'rest_mode_previous_night_good',
  'cf965242-d07c-485a-b3af-2ef91840c4b7:view:u7bci1': 'rest_mode_previous_night_good',
  'f1daadf5-625b-4dc7-a949-73c36dbf5bf8:view:l7etnn': 'recovery_mode_temp_high',
  'f1daadf5-625b-4dc7-a949-73c36dbf5bf8:view:kay5x4': 'recovery_mode_temp_high',
  'c1ea06a2-688c-4315-a803-5731fd573a80:view:38fwvb': 'rest_mode_efficiency_good',
  'c1ea06a2-688c-4315-a803-5731fd573a80:view:n3wp8n': 'rest_mode_efficiency_good',
  'fc761229-79bf-473b-b27f-4314da1626f7:view:k7p2e1': 'rest_mode_elevated_resting_hr',
  'fc761229-79bf-473b-b27f-4314da1626f7:view:ayi5c2': 'rest_mode_elevated_resting_hr',
  'b8b73e68-6d43-4b14-8b63-f33f478723f9:view:hu5pku': 'rest_mode_good_resting_hr',
  'b8b73e68-6d43-4b14-8b63-f33f478723f9:view:pdm4rt': 'rest_mode_good_resting_hr',
  'resting_hr_time_bad_score_good_activity_high_once_0:view:du2pm4':
    'resting_hr_time_bad_score_good_activity_high_once',
  'resting_hr_time_bad_score_good_activity_high_once_0:view:hiqycb':
    'resting_hr_time_bad_score_good_activity_high_once',
  'resting_hr_time_bad_score_good_activity_high_once_2:view:0y8l4n':
    'resting_hr_time_bad_score_good_activity_high_once',
  'resting_hr_time_bad_score_good_activity_high_once_2:view:jcna9u':
    'resting_hr_time_bad_score_good_activity_high_once',
  '19360d20-7f0b-4830-b318-470f9afbeda2:view:rrff1v': 'rest_mode_recovery_index_good',
  '19360d20-7f0b-4830-b318-470f9afbeda2:view:nzx3zt': 'rest_mode_recovery_index_good',
  '19360d20-7f0b-4830-b318-470f9afbeda2:view:tv9yd1': 'rest_mode_recovery_index_good',
  'Recharge_intro_score_trending_down_already_bad_0:view:ffi6kt': 'Recharge_intro_score_trending_down_already_bad',
  'Recharge_intro_score_trending_down_already_bad_0:view:m09unm': 'Recharge_intro_score_trending_down_already_bad',
  '7d9f0c6f-4efd-472d-abef-bdaa58e12006:view:fheh4o': 'rest_mode_day_1_sleep',
  '7d9f0c6f-4efd-472d-abef-bdaa58e12006:view:b60fxc': 'rest_mode_day_1_sleep',
  'b8c9ad2c-3e43-4ab6-bb96-60b1d05e917d:view:i56xnl': 'rest_mode_sleep_timing_good',
  'b8c9ad2c-3e43-4ab6-bb96-60b1d05e917d:view:l37b0l': 'rest_mode_sleep_timing_good',
  'b972bdf5-dada-4b10-bff2-26f99a57b0e0:view:atvstm': 'rest_mode_temp_high_day_4',
  'b972bdf5-dada-4b10-bff2-26f99a57b0e0:view:1xpgmt': 'rest_mode_temp_high_day_4',
  'e60a5159-d92e-43c1-90e3-9c95f3b6d96b:view:yircr5': 'rest_mode_temp_high_day_2_yesterday_midpoint',
  'e60a5159-d92e-43c1-90e3-9c95f3b6d96b:view:lfmlls': 'rest_mode_temp_high_day_2_yesterday_midpoint',
  '9ed6ce4d-97d1-41b9-a6a5-eb0d0ed2ccdb:view:nk8vnt': 'rest_mode_day_1_readiness',
  '9ed6ce4d-97d1-41b9-a6a5-eb0d0ed2ccdb:view:48axrm': 'rest_mode_day_1_readiness',
  '9ed6ce4d-97d1-41b9-a6a5-eb0d0ed2ccdb:view:pw1l56': 'rest_mode_day_1_readiness',
  '5c6f01b0-c87c-4ced-96ee-60a824724594:view:27yn71': 'rest_mode_deep_sleep_bad',
  '5c6f01b0-c87c-4ced-96ee-60a824724594:view:y2ruoq': 'rest_mode_deep_sleep_bad',
  'activity_target_changed_low_0:view:578dgs': 'activity_target_changed_low',
  'activity_target_changed_low_0:view:h7hstb': 'activity_target_changed_low',
  'activity_target_changed_low_1:view:de0vm8': 'activity_target_changed_low',
  'activity_target_changed_low_1:view:kgbejz': 'activity_target_changed_low',
  'activity_target_changed_low_1:view:h30z6m': 'activity_target_changed_low',
  '95ebc6a4-563b-4769-8dd6-25bf129db7e3:view:ag2e0a': 'rest_mode_readiness_score_good',
  '95ebc6a4-563b-4769-8dd6-25bf129db7e3:view:lljmf7': 'rest_mode_readiness_score_good',
  'f4e64816-0f43-4f00-a177-d8e918bad3ed:view:zbch9a': 'rest_mode_readiness_score_good',
  'f4e64816-0f43-4f00-a177-d8e918bad3ed:view:5cx8yz': 'rest_mode_readiness_score_good',
  'f4e64816-0f43-4f00-a177-d8e918bad3ed:view:glt5mm': 'rest_mode_readiness_score_good',
  'b3f37751-e926-47a5-ae95-fd51a7b7a4f2:view:6fltun': 'rest_mode_temp_high_day_2',
  'b3f37751-e926-47a5-ae95-fd51a7b7a4f2:view:ebdcjk': 'rest_mode_temp_high_day_2',
  '86fe0735-f805-46f3-9acf-88e4d43168ef:view:lj101n': 'rest_mode_temp_high_day_3',
  '86fe0735-f805-46f3-9acf-88e4d43168ef:view:vh6ool': 'rest_mode_temp_high_day_3',
  '34f6ce42-822f-4028-9895-8598f0b871a0:view:suidom': 'rest_mode_restfulness_bad',
  '34f6ce42-822f-4028-9895-8598f0b871a0:view:abkilp': 'rest_mode_restfulness_bad',
  '50ab4070-b5d8-4369-96b1-7e8d994e1289:view:t9pulu': 'rest_mode_readiness_score_bad',
  '50ab4070-b5d8-4369-96b1-7e8d994e1289:view:limlyk': 'rest_mode_readiness_score_bad',
  '8fd43ecd-9806-4318-8f98-235b417e8a71:view:48h4kc': 'rest_mode_readiness_score_bad',
  '8fd43ecd-9806-4318-8f98-235b417e8a71:view:hnscxw': 'rest_mode_readiness_score_bad',
  'ob_sleep_score_1:view:x7tcry': 'ob_sleep_score',
  'ob_sleep_score_1:view:qy808c': 'ob_sleep_score',
  'ob_sleep_score_1:view:ikmsx5': 'ob_sleep_score',
  'c6b854b4-3979-4c0e-8974-8509ea3a9c3d:view:pock5y': 'rest_mode_deep_sleep_good',
  'c6b854b4-3979-4c0e-8974-8509ea3a9c3d:view:ar6hm1': 'rest_mode_deep_sleep_good',
  'goal_productive_resting_hr_time_bad_0:view:q3w7rp': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_0:view:xg837m': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_0:view:90xyh4': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_1:view:pyv7iy': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_1:view:8wk993': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_1:view:pzu3qj': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_2:view:z4c7j9': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_2:view:nl6d54': 'goal_productive_resting_hr_time_bad',
  'goal_productive_resting_hr_time_bad_2:view:yg2yty': 'goal_productive_resting_hr_time_bad',
  '68aded59-c8a6-4cb1-959a-14194dce6788:view:7fithg': 'temp_high_day_2',
  '68aded59-c8a6-4cb1-959a-14194dce6788:view:zkeat2': 'temp_high_day_2',
  '9facbdf3-3051-450d-a4da-bbe829d50f20:view:3h0zk5': 'temp_midpoint_score_mediocre',
  '9facbdf3-3051-450d-a4da-bbe829d50f20:view:kseeoc': 'temp_midpoint_score_mediocre',
  'a488a9ec-a2d8-4d88-af96-c6f5acd51f7d:view:56bbci': 'temp_high_activity_high',
  'a488a9ec-a2d8-4d88-af96-c6f5acd51f7d:view:hixm8b': 'temp_high_activity_high',
  '7f674fd1-93c8-41e6-8b8c-b73511efa2f0:view:vnxdni': 'temp_midpoint_resting_hr_high',
  '7f674fd1-93c8-41e6-8b8c-b73511efa2f0:view:per529': 'temp_midpoint_resting_hr_high',
  '38daf14b-3374-418e-a3d8-1647b96f8a34:view:29g85a': 'temp_midpoint_previous_night_bad',
  '38daf14b-3374-418e-a3d8-1647b96f8a34:view:bi1vf2': 'temp_midpoint_previous_night_bad',
  '38daf14b-3374-418e-a3d8-1647b96f8a34:view:y8pnsd': 'temp_midpoint_previous_night_bad',
  'total_sleep_bad_midpoint_early_efficiency_good_once_0:view:cpdc3u':
    'total_sleep_bad_midpoint_early_efficiency_good_once',
  'total_sleep_bad_midpoint_early_efficiency_good_once_0:view:frgbbq':
    'total_sleep_bad_midpoint_early_efficiency_good_once',
  'total_sleep_bad_midpoint_early_efficiency_good_once_0:view:9zuslp':
    'total_sleep_bad_midpoint_early_efficiency_good_once',
  'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once_0:view:kzcx43':
    'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once',
  'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once_0:view:xno9kb':
    'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once',
  'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once_0:view:8ucwan':
    'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_long_once',
  '7bd85869-c73c-4510-8af6-e4ef2a32e91b:view:i1giie': 'temp_midpoint_day_2_yesterday_midpoint',
  '7bd85869-c73c-4510-8af6-e4ef2a32e91b:view:z3ngh4': 'temp_midpoint_day_2_yesterday_midpoint',
  '5d41314f-4b7d-4e11-a0d5-c1ed38c584d8:view:llohfz': 'temp_midpoint_score_low',
  '5d41314f-4b7d-4e11-a0d5-c1ed38c584d8:view:zq6mwf': 'temp_midpoint_score_low',
  'bbe13c64-2d85-46ee-accc-d30b890f4175:view:ma42c3': 'temp_high_resting_hr_high',
  'bbe13c64-2d85-46ee-accc-d30b890f4175:view:b3sq3z': 'temp_high_resting_hr_high',
  'da96a7ff-467e-4a4b-a18d-4cf7c8d9883f:view:se61z7': 'temp_midpoint_day_2_yesterday_high',
  'da96a7ff-467e-4a4b-a18d-4cf7c8d9883f:view:rg260u': 'temp_midpoint_day_2_yesterday_high',
  'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_short_once_0:view:o19tjh':
    'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_short_once',
  'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_short_once_0:view:q0tv0t':
    'total_sleep_bad_midpoint_late_efficiency_bad_time_in_bed_short_once',
  'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once_0:view:e23zlw':
    'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once',
  'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once_0:view:ugmclc':
    'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once',
  'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once_0:view:nys1ag':
    'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_short_once',
  '99aeca18-331e-4d9e-b209-2b899d2667f7:view:ss62sf': 'recovery_mode_deep_sleep_good',
  '99aeca18-331e-4d9e-b209-2b899d2667f7:view:q1vaqp': 'recovery_mode_deep_sleep_good',
  'd0228acc-7be7-4be7-83af-a30c168a3404:view:bcgw08': 'temp_high_day_4',
  'd0228acc-7be7-4be7-83af-a30c168a3404:view:n6fkz5': 'temp_high_day_4',
  'b452cbbb-197e-4260-a7a7-59f44e1e4b81:view:cgvzul': 'temp_high_day_5',
  'b452cbbb-197e-4260-a7a7-59f44e1e4b81:view:fna7j3': 'temp_high_day_5',
  'be20df0b-e17a-4826-bbe4-b9d88bdd2083:view:cuxatz': 'temp_high_day_5',
  'be20df0b-e17a-4826-bbe4-b9d88bdd2083:view:mrix53': 'temp_high_day_5',
  'fba9f6d9-e371-424c-aff1-e9f08a4ec6cc:view:3euw0m': 'temp_high_day_5',
  'fba9f6d9-e371-424c-aff1-e9f08a4ec6cc:view:c1d5gd': 'temp_high_day_5',
  '1e38df3b-e693-41a0-bfa5-7f6ac9613654:view:kjg95a': 'temp_midpoint_day_2_high_day_1',
  '1e38df3b-e693-41a0-bfa5-7f6ac9613654:view:0hw7l3': 'temp_midpoint_day_2_high_day_1',
  'tutorial_activity_total_cal_0:view:8ciyn0': 'tutorial_activity_total_cal',
  'tutorial_activity_total_cal_0:view:z2xz86': 'tutorial_activity_total_cal',
  'tutorial_activity_total_cal_0:view:mtrf0x': 'tutorial_activity_total_cal',
  'tutorial_activity_target_0:view:lwdiaq': 'tutorial_activity_target',
  'tutorial_activity_target_0:view:clnxua': 'tutorial_activity_target',
  'tutorial_activity_target_0:view:cog4pi': 'tutorial_activity_target',
  'b7abe895-08a2-4432-91ab-1efad05cf2bd:view:o2srrk': 'temp_midpoint_score_good',
  'b7abe895-08a2-4432-91ab-1efad05cf2bd:view:t0xv8u': 'temp_midpoint_score_good',
  '0b4907e6-9cd2-4b2e-908e-993edb743e4f:view:60ut5u': 'temp_high_previous_night_bad',
  '0b4907e6-9cd2-4b2e-908e-993edb743e4f:view:27mare': 'temp_high_previous_night_bad',
  '101d2f39-6351-46fa-98b6-d5236c15e1c8:view:2f2sl5': 'temp_high_day_3',
  '101d2f39-6351-46fa-98b6-d5236c15e1c8:view:cza0jd': 'temp_high_day_3',
  'temp_high_score_low_once_0:view:td56jv': 'temp_high_score_low_once',
  'temp_high_score_low_once_0:view:4c9a23': 'temp_high_score_low_once',
  'temp_high_score_low_once_2:view:v94tkc': 'temp_high_score_low_once',
  'temp_high_score_low_once_2:view:bkyddz': 'temp_high_score_low_once',
  'tutorial_activity_score_0:view:p9jn4z': 'tutorial_activity_score',
  'tutorial_activity_score_0:view:wcl5l8': 'tutorial_activity_score',
  'tutorial_activity_score_0:view:q9fhmi': 'tutorial_activity_score',
  'tutorial_activity_steps_0:view:lpo1qi': 'tutorial_activity_steps',
  'tutorial_activity_steps_0:view:wedhta': 'tutorial_activity_steps',
  'tutorial_activity_steps_0:view:rskggm': 'tutorial_activity_steps',
  '7bfd1e71-745b-48b2-a712-00c3a29cd6e6:view:i02dfr': 'temp_high_score_mediocre',
  '7bfd1e71-745b-48b2-a712-00c3a29cd6e6:view:e95xpn': 'temp_high_score_mediocre',
  'e5fa19fe-0b05-4bb4-b252-f2010ad62ef5:view:7cyelq': 'temp_high_score_mediocre',
  'e5fa19fe-0b05-4bb4-b252-f2010ad62ef5:view:oy1exe': 'temp_high_score_mediocre',
  'tutorial_activity_high_activity_0:view:14hs1y': 'tutorial_activity_high_activity',
  'tutorial_activity_high_activity_0:view:qzkzef': 'tutorial_activity_high_activity',
  'tutorial_activity_high_activity_0:view:atwo7h': 'tutorial_activity_high_activity',
  'tutorial_activity_distance_0:view:865fb4': 'tutorial_activity_distance',
  'tutorial_activity_distance_0:view:swwbmh': 'tutorial_activity_distance',
  'tutorial_activity_distance_0:view:28uzdv': 'tutorial_activity_distance',
  'tutorial_activity_low_activity_0:view:30exs6': 'tutorial_activity_low_activity',
  'tutorial_activity_low_activity_0:view:fwb4jt': 'tutorial_activity_low_activity',
  'tutorial_activity_low_activity_0:view:woc5gm': 'tutorial_activity_low_activity',
  'goal_productive_previous_night_bad_0:view:p17jnr': 'goal_productive_previous_night_bad',
  'goal_productive_previous_night_bad_0:view:vdhchz': 'goal_productive_previous_night_bad',
  'goal_productive_previous_night_bad_1:view:kf7mfk': 'goal_productive_previous_night_bad',
  'goal_productive_previous_night_bad_1:view:e53ykd': 'goal_productive_previous_night_bad',
  'goal_productive_previous_night_bad_2:view:ggg3ll': 'goal_productive_previous_night_bad',
  'goal_productive_previous_night_bad_2:view:fjhhw5': 'goal_productive_previous_night_bad',
  'tutorial_activity_view_0:view:tu5z80': 'tutorial_activity_view',
  'tutorial_activity_view_0:view:jurnya': 'tutorial_activity_view',
  'tutorial_activity_view_0:view:1l843n': 'tutorial_activity_view',
  '13788b2f-6675-466b-bd2d-aa9263da069a:view:ym08k4': 'training_frequency_volume_high',
  '13788b2f-6675-466b-bd2d-aa9263da069a:view:hovvx0': 'training_frequency_volume_high',
  'f7b6562b-71af-433f-87ef-5a092d3d2c3d:view:7s9z0d': 'training_frequency_volume_low_readiness_ok',
  'f7b6562b-71af-433f-87ef-5a092d3d2c3d:view:a1jfyu': 'training_frequency_volume_low_readiness_ok',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0:view:3w783a':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0:view:mcsfg7':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once_0:view:syjg8z':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_short_once',
  'tutorial_activity_medium_activity_0:view:f68s17': 'tutorial_activity_medium_activity',
  'tutorial_activity_medium_activity_0:view:buju1w': 'tutorial_activity_medium_activity',
  'tutorial_activity_medium_activity_0:view:6d4sbd': 'tutorial_activity_medium_activity',
  'tutorial_activity_breaking_up_inactive_time_0:view:5n6919': 'tutorial_activity_breaking_up_inactive_time',
  'tutorial_activity_breaking_up_inactive_time_0:view:cmv1w1': 'tutorial_activity_breaking_up_inactive_time',
  'tutorial_activity_breaking_up_inactive_time_0:view:3ybx81': 'tutorial_activity_breaking_up_inactive_time',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening_0:view:dsjeuh':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening_0:view:tm3fbb':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening_0:view:1j37ws':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_single_awakening',
  'tutorial_activity_act_cal_0:view:6j6yp4': 'tutorial_activity_act_cal',
  'tutorial_activity_act_cal_0:view:pqchdl': 'tutorial_activity_act_cal',
  'tutorial_activity_act_cal_0:view:t9djw1': 'tutorial_activity_act_cal',
  'total_sleep_bad_midpoint_late_efficiency_good_once_0:view:34ouah':
    'total_sleep_bad_midpoint_late_efficiency_good_once',
  'total_sleep_bad_midpoint_late_efficiency_good_once_0:view:u7ul9n':
    'total_sleep_bad_midpoint_late_efficiency_good_once',
  'total_sleep_bad_midpoint_late_efficiency_good_once_1:view:fhsrrp':
    'total_sleep_bad_midpoint_late_efficiency_good_once',
  'total_sleep_bad_midpoint_late_efficiency_good_once_1:view:cygulb':
    'total_sleep_bad_midpoint_late_efficiency_good_once',
  'total_sleep_bad_midpoint_late_efficiency_good_once_1:view:dysnkc':
    'total_sleep_bad_midpoint_late_efficiency_good_once',
  'tutorial_sleep_deep_0:view:t4kj0i': 'tutorial_sleep_deep',
  'tutorial_sleep_deep_0:view:n6enil': 'tutorial_sleep_deep',
  'weekly_equal_km_high_0:view:g3fa9i': 'weekly_equal_km_high',
  'weekly_equal_km_high_0:view:ahefms': 'weekly_equal_km_high',
  'tutorial_sleep_total_0:view:la4wws': 'tutorial_sleep_total',
  'tutorial_sleep_total_0:view:baqa2p': 'tutorial_sleep_total',
  'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_long_once_0:view:5xy4ti':
    'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_long_once',
  'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_long_once_0:view:s9bxk0':
    'total_sleep_bad_midpoint_early_efficiency_bad_time_in_bed_long_once',
  'tutorial_sleep_rem_0:view:vyfggh': 'tutorial_sleep_rem',
  'tutorial_sleep_rem_0:view:g9piw8': 'tutorial_sleep_rem',
  'tutorial_sleep_latency_0:view:cu261u': 'tutorial_sleep_latency',
  'tutorial_sleep_latency_0:view:7rudpb': 'tutorial_sleep_latency',
  'tutorial_sleep_score_0:view:cqygnn': 'tutorial_sleep_score',
  'tutorial_sleep_score_0:view:xgdb6t': 'tutorial_sleep_score',
  'e106058d-a4a6-49d7-b23d-066d1e678551:view:jvzg2o': 'ucsf_illness_study_invite',
  'e106058d-a4a6-49d7-b23d-066d1e678551:view:9dtdx3': 'ucsf_illness_study_invite',
  'activity_target_changed_high_0:view:rtwnx8': 'activity_target_changed_high',
  'activity_target_changed_high_0:view:ecne3k': 'activity_target_changed_high',
  'tutorial_sleep_efficiency_0:view:ukrfjo': 'tutorial_sleep_efficiency',
  'tutorial_sleep_efficiency_0:view:1y1it4': 'tutorial_sleep_efficiency',
  'tutorial_sleep_light_0:view:hd8qqs': 'tutorial_sleep_light',
  'tutorial_sleep_light_0:view:ztmsjj': 'tutorial_sleep_light',
  'tutorial_sleep_heart_rate_0:view:w7cvxk': 'tutorial_sleep_heart_rate',
  'tutorial_sleep_heart_rate_0:view:6y8iw5': 'tutorial_sleep_heart_rate',
  'total_sleep_good_once_0:view:8oxsa3': 'total_sleep_good_once',
  'total_sleep_good_once_0:view:q7qagg': 'total_sleep_good_once',
  'total_sleep_good_once_1:view:o4s7nm': 'total_sleep_good_once',
  'total_sleep_good_once_1:view:ognfiq': 'total_sleep_good_once',
  'total_sleep_good_once_2:view:h2byko': 'total_sleep_good_once',
  'total_sleep_good_once_2:view:dzpcfp': 'total_sleep_good_once',
  'total_sleep_good_once_2:view:jrufhe': 'total_sleep_good_once',
  'total_sleep_good_once_3:view:fzsbyi': 'total_sleep_good_once',
  'total_sleep_good_once_3:view:rsm95t': 'total_sleep_good_once',
  'total_sleep_good_once_3:view:sioftw': 'total_sleep_good_once',
  'total_sleep_good_once_4:view:4ffmi8': 'total_sleep_good_once',
  'total_sleep_good_once_4:view:wrtdwp': 'total_sleep_good_once',
  'total_sleep_good_once_5:view:v450yq': 'total_sleep_good_once',
  'total_sleep_good_once_5:view:bkpa7o': 'total_sleep_good_once',
  'total_sleep_good_once_6:view:eu4vxn': 'total_sleep_good_once',
  'total_sleep_good_once_6:view:zcc8lp': 'total_sleep_good_once',
  'total_sleep_good_once_7:view:x6er0c': 'total_sleep_good_once',
  'total_sleep_good_once_7:view:3nxj8g': 'total_sleep_good_once',
  'total_sleep_good_once_8:view:8td8s3': 'total_sleep_good_once',
  'total_sleep_good_once_8:view:wjntja': 'total_sleep_good_once',
  'total_sleep_good_once_9:view:xuimhx': 'total_sleep_good_once',
  'total_sleep_good_once_9:view:iqxvez': 'total_sleep_good_once',
  'total_sleep_good_once_9:view:emv5b5': 'total_sleep_good_once',
  'goal_stress_resting_hr_trend_optimal_0:view:lyr0d7': 'goal_stress_resting_hr_trend_optimal',
  'goal_stress_resting_hr_trend_optimal_0:view:ttxqh4': 'goal_stress_resting_hr_trend_optimal',
  'goal_stress_resting_hr_trend_optimal_0:view:lbx3ek': 'goal_stress_resting_hr_trend_optimal',
  'rem_sleep_good_total_good_once_0:view:4mdo8j': 'rem_sleep_good_total_good_once',
  'rem_sleep_good_total_good_once_0:view:w05pwo': 'rem_sleep_good_total_good_once',
  'rem_sleep_good_total_good_once_0:view:904qzq': 'rem_sleep_good_total_good_once',
  'rem_sleep_good_total_good_once_1:view:8dccms': 'rem_sleep_good_total_good_once',
  'rem_sleep_good_total_good_once_1:view:715cny': 'rem_sleep_good_total_good_once',
  'rem_sleep_good_total_good_once_1:view:62u5vx': 'rem_sleep_good_total_good_once',
  '6cd02eb1-22dc-484b-bb41-22abc2e60ce3:view:s7713v': 'recovery_mode_hrv_balance_good',
  '6cd02eb1-22dc-484b-bb41-22abc2e60ce3:view:zdc4wh': 'recovery_mode_hrv_balance_good',
  '6cd02eb1-22dc-484b-bb41-22abc2e60ce3:view:p943vt': 'recovery_mode_hrv_balance_good',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_0:view:7o16ve':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_0:view:j2j8f4':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once',
  'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once_0:view:gs8vig':
    'total_sleep_bad_midpoint_ok_efficiency_bad_time_in_bed_long_once',
  'activity_target_0:view:4nxkdt': 'activity_target',
  'activity_target_0:view:qvhiu4': 'activity_target',
  'battery_exhausted_during_sleep_0:view:st0dr9': 'battery_exhausted_during_sleep',
  'battery_exhausted_during_sleep_0:view:jjjywt': 'battery_exhausted_during_sleep',
  'score_trending_down_but_still_high_0:view:k58r7s': 'score_trending_down_but_still_high',
  'score_trending_down_but_still_high_0:view:q93pol': 'score_trending_down_but_still_high',
  'score_trending_down_but_still_high_0:view:nb46mo': 'score_trending_down_but_still_high',
  'score_trending_up_already_good_0:view:odr0cw': 'score_trending_up_already_good',
  'score_trending_up_already_good_0:view:0yich4': 'score_trending_up_already_good',
  'score_trending_up_already_good_0:view:xwn2te': 'score_trending_up_already_good',
  'score_trending_up_but_still_low_0:view:76upez': 'score_trending_up_but_still_low',
  'score_trending_up_but_still_low_0:view:inu5hg': 'score_trending_up_but_still_low',
  'score_trending_up_but_still_low_0:view:sqlsq2': 'score_trending_up_but_still_low',
  'rem_sleep_bad_total_ok_once_0:view:8kl03x': 'rem_sleep_bad_total_ok_once',
  'rem_sleep_bad_total_ok_once_0:view:j15yut': 'rem_sleep_bad_total_ok_once',
  'rem_sleep_bad_total_ok_once_0:view:jrxjp1': 'rem_sleep_bad_total_ok_once',
  'rem_sleep_bad_total_ok_once_1:view:qzvdyj': 'rem_sleep_bad_total_ok_once',
  'rem_sleep_bad_total_ok_once_1:view:3covt9': 'rem_sleep_bad_total_ok_once',
  'rem_sleep_bad_total_ok_once_1:view:74dqcf': 'rem_sleep_bad_total_ok_once',
  'ca5134db-0943-4567-b8ec-65ac163a412c:view:j62tyz': 'rest_mode_sleep_balance_good',
  'ca5134db-0943-4567-b8ec-65ac163a412c:view:qg4h8y': 'rest_mode_sleep_balance_good',
  'Recharge_intro_rem_sleep_bad_activity_high_once_0:view:umf4wb': 'Recharge_intro_rem_sleep_bad_activity_high_once',
  'Recharge_intro_rem_sleep_bad_activity_high_once_0:view:nitckl': 'Recharge_intro_rem_sleep_bad_activity_high_once',
  'Recharge_intro_rem_sleep_bad_activity_high_once_0:view:1b82mj': 'Recharge_intro_rem_sleep_bad_activity_high_once',
  'd2a46b11-f133-4254-8735-8dc0efe9344a:view:fxb4v7': 'recovery_mode_hrv_balance_improving',
  'd2a46b11-f133-4254-8735-8dc0efe9344a:view:gj39k1': 'recovery_mode_hrv_balance_improving',
  'd2a46b11-f133-4254-8735-8dc0efe9344a:view:fyp352': 'recovery_mode_hrv_balance_improving',
  'readiness_score_high_temp_midpoint_0:view:2g0zt6': 'readiness_score_high_temp_midpoint',
  'readiness_score_high_temp_midpoint_0:view:xgvfvf': 'readiness_score_high_temp_midpoint',
  'readiness_ok_1:view:t73pif': 'readiness_ok',
  'readiness_ok_1:view:ddi938': 'readiness_ok',
  'readiness_ok_2:view:gcq5me': 'readiness_ok',
  'readiness_ok_2:view:nna8bt': 'readiness_ok',
  'readiness_ok_3:view:amjb2n': 'readiness_ok',
  'readiness_ok_3:view:xqjpq4': 'readiness_ok',
  'readiness_ok_4:view:sd3k3l': 'readiness_ok',
  'readiness_ok_4:view:30fpif': 'readiness_ok',
  'daily_equal_km_above_your_average_0:view:864g1e': 'daily_equal_km_above_your_average',
  'daily_equal_km_above_your_average_0:view:43mmkp': 'daily_equal_km_above_your_average',
  'daily_equal_km_above_your_average_0:view:6itago': 'daily_equal_km_above_your_average',
  'daily_equal_km_0:view:itcyg0': 'daily_equal_km',
  'daily_equal_km_0:view:vbje3n': 'daily_equal_km',
  'c3b6961f-7ec2-47b3-9d38-992463fedbbf:view:cl6jke': 'd2dc0259-061a-4759-b97d-a9dd8bbbbfa7',
  'c3b6961f-7ec2-47b3-9d38-992463fedbbf:view:ee3eqz': 'd2dc0259-061a-4759-b97d-a9dd8bbbbfa7',
  'c3b6961f-7ec2-47b3-9d38-992463fedbbf:view:zwvsg3': 'd2dc0259-061a-4759-b97d-a9dd8bbbbfa7',
  'goal_athletic_readiness_score_ok_0:view:5lvtpx': 'goal_athletic_readiness_score_ok',
  'goal_athletic_readiness_score_ok_0:view:c6pqyl': 'goal_athletic_readiness_score_ok',
  'goal_athletic_readiness_score_ok_0:view:m7iwdb': 'goal_athletic_readiness_score_ok',
  '3db1b09a-f28b-4517-9441-57c2e489d5c4:view:uies24': 'goal_athletic_meet_daily_goals_optimal',
  '3db1b09a-f28b-4517-9441-57c2e489d5c4:view:ykxf03': 'goal_athletic_meet_daily_goals_optimal',
  'goal_athletic_previous_day_activity_good_0:view:8j5anz': 'goal_athletic_previous_day_activity_good',
  'goal_athletic_previous_day_activity_good_0:view:2vaozo': 'goal_athletic_previous_day_activity_good',
  'deep_sleep_good_total_ok_once_0:view:il2r62': 'deep_sleep_good_total_ok_once',
  'deep_sleep_good_total_ok_once_0:view:cwv565': 'deep_sleep_good_total_ok_once',
  'deep_sleep_good_total_ok_once_1:view:6slnh5': 'deep_sleep_good_total_ok_once',
  'deep_sleep_good_total_ok_once_1:view:ugr8dm': 'deep_sleep_good_total_ok_once',
  'goal_athletic_resting_hr_trend_optimal_0:view:ezqhb9': 'goal_athletic_resting_hr_trend_optimal',
  'goal_athletic_resting_hr_trend_optimal_0:view:2newig': 'goal_athletic_resting_hr_trend_optimal',
  'goal_athletic_resting_hr_trend_optimal_0:view:88r62h': 'goal_athletic_resting_hr_trend_optimal',
  'efficiency_good_score_ok_once_0:view:fwjoxr': 'efficiency_good_score_ok_once',
  'efficiency_good_score_ok_once_0:view:3mr7gx': 'efficiency_good_score_ok_once',
  'efficiency_good_score_ok_once_0:view:rrkr00': 'efficiency_good_score_ok_once',
  'deep_sleep_good_total_bad_once_0:view:de805k': 'deep_sleep_good_total_bad_once',
  'deep_sleep_good_total_bad_once_0:view:0zggbx': 'deep_sleep_good_total_bad_once',
  'deep_sleep_good_total_bad_once_0:view:ucnf1q': 'deep_sleep_good_total_bad_once',
  'goal_athletic_activity_balance_good_0:view:hr6irm': 'goal_athletic_activity_balance_good',
  'goal_athletic_activity_balance_good_0:view:tmvylg': 'goal_athletic_activity_balance_good',
  'goal_athletic_activity_balance_good_0:view:1xoa68': 'goal_athletic_activity_balance_good',
  'efficiency_good_score_bad_once_0:view:u66ymi': 'efficiency_good_score_bad_once',
  'efficiency_good_score_bad_once_0:view:475k40': 'efficiency_good_score_bad_once',
  'efficiency_good_score_bad_once_0:view:ebp9sy': 'efficiency_good_score_bad_once',
  'weekly_equal_km_low_0:view:df863m': 'weekly_equal_km_low',
  'weekly_equal_km_low_0:view:dewugl': 'weekly_equal_km_low',
  'weekly_equal_km_low_0:view:rw9d8g': 'weekly_equal_km_low',
  'goal_athletic_resting_hr_trend_optimal_readiness_ok_0:view:i01ad3':
    'goal_athletic_resting_hr_trend_optimal_readiness_ok',
  'goal_athletic_resting_hr_trend_optimal_readiness_ok_0:view:yxlfd8':
    'goal_athletic_resting_hr_trend_optimal_readiness_ok',
  'goal_athletic_resting_hr_trend_optimal_readiness_ok_0:view:c3f6wn':
    'goal_athletic_resting_hr_trend_optimal_readiness_ok',
  'goal_athletic_resting_hr_time_bad_score_bad_activity_low_0:view:7x6ovc':
    'goal_athletic_resting_hr_time_bad_score_bad_activity_low',
  'goal_athletic_resting_hr_time_bad_score_bad_activity_low_0:view:9bwaxz':
    'goal_athletic_resting_hr_time_bad_score_bad_activity_low',
  'goal_athletic_resting_hr_time_bad_score_bad_activity_low_0:view:fzwn24':
    'goal_athletic_resting_hr_time_bad_score_bad_activity_low',
  'a5409fda-9108-4a18-9100-5d42f365c508:view:20lfqk': 'goal_athletic_previous_day_inactivity_high',
  'a5409fda-9108-4a18-9100-5d42f365c508:view:1no0mn': 'goal_athletic_previous_day_inactivity_high',
  'goal_athletic_resting_hr_trend_bad_0:view:txfqlj': 'goal_athletic_resting_hr_trend_bad',
  'goal_athletic_resting_hr_trend_bad_0:view:mb6g0h': 'goal_athletic_resting_hr_trend_bad',
  'goal_athletic_resting_hr_trend_bad_0:view:w8igzh': 'goal_athletic_resting_hr_trend_bad',
  'first_weekly_summary_0:view:t89dmv': 'first_weekly_summary',
  'first_weekly_summary_0:view:4wujsl': 'first_weekly_summary',
  'first_weekly_summary_0:view:rna3wc': 'first_weekly_summary',
  'goal_athletic_previous_day_bad_0:view:ikd0v9': 'goal_athletic_previous_day_bad',
  'goal_athletic_previous_day_bad_0:view:jxjo17': 'goal_athletic_previous_day_bad',
  'goal_athletic_previous_day_bad_0:view:67ez8v': 'goal_athletic_previous_day_bad',
  'cf16450d-377c-4cce-a457-411d84c9137c:view:7why54': 'goal_focus_meet_daily_goals_bad',
  'cf16450d-377c-4cce-a457-411d84c9137c:view:iei81p': 'goal_focus_meet_daily_goals_bad',
  'goal_creativity_sleep_balance_good_0:view:emyzda': 'goal_creativity_sleep_balance_good',
  'goal_creativity_sleep_balance_good_0:view:fv3ivj': 'goal_creativity_sleep_balance_good',
  'goal_creativity_sleep_balance_good_0:view:keefbg': 'goal_creativity_sleep_balance_good',
  'efficiency_bad_latency_high_once_0:view:dxpo4v': 'efficiency_bad_latency_high_once',
  'efficiency_bad_latency_high_once_0:view:bclyvh': 'efficiency_bad_latency_high_once',
  'efficiency_bad_latency_high_once_0:view:hbwdxt': 'efficiency_bad_latency_high_once',
  'efficiency_bad_latency_high_once_1:view:qjnwsk': 'efficiency_bad_latency_high_once',
  'efficiency_bad_latency_high_once_1:view:1ri72o': 'efficiency_bad_latency_high_once',
  'efficiency_bad_latency_high_once_1:view:xxos51': 'efficiency_bad_latency_high_once',
  'goal_creativity_sleep_balance_bad_0:view:jitf1k': 'goal_creativity_sleep_balance_bad',
  'goal_creativity_sleep_balance_bad_0:view:q8tnpd': 'goal_creativity_sleep_balance_bad',
  'goal_creativity_recovery_index_good_0:view:sxg0tp': 'goal_creativity_recovery_index_good',
  'goal_creativity_recovery_index_good_0:view:kl12cc': 'goal_creativity_recovery_index_good',
  'goal_creativity_previous_night_good_score_excellent_0:view:8kjvlb':
    'goal_creativity_previous_night_good_score_excellent',
  'goal_creativity_previous_night_good_score_excellent_0:view:5vsyxb':
    'goal_creativity_previous_night_good_score_excellent',
  'efficiency_bad_activity_low_once_0:view:f8vov5': 'efficiency_bad_activity_low_once',
  'efficiency_bad_activity_low_once_0:view:56pwiv': 'efficiency_bad_activity_low_once',
  'efficiency_bad_activity_low_once_0:view:opr984': 'efficiency_bad_activity_low_once',
  'efficiency_bad_activity_high_once_0:view:f56li9': 'efficiency_bad_activity_high_once',
  'efficiency_bad_activity_high_once_0:view:brtegz': 'efficiency_bad_activity_high_once',
  'efficiency_bad_activity_high_once_0:view:nngk5s': 'efficiency_bad_activity_high_once',
  'deabb04b-7e2b-4725-a020-b6cd77f65e84:view:woup96': 'goal_creativity_previous_day_high_inactivity',
  'deabb04b-7e2b-4725-a020-b6cd77f65e84:view:fx7wzq': 'goal_creativity_previous_day_high_inactivity',
  'goal_creativity_previous_day_high_inactivity_0:view:9zgmco': 'goal_creativity_previous_day_high_inactivity',
  'goal_creativity_previous_day_high_inactivity_0:view:890xyg': 'goal_creativity_previous_day_high_inactivity',
  'goal_focus_activity_balance_good_score_good_0:view:uyh6qf': 'goal_focus_activity_balance_good_score_good',
  'goal_focus_activity_balance_good_score_good_0:view:fdxs77': 'goal_focus_activity_balance_good_score_good',
  'goal_creativity_previous_night_bad_score_ok_0:view:e2ssk2': 'goal_creativity_previous_night_bad_score_ok',
  'goal_creativity_previous_night_bad_score_ok_0:view:4x28t6': 'goal_creativity_previous_night_bad_score_ok',
  'goal_creativity_previous_night_bad_score_ok_1:view:9mlq5l': 'goal_creativity_previous_night_bad_score_ok',
  'goal_creativity_previous_night_bad_score_ok_1:view:7hp14l': 'goal_creativity_previous_night_bad_score_ok',
  'goal_creativity_previous_night_bad_score_ok_1:view:l60os6': 'goal_creativity_previous_night_bad_score_ok',
  'goal_creativity_resting_hr_time_bad_0:view:lgyczz': 'goal_creativity_resting_hr_time_bad',
  'goal_creativity_resting_hr_time_bad_0:view:jlwt2e': 'goal_creativity_resting_hr_time_bad',
  'goal_creativity_resting_hr_time_bad_0:view:lnm29c': 'goal_creativity_resting_hr_time_bad',
  'a2ec1488-c50b-46c9-9710-7dbb3d367057:view:1nnjei': 'goal_focus_meet_daily_goals_optimal',
  'a2ec1488-c50b-46c9-9710-7dbb3d367057:view:hlf2wi': 'goal_focus_meet_daily_goals_optimal',
  'goal_creativity_previous_night_good_readiness_good_0:view:d42ip7':
    'goal_creativity_previous_night_good_readiness_good',
  'goal_creativity_previous_night_good_readiness_good_0:view:65947z':
    'goal_creativity_previous_night_good_readiness_good',
  'goal_creativity_readiness_bad_0:view:az9kew': 'goal_creativity_readiness_bad',
  'goal_creativity_readiness_bad_0:view:7hose7': 'goal_creativity_readiness_bad',
  'goal_creativity_readiness_bad_1:view:yo6ca8': 'goal_creativity_readiness_bad',
  'goal_creativity_readiness_bad_1:view:13royw': 'goal_creativity_readiness_bad',
  'goal_creativity_previous_day_good_0:view:jo6se2': 'goal_creativity_previous_day_good',
  'goal_creativity_previous_day_good_0:view:mxtuiu': 'goal_creativity_previous_day_good',
  'goal_creativity_previous_day_good_0:view:fzvl3x': 'goal_creativity_previous_day_good',
  'ea0571d6-7145-4708-98da-61d11189af11:view:4zwir5': 'goal_athletic_stay_active_optimal_exercise_high',
  'ea0571d6-7145-4708-98da-61d11189af11:view:u2ifhh': 'goal_athletic_stay_active_optimal_exercise_high',
  '2cdc2348-7195-4272-a142-700cba4455ec:view:izw628': 'goal_creativity_meet_daily_goals_bad',
  '2cdc2348-7195-4272-a142-700cba4455ec:view:ngfopx': 'goal_creativity_meet_daily_goals_bad',
  'goal_creativity_activity_balance_good_0:view:nqwfgi': 'goal_creativity_activity_balance_good',
  'goal_creativity_activity_balance_good_0:view:fzy4n0': 'goal_creativity_activity_balance_good',
  'goal_athletic_sleep_balance_bad_activity_balance_bad_0:view:yx0r7p':
    'goal_athletic_sleep_balance_bad_activity_balance_bad',
  'goal_athletic_sleep_balance_bad_activity_balance_bad_0:view:1n6f85':
    'goal_athletic_sleep_balance_bad_activity_balance_bad',
  'goal_athletic_sleep_balance_bad_activity_balance_bad_0:view:8ip7j6':
    'goal_athletic_sleep_balance_bad_activity_balance_bad',
  'goal_focus_sleep_balance_trend_good_0:view:5uhj84': 'goal_focus_sleep_balance_trend_good',
  'goal_focus_sleep_balance_trend_good_0:view:f0y61q': 'goal_focus_sleep_balance_trend_good',
  'goal_focus_sleep_balance_trend_good_0:view:42i0gx': 'goal_focus_sleep_balance_trend_good',
  'goal_athletic_sleep_balance_good_previous_night_good_0:view:zzs9cx':
    'goal_athletic_sleep_balance_good_previous_night_good',
  'goal_athletic_sleep_balance_good_previous_night_good_0:view:97gdjc':
    'goal_athletic_sleep_balance_good_previous_night_good',
  'goal_athletic_sleep_balance_good_previous_night_good_0:view:4n51cy':
    'goal_athletic_sleep_balance_good_previous_night_good',
  'goal_athletic_sleep_trending_down_sleep_balance_bad_0:view:e98nam':
    'goal_athletic_sleep_trending_down_sleep_balance_bad',
  'goal_athletic_sleep_trending_down_sleep_balance_bad_0:view:ad2j4g':
    'goal_athletic_sleep_trending_down_sleep_balance_bad',
  'goal_athletic_sleep_trending_down_sleep_balance_bad_0:view:uoa4ad':
    'goal_athletic_sleep_trending_down_sleep_balance_bad',
  'goal_focus_previous_night_bad_0:view:45yey2': 'goal_focus_previous_night_bad',
  'goal_focus_previous_night_bad_0:view:vepo9h': 'goal_focus_previous_night_bad',
  'goal_focus_previous_night_bad_0:view:0q6bw4': 'goal_focus_previous_night_bad',
  'goal_focus_previous_night_bad_0:view:tofajj': 'goal_focus_previous_night_bad',
  'sleep_balance_good_score_good_once_0:view:4ofzn2': 'sleep_balance_good_score_good_once',
  'sleep_balance_good_score_good_once_0:view:uu67rl': 'sleep_balance_good_score_good_once',
  'sleep_balance_good_score_good_once_2:view:lms7vg': 'sleep_balance_good_score_good_once',
  'sleep_balance_good_score_good_once_2:view:s1ra1f': 'sleep_balance_good_score_good_once',
  'sleep_balance_good_score_bad_once_0:view:aopusb': 'sleep_balance_good_score_bad_once',
  'sleep_balance_good_score_bad_once_0:view:cda2z5': 'sleep_balance_good_score_bad_once',
  'sleep_balance_good_score_bad_once_2:view:o7e6s7': 'sleep_balance_good_score_bad_once',
  'sleep_balance_good_score_bad_once_2:view:bsrot2': 'sleep_balance_good_score_bad_once',
  'sleep_balance_good_score_bad_once_3:view:wqof61': 'sleep_balance_good_score_bad_once',
  'sleep_balance_good_score_bad_once_3:view:w04rli': 'sleep_balance_good_score_bad_once',
  'other_bedtime_adherence_excellent_0:view:wmb2nz': 'other_bedtime_adherence_excellent',
  'other_bedtime_adherence_excellent_0:view:zs2czr': 'other_bedtime_adherence_excellent',
  'other_bedtime_adherence_excellent_0:view:udvnc1': 'other_bedtime_adherence_excellent',
  'other_bedtime_no_ideal_day_0:view:9szort': 'other_bedtime_no_ideal_day',
  'other_bedtime_no_ideal_day_0:view:lsx0nt': 'other_bedtime_no_ideal_day',
  'other_bedtime_no_ideal_day_0:view:z1snyv': 'other_bedtime_no_ideal_day',
  'other_bedtime_no_ideal_day_0:view:jyzpcr': 'other_bedtime_no_ideal_day',
  'other_bedtime_no_ideal_day_0:view:ralpjo': 'other_bedtime_no_ideal_day',
  '65d52228-55aa-42b8-b608-501081364657:view:th6dzr': 'goal_athletic_training_frequency_volume_optimal',
  '65d52228-55aa-42b8-b608-501081364657:view:hx4w2n': 'goal_athletic_training_frequency_volume_optimal',
  'goal_focus_sleep_balance_good_0:view:dnuav5': 'goal_focus_sleep_balance_good',
  'goal_focus_sleep_balance_good_0:view:l8eqb4': 'goal_focus_sleep_balance_good',
  'goal_focus_sleep_balance_good_0:view:9z2zu6': 'goal_focus_sleep_balance_good',
  'dfdea2b5-5216-4a18-a19e-316ed631c49e:view:b0lf6n': 'goal_athletic_training_frequency_volume_low_readiness_ok',
  'dfdea2b5-5216-4a18-a19e-316ed631c49e:view:ndv9qf': 'goal_athletic_training_frequency_volume_low_readiness_ok',
  'goal_athletic_sleep_balance_bad_previous_night_bad_0:view:4o8utv':
    'goal_athletic_sleep_balance_bad_previous_night_bad',
  'goal_athletic_sleep_balance_bad_previous_night_bad_0:view:jjml6j':
    'goal_athletic_sleep_balance_bad_previous_night_bad',
  'goal_athletic_sleep_balance_bad_previous_night_bad_0:view:hiooq4':
    'goal_athletic_sleep_balance_bad_previous_night_bad',
  'goal_focus_resting_hr_time_bad_0:view:rt6zy9': 'goal_focus_resting_hr_time_bad',
  'goal_focus_resting_hr_time_bad_0:view:r0dc42': 'goal_focus_resting_hr_time_bad',
  'goal_focus_resting_hr_time_bad_0:view:y5hag3': 'goal_focus_resting_hr_time_bad',
  'goal_athletic_sleep_trending_up_sleep_balance_ok_0:view:jkl6vt': 'goal_athletic_sleep_trending_up_sleep_balance_ok',
  'goal_athletic_sleep_trending_up_sleep_balance_ok_0:view:gbdvwv': 'goal_athletic_sleep_trending_up_sleep_balance_ok',
  'goal_athletic_sleep_trending_up_sleep_balance_ok_0:view:l1hrig': 'goal_athletic_sleep_trending_up_sleep_balance_ok',
  'goal_focus_sleep_balance_bad_previous_night_good_0:view:le387u': 'goal_focus_sleep_balance_bad_previous_night_good',
  'goal_focus_sleep_balance_bad_previous_night_good_0:view:wt3gwh': 'goal_focus_sleep_balance_bad_previous_night_good',
  'goal_focus_sleep_balance_bad_previous_night_good_0:view:01dzey': 'goal_focus_sleep_balance_bad_previous_night_good',
  'goal_focus_recovery_score_trending_up_already_good_0:view:2j7upe':
    'goal_focus_recovery_score_trending_up_already_good',
  'goal_focus_recovery_score_trending_up_already_good_0:view:85z57w':
    'goal_focus_recovery_score_trending_up_already_good',
  'goal_focus_recovery_score_trending_up_already_good_0:view:ogk35v':
    'goal_focus_recovery_score_trending_up_already_good',
  'goal_focus_previous_night_bad_score_ok_0:view:d1bpxq': 'goal_focus_previous_night_bad_score_ok',
  'goal_focus_previous_night_bad_score_ok_0:view:dfva5v': 'goal_focus_previous_night_bad_score_ok',
  'goal_focus_previous_night_bad_score_ok_0:view:q86puq': 'goal_focus_previous_night_bad_score_ok',
  'goal_focus_readiness_ok_0:view:lwfp79': 'goal_focus_readiness_ok',
  'goal_focus_readiness_ok_0:view:ne9d2o': 'goal_focus_readiness_ok',
  'goal_focus_readiness_ok_0:view:f02hj1': 'goal_focus_readiness_ok',
  '4a9a9892-1f47-4eab-9248-2545f338e09e:view:db4pc6': 'goal_focus_stay_active_bad_7d_exercise_high',
  '4a9a9892-1f47-4eab-9248-2545f338e09e:view:m8kyqi': 'goal_focus_stay_active_bad_7d_exercise_high',
  '4a9a9892-1f47-4eab-9248-2545f338e09e:view:2y1rdz': 'goal_focus_stay_active_bad_7d_exercise_high',
  'snippet_ideal_bedtime_not_available_intro_0:view:0mychu': 'snippet_ideal_bedtime_not_available_intro',
  'snippet_ideal_bedtime_not_available_intro_0:view:6ltjdw': 'snippet_ideal_bedtime_not_available_intro',
  'snippet_ideal_bedtime_not_available_intro_0:view:dw2stf': 'snippet_ideal_bedtime_not_available_intro',
  'snippet_ideal_bedtime_not_available_intro_0:view:5jwv96': 'snippet_ideal_bedtime_not_available_intro',
  'snippet_ideal_bedtime_not_available_intro_0:view:5dae5t': 'snippet_ideal_bedtime_not_available_intro',
  'goal_focus_sleep_balance_bad_0:view:caijdg': 'goal_focus_sleep_balance_bad',
  'goal_focus_sleep_balance_bad_0:view:kj1ryf': 'goal_focus_sleep_balance_bad',
  'goal_focus_sleep_balance_bad_0:view:c280fg': 'goal_focus_sleep_balance_bad',
  'goal_focus_previous_day_good_score_good_0:view:mapcte': 'goal_focus_previous_day_good_score_good',
  'goal_focus_previous_day_good_score_good_0:view:7wgh3e': 'goal_focus_previous_day_good_score_good',
  'goal_focus_previous_day_good_score_good_0:view:ltx44d': 'goal_focus_previous_day_good_score_good',
  '76201461-bac0-4899-b093-e1af99dd17c4:view:q9izfo': 'stay_active_optimal_7d_exercise_high',
  '76201461-bac0-4899-b093-e1af99dd17c4:view:yyk3o6': 'stay_active_optimal_7d_exercise_high',
  'sleep_weekly_summary_good_0:view:g37hrc': 'sleep_weekly_summary_good',
  'sleep_weekly_summary_good_0:view:2rzkrv': 'sleep_weekly_summary_good',
  'sleep_weekly_summary_good_0:view:s8m4hn': 'sleep_weekly_summary_good',
  'sleeptiming_good_score_good_once_0:view:9s6rtv': 'sleeptiming_good_score_good_once',
  'sleeptiming_good_score_good_once_0:view:74oo5q': 'sleeptiming_good_score_good_once',
  'sleeptiming_good_score_good_once_0:view:ocg6oq': 'sleeptiming_good_score_good_once',
  'sleeptiming_good_score_good_once_1:view:5cedtx': 'sleeptiming_good_score_good_once',
  'sleeptiming_good_score_good_once_1:view:l45iah': 'sleeptiming_good_score_good_once',
  'sleeptiming_good_score_good_once_1:view:ysbpg5': 'sleeptiming_good_score_good_once',
  'other_bedtime_recent_ideal_day_0:view:bxvjjx': 'other_bedtime_recent_ideal_day',
  'other_bedtime_recent_ideal_day_0:view:f63oi0': 'other_bedtime_recent_ideal_day',
  'other_bedtime_recent_ideal_day_0:view:7cfhcu': 'other_bedtime_recent_ideal_day',
  'snippet_ideal_bedtime_available_0:view:ug0xnf': 'snippet_ideal_bedtime_available',
  'snippet_ideal_bedtime_available_0:view:ou3k69': 'snippet_ideal_bedtime_available',
  'snippet_ideal_bedtime_available_0:view:f92b49': 'snippet_ideal_bedtime_available',
  'snippet_ideal_bedtime_available_0:view:bsbn85': 'snippet_ideal_bedtime_available',
  'snippet_ideal_bedtime_available_0:view:51tzet': 'snippet_ideal_bedtime_available',
  'snippet_ideal_bedtime_available_0:view:hzzt56': 'snippet_ideal_bedtime_available',
  'sleep_weekly_summary_bad_0:view:5878xe': 'sleep_weekly_summary_bad',
  'sleep_weekly_summary_bad_0:view:4g09wt': 'sleep_weekly_summary_bad',
  'sleep_weekly_summary_bad_0:view:3c0er0': 'sleep_weekly_summary_bad',
  'temp_high_score_high_once_0:view:n0v095': 'temp_high_score_high_once',
  'temp_high_score_high_once_0:view:z8dexo': 'temp_high_score_high_once',
  'temp_high_score_high_once_2:view:vozv72': 'temp_high_score_high_once',
  'temp_high_score_high_once_2:view:iccd09': 'temp_high_score_high_once',
  'temp_high_score_high_once_3:view:28fk0u': 'temp_high_score_high_once',
  'temp_high_score_high_once_3:view:ggpes0': 'temp_high_score_high_once',
  'temp_high_score_high_once_4:view:p32jry': 'temp_high_score_high_once',
  'temp_high_score_high_once_4:view:fy8qs5': 'temp_high_score_high_once',
  'temp_high_score_high_once_5:view:qx6sss': 'temp_high_score_high_once',
  'temp_high_score_high_once_5:view:s35777': 'temp_high_score_high_once',
  'tutorial_activity_inactive_time_0:view:qiip43': 'tutorial_activity_inactive_time',
  'tutorial_activity_inactive_time_0:view:1smvkh': 'tutorial_activity_inactive_time',
  'tutorial_activity_inactive_time_0:view:n2976e': 'tutorial_activity_inactive_time',
  'weekly_summary_0:view:ajg9s0': 'weekly_summary',
  'weekly_summary_0:view:e8ccts': 'weekly_summary',
  'weekly_summary_0:view:m4yoft': 'weekly_summary',
  'weekly_summary_1:view:gc64tc': 'weekly_summary',
  'weekly_summary_1:view:um5chk': 'weekly_summary',
  'weekly_summary_1:view:10agb1': 'weekly_summary',
  'weekly_summary_1:view:aoivj8': 'weekly_summary',
  'weekly_summary_1:view:ylpft4': 'weekly_summary',
  'weekly_summary_1:view:ngss46': 'weekly_summary',
  'weekly_summary_1:view:59q8dt': 'weekly_summary',
  'weekly_summary_1:view:9hdxra': 'weekly_summary',
  'weekly_summary_1:view:ejyrkn': 'weekly_summary',
  'weekly_summary_2:view:4tl9pi': 'weekly_summary',
  'weekly_summary_2:view:faxqun': 'weekly_summary',
  'weekly_summary_2:view:9agmgf': 'weekly_summary',
  'weekly_summary_2:view:d88b2v': 'weekly_summary',
  'weekly_summary_2:view:sgwyij': 'weekly_summary',
  'weekly_summary_2:view:scwu5m': 'weekly_summary',
  'weekly_summary_2:view:n04ywd': 'weekly_summary',
  'weekly_summary_2:view:ynaf4c': 'weekly_summary',
  'weekly_summary_2:view:u11nqb': 'weekly_summary',
  'weekly_summary_3:view:apfg1h': 'weekly_summary',
  'weekly_summary_3:view:bdmrcv': 'weekly_summary',
  'weekly_summary_3:view:ojnqwt': 'weekly_summary',
  'weekly_summary_3:view:equhoi': 'weekly_summary',
  'weekly_summary_3:view:orr0o3': 'weekly_summary',
  'weekly_summary_3:view:0dpk93': 'weekly_summary',
  'weekly_summary_3:view:9l6lej': 'weekly_summary',
  'weekly_summary_3:view:cfjgho': 'weekly_summary',
  'weekly_summary_3:view:c2jprg': 'weekly_summary',
  'sleep_weekly_summary_better_0:view:iogtxm': 'sleep_weekly_summary_better',
  'sleep_weekly_summary_better_0:view:rs9pj8': 'sleep_weekly_summary_better',
  'sleep_weekly_summary_better_0:view:k6k4he': 'sleep_weekly_summary_better',
  'sleeptiming_good_score_bad_once_0:view:crc43n': 'sleeptiming_good_score_bad_once',
  'sleeptiming_good_score_bad_once_0:view:6ntrsw': 'sleeptiming_good_score_bad_once',
  'sleeptiming_good_score_bad_once_0:view:g5kbnb': 'sleeptiming_good_score_bad_once',
  'goal_stress_readiness_good_0:view:4scggl': 'goal_stress_readiness_good',
  'goal_stress_readiness_good_0:view:tvol7q': 'goal_stress_readiness_good',
  'goal_stress_readiness_good_0:view:h5ip09': 'goal_stress_readiness_good',
  'sleeptiming_early_once_0:view:wafgzf': 'sleeptiming_early_once',
  'sleeptiming_early_once_0:view:dhz004': 'sleeptiming_early_once',
  'sleeptiming_early_once_0:view:ru9gnh': 'sleeptiming_early_once',
  'sleeptiming_early_once_1:view:02aqs2': 'sleeptiming_early_once',
  'sleeptiming_early_once_1:view:kx0l13': 'sleeptiming_early_once',
  'sleeptiming_early_once_1:view:df51rx': 'sleeptiming_early_once',
  'other_bedtime_adherence_good_0:view:2rhxub': 'other_bedtime_adherence_good',
  'other_bedtime_adherence_good_0:view:f975wy': 'other_bedtime_adherence_good',
  'other_bedtime_adherence_good_0:view:4i364m': 'other_bedtime_adherence_good',
  'other_bedtime_adherence_poor_0:view:8a7w9y': 'other_bedtime_adherence_poor',
  'other_bedtime_adherence_poor_0:view:ox0bgc': 'other_bedtime_adherence_poor',
  'other_bedtime_adherence_poor_0:view:f9489v': 'other_bedtime_adherence_poor',
  'Recharge_intro_previous_night_bad_score_bad_once_0:view:ghr4u3': 'Recharge_intro_previous_night_bad_score_bad_once',
  'Recharge_intro_previous_night_bad_score_bad_once_0:view:3dprtc': 'Recharge_intro_previous_night_bad_score_bad_once',
  'Recharge_intro_previous_night_bad_score_bad_once_0:view:8hzc81': 'Recharge_intro_previous_night_bad_score_bad_once',
  'activity_target_back_to_normal_0:view:le1cm8': 'activity_target_back_to_normal',
  'activity_target_back_to_normal_0:view:xcipk8': 'activity_target_back_to_normal',
  'previous_night_good_score_bad_once_0:view:qqcrwj': 'previous_night_good_score_bad_once',
  'previous_night_good_score_bad_once_0:view:1pr2no': 'previous_night_good_score_bad_once',
  'previous_night_good_score_bad_once_2:view:l83vpv': 'previous_night_good_score_bad_once',
  'previous_night_good_score_bad_once_2:view:rwfwye': 'previous_night_good_score_bad_once',
  'previous_night_good_score_bad_once_3:view:fg6lrs': 'previous_night_good_score_bad_once',
  'previous_night_good_score_bad_once_3:view:nbtx1h': 'previous_night_good_score_bad_once',
  'previous_day_inactive_score_good_once_0:view:q44chq': 'previous_day_inactive_score_good_once',
  'previous_day_inactive_score_good_once_0:view:4po2gw': 'previous_day_inactive_score_good_once',
  'previous_day_inactive_score_good_once_2:view:r4f6se': 'previous_day_inactive_score_good_once',
  'previous_day_inactive_score_good_once_2:view:ux03o4': 'previous_day_inactive_score_good_once',
  'previous_day_inactive_score_good_once_3:view:viuo30': 'previous_day_inactive_score_good_once',
  'previous_day_inactive_score_good_once_3:view:1xfn4c': 'previous_day_inactive_score_good_once',
  'previous_day_inactive_score_good_once_4:view:eczrga': 'previous_day_inactive_score_good_once',
  'previous_day_inactive_score_good_once_4:view:4xcg2b': 'previous_day_inactive_score_good_once',
  'previous_day_good_activity_good_score_bad_once_0:view:pxa15g': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_0:view:48a7th': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_2:view:ft2rb0': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_2:view:r554n6': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_2:view:3ywzfn': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_3:view:jpbbj3': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_3:view:p3q0jy': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_3:view:hugs9n': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_4:view:b1efbq': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_4:view:hzpt98': 'previous_day_good_activity_good_score_bad_once',
  'previous_day_good_activity_good_score_bad_once_4:view:leoitq': 'previous_day_good_activity_good_score_bad_once',
  'previous_night_bad_score_bad_once_0:view:kgxb5f': 'previous_night_bad_score_bad_once',
  'previous_night_bad_score_bad_once_0:view:fowb9l': 'previous_night_bad_score_bad_once',
  'previous_night_bad_score_bad_once_2:view:k6jv32': 'previous_night_bad_score_bad_once',
  'previous_night_bad_score_bad_once_2:view:ahuq09': 'previous_night_bad_score_bad_once',
  'previous_night_bad_score_bad_once_3:view:2w3eya': 'previous_night_bad_score_bad_once',
  'previous_night_bad_score_bad_once_3:view:f03oz5': 'previous_night_bad_score_bad_once',
  'previous_night_bad_score_bad_once_4:view:zvi2da': 'previous_night_bad_score_bad_once',
  'previous_night_bad_score_bad_once_4:view:rkfq6v': 'previous_night_bad_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_0:view:36gky3': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_0:view:ndy7a6': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_1:view:64k96n': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_1:view:9jfjsy': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_2:view:k6dj39': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_2:view:b8owyf': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_3:view:ahlhzx': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_3:view:di25fn': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_5:view:ikoy4t': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_bad_activity_passive_score_bad_once_5:view:nqu14u': 'previous_day_bad_activity_passive_score_bad_once',
  'previous_day_good_activity_good_score_good_once_0:view:coj1xz': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_0:view:3z0ybn': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_2:view:rv7tdx': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_2:view:vpnxbd': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_3:view:895jaf': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_3:view:km5j6j': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_4:view:qboojj': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_4:view:7ow9la': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_5:view:9bcvqg': 'previous_day_good_activity_good_score_good_once',
  'previous_day_good_activity_good_score_good_once_5:view:o7154s': 'previous_day_good_activity_good_score_good_once',
  'previous_day_too_active_score_bad_once_0:view:c0k380': 'previous_day_too_active_score_bad_once',
  'previous_day_too_active_score_bad_once_0:view:al8q9e': 'previous_day_too_active_score_bad_once',
  'previous_day_too_active_score_bad_once_2:view:sll9y8': 'previous_day_too_active_score_bad_once',
  'previous_day_too_active_score_bad_once_2:view:d2hdu5': 'previous_day_too_active_score_bad_once',
  'previous_day_too_active_score_bad_once_3:view:ajee53': 'previous_day_too_active_score_bad_once',
  'previous_day_too_active_score_bad_once_3:view:z2hrz5': 'previous_day_too_active_score_bad_once',
  'previous_day_too_active_score_bad_once_4:view:2qrpny': 'previous_day_too_active_score_bad_once',
  'previous_day_too_active_score_bad_once_4:view:jjma33': 'previous_day_too_active_score_bad_once',
  'goal_athletic_previous_night_bad_readiness_score_ok_0:view:5sl2mk':
    'goal_athletic_previous_night_bad_readiness_score_ok',
  'goal_athletic_previous_night_bad_readiness_score_ok_0:view:k0acqs':
    'goal_athletic_previous_night_bad_readiness_score_ok',
  'goal_athletic_previous_night_bad_readiness_score_ok_0:view:k70y30':
    'goal_athletic_previous_night_bad_readiness_score_ok',
  'activity_balance_good_lowish_score_good_once_0:view:1j7ku0': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_0:view:vhmykg': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_2:view:g94rrb': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_2:view:iroa08': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_2:view:x86j62': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_3:view:3i2iro': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_3:view:288dm0': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_3:view:r0zwaw': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_4:view:v1ia6e': 'activity_balance_good_lowish_score_good_once',
  'activity_balance_good_lowish_score_good_once_4:view:k9h92x': 'activity_balance_good_lowish_score_good_once',
  'latency_high_triple_0:view:ws57l5': 'latency_high_triple',
  'latency_high_triple_0:view:oq8ax8': 'latency_high_triple',
  'latency_high_triple_0:view:ckkzam': 'latency_high_triple',
  'latency_high_triple_1:view:ux1hro': 'latency_high_triple',
  'latency_high_triple_1:view:alnxw5': 'latency_high_triple',
  'latency_high_triple_1:view:reen6z': 'latency_high_triple',
  'latency_high_triple_2:view:koypwz': 'latency_high_triple',
  'latency_high_triple_2:view:sfdcm6': 'latency_high_triple',
  'latency_high_triple_2:view:ju0u19': 'latency_high_triple',
  '518f2123-d94a-4217-a7d7-04a7dd8b05ea:home:igjzk5': 'rni_study',
  '518f2123-d94a-4217-a7d7-04a7dd8b05ea:home:u9qozi': 'rni_study',
  'previous_day_good_and_active_score_good_once_0:view:7pfgod': 'previous_day_good_and_active_score_good_once',
  'previous_day_good_and_active_score_good_once_0:view:jxl3v9': 'previous_day_good_and_active_score_good_once',
  'previous_day_good_and_active_score_good_once_1:view:3x1my4': 'previous_day_good_and_active_score_good_once',
  'previous_day_good_and_active_score_good_once_1:view:2b5vzb': 'previous_day_good_and_active_score_good_once',
  'previous_day_good_and_active_score_good_once_3:view:6252d2': 'previous_day_good_and_active_score_good_once',
  'previous_day_good_and_active_score_good_once_3:view:0b9q0z': 'previous_day_good_and_active_score_good_once',
  'previous_day_good_and_active_score_good_once_5:view:fu4uzo': 'previous_day_good_and_active_score_good_once',
  'previous_day_good_and_active_score_good_once_5:view:ad49qo': 'previous_day_good_and_active_score_good_once',
  'ob_sleep_efficiency_0:home:r08gi5': 'ob_sleep_efficiency',
  'ob_sleep_efficiency_0:home:bqv5z0': 'ob_sleep_efficiency',
  'ob_sleep_efficiency_0:home:q35j6b': 'ob_sleep_efficiency',
  'ob_sleep_efficiency_0:view:jb9h1y': 'ob_sleep_efficiency',
  'ob_sleep_efficiency_0:view:8pdmsj': 'ob_sleep_efficiency',
  'ob_sleep_efficiency_0:view:u0d4hh': 'ob_sleep_efficiency',
  'goal_creativity_readiness_stable_mediocre_0:view:1iqa9f': 'goal_creativity_readiness_stable_mediocre',
  'goal_creativity_readiness_stable_mediocre_0:view:fjgxtz': 'goal_creativity_readiness_stable_mediocre',
  'goal_focus_previous_night_good_0:view:myh07l': 'goal_focus_previous_night_good',
  'goal_focus_previous_night_good_0:view:hzefxq': 'goal_focus_previous_night_good',
  'goal_focus_previous_night_good_0:view:042zbz': 'goal_focus_previous_night_good',
  'resting_hr_time_good_score_good_once_0:view:7ian2k': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_0:view:64j2wa': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_0:view:bojdrk': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_2:view:tgp9wg': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_2:view:xgonyr': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_2:view:17s5cw': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_3:view:da4vuo': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_3:view:qa88fo': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_3:view:a5dzw8': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_4:view:vpgwbq': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_4:view:ez3jp2': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_4:view:4rhgyl': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_5:view:abilkn': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_5:view:wbn85k': 'resting_hr_time_good_score_good_once',
  'resting_hr_time_good_score_good_once_5:view:q53j7z': 'resting_hr_time_good_score_good_once',
  'ob_recovery_index_0:home:h6lqej': 'ob_recovery_index',
  'ob_recovery_index_0:home:nxryuy': 'ob_recovery_index',
  'ob_recovery_index_0:home:ln2gup': 'ob_recovery_index',
  'ob_recovery_index_0:view:x9otqz': 'ob_recovery_index',
  'ob_recovery_index_0:view:lhisrj': 'ob_recovery_index',
  'ob_recovery_index_0:view:vlhzwk': 'ob_recovery_index',
  'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once_0:view:pj07u8':
    'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once',
  'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once_0:view:gsuwmy':
    'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once',
  'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once_0:view:iud00s':
    'total_sleep_bad_midpoint_ok_efficiency_good_time_in_bed_short_once',
  'efficiency_bad_time_in_bed_long_once_0:view:bde899': 'efficiency_bad_time_in_bed_long_once',
  'efficiency_bad_time_in_bed_long_once_0:view:x4v7me': 'efficiency_bad_time_in_bed_long_once',
  'efficiency_bad_time_in_bed_long_once_0:view:psf1sg': 'efficiency_bad_time_in_bed_long_once',
  'deep_sleep_bad_total_bad_once_0:view:k3ebib': 'deep_sleep_bad_total_bad_once',
  'deep_sleep_bad_total_bad_once_0:view:odmkke': 'deep_sleep_bad_total_bad_once',
  'deep_sleep_bad_total_bad_once_0:view:h0lnv7': 'deep_sleep_bad_total_bad_once',
  'deep_sleep_bad_total_ok_once_0:view:ku886k': 'deep_sleep_bad_total_ok_once',
  'deep_sleep_bad_total_ok_once_0:view:01b3ym': 'deep_sleep_bad_total_ok_once',
  'deep_sleep_bad_total_ok_once_0:view:xzksuz': 'deep_sleep_bad_total_ok_once',
  'efficiency_bad_activity_normal_once_0:view:buy2rz': 'efficiency_bad_activity_normal_once',
  'efficiency_bad_activity_normal_once_0:view:x22m0q': 'efficiency_bad_activity_normal_once',
  'efficiency_bad_activity_normal_once_0:view:ym4hr4': 'efficiency_bad_activity_normal_once',
  'efficiency_bad_activity_normal_once_1:view:57p6rj': 'efficiency_bad_activity_normal_once',
  'efficiency_bad_activity_normal_once_1:view:hhqwa6': 'efficiency_bad_activity_normal_once',
  'efficiency_bad_activity_normal_once_1:view:k6q2dc': 'efficiency_bad_activity_normal_once',
  'previous_night_good_score_good_once_0:view:huk0nd': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_0:view:kup7vv': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_2:view:nbafn4': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_2:view:ko4j3d': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_3:view:vl97ag': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_3:view:fx2ydl': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_4:view:5xowtw': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_4:view:0kik5k': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_5:view:dyae9v': 'previous_night_good_score_good_once',
  'previous_night_good_score_good_once_5:view:ntvybv': 'previous_night_good_score_good_once',
  'previous_day_bad_activity_too_score_good_once_0:view:cqc987': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_0:view:svp5f1': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_2:view:l4x749': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_2:view:kvut09': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_3:view:fmafv7': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_3:view:59c0a3': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_4:view:33jg0d': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_4:view:w94sve': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_5:view:yly42f': 'previous_day_bad_activity_too_score_good_once',
  'previous_day_bad_activity_too_score_good_once_5:view:hhfaao': 'previous_day_bad_activity_too_score_good_once',
  'ob_deep_sleep_0:home:etw8e8': 'ob_deep_sleep',
  'ob_deep_sleep_0:home:gkp9y8': 'ob_deep_sleep',
  'ob_deep_sleep_0:home:661dq7': 'ob_deep_sleep',
  'ob_deep_sleep_0:view:7rwvy9': 'ob_deep_sleep',
  'ob_deep_sleep_0:view:wvd4th': 'ob_deep_sleep',
  'ob_deep_sleep_0:view:v7l5pv': 'ob_deep_sleep',
  'ob_recovery_time_0:home:rygdke': 'ob_recovery_time',
  'ob_recovery_time_0:home:ixhotd': 'ob_recovery_time',
  'ob_recovery_time_0:home:lyn8p8': 'ob_recovery_time',
  'ob_recovery_time_0:view:8wk4xc': 'ob_recovery_time',
  'ob_recovery_time_0:view:xs46pg': 'ob_recovery_time',
  'ob_recovery_time_0:view:5m7w3h': 'ob_recovery_time',
  'ob_recovery_time_0:view:kcvol2': 'ob_recovery_time',
  'ob_ideal_bedtime_0:home:6vg1th': 'ob_ideal_bedtime',
  'ob_ideal_bedtime_0:home:4c6c7g': 'ob_ideal_bedtime',
  'ob_ideal_bedtime_0:home:9xj016': 'ob_ideal_bedtime',
  'ob_ideal_bedtime_0:view:btu6t0': 'ob_ideal_bedtime',
  'ob_ideal_bedtime_0:view:wto2zc': 'ob_ideal_bedtime',
  'ob_ideal_bedtime_0:view:dfyuje': 'ob_ideal_bedtime',
  'ob_rem_sleep_0:home:3x6fz4': 'ob_rem_sleep',
  'ob_rem_sleep_0:home:mzg60h': 'ob_rem_sleep',
  'ob_rem_sleep_0:home:5mh6a9': 'ob_rem_sleep',
  'ob_rem_sleep_0:view:18hr5h': 'ob_rem_sleep',
  'ob_rem_sleep_0:view:wsolvw': 'ob_rem_sleep',
  'ob_rem_sleep_0:view:uumtvb': 'ob_rem_sleep',
  'restfulness_good_once_0:view:p01d1o': 'restfulness_good_once',
  'restfulness_good_once_0:view:oe0bbi': 'restfulness_good_once',
  'restfulness_good_once_0:view:qtlnr8': 'restfulness_good_once',
  'ob_sleep_stages_0:home:niivtk': 'ob_sleep_stages',
  'ob_sleep_stages_0:home:gtekf8': 'ob_sleep_stages',
  'ob_sleep_stages_0:home:ondp5u': 'ob_sleep_stages',
  'ob_sleep_stages_0:view:juohha': 'ob_sleep_stages',
  'ob_sleep_stages_0:view:4muedi': 'ob_sleep_stages',
  'ob_sleep_stages_0:view:1nmxe5': 'ob_sleep_stages',
  'ob_resting_heart_rate_0:home:29ono3': 'ob_resting_heart_rate',
  'ob_resting_heart_rate_0:home:xpti7c': 'ob_resting_heart_rate',
  'ob_resting_heart_rate_0:home:ytdjgf': 'ob_resting_heart_rate',
  'ob_resting_heart_rate_0:view:ya5vti': 'ob_resting_heart_rate',
  'ob_resting_heart_rate_0:view:pwv2za': 'ob_resting_heart_rate',
  'ob_resting_heart_rate_0:view:vr1zv8': 'ob_resting_heart_rate',
  '1afa4ae9-b146-4be9-a836-1887fc21869b:home:2btgj3': 'onboarding_movement_during_sleep',
  '1afa4ae9-b146-4be9-a836-1887fc21869b:home:4j66tw': 'onboarding_movement_during_sleep',
  '1afa4ae9-b146-4be9-a836-1887fc21869b:home:eb64su': 'onboarding_movement_during_sleep',
  '1afa4ae9-b146-4be9-a836-1887fc21869b:view:u90ise': 'onboarding_movement_during_sleep',
  '1afa4ae9-b146-4be9-a836-1887fc21869b:view:dkq4nl': 'onboarding_movement_during_sleep',
  '1afa4ae9-b146-4be9-a836-1887fc21869b:view:wps5bq': 'onboarding_movement_during_sleep',
  '1afa4ae9-b146-4be9-a836-1887fc21869b:view:uu0rrp': 'onboarding_movement_during_sleep',
  'ob_body_temperature_0:home:ejcwhn': 'ob_body_temperature',
  'ob_body_temperature_0:home:j34qui': 'ob_body_temperature',
  'ob_body_temperature_0:home:9z3qbw': 'ob_body_temperature',
  'ob_body_temperature_0:view:mcbslr': 'ob_body_temperature',
  'ob_body_temperature_0:view:25dq88': 'ob_body_temperature',
  'ob_body_temperature_0:view:0vo4rv': 'ob_body_temperature',
  'ob_sleep_latency_0:home:9bt0os': 'ob_sleep_latency',
  'ob_sleep_latency_0:home:1pam8j': 'ob_sleep_latency',
  'ob_sleep_latency_0:home:juqy5n': 'ob_sleep_latency',
  'ob_sleep_latency_0:view:nqkdfz': 'ob_sleep_latency',
  'ob_sleep_latency_0:view:dzerjx': 'ob_sleep_latency',
  'ob_sleep_latency_0:view:jexqdm': 'ob_sleep_latency',
  'ob_sleep_trends_0:home:bk63pw': 'ob_sleep_trends',
  'ob_sleep_trends_0:home:p0js8p': 'ob_sleep_trends',
  'ob_sleep_trends_0:home:mqk5gb': 'ob_sleep_trends',
  'ob_sleep_trends_0:view:asn1k8': 'ob_sleep_trends',
  'ob_sleep_trends_0:view:ln5c5k': 'ob_sleep_trends',
  'ob_sleep_trends_0:view:5qqgry': 'ob_sleep_trends',
  'ob_steps_0:home:wbxuci': 'ob_steps',
  'ob_steps_0:home:ue4sca': 'ob_steps',
  'ob_steps_0:home:5dmsw2': 'ob_steps',
  'ob_steps_0:view:nndone': 'ob_steps',
  'ob_steps_0:view:w8xbp6': 'ob_steps',
  'ob_steps_0:view:ia9ms2': 'ob_steps',
  'other_user_profile_0:view:m42cxa': 'other_user_profile',
  'other_user_profile_0:view:cdlmdp': 'other_user_profile',
  'other_user_profile_1:view:90xnf0': 'other_user_profile',
  'other_user_profile_1:view:1sow7n': 'other_user_profile',
  'other_user_profile_2:view:68kpq2': 'other_user_profile',
  'other_user_profile_2:view:lsjsrz': 'other_user_profile',
  'other_user_profile_3:view:o2o8qz': 'other_user_profile',
  'other_user_profile_3:view:g6locq': 'other_user_profile',
  'ob_sedentary_time_0:home:g7yhdx': 'ob_sedentary_time',
  'ob_sedentary_time_0:home:5qa34v': 'ob_sedentary_time',
  'ob_sedentary_time_0:home:ppavie': 'ob_sedentary_time',
  'ob_sedentary_time_0:view:w7hhxq': 'ob_sedentary_time',
  'ob_sedentary_time_0:view:fk4up1': 'ob_sedentary_time',
  'ob_sedentary_time_0:view:w81jx9': 'ob_sedentary_time',
  'ob_sedentary_time_0:view:i7uwwb': 'ob_sedentary_time',
  'ob_calorie_calculations_0:home:957afc': 'ob_calorie_calculations',
  'ob_calorie_calculations_0:home:x4bx4a': 'ob_calorie_calculations',
  'ob_calorie_calculations_0:home:tjuoy3': 'ob_calorie_calculations',
  'ob_calorie_calculations_0:view:kntmnl': 'ob_calorie_calculations',
  'ob_calorie_calculations_0:view:7ugv6h': 'ob_calorie_calculations',
  'ob_calorie_calculations_0:view:mhnkch': 'ob_calorie_calculations',
  'ob_calorie_calculations_0:view:dtmm30': 'ob_calorie_calculations',
  'readiness_bad_1:view:7uve72': 'readiness_bad',
  'readiness_bad_1:view:qtkidj': 'readiness_bad',
  'readiness_bad_2:view:ao9yda': 'readiness_bad',
  'readiness_bad_2:view:nm1yai': 'readiness_bad',
  'readiness_bad_2:view:gmhbpk': 'readiness_bad',
  'readiness_bad_3:view:gxvm2a': 'readiness_bad',
  'readiness_bad_3:view:03388w': 'readiness_bad',
  'readiness_bad_4:view:fbz1o7': 'readiness_bad',
  'readiness_bad_4:view:tnzy7t': 'readiness_bad',
  'readiness_bad_5:view:orrh5x': 'readiness_bad',
  'readiness_bad_5:view:s0onsp': 'readiness_bad',
  'other_bedtime_no_ideal_evening_0:view:g9mx8s': 'other_bedtime_no_ideal_evening',
  'other_bedtime_no_ideal_evening_0:view:nj05rr': 'other_bedtime_no_ideal_evening',
  'other_bedtime_no_ideal_evening_0:view:eui3ln': 'other_bedtime_no_ideal_evening',
  'other_bedtime_no_ideal_evening_0:view:4r9eu2': 'other_bedtime_no_ideal_evening',
  'other_bedtime_no_ideal_evening_0:home:sxvtwz': 'other_bedtime_no_ideal_evening',
  'other_bedtime_no_ideal_evening_0:home:id9ojc': 'other_bedtime_no_ideal_evening',
  'other_bedtime_no_ideal_evening_0:view:j82k9j': 'other_bedtime_no_ideal_evening',
  'restfulness_bad_evenly_distributed_once_0:view:e2gl1p': 'restfulness_bad_evenly_distributed_once',
  'restfulness_bad_evenly_distributed_once_0:view:kdqfj9': 'restfulness_bad_evenly_distributed_once',
  'restfulness_bad_evenly_distributed_once_0:view:nb3kg2': 'restfulness_bad_evenly_distributed_once',
  'restfulness_bad_evenly_distributed_once_1:view:jbpwc6': 'restfulness_bad_evenly_distributed_once',
  'restfulness_bad_evenly_distributed_once_1:view:rf0wpp': 'restfulness_bad_evenly_distributed_once',
  'resting_hr_time_bad_score_good_activity_low_once_0:view:aifenw': 'resting_hr_time_bad_score_good_activity_low_once',
  'resting_hr_time_bad_score_good_activity_low_once_0:view:zwu7b7': 'resting_hr_time_bad_score_good_activity_low_once',
  'other_bedtime_recent_ideal_evening_0:view:a51tqz': 'other_bedtime_recent_ideal_evening',
  'other_bedtime_recent_ideal_evening_0:view:j0m49m': 'other_bedtime_recent_ideal_evening',
  'other_bedtime_recent_ideal_evening_0:home:p1sxjs': 'other_bedtime_recent_ideal_evening',
  'other_bedtime_recent_ideal_evening_0:home:mbnmeo': 'other_bedtime_recent_ideal_evening',
  'other_bedtime_recent_ideal_evening_0:view:dwsxk8': 'other_bedtime_recent_ideal_evening',
  'previous_night_bad_score_ok_once_0:view:py1exv': 'previous_night_bad_score_ok_once',
  'previous_night_bad_score_ok_once_0:view:bdhbve': 'previous_night_bad_score_ok_once',
  'previous_night_bad_score_ok_once_0:view:z49ag7': 'previous_night_bad_score_ok_once',
  'previous_night_bad_score_ok_once_2:view:jvwkot': 'previous_night_bad_score_ok_once',
  'previous_night_bad_score_ok_once_2:view:yw4p15': 'previous_night_bad_score_ok_once',
  'previous_night_bad_score_ok_once_3:view:s253nx': 'previous_night_bad_score_ok_once',
  'previous_night_bad_score_ok_once_3:view:b51rxx': 'previous_night_bad_score_ok_once',
  'previous_night_bad_score_ok_once_3:view:ozbh8u': 'previous_night_bad_score_ok_once',
  'sleeptiming_late_once_0:view:x5x94s': 'sleeptiming_late_once',
  'sleeptiming_late_once_0:view:lsiplt': 'sleeptiming_late_once',
  'sleeptiming_late_once_0:view:rv8sv4': 'sleeptiming_late_once',
  'sleeptiming_late_once_1:view:abdfnh': 'sleeptiming_late_once',
  'sleeptiming_late_once_1:view:866zsf': 'sleeptiming_late_once',
  'sleeptiming_late_once_1:view:jrci9o': 'sleeptiming_late_once',
  'score_trending_down_already_bad_0:view:ta2juu': 'score_trending_down_already_bad',
  'score_trending_down_already_bad_0:view:d1lzlf': 'score_trending_down_already_bad',
  'score_trending_down_already_bad_0:view:djexew': 'score_trending_down_already_bad',
  'score_trending_down_already_bad_1:view:s6l4pa': 'score_trending_down_already_bad',
  'score_trending_down_already_bad_1:view:bpp8q0': 'score_trending_down_already_bad',
  'score_trending_down_already_bad_1:view:42uo8v': 'score_trending_down_already_bad',
  'resting_hr_high_score_low_once_0:view:1c2nl7': 'resting_hr_high_score_low_once',
  'resting_hr_high_score_low_once_0:view:2jce1b': 'resting_hr_high_score_low_once',
  'resting_hr_high_score_low_once_2:view:b687lj': 'resting_hr_high_score_low_once',
  'resting_hr_high_score_low_once_2:view:db7hzd': 'resting_hr_high_score_low_once',
  'resting_hr_high_score_low_once_3:view:47nzvr': 'resting_hr_high_score_low_once',
  'resting_hr_high_score_low_once_3:view:sv5n5m': 'resting_hr_high_score_low_once',
  'resting_hr_high_score_high_once_0:view:vmmkiz': 'resting_hr_high_score_high_once',
  'resting_hr_high_score_high_once_0:view:ifwtg3': 'resting_hr_high_score_high_once',
  'resting_hr_high_score_high_once_2:view:k0cc8b': 'resting_hr_high_score_high_once',
  'resting_hr_high_score_high_once_2:view:28hhcs': 'resting_hr_high_score_high_once',
  'resting_hr_high_score_high_once_3:view:xpcp08': 'resting_hr_high_score_high_once',
  'resting_hr_high_score_high_once_3:view:j2z88y': 'resting_hr_high_score_high_once',
}
