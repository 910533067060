<template>
  <div class="pa-4">
    <div class="text-h5">Components using this template ({{ messages.length }})</div>

    <v-divider class="my-2" />

    <v-progress-linear v-if="isLoading" indeterminate />

    <v-table v-else-if="!!messages.length">
      <template #default>
        <thead>
          <tr>
            <th class="text-primary">Where</th>
            <th class="text-primary">Insight</th>
            <th class="text-primary">Message</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="message in messages" :key="message.id">
            <td>{{ message.env }}</td>
            <td>
              <a style="cursor: pointer; text-decoration: underline" @click="$router.push(message.parentPath)">
                {{ message.parent }}
              </a>
            </td>
            <td>
              <a style="cursor: pointer; text-decoration: underline" @click="$router.push(message.path)">
                {{ message.id }}
              </a>
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </div>
</template>

<script lang="ts">
  import { upperFirst } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collectionGroup, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { Template } from '#types'

  @Component({})
  class AttachedComponents extends Vue {
    @Prop() public template!: Template

    public isLoading = true

    public messages: any = []

    private unsubscribe: Unsubscribe | null = null

    public created() {
      const translations = (this.template?.facets?.view?.components || [])
        .map((c: any) => c?.title?.translationId)
        .filter(Boolean)

      this.unsubscribe = onSnapshot(query(collectionGroup(getFirestore(), `messages`)), (snap) => {
        this.messages = snap.docs
          .filter((doc) => {
            return (doc.data().facets?.view?.components || [])
              .filter((c: any) => !!c.title)
              .find((c: any) => translations.includes(c.title.translationId))
          })
          .map((doc) => {
            let parent = doc.ref.parent.path.split('/')[0]
            let id = parent == 'users' ? 'workspace' : parent

            let parentPath = `/${doc.ref.parent.path.replace('users', 'workspace')}`

            if (/^users/.test(doc.ref.parent.path)) {
              parentPath = parentPath.replace('/insights', '')
            }

            let path = `${parentPath}/${doc.id}`

            parentPath = parentPath.replace('/messages', '')

            return {
              ...doc.data(),
              path,
              parentPath,
              parent: doc.ref.parent.path.split('/')[doc.ref.parent.path.split('/').length - 2],
              env: upperFirst(id),
            }
          })

        this.isLoading = false
      })
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = null
      }
    }
  }

  export default toNative(AttachedComponents)
</script>
