<template>
  <div class="pa-2">
    <div class="pb-1 body-2">{{ component.title.text }}</div>
    <v-sparkline type="bar" color="white" :value="mockChartData" :padding="2" :line-width="3" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentActivityIntensity } from '#types'

  @Component({})
  class ActivityIntensityPreview extends Vue {
    @Prop() public component!: UIComponentActivityIntensity

    public get mockChartData() {
      return [3, 4, 5, 6, 7, 3, 4, 3, 4, 5, 6, 7, 3, 4, 3, 4, 5, 6, 7, 3, 4]
    }
  }

  export default toNative(ActivityIntensityPreview)
</script>
