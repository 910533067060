<template>
  <div>
    <v-text-field
      v-if="!images"
      v-model="data.image"
      label="Image"
      readonly
      append-icon="mdi-view-grid-outline"
      @click="galleryOpen = true"
      @click:append="galleryOpen = true"
    />

    <v-select v-else v-model="data.image" label="Image" :items="images" />

    <v-dialog v-model="galleryOpen" width="1000" scrollable>
      <ImageGallery
        file="OTA"
        title="Slideshow illustrations"
        category="slideshow"
        :slug="data.image"
        :thumbnail="message.order === 0"
        @cancel="galleryOpen = false"
        @select="updateImage($event)"
      />
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Message, UIComponentPicture } from '#types'

  @Component({})
  class Picture extends Vue {
    @Prop() public data!: UIComponentPicture

    @Prop() public message!: Message

    @Prop() public images!: any

    public galleryOpen = false

    public updateImage(image: any) {
      this.galleryOpen = false

      this.data.image = image.slug
    }
  }

  export default toNative(Picture)
</script>
