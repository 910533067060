<template>
  <v-overlay
    class="justify-end align-center"
    style="z-index: -1"
    opacity="0.5"
    persistent
    :close-on-back="false"
    :model-value="previewVisible || screenshotMode"
    :style="previewVisible ? 'z-index: 9999' : ''"
    @click="togglePreview(false)"
  >
    <div class="d-flex flex-column placeholder-background mr-3" style="max-height: calc(100vh - 32px); overflow: auto">
      <v-btn variant="plain" color="white" class="align-self-end" icon="mdi-close" @click="togglePreview(false)" />

      <div ref="preview">
        <ViewFacetPreview v-if="activeTab === 'view'" :message="message" :category="category" :preview="true" />
        <HomeFacetPreview v-if="activeTab === 'home'" :message="message" />
        <MediaObjectPreview v-if="activeTab === 'media'" :message="message" />
        <LinksObjectPreview v-if="activeTab === 'links'" :message="message" />
        <NotificationFacetPreview v-if="activeTab === 'notification'" :message="message" />
      </div>
    </div>
  </v-overlay>
</template>

<script lang="ts">
  import html2canvas from 'html2canvas'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { Message } from '#types'

  @Component({})
  class MessagePreview extends Vue {
    public declare $refs: {
      preview: HTMLElement
    }

    public activeTab!: string
    public category!: string

    public previewVisible = false
    public screenshotMode = false

    public message: Message | null = null

    public created() {
      this.activeTab = 'view'
    }

    public open(message: Message, activeTab: string, category: string) {
      this.message = message
      this.activeTab = activeTab
      this.category = category

      this.previewVisible = true
    }

    public async screenshot(message: Message, activeTab: string) {
      this.message = message
      this.activeTab = activeTab

      this.screenshotMode = true

      return new Promise((resolve) =>
        setTimeout(async () => {
          const canvas = await html2canvas(this.$refs.preview as HTMLElement)

          resolve(canvas.toDataURL())

          this.screenshotMode = false
        }, 1000),
      )
    }

    public togglePreview(value: boolean) {
      this.previewVisible = value
    }
  }

  export default toNative(MessagePreview)
</script>

<style lang="scss" scoped>
  .placeholder-background {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 80%),
      linear-gradient(135deg, #000 0%, #333 30%);
    background-size: cover;
    min-width: 350px;
    max-width: 350px;
  }
</style>
