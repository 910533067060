<template>
  <div>
    <Translatable :data="data.title">
      <v-text-field v-model="data.title.text" spellcheck="true" lang="en" label="Title" />
    </Translatable>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentTitle } from '#types'

  @Component({})
  class Title extends Vue {
    @Prop() public data!: UIComponentTitle
  }

  export default toNative(Title)
</script>
