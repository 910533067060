<template>
  <div>
    <Translatable :data="data.title">
      <v-text-field v-model="data.title.text" spellcheck="true" lang="en" label="Title" />
    </Translatable>

    <v-checkbox
      v-model="data.show_movement_graph"
      hint="Select to show Nighttime movement graph"
      persistent-hint
      label="Night time movement graph"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentSleepStages } from '#types'

  @Component({})
  class SleepStages extends Vue {
    @Prop() public data!: UIComponentSleepStages
  }

  export default toNative(SleepStages)
</script>
