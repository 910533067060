<template>
  <v-card flat class="pa-4 pl-6 pr-4">
    <div class="insight" style="cursor: pointer" @click="setInsightActive()">
      <v-row dense>
        <v-col
          class="title-container d-flex text-uppercase align-self-center col-9 flex-shrink-1 text-no-wrap"
          style="font-size: 18px; min-width: 0"
          :class="{ active: active }"
          @click="editInsight()"
        >
          <v-badge v-if="notify" style="top: -4px; margin-right: 9px" dot />

          <span class="flex-shrink-1" style="overflow: hidden; text-overflow: ellipsis">{{ insight.id }}</span>

          <v-icon v-if="hasEditRights || isInsightsEditor" class="pl-2" color="primary">mdi-pencil-outline</v-icon>
        </v-col>

        <v-col v-if="inMessaging" class="d-inline-flex flex-column flex-grow-0 align-end" @click.stop>
          <v-tooltip text="Generated count (viewed percentage / clicked percentage)" location="start">
            <template #activator="{ props }">
              <StatisticsChip
                v-bind="props"
                class="mt-1"
                :stat="
                  insightStatistics.generated
                    ? `${insightStatistics.generated} (${Math.round(insightStatistics.viewedRatio)}% / ${Math.round(insightStatistics.clickedRatio)}%)`
                    : ''
                "
                :warnings="insightWarnings"
                :stats-data="insightStatsData"
                @open="$refs.insightChart?.open('generated')"
              />
            </template>
          </v-tooltip>

          <InsightChart ref="insightChart" :insight-id="insight.id" />
        </v-col>

        <v-col class="col-3 d-flex align-center flex-grow-0 justify-end" @click.stop>
          <StateChip
            :readonly="!hasEditRights && !isInsightsEditor"
            :state-key="insight.state"
            @select="editState(insight)"
          />

          <div v-if="!inApprovals && !inWorkspace" class="actions mr-n2">
            <v-menu offset-y left nudge-bottom="10">
              <template #activator="{ props }">
                <v-btn icon="mdi-dots-vertical" v-bind="props" />
              </template>
              <v-list>
                <v-list-item
                  v-if="copiedInsights.includes(insight.id)"
                  prepend-icon="mdi-duplicate"
                  title="Copied to workspace"
                />

                <v-list-item
                  v-else
                  prepend-icon="mdi-content-copy"
                  title="Copy to workspace"
                  @click="copyToWorkspace()"
                />

                <v-list-item
                  v-if="inMessaging"
                  prepend-icon="mdi-code-json"
                  title="Show rules / condition"
                  @click="$refs.editRuleDialog?.open(insight.id)"
                />
                <v-list-item
                  v-if="inMessaging"
                  prepend-icon="mdi-cloud-download-outline"
                  title="Export content as JSON"
                  @click="exportInsight()"
                />
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>

      <v-row dense class="d-flex justify-end mt-n2">
        <span class="tags col-9">
          <v-chip size="small" class="font-weight-black mr-2" style="opacity: 0.6">
            {{ insight.category.toUpperCase() }}
          </v-chip>

          <template v-if="insight.tags">
            <v-chip v-for="tag in humanReadableTags" :key="tag" size="small" class="mr-2" style="opacity: 0.8">
              {{ tag }}
            </v-chip>
          </template>
        </span>
        <v-spacer />
      </v-row>

      <v-row class="body-2" style="padding: 0 1px" @click.stop="toggleActiveInsight()">
        <v-col class="text-grey-darken-1" flex-grow-1>{{ insight.description }}</v-col>
        <v-col class="d-flex justify-end align-end flex-shrink-1">
          <div v-if="insight.notes && insight.notes > 0" @click="showNotes()">
            {{ insight.notes }} {{ insight.notes === 1 ? 'note' : 'notes' }}
            <v-icon style="font-size: 14px">mdi-note-text-outline</v-icon>
            &nbsp;
            <span>•</span>
            &nbsp;
          </div>
          <div v-if="!isLoading">{{ messages.length }} {{ messageName }}{{ messages.length === 1 ? '' : 's' }}</div>
          <v-icon v-if="!isLoading && !active" icon="mdi-chevron-down" class="mx-2" />
          <v-icon v-if="!isLoading && active" icon="mdi-chevron-up" class="mx-2" />
        </v-col>
      </v-row>

      <v-expansion-panels accordion tile :model-value="active ? 0 : null">
        <v-expansion-panel eager elevation="0" style="background: transparent">
          <v-expansion-panel-text class="pt-4 mb-n4">
            <template v-if="isLoading">
              <div class="loader">
                <v-progress-circular size="64" color="primary" indeterminate />
              </div>
            </template>
            <template v-else>
              <MessageList
                :insight="insight"
                :insights-path="insightsPath"
                :message-snapshots="messageSnapshots"
                :message-path="messagePath"
                :disable-edit="disableEdit()"
                :default-message-path="insightsPath"
                @create="openCreateMessageDialog($event)"
                @set-messages="setMessages($event)"
              />
            </template>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <CreateMessageDialog
        ref="createMessageDialog"
        :insight="insight"
        :messages="messages"
        :insight-path="`${insightsPath}/${insight.id}`"
      />

      <EditRuleDialog ref="editRuleDialog" :rules="existingRules" :insight="insight" />
    </div>
  </v-card>
</template>

<script lang="ts">
  import { sum } from 'lodash-es'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { calculateRatio, copyInsightToWorkspace } from '#views/insights/utilities'

  import {
    AppStore,
    ContentsStore,
    FormulasStore,
    InsightsStore,
    SlideshowsStore,
    TipthemesStore,
    WorkspaceStore,
  } from '#stores'

  import { AmplitudeStats, Dialog, Insight, Message, MessageSnapshot } from '#types'

  @Component({})
  class InsightDrawer extends Vue {
    @Prop() public stats!: any
    @Prop() public store!: string
    @Prop() public active!: boolean
    @Prop() public notify!: boolean
    @Prop() public insight!: Insight

    @Prop() public insightsPath!: string

    public declare $refs: {
      insightChart: Dialog
      editRuleDialog: Dialog
      createMessageDialog: Dialog
    }

    @Emit('editState')
    public editState(insight: any) {
      return insight
    }

    @Emit('showNotes')
    public showNotes() {
      return true
    }

    public messages: Message[] = []

    public copiedInsights: string[] = []

    public messageSnapshots: MessageSnapshot[] = []

    public insightStatsData: AmplitudeStats | null = null

    protected readonly appStore = new AppStore()

    protected readonly workspaceStore = new WorkspaceStore()

    protected readonly contentsStore = new ContentsStore()
    protected readonly formulasStore = new FormulasStore()
    protected readonly insightsStore = new InsightsStore()
    protected readonly tipthemesStore = new TipthemesStore()
    protected readonly slideshowsStore = new SlideshowsStore()

    public get isLoading(): boolean {
      return this[`${this.store}Store` as keyof InsightDrawer].loading
    }

    public get inApprovals() {
      return this.appStore.inApprovals
    }

    public get inMessaging() {
      return this.appStore.inMessaging
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get messagePath() {
      return `${this.insightsPath}/${this.insight.id}/messages`
    }

    public get messageName() {
      return this.insightsPath.includes('slideshow') ? 'slide' : 'message'
    }

    public get existingRules() {
      return this.formulasStore.rules
    }

    public get hasEditRights() {
      return this.appStore.canUserEdit
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get insightWarnings() {
      const warnings: any[] = []

      if (this.insightStatsData) {
        const generatedDuringLastWeek = sum(this.insightStatsData.series)

        if (generatedDuringLastWeek === 0) {
          warnings.push({ name: 'generatedDuringLastWeek', label: 'Not generated during last week' })
        }
      }

      return warnings
    }

    public get insightStatistics() {
      const insightStats = this.insightsStore.insightsStatistics.find(
        (insightStat: any) => insightStat.insightName.toLowerCase() === this.insight.id,
      )

      return insightStats
        ? {
            ...insightStats,
            viewedRatio: calculateRatio(insightStats.viewed, insightStats.generated),
            clickedRatio: calculateRatio(insightStats.clicked, insightStats.generated),
          }
        : {}
    }

    public get humanReadableTags() {
      const tags = this.insightsStore.featureTags

      return tags.filter((tag: any) => this.insight!.tags.includes(tag.value)).map((tag: any) => tag.text)
    }

    public async mounted() {
      const dateTo = this.$dayjs(new Date()).format('YYYY-MM-DD')
      const dateFrom = this.$dayjs(new Date()).subtract(7, 'day').format('YYYY-MM-DD')

      const results = await this.insightsStore.fetchStats({ dateFrom, dateTo, insightId: this.insight.id })

      const stats = {
        xValues: results.map((result: any) => result.date),
        series: results.map((result: any) => result.count),
        seriesLabels: ['Generated'],
        seriesCollapsed: [],
      }

      this.insightStatsData = stats
    }

    public disableEdit() {
      return this.inApprovals || this.insight.state === 'approved'
    }

    @Emit('editInsight')
    public editInsight() {
      if (this.active && (this.hasEditRights || this.isInsightsEditor)) {
        return this.insight
      }
    }

    public async toggleActiveInsight() {
      if (this[`${this.store}Store` as keyof InsightDrawer].activeInsight === this.insight!.id) {
        this[`${this.store}Store` as keyof InsightDrawer].activeInsight = null
      } else {
        this[`${this.store}Store` as keyof InsightDrawer].activeInsight = this.insight!.id

        /* if (this.notify) {
          await this.approvalsStore.closeNotification(this.insight.id)
        } */
      }
    }

    public exportInsight() {
      this.$confirm('Include translations?').then((confirmed) => {
        this.insightsStore.downloadInsights({
          insightIds: [this.insight.id],
          collectionPath: 'insights',
          insightConfigData: !confirmed,
        })
      })
    }

    public setMessages(messages: Message[]) {
      this.messages = messages
    }

    public copyToWorkspace() {
      this.copiedInsights.push(this.insight.id)

      copyInsightToWorkspace(this.insight, this.messages)
    }

    public async setInsightActive() {
      this[`${this.store}Store` as keyof InsightDrawer].activeInsight = this.insight!.id

      /* if (this.notify) {
        await this.approvalsStore.closeNotification(this.insight.id)
      } */
    }

    public openCreateMessageDialog(program: { type: string | undefined; page: number | undefined } | undefined) {
      this.$refs.createMessageDialog?.open(program?.type, program?.page)
    }
  }

  export default toNative(InsightDrawer)
</script>

<style lang="scss" scoped>
  .loader {
    text-align: center;
    min-height: 100px;
    margin: 2rem;
  }

  .actions {
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }

  .title-container {
    transition: color 100ms;

    .v-icon {
      opacity: 0;
      transition: opacity 100ms;
    }

    &.active {
      color: rgb(var(--v-theme-primary));

      .v-icon {
        opacity: 1;
      }
    }
  }

  .insight:hover {
    .title-container {
      color: rgb(var(--v-theme-primary));
    }
  }
</style>
