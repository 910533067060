<template>
  <v-dialog v-model="isOpen" scrollable width="800px">
    <v-card v-if="originalItem && databasePath">
      <v-card-title class="d-flex flex-column">
        <h5 class="headline">Change state of {{ title }}</h5>
        <p class="text-subtitle-2 text-medium-emphasis">{{ originalItem.id.toUpperCase() }}</p>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col v-if="!nextState" cols="12">
            <v-alert
              type="info"
              title="Archived state"
              :text="`State of archived ${title} may not be updated from Waltari. If the state must be changed, please contact a Waltari admin through #waltari slack channel.`"
            />
          </v-col>

          <v-col>
            <h6 class="text-h6">State change order</h6>
            <p>State must be updated one step at a time to the next step. State may not be changed backwards.</p>
          </v-col>

          <v-col class="d-flex align-center justify-space-between" cols="12">
            <template v-for="state in insightStates" :key="`state-${state.value}`">
              <StateChip
                :label="state.value"
                :state-key="state.value"
                :readonly="isNextOrCurrentState(state.value)"
                :disabled="!isNextOrCurrentState(state.value)"
              />

              <v-icon v-if="state.value !== 'archived'" :color="transitionColour(state.value)">mdi-arrow-right</v-icon>
            </template>
          </v-col>
        </v-row>

        <v-row v-if="canMassUpdateMessages">
          <v-col cols="12"><v-divider /></v-col>

          <v-col cols="12">
            <v-switch v-model="massUpdateMessages" :label="massUpdateText" />
          </v-col>

          <v-col v-if="massUpdateMessages" cols="12">
            <v-expansion-panels :elevation="0">
              <v-expansion-panel
                color="primary"
                :title="`${messagesToUpdate.length} additional message${messagesToUpdate.length > 1 ? 's' : ''} selected for update`"
              >
                <v-expansion-panel-text>
                  <v-data-table
                    v-model="messagesToUpdate"
                    hide-default-header
                    hide-default-footer
                    items-per-page="-1"
                    select-strategy="all"
                    :items="messages"
                    :headers="messageHeaders"
                    :loading="fetchingMessages"
                  >
                    <template #item.id="{ item }">
                      <p>
                        {{ item.id }}

                        <template v-if="Object.values(item.facets).every((value) => !value)">
                          <v-chip text="No facets to preview" color="grey" />
                        </template>

                        <template v-for="([key, value], index) in Object.entries(item.facets)" v-else :key="index">
                          <v-chip
                            v-if="value"
                            class="mx-2"
                            color="primary"
                            append-icon="mdi-eye"
                            :text="key"
                            @click="openMessagePreview(item, key)"
                          />
                        </template>
                      </p>
                    </template>

                    <template #item.actions="{ internalItem, toggleSelect, isSelected }">
                      <v-checkbox
                        :model-value="isSelected(internalItem)"
                        @update:model-value="toggleSelect(internalItem)"
                      />
                    </template>
                  </v-data-table>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn :text="nextState ? 'Cancel' : 'Close'" @click="isOpen = false" />

        <v-btn
          v-if="nextState"
          color="primary"
          :loading="isSaving"
          :disabled="fetchingMessages"
          :text="`Change state to ${nextState}`"
          @click="save()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>

  <MessagePreview ref="messagePreviewRef" />
</template>

<script lang="ts">
  import { find } from 'lodash-es'

  import { Component, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { insightStates } from '#views/insights/constants'

  import { AppStore, InsightsStore } from '#stores'

  import { Content, Insight, Message, Preview } from '#types'

  @Component({})
  class ChangeStateDialog extends Vue {
    public type = ''
    public title = ''
    public databasePath = ''

    public isOpen = false
    public isSaving = false
    public fetchingMessages = false
    public massUpdateMessages = false

    public insightStates = insightStates

    public originalItem: Insight | Content | Message | null = null

    public messages: Message[] = []
    public messagesToUpdate: string[] = [] // format insightId:messageId
    public messageHeaders = [{ key: 'id' }, { key: 'actions', width: 70, sortable: false }]

    private appStore = new AppStore()
    private insightsStore = new InsightsStore()

    @Ref() private readonly messagePreviewRef: Preview | null = null

    public get nextState() {
      switch (this.originalItem?.state) {
        case 'draft':
          return 'experimental'

        case 'experimental':
          return 'production'

        case 'production':
          return 'archived'

        case 'archived':
          return null

        default:
          return 'draft'
      }
    }

    public get canMassUpdateMessages() {
      const isValidPath = ['/insights', '/tipthemes', '/slideshows'].find((validPath) =>
        this.databasePath.startsWith(validPath),
      )

      return isValidPath && this.nextState && this.messages.length && !this.fetchingMessages
    }

    public get massUpdateText() {
      const originalState = this.originalItem?.state || 'draft'

      if (this.isMessage) {
        return `Update state for other ${this.originalItem?.id.split(':')[0].toUpperCase()} messages from ${originalState} to ${this.nextState}`
      } else {
        return `Update state for rest of ${this.originalItem?.id.toUpperCase()} messages from ${originalState} to ${this.nextState}`
      }
    }

    private get isMessage() {
      return this.databasePath.split('/').includes('messages')
    }

    private get updateData() {
      const hasGenerated = this.originalItem !== null && 'generated' in this.originalItem

      return {
        author: {
          ...this.appStore.author,
          comment: `State changed to ${this.nextState}.`,
        },
        state: this.nextState,
        ...(hasGenerated ? { generated: (this.originalItem as Message).generated } : {}),
      } as Partial<Insight>
    }

    @Watch('massUpdateMessages')
    public async massUpdateMessagesChanged() {
      this.messagesToUpdate = this.massUpdateMessages ? this.messages.map((message) => message.id) : []
    }

    public async open(originalItem: Insight | Content | Message, databasePath: string, title: string) {
      this.isOpen = true

      this.title = title
      this.databasePath = databasePath
      this.originalItem = originalItem

      this.messagesToUpdate = []
      this.massUpdateMessages = false
      this.messages = await this.fetchMessages()
    }

    public async save() {
      this.isSaving = true

      await this.insightsStore.updateInsightByPath(this.databasePath, this.updateData)

      if (!this.isMessage) {
        await this.insightsStore.updateMessagesByPath(`${this.databasePath}/messages`)
      }

      if (this.massUpdateMessages) {
        this.messagesToUpdate.forEach(async (messageId) => {
          // Make sure path only references to specific insights in case the originalItem is a message.
          const inisightPath = this.databasePath.split('/messages')[0]

          await this.insightsStore.updateInsightByPath(`${inisightPath}/messages/${messageId}`, this.updateData)
        })
      }

      this.isSaving = false
      this.isOpen = false
    }

    public isNextOrCurrentState(state: string) {
      return this.originalItem?.state === state || this.nextState === state
    }

    public transitionColour(state: string) {
      if (!this.nextState) {
        return 'black'
      }

      const nextColor = find(insightStates, (state) => state.value === this.nextState)?.color
      if (!nextColor) {
        return 'black'
      }

      return this.originalItem?.state === state ? nextColor : 'grey-lighten-2'
    }

    public openMessagePreview(message: Message, tab: string) {
      this.messagePreviewRef?.open(message, tab, 'messages')
    }

    private async fetchMessages() {
      this.fetchingMessages = true

      const [_, type, id] = this.databasePath.split('/')
      const messages = await this.insightsStore.getMessages(type, id)

      this.fetchingMessages = false

      return messages.filter(
        (message) => message.state === this.originalItem?.state && message.id !== this.originalItem?.id,
      )
    }
  }

  export default toNative(ChangeStateDialog)
</script>
