<template>
  <div class="pa-2 body-2">
    {{ component.contents.text }}
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentParagraph } from '#types'

  @Component({})
  class ParagraphPreview extends Vue {
    @Prop() public component!: UIComponentParagraph
  }

  export default toNative(ParagraphPreview)
</script>
