<template>
  <v-card style="min-height: 600px; max-height: 600px">
    <v-card-title>{{ title }}</v-card-title>

    <v-divider />

    <v-card-text>
      <v-row align="center" class="mt-0 mb-n6">
        <div v-if="loading" class="d-flex flex-wrap flex-column mt-3" style="width: 100%">
          <v-skeleton-loader type="text, text, text, text, text, text, text, text, text, text" />
        </div>

        <template v-else>
          <v-col v-for="content in audios" :key="content.id" cols="12" @click="selected = content">
            <AudioObject
              :slug="getMediaFile(content).slug"
              :content="content"
              :category="category"
              :preview="selected === content"
              :class="selected === content ? 'blue lighten-5' : ''"
            />
          </v-col>
        </template>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />

      <v-btn text="Cancel" @click="cancel()" />
      <v-btn text="Select" color="primary" :disabled="loading || !selected" @click="select(selected)" />
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { sortBy } from 'lodash-es'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { ContentsStore, MediaStore } from '#stores'

  @Component({})
  class MediaGallery extends Vue {
    @Prop() public open!: boolean
    @Prop() public title!: string
    @Prop() public category!: string

    @Prop({ default: ['audios'] }) public types!: string[]

    @Emit('cancel')
    public cancel() {
      return null
    }

    @Emit('select')
    public select(data: any) {
      return data
    }

    public selected = null

    private mediaStore = new MediaStore()
    private contentsStore = new ContentsStore()

    public get files() {
      return (
        sortBy(
          this.types.flatMap((type) =>
            this.mediaStore[
              `${type.slice(0, type.length - 1)}Files` as 'imageFiles' | 'audioFiles' | 'videoFiles'
            ].filter((i: any) => !this.category || (this.category && i.slug.startsWith(this.category))),
          ),
          'slug',
        ) || []
      )
    }

    public get audios() {
      return this.contentsStore.audios
    }

    public get loading() {
      return this.mediaStore.listing || this.contentsStore.loading
    }

    public mounted() {
      this.mediaStore.listMediaFiles({
        types: this.types,
      })

      this.contentsStore.subscribeToContents()
    }

    public beforeUnmount() {
      this.contentsStore.unsubscribeFromContents()
    }

    public getMediaFile(content: any) {
      return this.files.find((f) => f.slug === content.media)
    }
  }

  export default toNative(MediaGallery)
</script>

<style lang="scss" scoped>
  :deep(.v-skeleton-loader__text) {
    height: 48px !important;
  }
</style>
