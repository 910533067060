import { Value_FixedPoint_Sign } from '@jouzen/control-api/parameter'

export const featureStates: any[] = [
  { title: 'Proposed', value: 'proposed', color: '#e0e0e0', textColor: 'black' },
  { title: 'Published', value: 'published', color: 'green', textColor: 'white' },
  { title: 'deprecated', value: 'deprecated', color: 'red', textColor: 'white' },
]

export const timeOptions: any = [
  { title: 'My time', value: 'local' },
  { title: 'UTC time', value: 'utc' },
  { title: 'Detroit time', value: 'detroit' },
  { title: 'Helsinki time', value: 'helsinki' },
  { title: 'Relative times', value: 'relative' },
]

export const launchStrategies: any[] = [
  { value: 'default', title: 'Rollout Android and iOS together' },
  { value: 'advanced', title: 'Rollout Android and iOS separately' },
]

export const stageTooltips: any = {
  FINISHED: 'This step has already completed',
  CURRENT: 'This is the currently active step',
  FUTURE: 'This step is scheduled for future',
}

export const stageColors: any = {
  FUTURE: 'blue',
  CURRENT: 'green',
  COMPLETED: 'black',
}

export const stageStatuses: any = {
  FUTURE: 'Scheduled for future',
  CURRENT: 'Currently active',
  COMPLETED: 'Completed / inactive',
}

export const statusColors: any = {
  ACTIVE: 'green',
  PAUSED: 'orange',
  ENABLED: 'green',
  DISABLED: 'red',
  INACTIVE: 'red',
  SCHEDULED: 'blue',
  FINALIZED: 'black',
}

export const statusIcons: any = {
  ACTIVE: 'mdi-check',
  PAUSED: 'mdi-pause',
  ENABLED: 'mdi-check',
  INACTIVE: 'mdi-cancel',
  DISABLED: 'mdi-close-outline',
  PREPARED: 'mdi-rocket-outline',
  SCHEDULED: 'mdi-clock-outline',
  FINALIZED: 'mdi-check-outline',
}

export const progressColors: any = {
  ACTIVE: 'green',
  PAUSED: 'orange',
  ENABLED: 'green',
  DISABLED: 'red',
  INACTIVE: 'grey',
  SCHEDULED: 'blue',
  FINALIZED: 'green',
}

export const cloudEnvs: any = {
  'sandbox': 'test',
  'staging': 'stage',
  'release': 'prod',
  'ouranians': 'prod',
  'experimental': 'stage',
  'release-ios': 'prod',
  'release-android': 'prod',
}

export const rolloutStageIcons: any = {
  COMPLETED: 'mdi-check',
  CURRENT: 'mdi-arrow-right-bold',
  FUTURE: 'mdi-clock-outline',
}

export const rolloutStateColors: any = {
  COMPLETED: 'grey',
  CURRENT: 'black',
  FUTURE: 'blue',
}

export const rolloutsHeaders: any = [
  { key: 'data-table-expand' },
  { title: 'Name / description', key: 'name', width: '30%', sortable: false, headerProps: { class: 'text-no-wrap' } },
  {
    title: 'Other / included flags',
    key: 'features',
    width: '25%',
    sortable: false,
    headerProps: { class: 'text-no-wrap' },
  },
  {
    title: 'Type / current status',
    key: 'type',
    width: '25%',
    sortable: false,
    headerProps: { class: 'text-no-wrap' },
  },
  { title: 'Rollout state', key: 'status', width: '15%', sortable: false, headerProps: { class: 'text-no-wrap' } },
  {
    title: 'Priority',
    key: 'priority',
    width: '10%',
    align: 'end',
    sortable: false,
    headerProps: { class: 'text-no-wrap' },
  },
]

export const accountsHeaders: any = [
  { key: 'icon', width: '40px' },
  { title: "Include / exclude individual uuid's", key: 'userUid', width: '30%' },
  { title: 'Targeting type', key: 'state', width: '25%' },
  { title: 'Added by / from', key: 'author', width: '20%' },
  { title: 'Added / last edited', key: 'createdAt', align: 'end' },
]

export const rolloutStagesHeaders: any = [
  { key: 'icon', width: '40px', sortable: false },
  { title: 'Rollout steps / progress', key: 'step', width: '30%', sortable: false },
  { title: 'Step state', key: 'status', width: '25%', sortable: false },
  { title: 'User count', key: 'count', width: '20%', sortable: false },
  { title: 'Started / Starting', key: 'started', align: 'end', sortable: false },
]

export const rolloutGroupsHeaders: any = [
  { key: 'icon', width: '40px' },
  { title: 'Group targeting percentile', key: 'step', width: '30%' },
  { title: 'Status', key: 'status', width: '25%' },
  { title: 'User count', key: 'count', width: '20%' },
  { title: 'Started / Starting', key: 'started', align: 'end' },
]

export const parametersHeaders: any = [
  { key: 'icon', width: '40px' },
  { title: 'Feature flag configuration', key: 'description', width: '30%' },
  { title: 'Parameter name', key: 'name', width: '25%' },
  { title: 'Default value', key: 'default', width: '20%' },
  { title: 'Parameter value', key: 'override', align: 'end' },
]

export const featuresHeaders: any = [
  {
    key: 'data-table-expand',
  },
  {
    key: 'metadata.name',
    title: 'Name',
  },
  {
    key: 'project',
    title: 'Project',
  },
  {
    key: 'parameters',
    title: 'Parameters',
  },
  {
    key: 'metadata',
    title: 'Enabled for',
    align: 'end',
  },
  {
    key: 'metadata.changeRecord',
    title: 'Last edited',
    align: 'end',
    sortRaw: (a: any, b: any) => {
      return (
        new Date(b.metadata.changeRecord?.lastModifiedAt || b.metadata.changeRecord?.createdAt).getTime() -
        new Date(a.metadata.changeRecord?.lastModifiedAt || a.metadata.createdAt).getTime()
      )
    },
  },
  /* {
    key: 'featureState',
    title: 'State',
    class: 'text-primary pr-8',
    align: 'end',
  }, */
]

export const parameterTypes = [
  {
    title: 'string',
    value: 'string',
    type: {
      oneOf: {
        $case: 'string',
        string: {
          defaultValue: '',
        },
      },
    },
  },
  {
    title: 'integer',
    value: 'integer',
    type: {
      oneOf: {
        $case: 'integer',
        integer: {
          defaultValue: 0,
        },
      },
    },
  },
  {
    title: 'boolean',
    value: 'boolean',
    type: {
      oneOf: {
        $case: 'boolean',
        boolean: {
          defaultValue: false,
        },
      },
    },
  },
  {
    title: 'fraction',
    value: 'fixedPoint',
    type: {
      oneOf: {
        $case: 'fixedPoint',
        fixedPoint: {
          defaultValue: {
            sign: Value_FixedPoint_Sign.POSITIVE,
            integerPart: 0,
            millionthPart: 0,
          },
        },
      },
    },
  },
  {
    title: 'duration',
    value: 'duration',
    type: {
      oneOf: {
        $case: 'duration',
        duration: {
          defaultValue: {
            nanos: 0,
            seconds: 0,
          },
        },
      },
    },
  },
  {
    title: 'enum',
    value: 'enum',
    type: {
      oneOf: {
        $case: 'enum',
        enum: {
          defaultValue: '',
          values: [],
        },
      },
    },
  },
]

export const rolloutTypes = [
  { value: 'percentage-sandbox', title: 'Percentage - Sandbox apps' },
  { value: 'percentage-staging', title: 'Percentage - Staging app' },
  { value: 'percentage-ouranians', title: 'Percentage - Ouranians only' },
  { value: 'percentage-experimental', title: 'Percentage - Experimental app' },
  { value: 'percentage-release', title: 'Percentage - Release app (All)' },
  { value: 'percentage-release-ios', title: 'Percentage - Release app (iOS)' },
  { value: 'percentage-release-android', title: 'Percentage - Release app (Android)' },
  { value: 'percentage-advanced', title: 'Percentage - Advanced / custom' },
  { value: 'experiment-ab-testing', title: 'Experiment - Basic A/B testing' },
  { value: 'experiment-mv-testing', title: 'Experiment - Multivariate testing' },
]

export const calendarViewTypes: string[] = ['month', 'week']
