<template>
  <div
    v-if="!!editedInsight"
    class="d-flex flex-column flex-grow-1 flex-shrink-1 fill-height"
    :class="hasChanges ? 'dirty' : ''"
  >
    <v-card class="mt-4">
      <v-card-title>Condition</v-card-title>

      <v-card-text>
        <v-alert
          v-if="changedRules.length"
          type="info"
          text="Some rules have condition changes made after insight is updated."
        />

        <v-row :class="!inMessaging ? 'hidden' : 'visible'">
          <v-col>
            <v-autocomplete
              v-if="rules"
              v-model="editedInsight.rules"
              :items="rules"
              :readonly="!isInsightsEditor"
              :return-object="false"
              :disabled="!rules"
              item-title="title"
              item-value="id"
              hide-selected
              append-icon="mdi-square-edit-outline"
              label="Condition rules"
              multiple
              no-data-text="All existing rules already applied"
              class="conditions"
              @click:append="$refs.editRuleDialog?.open()"
            >
              <template #item="{ item }">
                <p
                  style="cursor: pointer"
                  :class="'rounded-lg ma-3 px-4 py-2 bg-' + item.raw.color + '-lighten-3'"
                  @click="editedInsight.rules.push(item.raw.id)"
                >
                  {{ item.title }}
                </p>
              </template>

              <template #prepend-inner>
                <v-chip v-if="editedInsight.rule && !editedInsight.rules.length" label class="my-2">
                  Insight specific rule
                </v-chip>
              </template>

              <template #selection="{ item, index }">
                <!-- TODO: Change to chip when the coloring works correctly -->

                <div :class="'rounded-lg d-flex pa-1 bg-' + item.raw.color + '-lighten-3'">
                  <p>
                    {{ item.title }}
                    <span v-if="changedRules.includes(item.raw.id)" class="ml-1">*</span>
                  </p>

                  <v-icon
                    icon="mdi-history"
                    style="cursor: pointer; font-size: 18px"
                    class="align-self-center ml-2"
                    @click.stop="showRuleHistoryDialog(item.raw.id)"
                  />

                  <v-icon
                    icon="mdi-trash-can-outline"
                    style="cursor: pointer; font-size: 18px"
                    class="align-self-center ml-2"
                    @click="removeInsightFormula(item.value)"
                  />
                </div>

                <p
                  v-if="editedInsight.rule || (editedInsight.rules && index !== editedInsight.rules.length - 1)"
                  class="mx-2"
                >
                  AND
                </p>

                <v-chip
                  v-if="editedInsight.rule && editedInsight.rules && index === editedInsight.rules.length - 1"
                  label
                  text="Insight specific rule"
                />
              </template>
            </v-autocomplete>

            <Statement
              v-if="editedInsight && !inWorkspace"
              v-model="editedInsight.rule"
              rows="4"
              simplify
              :readonly="!isInsightsEditor"
              label="Insight specific rule"
              @update-save-changes="validContent = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-8">
      <v-card-title>Generation</v-card-title>

      <v-card-text>
        <v-row :class="!inMessaging ? 'hidden' : 'visible'">
          <v-col cols="12">
            <Statement
              v-if="!inWorkspace"
              v-model="editedInsight.priority"
              label="Priority"
              type="number"
              required
              @update-save-changes="validContent = $event"
            />
          </v-col>
        </v-row>

        <v-row :class="!inMessaging ? 'hidden' : 'visible'">
          <v-col cols="4">
            <v-select
              :model-value="editedInsight.group_id || null"
              :items="insightGroups"
              :readonly="!isInsightsEditor"
              persistent-placeholder
              placeholder="No group identifier set"
              clearable
              label="Group identifier"
              @update:model-value="editedInsight.group_id = $event || ''"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model.number="editedInsight.minimum_interval"
              :readonly="!isInsightsEditor"
              label="Minimum interval"
              type="number"
              :rules="[minimumIntervalValidationRule]"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-if="!inWorkspace"
              v-model.number="editedInsight.minimum_category_interval"
              :readonly="!isInsightsEditor"
              label="Minimum category interval"
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="inMessaging" class="mt-8">
      <v-card-title>Relations</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-autocomplete
              v-model="editedInsight.tips"
              chips
              multiple
              hide-selected
              closable-chips
              persistent-placeholder
              label="Linked tips"
              placeholder="No tips linked to this insight"
              no-data-text="All tips are already selected"
              :items="tipthemes"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field v-model.number="editedInsight.relations_tips_limit" type="number" label="Limit" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-else-if="inTipThemes" class="mt-8">
      <v-card-title>Relations</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="editedInsightsLinkedToTip"
          chips
          multiple
          hide-selected
          closable-chips
          persistent-placeholder
          label="Linked insights"
          placeholder="No insights linked to this tip theme"
          no-data-text="All insights are already selected"
          :items="insights"
        />
      </v-card-text>
    </v-card>
  </div>

  <SaveChanges
    v-if="!!originalInsight"
    :original="{ insight: originalInsight, insightsLinkedToTip }"
    :author="author"
    :edited="edited"
    :loading="isLoading"
    :current="{ insight: editedInsight, insightsLinkedToTip: editedInsightsLinkedToTip }"
    :disabled="!validContent"
    :data-source="editedInsight"
    :error="errorsForSaveChanges"
    :deletable="originalInsight?.state === 'draft'"
    @save="saveInsightData()"
    @confirm-delete="confirmInsightDelete()"
    @open-history-dialog="$refs.historyDialog?.open(insightPath, insight.id || '')"
  >
    <InsightsBar :insight-id="insight.id" />
  </SaveChanges>

  <CommitDialog ref="commitDialog" @saved="saveLinkedInsights()" />
  <HistoryDialog ref="historyDialog" />

  <EditRuleDialog ref="editRuleDialog" :rules="rules" :insight="editedInsight" />
</template>

<script lang="ts">
  import { cloneDeep, isEqual, orderBy, sortBy, xor } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import {
    collection,
    doc,
    getDocs,
    getFirestore,
    query,
    serverTimestamp,
    setDoc,
    writeBatch,
  } from 'firebase/firestore'

  import { formulaColors } from '#views/formulas/constants'
  import { insightCategoriesThatAllowZeroInterval, insightGroups } from '#views/insights/constants'

  import { createDefaultInsight } from '#views/insights/utilities'

  import { AppStore, FormulasStore, InsightsStore, TipthemesStore } from '#stores'

  import { Author, Dialog, Insight } from '#types'

  @Component({})
  class InsightEditor extends Vue {
    @Prop() public insight!: Insight

    @Prop() public insightPath!: string
    @Prop() public changedRules!: string[]

    @Emit('changed')
    public emitChanged() {
      return this.hasChanges
    }

    public declare $refs: {
      commitDialog: Dialog
      historyDialog: Dialog
      editRuleDialog: Dialog
    }

    public isLoading = false
    public validContent = true

    public author: Author | null = null
    public edited: string | null = null

    public editedInsight: Insight | null = null
    public originalInsight: Insight | null = null

    public readonly insightGroups = insightGroups

    private rulesSessionOrder: string[] = []

    private readonly appStore = new AppStore()
    private readonly formulasStore = new FormulasStore()
    private readonly insightsStore = new InsightsStore()
    private readonly tipthemesStore = new TipthemesStore()

    public editedInsightsLinkedToTip: string[] = []

    public get rules() {
      return orderBy(
        this.formulasStore.allRules,
        [(o) => this.rulesSessionOrder.indexOf(o.id), (o) => formulaColors.indexOf(o.color), (o) => o.title],
        ['desc', 'asc', 'asc'],
      )
    }

    public get insights() {
      return this.insightsStore.allInsights.map((i) => ({ title: i.id.toUpperCase(), value: i.id }))
    }

    public get tipthemes() {
      return this.tipthemesStore.allInsights.map((i) => ({ title: i.id.toUpperCase(), value: i.id }))
    }

    public get hasChanges() {
      return this.insightDataHasChanges || this.linkedInsightsHaveChanges
    }

    public get inMessaging() {
      return this.appStore.inMessaging
    }

    public get inTipThemes() {
      return this.appStore.inTipthemes
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public get errorsForSaveChanges() {
      if (!this.editedInsight?.project) {
        return 'Project not set, changes can not be saved'
      } else {
        return null
      }
    }

    public get insightDataHasChanges() {
      return !isEqual(this.originalInsight, this.editedInsight)
    }

    public get linkedInsightsHaveChanges() {
      return !isEqual(this.insightsLinkedToTip, this.editedInsightsLinkedToTip)
    }

    @Watch('rules', { immediate: true })
    protected rulesChanged() {
      this.fetchChangedRulesHistory()
    }

    @Watch('insight', { immediate: true })
    protected insightChanged() {
      if (this.insight) {
        this.originalInsight = { ...createDefaultInsight(), ...this.insight }

        this.editedInsight = cloneDeep(this.originalInsight)

        this.fetchChangedRulesHistory()
      }
    }

    @Watch('insightsLinkedToTip', { immediate: true })
    protected insightsLinkedToTipChanged(prev: string[], next: string[]) {
      if (!isEqual(prev, next)) {
        this.editedInsightsLinkedToTip = [...this.insightsLinkedToTip]
      }
    }

    @Watch('hasChanges')
    protected hasChangesChanged() {
      this.emitChanged()
    }

    @Watch('originalInsight.rules')
    protected insightRulesChanged(val: any, oldVal: any) {
      const rule = xor(val, oldVal)[0] as string

      if (rule && val !== undefined && oldVal !== undefined) {
        this.rulesSessionOrder = this.rulesSessionOrder.filter((r) => r !== rule)

        this.rulesSessionOrder.push(rule)

        sessionStorage.OuraConditionRules = this.rulesSessionOrder.join(',')
      }
    }

    @Watch('insightPath', { immediate: true })
    protected insightPathChanged() {
      this.fetchAuthorAndEditedByInfo()

      this.rulesSessionOrder = (sessionStorage.OuraConditionRules || '').split(',')
    }

    public get insightsLinkedToTip() {
      return this.inTipThemes
        ? this.insightsStore.allInsights
            .filter((i) => {
              return i.tips?.includes(this.insight?.id)
            })
            .map((i) => i?.id)
        : []
    }

    public saveInsightData() {
      if (this.inWorkspace) {
        this.saveForWorkspace()
      } else {
        const previewPrev = { ...this.originalInsight, relations: this.insightsLinkedToTip }
        const previewNext = { ...this.editedInsight, relations: this.editedInsightsLinkedToTip }

        this.$refs.commitDialog?.open(this.insight.id, this.insightPath, this.editedInsight, previewPrev, previewNext)
      }
    }

    public async saveForWorkspace() {
      this.isLoading = true

      await this.saveLinkedInsights()

      const data = { ...this.editedInsight, author: this.appStore.author, updatedAt: serverTimestamp() }

      await setDoc(doc(getFirestore(), this.insightPath), data)

      this.isLoading = false
    }

    public async saveLinkedInsights() {
      if (this.linkedInsightsHaveChanges) {
        const newLinkedInsights = this.editedInsightsLinkedToTip.filter((i) => !this.insightsLinkedToTip.includes(i))
        const removedLinkedInsights = this.insightsLinkedToTip.filter(
          (i) => !this.editedInsightsLinkedToTip.includes(i),
        )

        const changedInsights = [
          ...newLinkedInsights.map((insightId) => ({ insightId, isAdd: true })),
          ...removedLinkedInsights.map((insightId) => ({ insightId, isAdd: false })),
        ]

        for (const { insightId, isAdd } of changedInsights) {
          const insight = this.insightsStore.allInsights.find((i) => i.id === insightId)

          let newTips = [...(insight?.tips || [])]

          if (isAdd) {
            newTips.push(this.insight.id)
          } else {
            newTips = newTips.filter((tip) => tip !== this.insight.id)
          }

          const changedInsight = { ...insight, tips: newTips }
          const author = { ...this.appStore.author, comment: 'Updated linked tips' }

          const data = { ...changedInsight, author, updatedAt: serverTimestamp() }

          if (insight?.id) {
            await setDoc(doc(getFirestore(), `/insights/${insight.id}`), data)
          }
        }
      }
    }

    public confirmInsightDelete() {
      this.$confirm('Are you sure you want to delete this insight?', this.insight?.id.toUpperCase(), {
        buttonTrueColor: 'error',
      }).then(async (confirmed) => {
        if (confirmed) {
          const db = getFirestore()
          const batch = writeBatch(db)
          const pathArr = this.insightPath.split('/')
          const id = pathArr.slice(-1)[0]
          const path = pathArr.join('/').replace(`/${id}`, '')

          const history = await getDocs(query(collection(db, `${this.insightPath}/history`)))
          const messages = await getDocs(query(collection(db, `${this.insightPath}/messages`)))
          const notes = await getDocs(query(collection(db, `${this.insightPath}/notes`)))

          batch.delete(doc(db, `${path}`, id))

          history.docs.forEach((d) => {
            batch.delete(doc(db, `${this.insightPath}/history`, d.id))
          })

          messages.docs.forEach((d) => {
            batch.delete(doc(db, `${this.insightPath}/messages`, d.id))
          })

          notes.docs.forEach((d) => {
            batch.delete(doc(db, `${this.insightPath}/notes`, d.id))
          })

          batch.delete(doc(db, `${path.replace('insights', 'reviews')}`, this.insight!.id))
          batch.delete(doc(db, `${path.replace('insights', 'notifications')}`, this.insight!.id))

          await batch.commit()

          this.$router.go(-1)
        }
      })
    }

    public removeInsightFormula(ruleId: string) {
      this.editedInsight!.rules = this.editedInsight?.rules?.filter((r) => r !== ruleId) || []
    }

    public showRuleHistoryDialog(id: string) {
      this.$refs.historyDialog.open(`rules/${id}`, id)
    }

    public minimumIntervalValidationRule(interval: number) {
      const zeroIntervalCategories = insightCategoriesThatAllowZeroInterval.map((category) => category.value)

      const limit = zeroIntervalCategories.includes(this.editedInsight?.category || '') ? 0 : 1

      return interval >= limit ? true : `Interval must be greater than or equal to ${limit}`
    }

    private async fetchChangedRulesHistory() {
      if (this.rules) {
        for (const rule of this.rules) {
          if (this.originalInsight?.rules?.includes(rule.id)) {
            if (
              this.originalInsight.updatedAt &&
              rule.createdAt &&
              +this.originalInsight.updatedAt.toDate() > +rule.createdAt.toDate()
            ) {
              const history = await getDocs(query(collection(getFirestore(), `rules/${rule.id}/history`)))
              history.docs.forEach((event: any) => {
                let data = event.data()

                if (
                  this.originalInsight?.updatedAt &&
                  +data.timestamp.toDate() > +this.originalInsight.updatedAt.toDate()
                ) {
                  if (data.after.condition != data.before.condition) {
                    this.changedRules.push(data.after.id)
                  }
                }
              })
            }
          }
        }
      }
    }

    private async fetchAuthorAndEditedByInfo() {
      const dbCollection = await getDocs(query(collection(getFirestore(), `${this.insightPath}/history`)))

      const docs = sortBy(
        dbCollection.docs.map((d) => d.data()),
        'timestamp',
      ).reverse()

      if (docs[0]) {
        this.author = docs[0].after.author
        this.edited = docs[0].timestamp.toDate()
      }
    }
  }

  export default toNative(InsightEditor)
</script>
