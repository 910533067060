<template>
  <v-text-field v-model="data.mnemonic" class="mb-4" label="Mnemonic" />
  <v-row dense>
    <v-col>
      <v-text-field v-model.number="data.select_min" type="number" label="Select min" />
    </v-col>
    <v-col>
      <v-text-field v-model.number="data.select_max" type="number" label="Select max" />
    </v-col>
  </v-row>
  <v-divider class="mb-4" />
  <draggable v-model="data.options">
    <transition-group>
      <QuestionnaireOption
        v-for="(option, index) in data.options"
        :key="option.id"
        :data="option"
        :index="index"
        @delete="deleteOption(index)"
      />
    </transition-group>
  </draggable>
  <div class="d-flex">
    <v-spacer />
    <v-btn title="Add option" @click="addOption()" />
  </div>
</template>

<script lang="ts">
  import { VueDraggableNext } from 'vue-draggable-next'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { createDefaultTranslatable } from '#views/messages/utilities'

  import { UIComponentQuestionnaire } from '#types'

  import { prefixedId } from '#utilities'

  @Component({
    components: {
      draggable: VueDraggableNext,
    },
    inheritAttrs: false,
  })
  class Questionnaire extends Vue {
    @Prop() public data!: UIComponentQuestionnaire

    public addOption() {
      this.data.options.push({
        id: prefixedId(this.data.id),
        mnemonic: '',
        name: createDefaultTranslatable(this.data.id, 'questionnaire option title'),
      })
    }

    public deleteOption(index: number) {
      this.data.options.splice(index, 1)
    }
  }

  export default toNative(Questionnaire)
</script>
