<template>
  <div class="px-2 pb-4">
    <v-img max-width="320" :src="src" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { MediaStore } from '#stores'

  import { UIComponentPicture } from '#types'

  @Component({})
  class PicturePreview extends Vue {
    @Prop() public component!: UIComponentPicture

    public src = '/images/placeholder.png'

    private mediaStore = new MediaStore()

    public created() {
      this.getImageSrc()
    }

    public async getImageSrc() {
      if (this.component.image) {
        const response = await this.mediaStore.getMediaInfo({
          slug: this.component.image,
          type: 'image',
          fetch: true,
        })

        this.src = response?.data?.file || ''
      }
    }
  }

  export default toNative(PicturePreview)
</script>
