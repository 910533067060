import { v4 as uuid } from 'uuid'

import { Formula } from '#types'

export function createDefaultFormula(project?: string): Formula {
  return {
    id: uuid(),

    title: '',
    notes: '',
    color: 'blue',

    condition: '',
    description: '',

    project: project || '',
  }
}
