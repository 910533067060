<template>
  <div>
    <AttachedMessages :template="template" />

    <AttachedComponents :template="template" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Template } from '#types'

  @Component({})
  class TemplateStatistics extends Vue {
    @Prop() public template!: Template
  }

  export default toNative(TemplateStatistics)
</script>
