<template>
  <div>
    <Translatable :data="data.text">
      <v-text-field v-model="data.text.text" spellcheck="true" lang="en" label="Text" />
    </Translatable>
    <v-select v-model="data.category" :items="categoryItems" label="Category" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { scoreCategoryItems } from '#views/messages/constants'

  import { UIComponentScore } from '#types'

  @Component({})
  class Score extends Vue {
    @Prop() public data!: UIComponentScore

    public get categoryItems() {
      return scoreCategoryItems
    }
  }

  export default toNative(Score)
</script>
