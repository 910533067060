import { Pinia, Store } from 'pinia-class-component'

import { Unsubscribe, collection, doc, getFirestore, onSnapshot, query, setDoc } from 'firebase/firestore'

let unsubscribeSettings: Unsubscribe | undefined = undefined

@Store
export class SettingsStore extends Pinia {
  public loading = true

  private openAiSettingKey = 'openai'

  public allSettings: any[] = []

  public get getOpenAiSettings() {
    return this.allSettings.find((s) => s.id === this.openAiSettingKey)
  }

  public async updateOpenAiSettings(openAiSettings: any) {
    return await setDoc(doc(getFirestore(), `/settings/${this.openAiSettingKey}`), openAiSettings, { merge: true })
  }

  public async subscribeToSettings() {
    if (!unsubscribeSettings) {
      this.loading = true

      unsubscribeSettings = onSnapshot(query(collection(getFirestore(), '/settings')), (snap) => {
        this.allSettings = snap.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        this.loading = false
      })
    }
  }
  public async unsubscribeFromSettings() {
    if (unsubscribeSettings) {
      unsubscribeSettings()

      unsubscribeSettings = undefined
    }
  }
}
