<template>
  <v-dialog v-model="isOpen" max-width="1000px" scrollable>
    <v-card>
      <v-card-title class="headline">{{ 'Weight settings' }}</v-card-title>

      <v-spacer />

      <v-card-text>
        <v-switch v-model="toggleWeightsEnabled" label="Enable custom weights" color="primary" hide-details />
      </v-card-text>

      <v-card-text>
        <v-data-table
          v-model="selected"
          :items="editedWeights"
          :headers="weightHeaders"
          hide-default-footer
          scrollable
          disable-pagination
          class="elevation-5"
        >
          <template #item.new_value="{ item }">
            <input
              v-model="item.new_value"
              type="text"
              step="0.01"
              min="0.01"
              max="1.00"
              style="color: orange; background-color: lightyellow"
              name="new value"
              :placeholder="item.value.toString()"
            />
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="saveToLocalStorage()">Save values locally</v-btn>
        <v-btn color="red" @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { priorityWeights, weightDialogHeaders } from '#views/priorities/constants'

  import { Weight } from '#types'

  @Component({})
  class WeightsDialog extends Vue {
    @Prop() public storedWeights!: Weight[]
    @Prop() public storedEnableWeightsToggle!: boolean

    @Emit('updateSavedWeights')
    public updateSavedWeights(weights: Weight[]) {
      return weights
    }
    @Emit('toggleEnableWeights')
    public toggleEnableWeights(toggleWeightsEnabled: boolean) {
      return toggleWeightsEnabled
    }

    public isOpen = false
    public selected: any[] = []
    public weightHeaders = weightDialogHeaders
    // update these when selected
    public defaultPriorityWeights: Weight[] = priorityWeights
    public editedWeights: Weight[] = this.getStoredWeights()
    public toggleWeightsEnabled = this.getStoredWeightToggleValue()

    @Watch('toggleWeightsEnabled')
    public ToggleWeightsEnabledChanged() {
      localStorage.setItem('UserDefinedWeightToggle', JSON.stringify(this.toggleWeightsEnabled))
      this.toggleEnableWeights(this.toggleWeightsEnabled)
    }

    public open() {
      this.isOpen = true
    }

    public closeDialog() {
      this.updateSavedWeights(this.editedWeights)
      this.toggleEnableWeights(this.toggleWeightsEnabled)
      this.isOpen = false
    }

    public getStoredWeights(): Weight[] {
      return this.storedWeights
    }

    public getStoredWeightToggleValue(): boolean {
      return this.storedEnableWeightsToggle
    }

    public saveToLocalStorage() {
      localStorage.setItem('UserDefinedWeights', JSON.stringify(this.editedWeights))
      this.closeDialog()
    }
  }

  export default toNative(WeightsDialog)
</script>

<style lang="scss" scoped>
  .message {
    width: 320px;
  }
</style>
