<template>
  <v-navigation-drawer
    v-if="!!path"
    disable-resize-watcher
    style="right: 0"
    order="0"
    width="450"
    location="end"
    :model-value="isOpen"
  >
    <div class="d-flex flex-column fill-height pr-3">
      <v-row class="flex-grow-0 py-4 px-8">
        <v-col class="flex-grow-1">
          <div class="text-h5 font-weight-light">{{ startCase(title) }} notes / comments</div>
          <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
            Since the creation of this {{ title }}
          </div>
        </v-col>

        <v-col class="flex-grow-0">
          <v-btn class="mt-n2 mr-n8" icon="mdi-close" variant="text" rounded="100%" @click="close()" />
        </v-col>
      </v-row>

      <v-divider />

      <div class="flex-grow-1 flex-shrink-1" style="overflow: auto">
        <NoteList class="mt-n4" :parent-path="path" />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { startCase } from 'lodash-es'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { AppStore } from '#stores'

  @Component({})
  class NotesPanel extends Vue {
    @Prop() public open!: boolean

    @Prop() public path!: string
    @Prop() public title!: string

    @Emit('close')
    public close() {
      if (this.appStore.navDrawer === 'notes') {
        this.appStore.closeNavDrawer()
      }

      return
    }

    public readonly startCase = startCase

    private readonly appStore = new AppStore()

    public get isOpen() {
      return this.open || this.appStore.navDrawer === 'notes'
    }
  }

  export default toNative(NotesPanel)
</script>
