<template>
  <v-dialog v-model="isOpen" max-width="1600px" scrollable>
    <v-card>
      <v-card-title class="headline">Reorder Messages</v-card-title>

      <v-card-text>
        <div class="my-6 text-pre-wrap">
          <draggable :model-value="messages" class="d-flex flex-row flex-wrap" @update:model-value="handleDrag($event)">
            <div v-for="(message, index) in messages" :key="message.id" class="d-flex flex-row align-center">
              <div class="mx-2" style="max-height: 480px; min-height: 320px; overflow: auto">
                <MessageItem
                  class="message pa-3"
                  :insight="insight"
                  :message="message"
                  :previous-order="getPreviousOrderNumber(message.id)"
                />
              </div>
              <v-btn
                v-if="index < messages.length - 1"
                icon="mdi-swap-horizontal"
                class="pa-2"
                @click="swapPlace(message.order, message.order + 1)"
              />
            </div>
          </draggable>
        </div>
        <div v-if="hasChanges">You have unsaved changes.</div>
      </v-card-text>

      <v-card-actions>
        <div class="caption">
          HINT: You can either drag and drop the item or use the swap button to change the order.
        </div>

        <v-spacer />
        <v-btn color="primary" text="Cancel" variant="plain" :disabled="saving" @click="onCancelClicked()" />
        <v-btn
          color="primary"
          text="Save"
          :loading="saving"
          :disabled="!hasChanges"
          variant="plain"
          @click="onOkClicked()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { difference, orderBy } from 'lodash-es'

  import { VueDraggableNext } from 'vue-draggable-next'

  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { doc, getFirestore, updateDoc } from 'firebase/firestore'

  import { Insight, Message } from '#types'

  @Component({
    components: {
      draggable: VueDraggableNext,
    },
  })
  class ReorderMessagesDialog extends Vue {
    public isOpen = false
    public saving = false

    public insight: Insight | null = null
    public messages: Message[] = []

    private originalMessages: Message[] = []
    private messageCollectionPath: string = ''

    public get hasChanges() {
      return difference(this.messages, this.originalMessages).length > 0
    }

    public open(insight: Insight, messages: Message[], collectionPath: string) {
      this.originalMessages = messages
      this.messages = messages
      this.insight = insight
      this.messageCollectionPath = collectionPath
      this.isOpen = true
    }

    public async onOkClicked() {
      this.saving = true

      for (const message of this.messages) {
        const originalMessage = this.originalMessages.find((originalMessage) => originalMessage.id === message.id)
        if (message.order !== originalMessage?.order) {
          await updateDoc(doc(getFirestore(), `${this.messageCollectionPath}/${message.id}`), { order: message.order })
        }
      }

      this.isOpen = false
      this.saving = false
    }

    public onCancelClicked() {
      this.isOpen = false
    }

    public getPreviousOrderNumber(id: string): number {
      return this.originalMessages?.find((message) => message.id === id)?.order || 0
    }

    public handleDrag(event: any) {
      this.messages = event.map((message: Message, index: number) => {
        return { ...message, order: index }
      })
    }

    public swapPlace(from: number, to: number) {
      this.messages = this.messages.map((message) => {
        if (message.order === from) {
          return { ...message, order: to }
        }

        if (message.order === to) {
          return { ...message, order: from }
        }
        return message
      })
      this.messages = orderBy(this.messages, ['order'], ['asc'])
    }
  }

  export default toNative(ReorderMessagesDialog)
</script>

<style lang="scss" scoped>
  .message {
    width: 320px;
  }
</style>
