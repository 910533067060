<template>
  <div>
    <v-icon color="white" size="x-large">mdi-clock-time-eleven</v-icon>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentSleepClock } from '#types'

  @Component({})
  class SleepClockPreview extends Vue {
    @Prop() public component!: UIComponentSleepClock
  }

  export default toNative(SleepClockPreview)
</script>
