<template>
  <v-dialog v-model="isOpen" max-width="1000px" scrollable>
    <v-card>
      <v-card-title class="headline">{{ 'Settings' }}</v-card-title>

      <v-spacer />

      <v-card-text>
        <v-switch v-model="toggleHacksEnabled" label="Ignore hacked priority values" color="primary" hide-details />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="saveToLocalStorage()">Save values locally</v-btn>
        <v-btn color="red" @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  @Component({})
  class SettingsDialog extends Vue {
    @Prop() public storedIgnoreHacks!: boolean
    @Emit('toggleIgnoreHacks')
    public toggleIgnoreHacks(ignoreHacks: boolean) {
      return ignoreHacks
    }

    public isOpen = false
    public toggleHacksEnabled = this.getStoredHackValue()

    @Watch('toggleHacksEnabled')
    public ToggleHacksEnabledChanged() {
      this.toggleIgnoreHacks(this.toggleHacksEnabled)
    }

    public open() {
      this.isOpen = true
    }

    public closeDialog() {
      this.toggleIgnoreHacks(this.toggleHacksEnabled)
      this.isOpen = false
    }

    public getStoredHackValue(): boolean {
      return this.storedIgnoreHacks
    }

    public saveToLocalStorage() {
      localStorage.setItem('UserDefinedIgnoreHacks', JSON.stringify(this.toggleHacksEnabled))
      this.closeDialog()
    }
  }

  export default toNative(SettingsDialog)
</script>

<style lang="scss" scoped>
  .message {
    width: 320px;
  }
</style>
