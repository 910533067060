<template>
  <v-app-bar v-if="editedTemplate">
    <v-btn icon="mdi-arrow-left" @click="$router.push('/templates')" />

    <v-app-bar-title class="text-button ml-2">{{ editedTemplate.name }}</v-app-bar-title>
  </v-app-bar>

  <v-container v-if="editedTemplate" class="flex-grow-1">
    <v-card>
      <Facets
        tab="templateSettings"
        :message="editedTemplate"
        :template="editedTemplate"
        :message-path="templatePath"
        :allowed-pages="editedTemplate.allowedPages"
        :allowed-facets="editedTemplate.allowedFacets"
        :allowed-components="editedTemplate.allowedComponents"
        :required-components="editedTemplate.requiredComponents"
        @components-changed="setTemplateComponents($event)"
        @update-save-changes="updateSaveChanges($event)"
      />
    </v-card>
  </v-container>

  <SaveChanges
    v-if="editedTemplate && originalTemplate"
    :author="author"
    :edited="edited"
    :loading="isLoading"
    :current="editedTemplate"
    :original="originalTemplate"
    :disabled="disableSaveChanges"
    :data-source="editedTemplate"
    @save="openCommitDialog()"
    @open-history-dialog="openHistoryDialog()"
  />

  <CommitDialog ref="commitDialogRef" />
  <HistoryDialog ref="historyDialogRef" />
</template>

<script lang="ts">
  import { cloneDeep, sortBy } from 'lodash-es'

  import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, deleteDoc, doc, getDocs, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { Author, Dialog, Template } from '#types'

  @Component({})
  class TemplateView extends Vue {
    @Prop() public templateId!: string
    @Prop() public templatePath!: string
    @Prop() public templatesPath!: string

    public isLoading = true

    public disableSaveChanges = false

    public author: Author | null = null
    public edited: string = '(No edit history)'

    public editedTemplate: Template | null = null
    public originalTemplate: Template | null = null

    private unsubscribeTemplate: Unsubscribe | null = null

    @Ref() private readonly commitDialogRef: Dialog | null = null
    @Ref() private readonly historyDialogRef: Dialog | null = null

    @Watch('templatePath', { immediate: true })
    protected async onTemplatePathChange() {
      if (this.templatePath) {
        this.isLoading = true

        this.editedTemplate = null
        this.originalTemplate = null

        this.getAuthor()

        if (this.unsubscribeTemplate) {
          this.unsubscribeTemplate()
        }

        this.unsubscribeTemplate = await onSnapshot(doc(getFirestore(), this.templatePath), (snap) => {
          const data = snap.data() as Template

          if (data) {
            this.originalTemplate = data
            this.editedTemplate = cloneDeep(data)
          }

          this.isLoading = false
        })
      }
    }

    public beforeUnmount() {
      if (this.unsubscribeTemplate) {
        this.unsubscribeTemplate()
        this.unsubscribeTemplate = null
      }
    }

    private async getAuthor() {
      const dbCollection = await getDocs(query(collection(getFirestore(), `${this.templatePath}/history`)))

      const docs = sortBy(
        dbCollection.docs.map((d) => d.data()),
        'timestamp',
      ).reverse()

      if (docs[0]) {
        this.author = docs[0].after.author
        this.edited = docs[0].timestamp.toDate()
      }
    }

    public async deleteTemplate() {
      this.$confirm('Confirm template delete?').then(async (confirmed) => {
        if (confirmed) {
          this.isLoading = true

          await deleteDoc(doc(getFirestore(), this.templatePath))

          this.isLoading = false
          this.$router.go(-1)
        }
      })
    }

    public openCommitDialog() {
      this.commitDialogRef!.open(this.templateId, this.templatePath, this.editedTemplate, this.originalTemplate)
    }

    public openHistoryDialog() {
      const templateName = this.templatePath.split('/').pop()!

      this.historyDialogRef!.open(this.templatePath, templateName)
    }

    public updateSaveChanges(success: boolean) {
      this.disableSaveChanges = !success
    }

    public setTemplateComponents(data: any) {
      if (data.id == 'allowedComponents' && this.editedTemplate?.requiredComponents) {
        this.editedTemplate.requiredComponents = this.editedTemplate.requiredComponents?.filter((c: string) =>
          data.selected.includes(c),
        )
      }

      if (
        this.editedTemplate &&
        ['id', 'name', 'requiredComponents', 'allowedComponents', 'author', 'facets'].includes(data.id)
      ) {
        this.editedTemplate[data.id as keyof Template] = data.selected
      }
    }
  }

  export default toNative(TemplateView)
</script>

<style lang="scss" scoped></style>
