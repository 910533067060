import { v4 as uuid } from 'uuid'

import { Pinia, Store } from 'pinia-class-component'

import { deleteDoc, doc, getDoc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'

import { AppStore } from '#stores'

@Store
export class SimulatorStore extends Pinia {
  public file: any = null
  public bookmark: any = null

  public simulation: any = {
    '2022-04-25': [
      'sleep_balance_good_score_good_once',
      'restfulness_bad_evenly_distributed_once_activity_balance_good',
    ],
  }

  public async setFile(file: any) {
    this.file = file
  }
  public async saveFile(file: any) {
    const appStore = new AppStore()

    file = file || this.file

    if (!file.id) {
      file.id = uuid()
    }

    if (!file.createdAt) {
      file.createdAt = serverTimestamp()
    }

    file.updatedAt = serverTimestamp()

    await setDoc(doc(getFirestore(), `/users/${appStore.uuid}/files/${file.id}`), file)

    if (file.public) {
      const publicFile = { ...file, owner: appStore.uuid }

      await setDoc(doc(getFirestore(), `/files/${file.id}`), publicFile)
    } else {
      const publicData = await getDoc(doc(getFirestore(), `/files/${file.id}`))

      if (publicData.exists()) {
        await deleteDoc(doc(getFirestore(), `/files/${file.id}`))
      }
    }

    this.file = file
  }

  public async setBookmark(bookmark: any) {
    this.bookmark = bookmark
  }
  public async saveBookmark(bookmark: any) {
    const appStore = new AppStore()

    const file = this.file

    bookmark = bookmark || this.bookmark

    if (!bookmark.id) {
      bookmark.id = uuid()
    }

    if (!bookmark.createdAt) {
      bookmark.createdAt = serverTimestamp()
    }

    bookmark.updatedAt = serverTimestamp()

    await setDoc(doc(getFirestore(), `/users/${appStore.uuid}/files/${file.id}/bookmarks/${bookmark.id}`), bookmark)

    if (file.public) {
      await setDoc(doc(getFirestore(), `/files/${file.id}/bookmarks/${bookmark.id}`), bookmark)
    }

    this.bookmark = bookmark
  }
}
