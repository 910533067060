import Long from 'long'

import { orderBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { HttpsCallableResult, getFunctions, httpsCallable } from 'firebase/functions'

import { ListObjectsRequest_DataReturn, ListObjectsRequest_Type } from '@jouzen/control-api/control_service'
import { TopLevel_ObjectType } from '@jouzen/control-api/metadata'
import { Rollout_State } from '@jouzen/control-api/rollout'

import { rolloutTypes } from '#views/features/constants'

import { AppStore } from '#stores'

import { AmplitudeStats, Feature, Override, OverrideEntry, Reference } from '#types'

import { nanoId } from '#utilities'

let unsubscribeFeatures: number | undefined = undefined

const callWithErrorHandling = async function (
  this: any,
  functionName: string,
  data: any,
  clearPreviousError: boolean = true,
): Promise<HttpsCallableResult<unknown> | null> {
  if (clearPreviousError) {
    this.errorMessage = null
  }

  let response: HttpsCallableResult | null = null

  const functions = getFunctions(getApp(), 'europe-west1')

  try {
    response = await httpsCallable(functions, functionName)(data)
  } catch (error) {
    console.error('Cloud function error', error)

    if (functionName.includes('modify')) {
      this.errorMessage =
        'Unexpected error while saving, UI might show wrong information, please contact Rollout Services squad.'
    } else {
      this.errorMessage =
        'Something went wrong while calling feature management backend, please contact Rollout Services squad.'
    }
  }

  return response || Promise.resolve(null)
}

/**
 * "Features" store definition.
 */
@Store
export class FeaturesStore extends Pinia {
  public loading = false

  public features: Feature[] = []

  public stats: AmplitudeStats = {
    series: [],
    seriesLabels: [],
    seriesCollapsed: [],
    xValues: [],
  }

  public errorMessage: string | null = null

  public async listFeatures(update?: boolean) {
    if (!update) {
      this.loading = true
    }

    const dataForAmplitude = {
      count: 30,
      period: 'day',
      segment: {
        event_type: '_active',
        group_by: [{ type: 'user', value: 'gp:enabledFeatureFlagsApp' }],
      },
    }

    const amplitudeResponse = await callWithErrorHandling.call(
      this,
      'fetchStatisticsFromAmplitude',
      dataForAmplitude,
      false,
    )

    if (amplitudeResponse?.data) {
      this.stats = amplitudeResponse.data as AmplitudeStats
    }

    const response: any = await callWithErrorHandling.call(
      this,
      'listObjectsFromControlBee',
      {
        type: ListObjectsRequest_Type.FEATURES,
        dataReturn: ListObjectsRequest_DataReturn.ALL_DATA,
      },
      false,
    )

    this.features = orderBy(response?.data?.features || [], (feature) => feature.metadata.changeRecord.createdAt, [
      'desc',
    ])

    // TODO: Migration remove once dones
    let count = 0,
      total = 0

    const experiments = await getDocs(collection(getFirestore(), '/experiments'))

    for (const f of this.features) {
      if (!f.metadata?.informative?.additionalData) {
        f.metadata!.informative!.additionalData = {}
      }

      for (const o of f.overrideList.flatMap((o) =>
        o.oneOf?.$case === 'override' ? [o.oneOf.override] : o.oneOf!.group.overrides,
      )) {
        total++

        if (o.metadata?.informative?.labels.template) {
          o.metadata!.informative.labels.template = o
            .metadata!.informative.labels.template.replace(/-test$/, '-testing')
            .replace('rollout-', 'percentage-')
            .replace('oldstage', 'experimental')
        } else {
          o.metadata!.informative!.labels.template = 'percentage-advanced'
        }

        if (
          !o.metadata?.informative?.labels.rollout &&
          o.metadata?.informative?.labels.target?.length &&
          o.metadata?.informative?.labels.target !== 'null'
        ) {
          o.metadata!.informative.labels.rollout = o.metadata!.informative!.labels.target
        }

        delete o.metadata?.informative?.labels.target // Deleted "null" and other rubbish data

        if (o.metadata?.informative?.labels?.experiment) {
          const e = experiments.docs.find((e) => e.id === o.metadata?.informative?.labels?.experiment)

          if (e && e.data()) {
            count++

            const experiment = e.data()

            const config = experiment.settings.find((s: any) => s.id === o.metadata?.informative?.labels?.configuration)

            if (!o.metadata?.informative.additionalData) {
              o.metadata!.informative.additionalData = {}
            }

            o.metadata!.informative.labels.template =
              experiment.metadata.type
                .replace(/-test$/, '-testing')
                .replace('rollout-', 'percentage-')
                .replace('oldstage', 'experimental') || o.metadata?.informative.labels.template

            if (experiment.settings.length === 1) {
              o.metadata!.informative.displayName = rolloutTypes
                .find((e) => e.value === o.metadata?.informative!.labels.template)
                ?.title?.split('-')[1]
            } else {
              o.metadata!.informative.displayName = config?.name || 'Unknown'
            }

            o.metadata!.informative.additionalData.updatedBy = experiment.metadata.labels.editor || 'Unknown'
            o.metadata!.informative.additionalData.createdBy = experiment.metadata.labels.creator || 'Unknown'

            o.metadata!.informative.additionalData.launchAt = experiment.metadata.launchAt?.toDate().toISOString()
            o.metadata!.informative.additionalData.pausedAt = experiment.metadata.pausedAt?.toDate().toISOString()
            o.metadata!.informative.additionalData.resumeAt = experiment.metadata.resumeAt?.toDate().toISOString()
            o.metadata!.informative.additionalData.enabledAt = experiment.metadata.enabledAt?.toDate().toISOString()
            o.metadata!.informative.additionalData.disabledAt = experiment.metadata.disabledAt?.toDate().toISOString()
            o.metadata!.informative.additionalData.createdAt =
              experiment.metadata.createdAt?.toDate().toISOString() ||
              experiment.metadata.syncedAt?.toDate().toISOString() ||
              experiment.metadata.launchAt?.toDate().toISOString() ||
              experiment.metadata.disabledAt?.toDate().toISOString()
            o.metadata!.informative.additionalData.updatedAt =
              experiment.metadata.updatedAt?.toDate().toISOString() ||
              experiment.metadata.syncedAt?.toDate().toISOString() ||
              experiment.metadata.launchAt?.toDate().toISOString() ||
              experiment.metadata.disabledAt?.toDate().toISOString()

            if (!this.errorMessage) {
              /*console.log('Saving override', f.metadata?.name, o.metadata?.uid, o)

              
              await this.updateOverrides(f, [o])

              return
              */
            }
          } else {
            console.error('No experiment found')
          }
        }
      }
    }

    console.log('Migrated experiments', count, total)

    if (!update) {
      this.loading = false
    }

    return response?.data
  }

  public async updateFeature(feature: Feature) {
    this.loading = true

    const appStore = new AppStore()

    console.log('Updating feature', feature)

    if (!feature.metadata!.informative!.additionalData!.createdAt) {
      feature.metadata!.informative!.additionalData!.createdBy = appStore.email
      feature.metadata!.informative!.additionalData!.createdAt = new Date().toISOString()
    }

    feature.metadata!.informative!.additionalData!.updatedBy = appStore.email
    feature.metadata!.informative!.additionalData!.updatedAt = new Date().toISOString()

    // TODO: Remove after migrations are fully done

    delete feature.metadata!.informative!.labels.team
    delete feature.metadata!.informative!.labels.editor
    delete feature.metadata!.informative!.labels.creator

    await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
      objectOneOf: { $case: 'feature', feature },
    })

    await this.listFeatures()

    this.loading = false
  }

  public async moveOverride(feature: Feature, target: OverrideEntry, after?: string) {
    this.loading = true

    console.log('Moving override', target, after)

    const appendStrategy = !after
      ? {
          oneOf: {
            $case: 'placeFirst',
            placeFirst: {},
          },
        }
      : {
          oneOf: {
            $case: 'insertAfterUid',
            insertAfterUid: after,
          },
        }

    const op =
      target.oneOf?.$case === 'group'
        ? {
            oneOf: {
              $case: 'moveGroup',
              moveGroup: {
                targetUid: target.oneOf.group.metadata?.uid,
                appendStrategy: appendStrategy,
              },
            },
          }
        : target.oneOf?.$case === 'override'
          ? {
              oneOf: {
                $case: 'moveOverride',
                moveOverride: {
                  targetUid: target.oneOf.override.metadata?.uid,
                  appendStrategy: appendStrategy,
                },
              },
            }
          : undefined

    await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
      op: op,
      featureRef: {
        name: feature.metadata?.name,
        project: feature.metadata?.project,
        objectType: TopLevel_ObjectType.FEATURE,
      },
      expectedIteration: feature.metadata?.iteration,
    })

    await this.listFeatures()

    this.loading = false
  }

  public async deleteOverrides(feature: Feature, overrides: Override[], references?: Reference[]) {
    this.loading = true

    for (const reference of [{ feature, overrides }].concat(references || [])) {
      await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
        op: {
          oneOf: {
            $case: 'deleteOverrides',
            deleteOverrides: {
              deleteUids: reference.overrides.map((o) => o.metadata?.uid),
            },
          },
        },
        featureRef: {
          name: reference.feature.metadata?.name,
          project: reference.feature.metadata?.project,
          objectType: TopLevel_ObjectType.FEATURE,
        },
        expectedIteration: reference.feature.metadata?.iteration,
      })
    }

    await this.listFeatures()

    this.loading = false
  }

  public async updateOverrides(feature: Feature, overrides: Override[], references?: Reference[]) {
    this.loading = true

    let sortingKey = null

    const appStore = new AppStore()

    const existingGroups = feature.overrideList
      .filter((e) => e.oneOf?.$case === 'group')
      .map((e) => (e.oneOf?.$case === 'group' && e.oneOf.group.metadata?.uid) || null)

    const disableOverride = overrides[0].metadata?.informative?.labels?.rollout === 'disable'

    for (const reference of [{ feature, overrides }].concat(references || [])) {
      let overrideGroupUid = ''

      const createOverrides = []
      const updateOverrides = []

      for (const override of reference.overrides) {
        // TODO: Remove after migrations are fully done

        delete override.metadata!.informative!.labels.target
        delete override.metadata!.informative!.labels.experiment
        delete override.metadata!.informative!.labels.configuration

        if (!disableOverride && !overrideGroupUid) {
          if (overrides.length === 1 && override.metadata!.informative!.labels.rollout) {
            overrideGroupUid =
              existingGroups.find((uid) => uid?.slice(0, -7) === 'all_default_feature_rollouts') ||
              'all_default_feature_rollouts_' + nanoId()
          } else if (overrides.length > 1 && !override.metadata!.informative!.labels.rollout) {
            overrideGroupUid =
              existingGroups.find((uid) => uid?.slice(0, -7) === override.metadata?.uid?.slice(0, -7)) ||
              override.metadata!.uid!.slice(0, -7) + '_' + nanoId()
          }
        }

        if (!override.metadata!.informative!.additionalData!.createdAt) {
          createOverrides.push(override)

          override.metadata!.informative!.additionalData!.createdBy = appStore.email
          override.metadata!.informative!.additionalData!.createdAt = new Date().toISOString()
        } else {
          updateOverrides.push(override)

          override.metadata!.informative!.additionalData!.updatedBy = appStore.email

          override.metadata!.informative!.additionalData!.updatedAt = new Date().toISOString()
        }
      }

      if (createOverrides.length && updateOverrides.length) {
        console.error('Both create and update overrides in same operation', createOverrides, updateOverrides)

        return
      }

      if (createOverrides.length) {
        // Create sorting key when there is linked overrides (experiment or referenced)

        if (!sortingKey && (overrides.length > 1 || !!references?.length)) {
          sortingKey = await this.createUserSortingKey(reference.feature, createOverrides[0])
        }

        if (sortingKey) {
          for (const override of createOverrides) {
            if (override.metadata?.informative?.labels?.template.startsWith('percentage')) {
              if (override.rolloutOneOf?.$case === 'rollout') {
                override.rolloutOneOf!.rollout!.userSortingKeyRef = {
                  name: sortingKey.metadata.name,
                  project: sortingKey.metadata.project,
                  objectType: TopLevel_ObjectType.USER_SORTING_KEY,
                }
              }
            } else if (override.metadata?.informative?.labels?.template.startsWith('experiment')) {
              if (override.criteria?.oneOf?.$case === 'and') {
                const range = override.criteria.oneOf.and.expressions.find(
                  (e) =>
                    e.oneOf?.$case === 'predicate' &&
                    e.oneOf?.predicate.oneOf?.$case === 'user' &&
                    e.oneOf?.predicate.oneOf?.user.oneOf?.$case === 'range',
                )

                if (
                  range &&
                  range.oneOf?.$case === 'predicate' &&
                  range.oneOf?.predicate.oneOf?.$case === 'user' &&
                  range.oneOf?.predicate.oneOf?.user.oneOf?.$case === 'range'
                ) {
                  range!.oneOf.predicate.oneOf.user.oneOf.range.userSortingKey = {
                    name: sortingKey.metadata.name,
                    project: sortingKey.metadata.project,
                    objectType: TopLevel_ObjectType.USER_SORTING_KEY,
                  }
                }
              }
            }
          }
        }

        const groupExists = existingGroups.find((uid) => uid === overrideGroupUid)

        if (!groupExists && overrideGroupUid) {
          console.info('Building overrides group', reference.feature, createOverrides)

          await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
            //console.log({
            op: {
              oneOf: {
                $case: 'buildGroup',
                buildGroup: {
                  groupUid: overrideGroupUid,
                  informative: overrideGroupUid.startsWith('all_default_feature_rollouts')
                    ? {
                        labels: { type: 'percentage' },
                        additionalData: {
                          createdBy: appStore.email,
                          createdAt: new Date().toISOString(),
                        },
                        displayName: 'Default rollouts',
                        description: 'Created by Waltari for groupping default rollouts',
                        referenceUrls: createOverrides[0].metadata?.informative?.referenceUrls,
                      }
                    : {
                        labels: { type: 'experiment' },
                        additionalData: {
                          createdBy: appStore.email,
                          createdAt: new Date().toISOString(),
                        },
                        displayName: 'Experiment rollouts',
                        description: 'Created by Waltari for groupping experiment rollouts',
                        referenceUrls: createOverrides[0].metadata?.informative?.referenceUrls,
                      },
                  overrides: createOverrides.map((o) => ({
                    oneOf: { $case: 'newOverride', newOverride: o },
                  })),
                  appendStrategy: {
                    oneOf: {
                      $case: 'placeLast',
                      placeLast: {},
                    },
                  },
                  groupTypeSpec: {
                    oneOf: {
                      $case: 'simpleGroup',
                      simpleGroup: {},
                    },
                  },
                },
              },
            },
            featureRef: {
              name: reference.feature.metadata?.name,
              project: reference.feature.metadata?.project,
              objectType: TopLevel_ObjectType.FEATURE,
            },
            expectedIteration: reference.feature.metadata?.iteration,
          })
        } else {
          console.info('Creating feature overrides', reference.feature, createOverrides)

          await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
            //console.log({
            op: {
              oneOf: {
                $case: 'createOverrides',
                createOverrides: {
                  overrides: createOverrides,
                  appendStrategy: {
                    oneOf: {
                      $case: 'placeLast',
                      placeLast: {},
                    },
                  },
                  moveIntoGroupUid: overrideGroupUid || undefined,
                },
              },
            },
            featureRef: {
              name: reference.feature.metadata?.name,
              project: reference.feature.metadata?.project,
              objectType: TopLevel_ObjectType.FEATURE,
            },
            expectedIteration: reference.feature.metadata?.iteration,
          })
        }
      } else if (updateOverrides.length) {
        console.info('Updating feature overrides', reference.feature, updateOverrides)

        await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
          //console.log({
          op: {
            oneOf: {
              $case: 'updateOverrides',
              updateOverrides: {
                overrides: updateOverrides,
              },
            },
          },
          featureRef: {
            name: reference.feature.metadata?.name,
            project: reference.feature.metadata?.project,
            objectType: TopLevel_ObjectType.FEATURE,
          },
          expectedIteration: reference.feature.metadata?.iteration,
        })
      }
    }

    await this.listFeatures()

    this.loading = false
  }

  public async pauseOverridesRollout(feature: Feature, overrides: Override[], references?: Reference[]) {
    const rollouts: any = []

    for (const reference of [{ feature, overrides }].concat(references || [])) {
      for (const override of reference.overrides) {
        if (
          override.rolloutOneOf?.$case === 'rollout' &&
          override.rolloutOneOf?.rollout?.state !== undefined &&
          [Rollout_State.ONGOING, Rollout_State.SCHEDULED_NOT_STARTED].includes(override.rolloutOneOf.rollout.state)
        ) {
          console.info('Pausing', reference)

          rollouts.push({
            actionOneOf: {
              $case: 'pause',
              pause: {},
            },
            rolloutRefOneOf: {
              $case: 'unnamedRolloutRef',
              unnamedRolloutRef: {
                featureRef: {
                  name: reference.feature!.metadata?.name,
                  project: reference.feature!.metadata?.project,
                  objectType: reference.feature!.metadata?.objectType,
                },
                overrideUid: override.metadata?.uid,
              },
            },
          })
        }
      }
    }

    const response: any = await callWithErrorHandling.call(this, 'modifyRolloutInControlBee', { rollouts }, false)

    await this.listFeatures()

    return response.data
  }

  public async launchOverridesRollout(feature: Feature, overrides: Override[], references?: Reference[]) {
    const rollouts: any = []

    this.loading = true

    for (const reference of [{ feature, overrides }].concat(references || [])) {
      for (const override of reference.overrides) {
        if (
          override.rolloutOneOf?.$case === 'rollout' &&
          (override.rolloutOneOf?.rollout?.state === undefined ||
            [Rollout_State.INACTIVE, Rollout_State.AUTO_PAUSED, Rollout_State.USER_PAUSED].includes(
              override.rolloutOneOf.rollout.state,
            ))
        ) {
          const launchDate = new Date(
            override.metadata?.informative?.additionalData?.resumeAt ||
              override.metadata?.informative?.additionalData?.launchAt,
          )

          console.info('Launching', reference, launchDate.toISOString())

          rollouts.push({
            actionOneOf: {
              $case: 'unpause',
              unpause: {},
            },
            rolloutRefOneOf: {
              $case: 'unnamedRolloutRef',
              unnamedRolloutRef: {
                featureRef: {
                  name: reference.feature!.metadata?.name,
                  project: reference.feature!.metadata?.project,
                  objectType: reference.feature!.metadata?.objectType,
                },
                overrideUid: override.metadata?.uid,
              },
            },
          })

          // Dont set the resume time (i.e. launch now) if launch time is within last 15 minutes or very near future

          if (launchDate.getTime() < Date.now() - 15 * 60 * 1000 || launchDate.getTime() > Date.now() + 60 * 1000) {
            rollouts[rollouts.length - 1].actionOneOf.unpause.resumeAt = launchDate.toISOString()
          }
        }
      }
    }

    const response: any = await callWithErrorHandling.call(this, 'modifyRolloutInControlBee', { rollouts })

    await this.listFeatures()

    this.loading = false

    return response.data
  }

  public async subscribeToFeatures() {
    if (!unsubscribeFeatures) {
      this.listFeatures()

      unsubscribeFeatures = window.setInterval(
        () => {
          this.listFeatures(true)
        },
        5 * 60 * 1000,
      )
    }
  }

  public async unsubscribeFromFeatures() {
    if (unsubscribeFeatures) {
      window.clearInterval(unsubscribeFeatures)

      unsubscribeFeatures = undefined
    }
  }

  private async createUserSortingKey(feature: Feature, override: Override, seed?: string) {
    console.info('Creating user sorting key', feature, override)

    const sortingKey = {
      metadata: {
        name: override.metadata?.uid!.slice(0, -6) + 'key',
        informative: {
          labels: {},
          displayName: 'Shared user seed',
          description: override.metadata?.informative?.description,
          referenceUrls: override.metadata?.informative?.referenceUrls,
        },
        project: feature.metadata?.project,
        objectType: TopLevel_ObjectType.USER_SORTING_KEY,
      },
    }

    await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
      //console.log({
      objectOneOf: {
        $case: 'userSortingKey',
        userSortingKey: {
          ...sortingKey,
          seed: {
            seed: seed || new Long((Math.random() * 2 ** 32) >>> 0, (Math.random() * 2 ** 32) >>> 0, true).toString(),
          },
        },
      },
    })

    return sortingKey
  }
}
