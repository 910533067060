import { createDefaultTranslatable } from '#views/messages/utilities'

import { Content } from '#types'

export function createDefaultContent(slug: string, type: string, category: string, provider: string): Content {
  const id = slug.replace(/-/g, '_')

  return {
    id: id,
    tags: [],
    type: type,
    cover: '',
    media: slug,
    state: 'draft',
    upload: '',
    vendor: provider,
    category: category,
    title: createDefaultTranslatable(`${id}:explore`, `${type} entry title`),
    description: createDefaultTranslatable(`${id}:explore`, `${type} entry description`),
    duration: 0,
    languages: [],
  }
}
