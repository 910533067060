<template>
  <v-expansion-panel class="mb-0" elevation="0">
    <v-expansion-panel-title>
      <v-row align="center">
        <span class="title">
          <v-icon size="large">{{ componentIcon }}</v-icon>
          {{ componentName }}
        </span>
        <v-spacer />
        <v-btn icon="mdi-delete" class="mr-2" @click="emitDelete()" />
      </v-row>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-item-group>
        <v-item :key="data.id">
          <div class="d-flex flex-column">
            <div class="ma-4">
              <slot />
            </div>
          </div>
        </v-item>
      </v-item-group>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { appLocales } from '#views/messages/constants'

  import { AppStore } from '#stores'

  import { UIComponentDefault, UIComponentType } from '#types'

  @Component({})
  class ComponentContainer extends Vue {
    @Prop() public data!: UIComponentDefault<UIComponentType>
    @Prop() public componentName!: string
    @Prop() public componentIcon!: string

    public locales = appLocales

    private appStore = new AppStore()

    private original?: UIComponentDefault<UIComponentType>

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    @Watch('data', { deep: true })
    protected dataChanged(newVal: any, oldVal: any) {
      if (JSON.stringify(this.original) != JSON.stringify(oldVal)) {
        this.removeInfo(this.data)
      }
    }
    @Emit('removeInfo')
    public removeInfo(info: any) {
      return info
    }

    @Emit('delete')
    public emitDelete() {
      return true
    }

    public mounted() {
      this.original = JSON.parse(JSON.stringify(this.data))
    }
  }

  export default toNative(ComponentContainer)
</script>

<style lang="scss" scoped>
  .v-expansion-panel {
    margin: 0;
    border-radius: 3px;

    &:before {
      box-shadow: none;
    }

    &:after {
      border: 0 !important;
    }

    .v-expansion-panel-title {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 3px;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .v-expansion-panels {
    & > div > .mb-0 {
      margin: 5px 0 !important;
    }
  }

  .v-expansion-panel-title__icon {
    display: none;
  }
</style>
