export const listHeaders: any = [
  {
    key: 'icon',
    width: 20,
  },
  {
    key: 'name',
    title: 'Name',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'allowedPages',
    title: 'Available in',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'allowedFacets',
    title: 'Allowed facets',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'messageCount',
    title: 'Messages created',
    cellClass: 'text-no-wrap',
  },
  {
    title: 'Created at',
    key: 'createdAt',
    cellClass: 'text-no-wrap',
  },
  {
    key: 'updatedAt',
    title: 'Updated at',
    cellClass: 'text-no-wrap',
    align: 'right',
  },
]

export const templateTypes = [
  /* {
    title: 'Workspace',
    value: 'workspace',
  }, */
  {
    title: 'Insights',
    value: 'insights',
  },
  {
    title: 'Tip Themes',
    value: 'tipthemes',
  },
  {
    title: 'Slide Shows',
    value: 'slideshows',
  },
  {
    title: 'Components',
    value: 'components',
  },
]

export const templateFacets = [
  {
    title: 'Home',
    value: 'home',
  },
  {
    title: 'View',
    value: 'view',
  },
  {
    title: 'Notification',
    value: 'notification',
  },
  {
    title: 'Media / Links',
    value: 'media_links',
  },
]
