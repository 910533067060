<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-app-bar>
    <v-app-bar-title>
      Templates / Insights {{ !!templatesList.length ? `(${templatesList.length})` : '' }}
    </v-app-bar-title>

    <v-spacer />

    <v-text-field
      v-model="routeParams.searchText"
      clearable
      class="mr-2"
      style="max-width: 500px"
      prepend-icon="mdi-magnify"
      :placeholder="'Type to filter templates...'"
      @click:clear="routeParams.searchText = ''"
    />

    <v-menu offset="8" max-height="500" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-filter-variant-plus" :color="props.value ? 'primary' : ''" />
      </template>

      <v-list>
        <v-list-subheader>Select additional filters</v-list-subheader>

        <v-menu location="start" open-on-hover max-height="500" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Template type" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in templateTypes"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.templateTypes.includes(item.value)"
              @click="routeParams.templateTypes = [...routeParams.templateTypes, item.value]"
            />
          </v-list>
        </v-menu>

        <v-menu location="start" open-on-hover max-height="500" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Allowed facets" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in templateFacets"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.allowedFacets.includes(item.value)"
              @click="routeParams.allowedFacets = [...routeParams.allowedFacets, item.value]"
            />
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Templates speed up and unify insights creation</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isInsightsEditor">
            Your insights editor rights allow you to view and edit all templates
          </template>
          <template v-else>You can only view templates, apply for editor rights in the IT portal</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          text="Create template"
          color="primary"
          :disabled="!isInsightsEditor"
          @click="createTemplateDialogRef.open()"
        />
      </v-col>
    </v-row>

    <v-row v-if="routeParams.templateTypes.length > 0 || routeParams.allowedFacets.length > 0" class="my-0">
      <v-col>
        <v-chip
          v-for="filter in routeParams.templateTypes"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.templateTypes = [...routeParams.templateTypes.filter((f) => f !== filter)]"
        >
          <span>{{ (templateTypes.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>

        <v-chip
          v-for="filter in routeParams.allowedFacets"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.allowedFacets = [...routeParams.allowedFacets.filter((f) => f !== filter)]"
        >
          <span>{{ (templateFacets.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            single-select
            disable-pagination
            :search="routeParams.searchText"
            :loading="isLoading"
            :headers="listHeaders"
            :items="templatesList"
            :items-per-page="100"
            :items-per-page-options="[
              { title: '100', value: 100 },
              { title: '500', value: 500 },
              { title: '1000', value: 1000 },
            ]"
            :sort-by="[{ key: 'inamed', order: 'desc' }]"
            @click:row="(_event: any, row: any) => openTemplate(row.item)"
          >
            <template #[`item.icon`]="{ item }">
              <v-icon>{{ item.icon }}</v-icon>
            </template>

            <template #[`item.name`]="{ item }">
              {{ item.name }}
            </template>

            <template #[`item.allowedPages`]="{ item }">
              {{
                item.allowedPages
                  ? item.allowedPages
                      .map((p: string) => templateTypes.find((t) => t.value === p)?.title || upperFirst(p))
                      .join(', ')
                  : ''
              }}
            </template>

            <template #[`item.allowedFacets`]="{ item }">
              {{
                item.allowedFacets
                  ? item.allowedFacets
                      .map((f: string) => templateFacets.find((t) => t.value === f)?.title || upperFirst(f))
                      .join(', ')
                  : ''
              }}
            </template>

            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt ? $dayjs(item.createdAt.toDate()).format('DD MMM YYYY') : '' }}
            </template>

            <template #[`item.updatedAt`]="{ item }">
              {{ item.updatedAt ? $dayjs(item.updatedAt.toDate()).format('DD MMM YYYY') : '' }}
            </template>

            <template #[`item.messageCount`]="{ item }">
              {{ item.messageCount }}
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <CreateTemplate ref="createTemplateDialogRef" :templates-path="templatesPath" />
</template>

<script lang="ts">
  import { intersection, upperFirst } from 'lodash-es'

  import { Component, Prop, Ref, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import { listHeaders, templateFacets, templateTypes } from '#views/templates/constants'

  import { AppStore, TemplatesStore } from '#stores'

  import { Dialog } from '#types'

  @Component({})
  class TemplatesView extends mixins(RouteParams) {
    @Prop() public templatesPath!: string

    public routeParams = {
      searchText: '' as string,
      templateTypes: [] as string[],
      allowedFacets: [] as string[],
    }

    public readonly upperFirst = upperFirst

    public readonly listHeaders = listHeaders
    public readonly templateTypes = templateTypes
    public readonly templateFacets = templateFacets

    private readonly appStore = new AppStore()
    private readonly templatesStore = new TemplatesStore()

    @Ref() public readonly createTemplateDialogRef!: Dialog

    public get isLoading() {
      return this.templatesStore.loading
    }

    public get templatesList() {
      return this.templatesStore.templates.filter(
        (t) =>
          (this.routeParams.templateTypes.length
            ? !!intersection(t.allowedPages, this.routeParams.templateTypes).length
            : true) &&
          (this.routeParams.allowedFacets.length
            ? !!intersection(t.allowedFacets, this.routeParams.allowedFacets).length
            : true),
      )
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    public openTemplate(template: any) {
      this.$router.push(`/templates/${template.id}`)
    }
  }

  export default toNative(TemplatesView)
</script>

<style lang="scss">
  .template {
    .icon {
      display: none;
    }

    &:hover {
      .title-container {
        color: rgb(var(--v-theme-primary));
      }

      .icon {
        display: inline-flex;
        color: rgb(var(--v-theme-primary));
      }
    }
  }
</style>
