<template>
  <Translatable v-if="data" :data="data.title">
    <v-text-field
      v-if="!items.length"
      variant="underlined"
      label="Title"
      hint="Enter chart title"
      spellcheck="true"
      lang="en"
      :model-value="data.title.text"
      @update:model-value="updateTitle($event)"
    />
    <v-combobox
      v-else
      variant="underlined"
      spellcheck="true"
      lang="en"
      label="Title"
      :items="items"
      item-title="text"
      :loading="loading"
      :model-value="data.title.text"
      :hint="
        data.title.templateId && data.title.translationId.includes('template-component')
          ? ''
          : 'Select from the list or write new title'
      "
      @update:model-value="updateTitle($event)"
      @update:search-input="updateTitle($event)"
    >
      <template #item="{ item }">
        <v-list-item @click="updateTitle(item.raw)">
          <v-list-item-title>
            {{ item.title }}

            <v-list-item-subtitle>{{ item.raw.templateId }}</v-list-item-subtitle>
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-combobox>

    <div
      v-if="data.title.templateId && data.title.translationId.includes('template-component')"
      class="caption orange--text mt-n4"
      style="position: relative; z-index: 10"
    >
      Note: If you edit this text it will only change for this component. To change the text for all components using
      this text please edit the text in the
      <router-link :to="'/templates/' + data.title.templateId">
        <a link class="text-primary">template</a>
      </router-link>
      .
    </div>
  </Translatable>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { createDefaultTranslatable } from '#views/messages/utilities'

  import { Translatable as TranslatableType } from '#types'

  @Component({})
  class TranslatableTitle extends Vue {
    @Prop() public data!: { type: string; title: TranslatableType; [key: string]: any }
    @Prop() public items!: TranslatableType[]
    @Prop() public prefix!: string
    @Prop() public comment!: string
    @Prop() public loading!: boolean

    @Emit('changeTitle')
    public changeTitle(title: any) {
      return title
    }

    public oldTitle: any = null

    public get defaultTitle() {
      return this.oldTitle || createDefaultTranslatable(`${this.prefix}:view`, this.comment)
    }

    public updateTitle(title: string | TranslatableType) {
      // This function is for select events
      if (!title || typeof title === 'string') {
        if (title !== this.data.title.text) {
          if (this.data.title.templateId && this.data.title.translationId.includes('template-component')) {
            this.changeTitle({ ...this.defaultTitle, text: title || '' })
          } else {
            this.changeTitle({ ...this.data.title, text: title || '' })
          }
        }
      } else {
        if (!this.data.title.templateId || !this.data.title.translationId.includes('template-component')) {
          this.oldTitle = this.data.title
        }

        this.changeTitle(title)
      }
    }
  }

  export default toNative(TranslatableTitle)
</script>
