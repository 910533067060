import { sortBy } from 'lodash-es'

export const linkTypes = sortBy(
  [
    { value: 'web', title: 'Web' },
    { value: 'zendesk', title: 'Zendesk' },
    { value: 'slideshow', title: 'Slideshow' },
  ],
  'title',
)
