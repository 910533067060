// sort-imports-ignore

import JSZip from 'jszip'

import { customAlphabet } from 'nanoid'

export function nanoId() {
  const alphabet = '0123456789abcdefghijklmnopqrstuvwxyz'
  return customAlphabet(alphabet, 6)()
}

export function prefixedId(prefix: string) {
  return `${prefix}:${nanoId()}`
}

/**
 * Validate default ID format
 */
export function isIdentifierValid(id: string): boolean {
  return /^[a-z]{3,}[a-z0-9_]*[a-z0-9]+$/.test(id)
}

/**
 * Validate Filero media file slugs
 */
export function isMediaIdentifierValid(id: string): boolean {
  return /^[a-z]{3,}[a-z0-9-]*[a-z0-9]+$/.test(id)
}

/**
 * ZIP files handling / downloading utilities
 */
export async function createZipAndDownload(fileName: string, exportContent: any, exportTranslations: any) {
  const zip = new JSZip()
  const blob = new Blob([JSON.stringify(exportContent, null, 2)], {
    type: 'application/json',
  })

  zip.file(`${fileName}.json`, blob)

  if (exportTranslations) {
    exportTranslations.map((translation: any) => {
      const blob = new Blob([JSON.stringify(translation.messages, null, 2)], {
        type: 'application/json',
      })

      zip.file(`${fileName}_${translation.language}.json`, blob)
    })
  }

  const zipBlob = await zip.generateAsync({ type: 'blob' })

  const a = window.document.createElement('a')
  a.href = window.URL.createObjectURL(zipBlob)
  a.download = `${fileName}.zip`

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
