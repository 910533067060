<template>
  <v-card style="min-height: 90vh; max-height: 1000px">
    <v-card-title>{{ title }}</v-card-title>

    <v-card-text>
      <div v-if="figmaPreview && figmaErrors.length > 0">
        <v-alert v-for="(error, index) in figmaErrors" :key="index" class="mb-4" type="error" :text="error.message" />
      </div>

      <v-row class="fill-height">
        <div v-if="loading" class="d-flex flex-wrap flex-column" style="width: 100%">
          <v-skeleton-loader
            type="image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image, image"
          />
        </div>

        <template v-else-if="galleryImages.length">
          <v-col
            v-for="(image, idx) in galleryImages"
            :key="idx"
            class="d-flex child-flex align-stretch justify-center image"
            :class="{ selected: selectedImage?.slug === image.slug }"
            cols="2"
          >
            <ImageObject
              v-if="figmaPreview"
              :slug="image.slug"
              :urls="[image.imageFile?.url, image.thumbFile?.url]"
              :category="category"
              @click="selectedImage = image"
            />

            <ImageObject v-else :slug="image.slug" :category="category" @click="selectedImage = image" />
          </v-col>
        </template>

        <template v-else>
          <div class="d-flex flex-column align-center justify-center" style="font-size: 32px; width: 100%">
            <div>{{ figmaPreview ? 'No new images in Figma' : 'No images found' }}</div>
          </div>
        </template>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="figmaPreview" color="primary" text="Select from Filero" @click="figmaPreview = false" />
      <v-btn v-else-if="!figmaPreview" color="primary" text="Import from Figma" @click="figmaPreview = true" />

      <v-spacer />

      <v-btn text="Cancel" @click="cancel()" />

      <v-btn
        v-if="figmaPreview"
        text="Import"
        color="primary"
        :loading="importing"
        :disabled="importing || !selectedImage || figmaErrors.length > 0"
        @click="importImage()"
      />
      <v-btn v-else text="Select" color="primary" :disabled="!selectedImage" @click="selectImage()" />
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { MediaStore, UploadsStore } from '#stores'

  @Component({})
  class ImageGallery extends Vue {
    @Prop() public file!: string
    @Prop() public slug!: string
    @Prop() public title!: string
    @Prop() public category!: string

    @Prop({ type: Boolean }) public thumbnail!: boolean

    @Emit('cancel')
    public cancel() {
      return null
    }

    @Emit('select')
    public select(data: any) {
      return data
    }

    public figmaPreview = false

    public selectedImage: any = null

    private mediaStore = new MediaStore()
    private uploadsStore = new UploadsStore()

    public get loading() {
      return this.mediaStore.listing || this.mediaStore.previewing
    }

    public get importing() {
      return this.mediaStore.importing || this.uploadsStore.uploading
    }

    public get figmaErrors() {
      return this.mediaStore.figmaErrors
    }

    public get galleryImages() {
      const thumbs = this.mediaStore.figmaThumbs

      return this.figmaPreview
        ? this.mediaStore.figmaImages
            .map((i) => ({
              slug: i.slug,
              name: i.name,
              file: i.file,
              imageNode: i.node,
              imageFile: i.media_file,
              thumbNode: thumbs.find((t) => t.slug === i.slug)?.node,
              thumbFile: thumbs.find((t) => t.slug === i.slug)?.media_file,
            }))
            .filter((i) => !this.thumbnail || (!!i.thumbFile && !!i.thumbNode))
        : this.mediaStore.imageFiles.filter((i: any) => i.slug.startsWith(this.category)) || []
    }

    @Watch('figmaPreview')
    protected onFigmaPreviewChanged(val: any) {
      this.selectedImage = null

      if (val) {
        this.mediaStore.previewFigmaImages({
          slug: this.slug.split('-').slice(1).join('-'),
          files: [this.title],
        })
      }
    }

    public mounted() {
      this.mediaStore.listMediaFiles({
        types: ['images'],
      })
    }

    public reset() {
      this.figmaPreview = false
      this.selectedImage = null
    }

    public selectImage() {
      this.select(this.selectedImage)

      this.reset()
    }

    public async importImage() {
      if (this.selectedImage) {
        const uploads: any[] = []

        let response = await this.uploadsStore.uploadMedia({
          slug: this.selectedImage.slug,
          file: this.selectedImage.imageFile,
          variantInfo: { rendition: 'original' },
        })

        if (response?.data?.slugJobId) {
          uploads.push({
            name: this.selectedImage.name,
            file: this.selectedImage.file,
            node: this.selectedImage.imageNode,
            imageUrl: this.selectedImage.imageFile.url,
            presignedUrl: response.data.presignedUrl,
          })

          if (this.selectedImage.thumb_file) {
            response = await this.uploadsStore.uploadMedia({
              slug: this.selectedImage.slug,
              file: this.selectedImage.thumbFile,
              variantInfo: { rendition: 'thumbnail' },
            })

            if (response?.data?.slugJobId) {
              uploads.push({
                name: this.selectedImage.name,
                file: this.selectedImage.file,
                node: this.selectedImage.thumbNode,
                imageUrl: this.selectedImage.thumbFile.url,
                presignedUrl: response.data.presignedUrl,
              })
            }
          }
        }

        if (response?.data?.slugJobId) {
          await this.mediaStore.importFigmaImages({
            uploads,
          })
        }

        this.select(this.selectedImage)
      }

      this.reset()
    }
  }

  export default toNative(ImageGallery)
</script>

<style lang="scss" scoped>
  .image {
    padding: 8px;
    border: 2px solid transparent;

    &.selected {
      border: 2px solid rgb(var(--v-theme-primary));
    }
  }

  :deep(.v-skeleton-loader__image) {
    width: 138px;
    height: 138px;
    margin: 12px;
    display: inline-flex;
  }
</style>
