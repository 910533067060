<template>
  <Translatable :data="data.title">
    <v-text-field v-model="data.title.text" spellcheck="true" lang="en" label="Title" />
  </Translatable>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentActivityIntensity } from '#types'

  @Component({})
  class ActivityIntensity extends Vue {
    @Prop() public data!: UIComponentActivityIntensity
  }

  export default toNative(ActivityIntensity)
</script>
