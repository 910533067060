<template>
  <v-select v-model="contributorValue" :items="contributorItems" label="Contributor" />

  <v-switch
    v-model="useCumulative"
    class="mt-0"
    label="Use cumulative daily values"
    :disabled="!cumulativeContributors.includes(contributorValue)"
  />

  <TranslatableTitle
    comment="contributor title"
    :data="data"
    :prefix="id"
    :items="templateTitles"
    :loading="loadingTitles"
    @change-title="data.title = $event"
  />
</template>

<script lang="ts">
  import { sortBy } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { cumulativeContributors } from '#views/messages/components/constants'

  import { Template, Translatable, UIComponentContributor } from '#types'

  @Component({})
  class Contributor extends Vue {
    @Prop() public id!: string
    @Prop() public data!: UIComponentContributor
    @Prop() public template!: Template | null | undefined

    public loadingTitles = false

    public templateTitles: Translatable[] = []

    public cumulativeContributors = cumulativeContributors

    private unsubscribe: Unsubscribe | undefined = undefined

    public get useCumulative() {
      return this.data.contributor.startsWith('daily_')
    }

    public set useCumulative(value: boolean) {
      this.data.contributor = (value ? 'daily_' : '') + this.contributorValue
    }

    public get contributorItems() {
      return sortBy(
        [
          { value: 'activity.stay_active', title: 'Activity: Stay Active' },
          {
            value: 'activity.move_every_hour',
            title: 'Activity: Move Every Hour',
          },
          {
            value: 'activity.meet_daily_targets',
            title: 'Activity: Meet Daily Targets',
          },
          {
            value: 'activity.training_frequency',
            title: 'Activity: Training Frequency',
          },
          {
            value: 'activity.training_volume',
            title: 'Activity: Training Volume',
          },
          { value: 'activity.recovery_time', title: 'Activity: Recovery Time' },
          { value: 'sleep.total', title: 'Sleep: Total' },
          { value: 'sleep.efficiency', title: 'Sleep: Efficiency' },
          { value: 'sleep.tranquility', title: 'Sleep: Tranquility' },
          { value: 'sleep.rem', title: 'Sleep: REM' },
          { value: 'sleep.deep', title: 'Sleep: Deep' },
          { value: 'sleep.latency', title: 'Sleep: Latency' },
          { value: 'sleep.timing', title: 'Sleep: Timing' },
          {
            value: 'readiness.previous_night',
            title: 'Readiness: Previous Night',
          },
          { value: 'readiness.sleep_balance', title: 'Readiness: Sleep Balance' },
          { value: 'readiness.previous_day', title: 'Readiness: Previous Day' },
          {
            value: 'readiness.activity_balance',
            title: 'Readiness: Activity Balance',
          },
          {
            value: 'readiness.resting_heart_rate',
            title: 'Readiness: Resting Heart Rate',
          },
          {
            value: 'readiness.recovery_index',
            title: 'Readiness: Recovery Index',
          },
          {
            value: 'readiness.body_temperature',
            title: 'Readiness: Body Temperature',
          },
          {
            value: 'readiness.hrv_balance',
            title: 'Readiness: HRV Balance',
          },
        ],
        'title',
      )
    }

    public get contributorValue() {
      return this.data.contributor.replace(/daily_/g, '')
    }

    public set contributorValue(value: string) {
      this.data.contributor = value
    }

    public created() {
      if (!this.template) {
        this.loadingTitles = true

        this.unsubscribe = onSnapshot(query(collection(getFirestore(), `/templates`)), (snap) => {
          if (snap?.docs) {
            const templates = snap.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))

            this.loadingTitles = false

            this.templateTitles = templates
              .filter((t: any) => (t.allowedPages || []).includes('components'))
              .reduce(
                (titles: Translatable[], template: any) => [
                  ...titles,
                  ...(template.facets?.view?.components || [])
                    .map((c: any) => ({ ...c.title, templateId: template.id }))
                    .filter((t: any) => t?.translationId?.startsWith('template-component')),
                ],
                [],
              )
          }
        })
      }
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
        this.unsubscribe = undefined
      }
    }
  }

  export default toNative(Contributor)
</script>
