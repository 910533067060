import { isEqual, orderBy, uniqWith } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore'

import {
  AppBuild,
  AppBuildTest,
  AppPlatform,
  AppReleaseCandidates,
  PublicReleases,
  ReleaseNotes,
  ReleaseTypes,
} from '#types'

let unsubscribes: { [k: string]: Unsubscribe | undefined } = {}

@Store
export class ReleasesStore extends Pinia {
  public saving = false
  public loadingReleases = false

  public appBuilds: (AppBuild | Partial<AppBuild>)[] = []

  public appBuildTests: { [commit: string]: AppBuildTest[] } = {}

  public currentPublicReleases: PublicReleases = {
    ios: {},
    android: {},
  }

  public previousPublicReleases: PublicReleases = {
    ios: {},
    android: {},
  }

  public releaseNotes: { [type in ReleaseTypes]: { [platform in AppPlatform]: ReleaseNotes | undefined } } = {
    [ReleaseTypes.RELEASE_CANDIDATE]: { android: undefined, ios: undefined },
    [ReleaseTypes.CURRENT_PUBLIC_RELEASE]: { android: undefined, ios: undefined },
    [ReleaseTypes.PREVIOUS_PUBLIC_RELEASE]: { android: undefined, ios: undefined },
  }

  public releaseCandidates: AppReleaseCandidates = { ios: '', android: '' }

  public get builds() {
    // Vuetify data table does not support group ordering,
    // so we need to order the builds manually
    return orderBy(uniqWith(this.appBuilds, isEqual), 'buildVersion', 'asc')
  }

  public async updateReleaseNotesState(version: string, platform: AppPlatform, data: Partial<ReleaseNotes>) {
    try {
      return await setDoc(
        doc(getFirestore(), `/releases-app-release-notes/${platform}-${version}`),
        {
          ...data,
          updatedAt: serverTimestamp(),
        },
        { merge: true },
      )
    } catch (error) {
      console.error('Release note update failed', error)
    }
  }

  public async fetchReleaseNotes(platform: AppPlatform, type: ReleaseTypes): Promise<ReleaseNotes | undefined> {
    let version = ''

    if (type === ReleaseTypes.RELEASE_CANDIDATE) {
      const { major, minor, patch } = await this.fetchLatestVersion(platform)
      version = `${major}.${minor}.${patch}`
    } else if (type === ReleaseTypes.CURRENT_PUBLIC_RELEASE) {
      version = this.currentPublicReleases[platform].version ?? ''
    } else {
      version = this.previousPublicReleases[platform].version ?? ''
    }

    const releaseNotes = await getDoc(doc(getFirestore(), `/releases-app-release-notes/${platform}-${version}`))

    return (releaseNotes.data() as ReleaseNotes) || undefined
  }

  public async setReleaseCandidate(buildVersion: string, platform: AppPlatform) {
    try {
      await setDoc(
        doc(getFirestore(), `/releases-app-builds/${platform}`),
        { releaseCandidate: buildVersion },
        { merge: true },
      )
    } catch (error) {
      console.error('doc update failed', error)
    }
  }

  public async fetchLatestVersion(platform: AppPlatform, fallback = '5.0.0') {
    const appBuilds = (await getDoc(doc(getFirestore(), `/releases-app-builds/${platform}`))).data()

    const version = appBuilds?.latestBuildVersion || fallback

    const [major, minor, patch] = version.split('.').map((version: string) => parseInt(version))

    return {
      major,
      minor,
      patch,
    }
  }

  public async subscribeToReleases() {
    Promise.all([
      this.subscribeBuilds('ios'),
      this.subscribeReleases('ios'),
      this.subscribeReleaseNotes('ios'),
      this.subscribeBuilds('android'),
      this.subscribeReleases('android'),
      this.subscribeReleaseNotes('android'),
    ]).then((settled) => {
      const [iosBuilds, iosReleases, iosReleaseNotes, androidBuilds, androidReleases, androidReleaseNotes] = settled

      unsubscribes = {
        iosBuilds,
        iosReleases,
        iosReleaseNotes,
        androidBuilds,
        androidReleases,
        androidReleaseNotes,
      }
    })
  }

  public async unsubscribeFromReleases() {
    Object.values(unsubscribes).forEach((unsubscribe) => {
      if (unsubscribe) {
        unsubscribe()
      }
    })
  }

  private async subscribeBuilds(platform: AppPlatform) {
    const { major, minor, patch } = await this.fetchLatestVersion(platform)

    return onSnapshot(
      query(collection(getFirestore(), `/releases-app-builds/${platform}/${major}.${minor}.${patch}`)),
      async (snap) =>
        snap.docs.forEach(async (document) => {
          const buildData = document.data() as AppBuild
          this.appBuilds.push(buildData)

          const testsRef = await getDocs(
            collection(getFirestore(), `/releases-app-tests/${platform}/${buildData.commit}`),
          )
          this.appBuildTests[buildData.commit] = testsRef.docs.map((doc) => doc.data()) as AppBuildTest[]
        }),
    )
  }

  private async subscribeReleases(platform: AppPlatform) {
    return onSnapshot(doc(getFirestore(), `/releases-app-builds/${platform}`), async (document) => {
      const data = document.data()

      this.releaseCandidates[platform] = data?.releaseCandidate ?? ''

      if (data?.currentPublicRelease?.version && data?.currentPublicRelease?.build) {
        this.updatePublicRelease(
          ReleaseTypes.CURRENT_PUBLIC_RELEASE,
          platform,
          this.currentPublicReleases,
          data.currentPublicRelease,
        )
      }

      if (data?.previousPublicRelease?.version && data?.previousPublicRelease?.build) {
        this.updatePublicRelease(
          ReleaseTypes.PREVIOUS_PUBLIC_RELEASE,
          platform,
          this.previousPublicReleases,
          data.previousPublicRelease,
        )
      }
    })
  }

  /**
   * Fetches build and release note data for given public release and updates the cached public release data.
   */
  private async updatePublicRelease(
    type: ReleaseTypes,
    platform: AppPlatform,
    publicReleases: PublicReleases,
    publicRelease: { version: string; build: string },
  ) {
    if (publicRelease?.version && publicRelease?.build) {
      publicReleases[platform] = (await this.getBuild(platform, publicRelease.version, publicRelease.build)) as AppBuild

      this.releaseNotes[type][platform] = await this.fetchReleaseNotes(platform, type)
    }
  }

  private async subscribeReleaseNotes(platform: AppPlatform) {
    const { major, minor, patch } = await this.fetchLatestVersion(platform)

    return onSnapshot(
      doc(getFirestore(), `/releases-app-release-notes/${platform}-${major}.${minor}.${patch}`),
      async (document) => {
        if (document.exists()) {
          this.releaseNotes[ReleaseTypes.RELEASE_CANDIDATE][platform] = document.data() as ReleaseNotes
        }
      },
    )
  }

  private async getBuild(platform?: AppPlatform, version?: string, buildVersion?: string) {
    if (!platform || !version || !buildVersion) {
      return undefined
    }

    const build = await getDoc(doc(getFirestore(), `/releases-app-builds/${platform}/${version}/${buildVersion}`))

    return build.data() as AppBuild | undefined
  }
}
