<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-app-bar :title="'Contents / Explore (' + filteredContents.length + ')'">
    <v-spacer />

    <v-text-field
      v-model="routeParams.searchText"
      clearable
      class="mr-2"
      style="max-width: 500px"
      prepend-icon="mdi-magnify"
      placeholder="Type to filter content..."
      @click:clear="routeParams.searchText = ''"
    />

    <v-menu offset="8" max-height="500" location="start bottom" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-filter-variant-plus" :color="props.value ? 'primary' : ''" />
      </template>

      <v-list>
        <v-list-subheader>Select additional filters</v-list-subheader>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Content type" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in types"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.typeFilters.includes(item.value)"
              @click="routeParams.typeFilters = [...routeParams.typeFilters, item.value]"
            />
          </v-list>
        </v-menu>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Content category" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in categories"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.categoryFilters.includes(item.value)"
              @click="routeParams.categoryFilters = [...routeParams.categoryFilters, item.value]"
            />
          </v-list>
        </v-menu>

        <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-list-item v-bind="props" title="Publishing state" prepend-icon="mdi-menu-left" />
          </template>

          <v-list>
            <v-list-item
              v-for="item in states"
              :key="item.value"
              :title="item.title"
              :disabled="routeParams.stateFilters.includes(item.value)"
              @click="routeParams.stateFilters = [...routeParams.stateFilters, item.value]"
            />
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Contents provides media content for the Explore tab</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="isContentsEditor">
            Your content editor rights allow you to create and edit media content
          </template>
          <template v-else>You can only view contents, apply for editor rights in the IT portal</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-menu offset="8px">
          <template #activator="{ props }">
            <v-btn v-bind="props" text="Add content" color="primary" :disabled="!isContentsEditor" />
          </template>
          <v-list>
            <v-list-item title="Audio" :prepend-icon="getIcon('audio')" @click="openAddContentDialog('audio')" />

            <v-list-item title="Video" :prepend-icon="getIcon('video')" @click="openAddContentDialog('video')" />

            <v-list-item
              title="Slideshow"
              :prepend-icon="getIcon('slideshow')"
              @click="openAddContentDialog('slideshow')"
            />
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="my-0">
      <v-col>
        <v-chip
          v-for="filter in routeParams.typeFilters"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.typeFilters = [...routeParams.typeFilters.filter((f) => f !== filter)]"
        >
          <span>{{ (types.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>

        <v-chip
          v-for="filter in routeParams.categoryFilters"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.categoryFilters = [...routeParams.categoryFilters.filter((f) => f !== filter)]"
        >
          <span>{{ (categories.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>

        <v-chip
          v-for="filter in routeParams.stateFilters"
          :key="filter"
          closable
          class="ma-1"
          @click:close="routeParams.stateFilters = [...routeParams.stateFilters.filter((f) => f !== filter)]"
        >
          <span>{{ (states.find((i) => i.value === filter) || {}).title }}</span>
        </v-chip>
      </v-col>
    </v-row>

    <v-row class="mt-n2">
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            v-model="selected"
            :items="filteredContents"
            :headers="headers"
            :loading="loading"
            style="width: 100%"
            single-select
            items-per-page="1000"
            disable-pagination
            hide-default-footer
            @click:row="(_event: any, row: any) => editContent(row.item)"
          >
            <template #[`item.icon`]="{ item }">
              <v-icon v-if="item.publishedAt">{{ getIcon(item.type) }}</v-icon>

              <v-badge v-else color="primary" left dot>
                <v-icon>{{ getIcon(item.type) }}</v-icon>
              </v-badge>
            </template>

            <template #[`item.tags`]="{ item }">
              {{ item.tags.join(', ') }}
            </template>

            <template #[`item.state`]="{ item }">
              <StateChip
                :readonly="!isContentsEditor"
                :disabled="!!item.upload || !item.cover || !item.vendor || !item.category"
                :state-key="item.state"
                @select="editState(item)"
              />
            </template>

            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt ? $dayjs(item.createdAt.toDate()).format('DD MMM YYYY') : '' }}
            </template>

            <template #[`item.updatedAt`]="{ item }">
              {{ item.updatedAt ? $dayjs(item.updatedAt.toDate()).format('DD MMM YYYY') : '' }}
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <EditContentPanel
    ref="editContentPanelRef"
    :tags="tags"
    :vendors="vendors"
    :contents="contents"
    :contents-path="contentsPath"
    @close="() => $router.push(contentsPath)"
  />

  <ChangeStateDialog ref="changeStateDialogRef" />

  <SelectMediaDialog ref="selectMediaDialogRef" :vendors="vendors" :contents="contents" @confirm="createContent" />

  <SelectSlideshowDialog ref="selectSlideshowDialogRef" :contents="contents" @confirm="createContent" />
</template>

<script lang="ts">
  import { createDefaultContent } from './utilities'

  import { Component, Prop, Ref, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-apps-toolkit'

  import { contentCategories, contentIcons, contentTypes, listHeaders } from '#views/contents/constants'
  import { insightStates } from '#views/insights/constants'

  import { AppStore, ContentsStore, ProjectsStore } from '#stores'

  import { Content, Dialog, Panel } from '#types'

  @Component({})
  class ContentsView extends mixins(RouteParams) {
    @Prop() public contentId!: string
    @Prop() public contentsPath!: string

    public routeParams = {
      searchText: '' as string,
      typeFilters: [] as string[],
      stateFilters: [] as string[],
      categoryFilters: [] as string[],
    }

    public types = contentTypes

    public headers = listHeaders

    public states = insightStates
    public categories = contentCategories

    public tags: string[] = []
    public vendors: string[] = []

    public selected: any[] = []

    public filteredContents: Content[] = []

    private appStore = new AppStore()
    private projectsStore = new ProjectsStore()
    private contentsStore = new ContentsStore()

    @Ref() private readonly editContentPanelRef: Panel | null = null
    @Ref() private readonly changeStateDialogRef: Dialog | null = null
    @Ref() private readonly selectMediaDialogRef: Dialog | null = null
    @Ref() private readonly selectSlideshowDialogRef: Dialog | null = null

    public get project() {
      return this.projectsStore.project
    }

    public get loading() {
      return this.contentsStore.loading
    }

    public get contents() {
      return this.contentsStore.contents
    }

    public get isContentsEditor() {
      return this.appStore.isContentsEditor
    }

    @Watch('contents', { immediate: true })
    protected onContentsChanged() {
      this.tags = []
      this.vendors = []

      for (const content of this.contents) {
        content.tags.forEach((tag: string) => {
          if (!this.tags.includes(tag)) {
            this.tags.push(tag)
          }
        })

        if (!this.vendors.includes(content.vendor)) {
          this.vendors.push(content.vendor)
        }
      }

      const content = this.contents.find((content) => content.id === this.contentId)

      if (content && this.contentId) {
        this.editContentPanelRef!.open('edit', content)
      }

      this.updateContentsSearchResults()
    }

    @Watch('contentId', { immediate: true })
    protected onContentIdChanged() {
      this.onContentsChanged()
    }

    @Watch('routeParams', { immediate: true })
    protected onRouteParamsChanged() {
      this.updateContentsSearchResults()
    }

    public getIcon(type: string) {
      return contentIcons[type]
    }

    public editState(content: Content) {
      this.changeStateDialogRef!.open(content, `${this.contentsPath}/${content.id}`, 'Content')
    }

    public editContent(content: any) {
      if (this.$route.path !== `/contents/${content.id}`) {
        this.$router.push(`/contents/${content.id}`)
      }
    }

    public createContent(media: any) {
      this.selected = []

      const content = createDefaultContent(
        media.slug,
        media.media_type,
        media.info?.category || '',
        media.info?.provider || '',
      )

      if (this.project?.id) {
        content.project = this.project?.id
      }

      if (media.upload_job_id) {
        content.upload = media.upload_job_id
      }

      this.editContentPanelRef!.open('create', content)
    }

    public openAddContentDialog(type: string) {
      if (type !== 'slideshow') {
        this.selectMediaDialogRef!.open(type)
      } else {
        this.selectSlideshowDialogRef!.open()
      }
    }

    private updateContentsSearchResults() {
      const searchText = this.routeParams.searchText.toLowerCase()

      this.filteredContents = this.contents.filter(
        (content) =>
          (content.id.includes(searchText) ||
            (content.title?.text || '').toLowerCase().includes(searchText) ||
            (content.description?.text || '').toLowerCase().includes(searchText)) &&
          (!this.routeParams.typeFilters.length || this.routeParams.typeFilters.includes(content.type)) &&
          (!this.routeParams.categoryFilters.length || this.routeParams.categoryFilters.includes(content.category)) &&
          ((!this.routeParams.stateFilters.length && content.state !== 'archived') ||
            this.routeParams.stateFilters.includes(content.state)),
      )
    }
  }

  export default toNative(ContentsView)
</script>

<style lang="scss">
  .template {
    .icon {
      display: none;
    }

    &:hover {
      .title-container {
        color: rgb(var(--v-theme-primary));
      }
      .icon {
        display: inline-flex;
      }
    }
  }
</style>
