<template>
  <div>
    <span class="selector">
      <a @click.stop="selectDialog = true">
        <v-icon v-if="icon">{{ icon }}</v-icon>

        <span v-else>Select icon</span>
      </a>
    </span>

    <v-dialog v-model="selectDialog" width="800" height="600">
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" label="Filter icons" autofocus />
        </v-card-title>

        <v-card-text class="text-center">
          <div v-for="icon in list" :key="icon" class="button ma-1 pa-2" @click="setIcon(icon)">
            <v-icon>mdi-{{ icon }}</v-icon>
          </div>
        </v-card-text>

        <v-card-actions class="pa-8">
          <div class="text-center">
            <div
              v-for="page in pages"
              :key="page"
              class="button text-center ma-1 py-3"
              :class="active === page ? 'active' : ''"
              @click="active = page"
            >
              {{ page + 1 }}
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { icons, iconsCountLimit } from '#common/constants'

  @Component({})
  class IconPicker extends Vue {
    @Prop() public icon!: string

    @Emit('setIcon')
    public setIcon(icon: string) {
      this.selectDialog = false

      return `mdi-${icon}`
    }

    public active = 0

    public search = ''

    public selectDialog = false

    public list: string[] = []
    public pages: number[] = []

    @Watch('active')
    protected onActiveChange() {
      this.filterResults()
    }

    @Watch('search')
    protected onSearchChange() {
      this.active = 0

      this.filterResults()

      this.pages = [...Array(Math.ceil(this.list.length / iconsCountLimit)).keys()]
    }

    public mounted() {
      this.list = icons.slice(this.active, iconsCountLimit)

      this.pages = [...Array(Math.ceil(icons.length / iconsCountLimit)).keys()]
    }

    private filterResults() {
      this.list = icons
        .filter((icon) => (this.search ? icon.indexOf(this.search) >= 0 : true))
        .slice(this.active * iconsCountLimit, this.active * iconsCountLimit + iconsCountLimit)
    }
  }

  export default toNative(IconPicker)
</script>

<style lang="scss" scoped>
  .button {
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 1;
    border: 1px solid #efefef;

    &.active {
      color: #fff;
      background: rgb(var(--v-theme-primary));
      border-color: rgb(var(--v-theme-primary));
    }
  }

  .selector {
    cursor: pointer;
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 2px 2px 0 rgb(0 0 0 / 5%);

    span {
      display: block;
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
    }
  }
</style>
