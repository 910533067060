<template v-if="message.links">
  <div class="fill-height text-left">
    <div class="d-flex flex-column fill-height pa-1" style="max-width: 320px">
      <div class="d-flex flex-column card grow" :class="[category, preview ? 'preview' : '']">
        <div v-if="category === 'slideshow'" class="text-left">
          <v-icon class="close mb-4" color="white">mdi-close</v-icon>
        </div>

        <div class="d-flex flex-column grow">
          <div class="d-flex grow justify-center">
            <v-btn class="align-self-center" color="white" variant="outlined">
              <v-icon class="mr-2">mdi-reload</v-icon>
              <span style="text-transform: none">Replay</span>
            </v-btn>
          </div>

          <div class="grow">
            <div v-if="category === 'slideshow'" class="align-self-center pa-2" style="font-weight: bold">
              Keep learning
            </div>
            <div v-if="message.links" class="links py-2">
              <div v-for="(link, index) in message.links" :key="link.id">
                <v-tooltip top>
                  <template #activator="{ props }">
                    <div class="d-flex align-center" v-bind="props">
                      <v-icon class="px-2" color="white">mdi-image-multiple-outline</v-icon>
                      <span style="font-weight: bold">{{ link.title.text || 'No title' }}</span>
                    </div>
                  </template>

                  {{ link.value || 'No value' }}
                </v-tooltip>

                <v-divider v-if="index < message.links.length - 1" class="my-2" />
              </div>
            </div>
          </div>

          <div class="grow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Message } from '#types'

  @Component({})
  class LinksObjectPreview extends Vue {
    @Prop() public message!: Message
    @Prop() public category!: string

    @Prop() public preview!: boolean
    @Prop() public simulate!: boolean
  }

  export default toNative(LinksObjectPreview)
</script>

<style lang="scss" scoped>
  .card {
    background: rgba(36, 71, 123, 0.8);
    border-radius: 12px;
    padding: 0.5em 0.25em;
    font-size: 16px;
    text-align: center;

    &.preview {
      font-size: 12px;
    }
  }

  .links {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }

  .slideshow {
    border-radius: 4px;
    text-align: left;
  }
</style>
