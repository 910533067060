<template>
  <div v-if="message.facets.notification" class="pa-1">
    <div class="text-center py-2">
      <div class="heading-4">{{ message.facets.notification.title.text }}</div>
      <div class="body-2">{{ message.facets.notification.body.text }}</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Message } from '#types'

  @Component({})
  class NotificationFacetPreview extends Vue {
    @Prop() public message!: Message
  }

  export default toNative(NotificationFacetPreview)
</script>
