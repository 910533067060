<template>
  <div>
    <v-divider class="my-2" />

    <div class="text-h5 mb-4">{{ title }}</div>

    <v-row>
      <template v-if="!!componentItems.length">
        <v-col v-for="component in componentItems" :key="component.value" cols="12" sm="3" md="3">
          <v-checkbox
            v-model="selected"
            multiple
            :label="component.text"
            :value="component.value"
            @update:model-value="updateSelected()"
          />
        </v-col>
      </template>
      <template v-else>
        <v-col>
          <div class="my-8">No allowed components set, please select first the allowed components</div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { lowerCase, sortBy, upperFirst } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { componentMapping, deprecatedComponents, replaceableTitles } from '#views/messages/constants'

  @Component({})
  class ComponentsList extends Vue {
    @Prop() public id!: string

    @Prop() public title!: string

    @Prop({ default: () => [] }) public allowedComponents!: string[]
    @Prop({ default: () => [] }) public requiredComponents!: string[]
    @Prop({ default: () => [] }) public selectedComponents!: string[]

    public selected: string[] = []

    public get componentItems() {
      return sortBy(
        componentMapping
          .filter(
            (c) =>
              (this.allowedComponents.length > 0 && this.allowedComponents.includes(c.type)) ||
              (this.allowedComponents.length == 0 && this.id == 'allowedComponents'),
          )
          .filter((c) => !deprecatedComponents.includes(c.type))
          .map((c) => ({
            text: this.formatComponentName(c.type),
            value: c.type,
          })),
        'text',
      )
    }

    @Emit('componentsChanged')
    public updateSelected() {
      return { id: this.id, selected: this.selected }
    }

    @Watch('selectedComponents', { immediate: true })
    protected onSelectedComponentsChanged() {
      this.selected = this.selectedComponents.filter(
        (c) =>
          (this.allowedComponents.length > 0 && this.allowedComponents.includes(c)) || this.id == 'allowedComponents',
      )
    }

    private formatComponentName(type: string) {
      // We just want to change display name in component list.

      if (replaceableTitles[type]) {
        return replaceableTitles[type]
      }

      return (
        upperFirst(lowerCase(type.replace(/_/g, ' '))) + (deprecatedComponents.includes(type) ? ' (deprecated)' : '')
      )
    }
  }

  export default toNative(ComponentsList)
</script>

<style lang="scss"></style>
