<template>
  <div>{{ name }}</div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  // This is a default preview component
  // It will only show the component type in the preview

  @Component({})
  class PreviewComponent extends Vue {
    @Prop() public name!: string
  }

  export default toNative(PreviewComponent)
</script>
