<template>
  <div class="pa-2 pb-4">
    <div v-if="component.title" class="pb-1 body-2">{{ component.title.text }}</div>
    <v-sparkline
      color="white"
      :model-value="mockChartData"
      :padding="2"
      :line-width="3"
      :type="component.presentation === 'line' ? 'trend' : 'bar'"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentChart } from '#types'

  @Component({})
  class ChartPreview extends Vue {
    @Prop() public component!: UIComponentChart

    public get mockChartData() {
      return [3, 4, 5, 6, 7, 3, 4]
    }
  }

  export default toNative(ChartPreview)
</script>
