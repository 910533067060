<template>
  <v-sheet>
    <v-data-table
      v-model="selected"
      :items="inFilterView ? editorInsights : priorityInsights"
      :headers="inFilterView ? headers : simHeaders"
      :loading="loading"
      style="width: 100%"
      single-select
      items-per-page="1000"
      disable-pagination
      hide-default-footer
    >
      <template #[`item.tags`]="{ item }">
        {{ item.tags.join(', ') }}
      </template>

      <template #[`item.state`]="{ item }">
        <StateChip :readonly="true" :disabled="!item.category" :state-key="item.state" />
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script lang="ts">
  import { compact, sortBy, uniqBy } from 'lodash-es'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { editListHeaders, priorityWeights, simListHeaders } from '#views/priorities/constants'

  import { InsightsStore } from '#stores'

  import { ContributorScore, PriorityInsight, Weight } from '#types'

  @Component({})
  class PriorityEditTable extends Vue {
    @Prop() public store!: string
    @Prop() public tagFilters?: string[]
    @Prop() public stateFilters?: string[]
    @Prop() public categoryFilters?: string[]
    @Prop() public groupIdFilters?: string[]
    @Prop() public insightIdFilters?: string[]
    @Prop() public tableToggle!: string
    @Prop() public hacksToggle!: boolean
    @Prop() public contributorsToggle!: boolean
    @Prop() public weightsToggle!: boolean
    @Prop() public storedContributors!: ContributorScore[]
    @Prop() public storedWeights!: Weight[]
    @Prop() public changesMade!: boolean

    @Emit('updateInsightCount')
    public updateInsightCount() {
      return this.filteredInsights.length
    }

    public tags: string[] = []
    public defaultPriorityWeights: Weight[] = priorityWeights
    public filteredInsights: PriorityInsight[] = []
    public editorInsights: PriorityInsight[] = []
    public priorityInsights: PriorityInsight[] = []
    public intersected: string[] = []
    public selected: any[] = []
    public headers = editListHeaders
    public simHeaders = simListHeaders

    protected insightsStore = new InsightsStore()

    public get loading(): any {
      return this[`${this.store}Store` as keyof PriorityEditTable].loading
    }

    public get insights(): any {
      return this[`${this.store}Store` as keyof PriorityEditTable].insights
    }

    public get searchResults(): any {
      return this[`${this.store}Store` as keyof PriorityEditTable].searchResults
    }

    public get inFilterView(): boolean {
      return this.tableToggle === 'FILTER'
    }

    @Watch('store')
    protected storeChanged() {
      this.updateFilteredInsights()
    }

    @Watch('insights')
    protected insightsUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('tagFilters')
    protected tagFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('stateFilters')
    protected stateFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('categoryFilters')
    protected categoryFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('groupIdFilters')
    protected groupIdFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('insightIdFilters')
    protected insightIdFiltersUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('tableToggle')
    protected tableToggleUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('hacksToggle')
    protected hacksToggleUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('contributorsToggle')
    protected contributorsToggleUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('weightsToggle')
    protected weightsToggleUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('storedContributors', { deep: true })
    protected storedContributorsUpdated() {
      this.updateFilteredInsights()
    }

    @Watch('storedWeights', { deep: true })
    protected storedWeightsUpdated() {
      this.updateFilteredInsights()
    }

    public mounted() {
      this.updateFilteredInsights()
    }

    private contributorSuitable() {
      const contributors: ContributorScore[] = this.storedContributors
      for (const key in contributors) {
        if (contributors[key].value.toString() === '') {
          return false
        }
      }
      return true
    }

    private evaluatePriority(priorityFunction: string) {
      const priorityValue = eval?.(`"use strict";(${priorityFunction})`)
      return priorityValue
    }

    private fillPriorityInsights() {
      let work: PriorityInsight[] = []
      if (!this.filteredInsights) {
        this.priorityInsights = []
      } else {
        let copyInsights: PriorityInsight[] = []
        copyInsights = this.filteredInsights
        const simPriorities = copyInsights.map(this.addOutputPriority)
        let sortDefault = sortBy(simPriorities, 'default_output').reverse()
        let sortOutput = sortBy(simPriorities, 'priority_output').reverse()
        const defaultPositionedInsights: any[] = this.modifyPosition(sortDefault, 'default')
        const outputPositionedInsights: any[] = this.modifyPosition(sortOutput, 'output')
        const combinedPositions = defaultPositionedInsights.map((item: any) => {
          const outputPosition = outputPositionedInsights.find((item1: any) => item1.id === item.id).new_position
          return { ...item, new_position: outputPosition }
        })

        work = combinedPositions.map((item) => {
          const newPosition = item.new_position ?? 0
          const defaultPosition = item.position ?? 0
          return { ...item, position_change: defaultPosition - newPosition }
        })
      }
      this.priorityInsights = work
    }

    private addOutputPriority(insight: PriorityInsight) {
      const { id, priority, position } = insight
      if (priority.includes('random_number')) {
        return { id, priority, default_output: 0, priority_output: 0, position }
      }
      if (!priority.includes(',')) {
        const numericalPriority = parseInt(priority)
        return { id, priority, default_output: numericalPriority, priority_output: numericalPriority, position }
      }
      const indices: number[] = []
      for (let i = 0; i < priority.length; i++) {
        if (priority[i] === ',') {
          indices.push(i)
        }
      }
      // workaround for unique priority value in sleep_regularity_trending_up_already_optimal
      if (indices.length > 4) {
        if (priority.includes('Math.abs(77.5 - Math.max')) {
          indices.splice(1, 1)
        }
      }
      // default values are from user data in the next version
      const modPrio = priority.replace('incidence()', '1')
      const defaultContributorPriority = this.convertToSavedContributorValues(modPrio, indices, true)
      const defaultPriority = this.convertToSavedWeightValue(defaultContributorPriority, indices, true)
      const editedContributorPriority = this.convertToSavedContributorValues(modPrio, indices, false)
      const editedPriority = this.convertToSavedWeightValue(editedContributorPriority, indices, false)
      return {
        id,
        priority,
        default_output: this.evaluatePriority(defaultPriority),
        priority_output: this.evaluatePriority(editedPriority),
        position,
      }
    }

    private modifyPosition(sortedInsights: any[], type: string) {
      // if previous item has same priority output value, position is the same
      // add default positions and then output positions to the same array

      let positionValue = 0
      const positionModifiedInsights = sortedInsights.map((item, index) => {
        if (index === 0) {
          positionValue = 1
          if (type === 'output') {
            return { ...item, new_position: 1 }
          } else {
            return { ...item, position: 1 }
          }
        } else if (item.priority_output === sortedInsights[index - 1].priority_output) {
          if (type === 'output') {
            return { ...item, new_position: positionValue }
          } else {
            return { ...item, position: positionValue }
          }
        } else {
          positionValue = positionValue + 1
          if (type === 'output') {
            return { ...item, new_position: positionValue }
          } else {
            return { ...item, position: positionValue }
          }
        }
      })

      return positionModifiedInsights
    }

    private convertToSavedContributorValues(priority: any, indices: any[], defaultValues: boolean) {
      if (defaultValues) {
        if (indices.length === 0) {
          return priority
        }
        return priority.replace(priority.substring(indices[0] + 1, indices[1]), ' 77')
      } else {
        if (indices.length === 0) {
          return priority
        }
        let contributorPriority = priority.replace(priority.substring(indices[0] + 1, indices[1]), ' 77')
        if (this.contributorsToggle) {
          const testDummy = this.storedContributors
          testDummy.forEach((item) => {
            if (priority.includes(item.contributor)) {
              contributorPriority = priority.replace(
                priority.substring(indices[0] + 1, indices[1]),
                ' ' + item.value.toString(),
              )
            }
          })
        }
        return contributorPriority
      }
    }

    private convertToSavedWeightValue(priority: string, indices: any[], defaultValues: boolean) {
      let values: Weight[] = []
      let matchFound = false
      let match: string = ''
      let value: number = 0
      if (defaultValues) {
        values = this.defaultPriorityWeights
      } else if (this.weightsToggle) {
        values = this.storedWeights
      } else {
        values = this.defaultPriorityWeights
      }
      const weightOnly = priority.substring(priority.indexOf('(') + 1, indices[0]).replace(' ', '')
      values.forEach((item) => {
        if (weightOnly === item.weight) {
          matchFound = true
          match = item.weight
          value = item.new_value ?? item.value
          if (value.toString() == '') {
            value = item.value
          }
        }
      })
      if (matchFound) {
        return priority.replace(match, value.toString())
      }
      return priority.replace(weightOnly, '1 ')
    }

    @Debounce(100)
    public updateFilteredInsights() {
      let insights = [...this.insights]

      if (this.tagFilters?.length) {
        insights = insights.filter((insight) => insight.tags.some((t: any) => this.tagFilters!.includes(t)))
      }

      if (this.stateFilters?.length) {
        insights = insights.filter((insight) => insight.state && this.stateFilters!.includes(insight.state))
      } else {
        insights = insights.filter((insight) => insight.state && insight.state !== 'archived')
      }

      if (this.categoryFilters?.length) {
        insights = insights.filter((insight) => this.categoryFilters!.includes(insight.category))
      }

      if (this.groupIdFilters?.length) {
        insights = insights.filter((insight) => this.groupIdFilters!.includes(insight.group_id))
      }
      if (this.hacksToggle) {
        insights = insights.filter((insight) => insight.priority.includes('priority'))
      }

      if (this.insightIdFilters) {
        // We want to order the insights in the same order as the search results indicate
        insights = compact(this.insightIdFilters.map((id) => insights.find((insight) => insight.id === id)))
      }

      insights = uniqBy(insights, 'id')

      this[`${this.store}Store` as keyof PriorityEditTable].searchResults = insights

      this.filteredInsights = insights
      if (this.editorInsights.length != insights.length) {
        this.editorInsights = insights
      }
      if (this.contributorSuitable()) {
        this.fillPriorityInsights()
      }
      if (this.inFilterView) {
        this.editorInsights = insights
      }
      this.updateInsightCount()
    }
  }

  export default toNative(PriorityEditTable)
</script>
