<template>
  <v-app-bar v-if="!!insight">
    <v-app-bar-title class="ml-2">
      <v-btn icon="mdi-arrow-left" @click="checkChanges()" />

      <v-menu>
        <template #activator="{ props }">
          <v-btn v-bind="props" append-icon="mdi-chevron-down" size="large" depressed class="ml-n1 px-3">
            <span v-if="!messageId">
              {{ inSlideshows ? 'Slide show' : inTipthemes ? 'Tip theme' : 'Insight' }} Configuration
            </span>

            <span v-else>
              {{ inSlideshows ? 'Slide show slide' : inTipthemes ? 'Tip text variant' : 'message variant' }}
              {{ (messages?.findIndex((m) => m.id === messageId) || 0) + 1 }}
            </span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            append-icon="mdi-pencil"
            :disables="!messageId"
            @click="$router.push(`/${$route.path.split('/')[1]}/${insightId}`)"
          >
            <v-list-item-title>
              {{ inSlideshows ? 'Slide show' : inTipthemes ? 'Tip theme' : 'Insight' }} configuration
            </v-list-item-title>
          </v-list-item>

          <v-divider />

          <v-list-item
            v-for="(item, index) in messages"
            :key="item.id"
            :disabled="item.id === messageId"
            @click="$router.push(getMessageUrl(item.id))"
          >
            <template #append>
              <v-avatar>
                <v-badge dot class="mr-n6 mt-n2" :color="messageColorById(item.id)" />
              </v-avatar>
            </template>
            <v-list-item-title>
              {{ inSlideshows ? 'Slide show slide' : inTipthemes ? 'Tip text variant' : 'Message variant' }}
              {{ index + 1 }}
            </v-list-item-title>
          </v-list-item>

          <v-divider />

          <v-list-item append-icon="mdi-plus-thick" @click="createMessage()">
            <v-list-item-title class="primary--text">
              Add new {{ inSlideshows ? 'slide show slide' : inTipthemes ? 'tip text' : 'message' }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar-title>

    <v-spacer />

    <v-tooltip bottom text="Toggle message preview">
      <template #activator="{ props }">
        <v-btn icon="mdi-eye" :disabled="!messageId" v-bind="props" @click="openPreview()" />
      </template>
    </v-tooltip>

    <v-tooltip v-if="!inWorkspace" location="bottom">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-cloud-download-outline"
          :loading="isExportingInsights"
          @click="exportInsights()"
        />
      </template>

      Download content
    </v-tooltip>

    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon :active="openPanel === 'notes'" @click="togglePanel('notes')">
          <v-badge
            overlap
            color="primary"
            :content="message ? message.notes : insight.notes"
            :model-value="message ? !!message.notes : !!insight.notes"
          >
            <v-icon>mdi-note-text-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>

      Notes / messages
    </v-tooltip>

    <v-tooltip v-if="$featureEnabled('daphneIntegration') && !inSlideshows && !inTipthemes" location="bottom">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-auto-fix" :active="openPanel === 'daphne'" @click="togglePanel('daphne')" />
      </template>

      Generate new messages
    </v-tooltip>

    <v-tooltip location="bottom">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-cog"
          :active="openPanel === 'info'"
          :disabled="hasChanges"
          @click="togglePanel('info')"
        />
      </template>

      Metadata / settings
    </v-tooltip>

    <StateChip
      v-if="!message"
      class="mr-6"
      :readonly="!insight.project || (!inWorkspace && !isInsightsEditor)"
      :state-key="insight.state"
      @select="editState()"
    />

    <StateChip
      v-else-if="!inWorkspace"
      class="mr-6"
      :readonly="!insight.project || (!inWorkspace && !isInsightsEditor)"
      :state-key="message.state"
      :dangerous="message.generated"
      @select="editState()"
    />
  </v-app-bar>

  <v-container class="d-flex flex-column align-stretch flex-grow-1 flex-shrink-1 flex-nowrap">
    <template v-if="!insight">
      <v-progress-circular class="ma-auto" size="96" indeterminate color="primary" />
    </template>

    <template v-else>
      <v-row class="flex-grow-0">
        <v-col class="flex-grow-1 flex-shrink-1" style="min-width: 0">
          <div
            class="text-h5 font-weight-light flex-shrink-1 text-wrap"
            style="text-overflow: ellipsis; overflow: hidden"
          >
            {{ !message ? insight.id.toUpperCase() : message.id.toUpperCase() }}
          </div>
          <div class="text-subtitle-2 text-medium-emphasis font-weight-light flex-grow-0">
            {{
              !message
                ? insight.description || 'No description for this insight, please add one'
                : message.template
                  ? 'This message was created from template ' +
                    (templates.find((t) => t.id === message!.template)?.name || 'that has been deleted')
                  : 'This message was created from scratch, no template was used'
            }}
          </div>
        </v-col>

        <v-col class="d-flex flex-grow-0 justify-end align-top">
          <v-chip class="font-weight-bold" :text="insight.category.toUpperCase()" />
        </v-col>
      </v-row>

      <v-row v-if="insight.tags" class="my-0">
        <v-col>
          <v-chip v-for="tag in humanReadableTags" :key="tag" size="small" class="mr-2" style="opacity: 0.6">
            {{ tag }}
          </v-chip>
        </v-col>
      </v-row>

      <InsightEditor
        v-if="!messageId"
        :insight="insight"
        :insight-path="insightPath"
        :changed-rules="changedRules"
        @changed="hasChanges = $event"
      />

      <MessageEditor
        v-else
        :insight-id="insightId"
        :link-path="insightPath"
        :insight-path="insightPath"
        :message-path="messagePath"
        @changed="hasChanges = $event"
      />
    </template>
  </v-container>

  <NotesPanel v-if="!!insight" :title="messageId ? 'Message' : 'Insight'" :path="notesPath" />

  <DaphnePanel v-if="!!insight" :insight="insight" :message-order="messages?.length" />

  <InsightPanel
    v-if="!!insight"
    :title="store.slice(0, -1)"
    :store="store"
    :insight="insight"
    :insight-path="insightPath"
  />

  <CommitDialog ref="commitDialogRef" />
  <HistoryDialog ref="historyDialogRef" />
  <ChangeStateDialog ref="changeStateDialogRef" />
  <MessagePreview ref="messagePreviewRef" />

  <SendToReview ref="sendToReviewDialogRef" />

  <CreateMessageDialog
    ref="createMessageDialogRef"
    :insight="insight"
    :messages="messages"
    :insight-path="insightPath"
  />
</template>

<script lang="ts">
  import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import {
    Unsubscribe,
    collection,
    doc,
    getFirestore,
    onSnapshot,
    orderBy as orderByFirestore,
    query,
    setDoc,
  } from 'firebase/firestore'

  import { messageStates } from '#views/insights/constants'

  import { createDefaultMessage, getOrigin } from '#views/messages/utilities'

  import { AppStore, InsightsStore, SlideshowsStore, TemplatesStore, TipthemesStore, WorkspaceStore } from '#stores'

  import { Author, Dialog, Insight, Message, Preview } from '#types'

  @Component({})
  class InsightView extends Vue {
    @Prop() public store!: string

    @Prop() public insightId!: string
    @Prop() public messageId!: string

    @Prop() public insightPath!: string
    @Prop() public messagePath!: string

    @Ref() private readonly commitDialogRef: Dialog | null = null
    @Ref() private readonly historyDialogRef: Dialog | null = null
    //@Ref() private readonly editRuleDialogRef: Dialog | null = null
    @Ref() private readonly changeStateDialogRef: Dialog | null = null
    @Ref() private readonly sendToReviewDialogRef: Dialog | null = null
    @Ref() private readonly createMessageDialogRef: Dialog | null = null
    @Ref() private readonly messagePreviewRef: Preview | null = null

    public type = ''
    public activeTab = 'view'

    public isLoading = false
    public hasReviews = false
    public hasChanges = false
    public disableSaveChanges = false
    public isExportingInsights = false

    public changedRules: string[] = []

    public author: Author | null = null
    public edited: string | null = null

    public insight: Insight | null = null
    public messages: Message[] | null = []

    private readonly appStore = new AppStore()

    private readonly templatesStore = new TemplatesStore()
    private readonly workspaceStore = new WorkspaceStore()

    private readonly insightsStore = new InsightsStore()
    private readonly tipthemesStore = new TipthemesStore()
    private readonly slideshowsStore = new SlideshowsStore()

    private unsubscribe: Unsubscribe | undefined = undefined
    private unsubscribeReviews: Unsubscribe | undefined = undefined
    private unsubscribeMessages: Unsubscribe | undefined = undefined

    public get user() {
      return this.appStore.user!
    }

    public get message() {
      return this.messages?.find((m) => m.id === this.messageId)
    }

    public get templates() {
      return this.templatesStore.templates
    }

    public get rootPath() {
      return this.appStore.route[1]
    }

    public get openPanel() {
      return this.appStore.navDrawer
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get inMessaging() {
      return this.appStore.inMessaging
    }

    public get inTipthemes() {
      return this.appStore.inTipthemes
    }

    public get inSlideshows() {
      return this.appStore.inSlideshows
    }

    public get insights(): any[] {
      return this[`${this.store}Store` as keyof InsightView].searchResults.length > 0
        ? this[`${this.store}Store` as keyof InsightView].searchResults
        : this[`${this.store}Store` as keyof InsightView].insights
    }

    public get notesPath() {
      return this.messageId ? this.messagePath : this.insightPath
    }

    public get messageName() {
      return this.insightPath.startsWith('/slideshows/') ? 'Slide' : 'Message'
    }

    public get messagesPath() {
      return `${this.insightPath}/messages`
    }

    public get searchResults(): any {
      return this[`${this.store}Store` as keyof InsightView].searchResults
    }

    public get humanReadableTags() {
      const tags = this.insightsStore.featureTags

      return tags.filter((tag: any) => this.insight!.tags.includes(tag.value)).map((tag: any) => tag.text)
    }

    public get isInsightsEditor() {
      return this.appStore.isInsightsEditor
    }

    @Watch('insightPath', { immediate: true })
    protected insightPathChanged() {
      this.edited = ''

      this.getInsight()
      this.getMessages()
      this.checkReviews()
    }

    public beforeUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe()
      }

      if (this.unsubscribeReviews) {
        this.unsubscribeReviews()
      }
      if (this.unsubscribeMessages) {
        this.unsubscribeMessages()
      }
    }

    public openPreview() {
      this.messagePreviewRef!.open(this.message!, this.activeTab, this.insight?.category)
    }

    public editState() {
      if (this.message) {
        this.changeStateDialogRef!.open(this.message, this.messagePath, this.inSlideshows ? 'Slide' : 'Message')
      } else if (this.inWorkspace && this.insight?.state === 'created') {
        this.sendToReviewDialogRef!.open(this.insightId, this.insightPath)
      } else if (!this.inWorkspace && this.insight?.state !== 'deleted') {
        this.changeStateDialogRef!.open(this.insight, this.insightPath, 'Insight')
      }
    }

    public browseBack() {
      this[`${this.store}Store` as keyof InsightView].activeInsight = this.insight!.id

      const previousRoute = this.appStore.previousRoute

      if (/rules/.test(previousRoute)) {
        this.$router.push(previousRoute)
      } else if (/approvals/.test(previousRoute)) {
        this.$router.push(`/approvals`)
      } else if (this.inWorkspace) {
        this.$router.push(`/workspace/${this.appStore.uuid}`)
      } else {
        this.$router.push({ path: `/${this.rootPath}`, query: this.$route.query })
      }
    }

    public togglePanel(panel: string) {
      this.appStore.toggleNavDrawer(panel)
    }

    public checkChanges() {
      if (this.hasChanges) {
        this.$confirm('There are unsaved changes!', ' Do you want to proceed without saving?').then(
          async (confirmed) => confirmed && this.browseBack(),
        )
      } else {
        this.browseBack()
      }
    }

    public disableEdit() {
      return this.appStore.inApprovals || this.insight!.state === 'approved'
    }

    private async getMessages() {
      if (this.unsubscribeMessages) {
        this.unsubscribeMessages()
      }

      this.unsubscribeMessages = await onSnapshot(
        query(collection(getFirestore(), this.messagesPath), orderByFirestore('order')),
        (snap) => {
          this.messages = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Message[]
        },
      )
    }

    public getMessageUrl(id: string) {
      return `/${this.rootPath}/${this.insightId}/messages/${id}`
    }

    public exportInsights() {
      const collection = this.insightPath.split('/')[1]

      this.$confirm('Include translations?').then(async (confirmed) => {
        this.isExportingInsights = true

        await this.insightsStore.downloadInsights({
          insightIds: [this.insightId],
          collectionPath: collection,
          insightConfigData: !confirmed,
        })

        this.isExportingInsights = false
      })
    }

    public async createMessage() {
      this.isLoading = true

      const data = createDefaultMessage(this.insightId, getOrigin(this.insightPath), this.messages?.length)

      await setDoc(doc(getFirestore(), `${this.insightPath}/messages`, data.id), data)

      this.isLoading = false

      this.$router.push(`/${this.appStore.mainPath}/${this.insightId}/messages/${data.id}`)
    }

    public updateSaveChanges(success: boolean) {
      this.disableSaveChanges = !success
    }

    public openCreateMessageDialog() {
      this.createMessageDialogRef!.open()
    }

    public messageColorById(id: string) {
      const message = this.messages?.find((m) => m.id === id)

      return messageStates.find((s) => s.value === message?.state)?.color || 'grey-lighten-2'
    }

    private async getInsight() {
      this.isLoading = true
      if (this.unsubscribe) {
        this.unsubscribe()
      }
      this.unsubscribe = await onSnapshot(doc(getFirestore(), this.insightPath), (snap) => {
        const data = snap.data()
        if (!data) {
          return
        }
        const insight = { id: snap.id, ...data } as Insight

        this.insight = insight

        this.isLoading = false
      })
    }

    private async checkReviews() {
      if (this.inWorkspace) {
        this.unsubscribeReviews = await onSnapshot(
          doc(getFirestore(), `${this.insightPath.replace('insights', 'reviews')}`),
          (snap) => {
            this.hasReviews = Object.keys(snap.data() || {}).length > 0 ? true : false
          },
        )
      }
    }
  }

  export default toNative(InsightView)
</script>

<style lang="scss" scoped>
  .hidden {
    display: none;
  }

  :deep(.conditions) {
    .v-select__slot {
      position: static;
    }
  }

  :deep(#messages.v-window-item) {
    max-width: none;
    margin: 0 24px;

    .messages {
      max-width: 1300px;
      max-height: none !important;
    }
  }
</style>
