<template>
  <v-dialog v-if="insight" v-model="isOpen" width="800px">
    <v-card>
      <v-card-title>Create a new {{ insightName }}</v-card-title>

      <v-card-text>
        <p class="mb-4">
          Create a new {{ insightName }} by giving some basic information about the {{ insightName }}, all other fields
          than the
          <b v-if="!inWorkspace">Entity identifier</b>
          <b v-else>Name</b>
          can be edited later through the settings panel in {{ insightName }} editing view.
        </p>

        <InsightConfig
          v-model="insight"
          v-model:template="template"
          :store="store"
          :readonly="false"
          :create-mode="true"
          :category-items="categoryItems"
        />

        <v-row>
          <v-col>
            <div class="text-overline">Template selector</div>

            <div class="mx-n1" style="max-height: 240px; overflow: auto">
              <v-btn v-for="t in templates" :key="t.id" icon class="template-button" @click="template = t">
                <div :class="`${template && template.id == t.id ? 'active' : ''}`">
                  <v-icon dark>{{ t.icon }}</v-icon>
                  <div>{{ t.name }}</div>
                </div>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-checkbox v-model="createMultiple" hide-details label="Create multiple similarly named insights" />

        <v-spacer />

        <v-btn text="Cancel" @click="cancelInsightCreate()" />
        <v-btn text="Create" color="primary" :loading="isLoading" :disabled="!isFormValid" @click="createInsight()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore'

  import { createDefaultInsight } from '#views/insights/utilities'
  import { createMessage } from '#views/messages/utilities'

  import {
    AppStore,
    ContentsStore,
    InsightsStore,
    ProjectsStore,
    SlideshowsStore,
    TemplatesStore,
    TipthemesStore,
    WorkspaceStore,
  } from '#stores'

  import { Category, Insight, Template } from '#types'

  import { isIdentifierValid } from '#utilities'

  @Component({})
  class CreateInsight extends Vue {
    @Prop() public store!: string

    @Prop() public insightsPath!: string
    @Prop() public categoryItems!: Category[]

    public isOpen = false
    public isLoading = false
    public createMultiple = false

    public insight: Insight | null = null
    public template: Template | null = null

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()

    private readonly templatesStore = new TemplatesStore()
    private readonly workspaceStore = new WorkspaceStore()

    // Do not remove these stores, they are dynamically used based on the store prop
    private readonly contentsStore = new ContentsStore()
    private readonly insightsStore = new InsightsStore()
    private readonly tipthemesStore = new TipthemesStore()
    private readonly slideshowsStore = new SlideshowsStore()

    public get project() {
      return this.projectsStore.project
    }

    public get inMessaging() {
      return this.appStore.inMessaging
    }

    public get inTipthemes() {
      return this.appStore.inTipthemes
    }

    public get inSlideshows() {
      return this.appStore.inSlideshows
    }

    public get templates() {
      return this.templatesStore.templates.filter((t: Template) => {
        if (this.inTipthemes) {
          return t.allowedPages.includes('tipthemes')
        } else if (this.inSlideshows) {
          return t.allowedPages.includes('slideshows')
        } else if (this.inMessaging) {
          return t.allowedPages.includes('insights')
        }
      })
    }

    public get inWorkspace() {
      return this.appStore.inWorkspace
    }

    public get isIdentifierValid(): boolean {
      return this.insight?.id === '' ? false : isIdentifierValid(this.insight?.id || '')
    }

    public get insightName() {
      if (typeof this.$route.meta?.create !== 'string') {
        return 'insight'
      }

      return this.$route.meta.create.toLowerCase()
    }

    public get isFormValid() {
      return this.isIdentifierValid && this.insight?.project && this.insight?.category
    }

    public open() {
      this.isOpen = true
      this.isLoading = false

      this.createMultiple = false

      this.insight = { ...createDefaultInsight(), id: '', project: this.project?.id || null }
    }

    public async createInsight() {
      this.isLoading = true

      const data = {
        ...this.insight,
        id: this.insight!.id,
        author: this.appStore.author,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }

      await setDoc(doc(getFirestore(), `${this.insightsPath}/${this.insight!.id}`), data)

      this[`${this.store}Store` as keyof CreateInsight].activeInsight = this.insight!.id

      if (this.template) {
        const messageData = await createMessage({
          insight: data,
          messages: [],
          template: this.template,
          insightsPath: this.insightsPath,
          insightPath: `${this.insightsPath}/${this.insight!.id}`,
        })

        if (!this.createMultiple) {
          this.isOpen = false
          this.isLoading = false

          this.$router.push(`${this.appStore.mainPath}/${this.insight!.id}/messages/${messageData.id}#expand`)
        }
      }

      if (!this.createMultiple) {
        this.isOpen = false
      }

      this.isLoading = false
    }

    public cancelInsightCreate() {
      this.isOpen = false
      this.isLoading = false

      this.template = null
    }
  }

  export default toNative(CreateInsight)
</script>

<style lang="scss" scoped>
  :deep(.v-btn) {
    &.template-button {
      width: auto;
      height: auto;
      margin: 4px;

      .v-ripple__container {
        display: none !important;
      }

      .v-btn__content {
        & > div {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          width: 100px;
          height: 100px;
          padding: 16px 0 10px 0;
          color: #666;
          font-size: 16px;
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 3px;

          &.active {
            border-color: rgb(var(--v-theme-primary));
          }

          & > div {
            padding: 8px 8px 10px;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.5px;
            text-transform: none;
            white-space: normal;
          }
        }
      }
    }
  }
</style>
