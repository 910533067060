<template>
  <tr :class="backgroundColor" @click="emitEditRelease(build)">
    <td class="text-center">
      <v-icon size="small">mdi-{{ build.platform === 'ios' ? 'apple' : 'android' }}</v-icon>
    </td>

    <td class="text-center">
      <v-icon :color="releaseTestIconColor(tests)">
        {{ releaseTestIcon(tests) }}
      </v-icon>
    </td>

    <td>{{ build.buildVersion ?? 'no build yet' }}</td>

    <td>{{ build.version ?? 'Unknown' }}</td>

    <td>{{ build.insightsEngine?.split('v')?.[1] ?? 'Unknown' }}</td>

    <td>{{ build.insightsContent ?? 'Unknown' }}</td>

    <td>{{ build.ecore?.split('-')?.[1] ?? 'Unknown' }}</td>

    <td>
      <v-tooltip location="top start">
        <template #activator="{ props }">
          <p v-bind="props">
            {{
              $dayjs(build?.createdAt?.toDate())
                .fromNow(true)
                .replace('a day', '1 day')
                .replace('an hour', '1 hour')
                .replace('a month', '1 month')
            }}
          </p>
        </template>

        {{ $dayjs(build?.createdAt?.toDate()).format('ddd, MMM DD YYYY, HH:MM') }}
      </v-tooltip>
    </td>

    <td>
      <p :class="`rounded bg-${releaseStatusColor(build.tier, isOlderBuild)} text-center px-2 py-1`">
        {{ releaseTierMeaning[build.tier || ReleaseTiers.UNKNOWN] }}
      </p>
    </td>
  </tr>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { releaseTierMeaning } from '#views/releases/constants'

  import { releaseStatusColor, releaseTestIcon, releaseTestIconColor } from '#views/releases/utilities'

  import { ReleasesStore } from '#stores'

  import { AppBuild, ReleaseTiers } from '#types'

  @Component({})
  class ReleaseListItem extends Vue {
    @Prop({ required: true }) public build!: AppBuild

    @Prop({ default: false }) public isSelected!: boolean
    @Prop({ default: false }) public isOlderBuild!: boolean
    @Prop({ default: false }) public hasGroupHeader!: boolean

    public readonly ReleaseTiers = ReleaseTiers

    public readonly releaseTierMeaning = releaseTierMeaning

    public readonly releaseTestIcon = releaseTestIcon
    public readonly releaseTestIconColor = releaseTestIconColor

    public readonly releaseStatusColor = releaseStatusColor

    private readonly releasesStore = new ReleasesStore()

    public get isReleaseCandidate() {
      const { platform, buildVersion } = this.build

      return this.releasesStore.releaseCandidates[platform] === buildVersion
    }

    public get tests() {
      return this.releasesStore.appBuildTests[this.build.commit] ?? []
    }

    public get backgroundColor() {
      if (this.isSelected && !this.isReleaseCandidate) {
        return 'bg-grey-lighten-2'
      } else if (this.isSelected && this.isReleaseCandidate) {
        return 'bg-green-lighten-4'
      } else if (this.isReleaseCandidate) {
        return 'bg-green-lighten-5'
      }

      return 'bg-transparent'
    }

    @Emit('edit-release')
    public emitEditRelease(release: any) {
      return release
    }
  }

  export default toNative(ReleaseListItem)
</script>
