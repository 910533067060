<template>
  <v-stepper v-model="rolloutEnv">
    <v-stepper-header>
      <v-stepper-item
        editable
        class="text-left"
        value="sandbox"
        title="Sandbox apps"
        :color="
          featureOverrideState(feature, currentEnvRolloutTarget(feature, 'sandbox')) === 'DISABLED'
            ? 'error'
            : progressColors[overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'sandbox'))]
        "
        :error="overrideRolloutStatus(feature, 'disable') !== 'INACTIVE'"
        :complete="isFeatureActive(feature, 'sandbox')"
        :icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'sandbox')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'sandbox'))
          ]
        "
        :edit-icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'sandbox')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'sandbox'))
          ]
        "
        :complete-icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'sandbox')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'sandbox'))
          ]
        "
      />

      <v-divider style="max-width: 15%" />

      <v-stepper-item
        class="text-left"
        value="experimental"
        title="Staging apps"
        :color="
          featureOverrideState(feature, currentEnvRolloutTarget(feature, 'staging')) === 'DISABLED'
            ? 'error'
            : progressColors[overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'staging'))]
        "
        :error="
          overrideRolloutStatus(feature, 'disable') !== 'INACTIVE' ||
          (overrideRolloutStatus(feature, 'sandbox') === 'INACTIVE' &&
            overrideRolloutStatus(feature, 'staging') === 'INACTIVE' &&
            overrideRolloutStatus(feature, 'experimental') === 'INACTIVE')
        "
        :editable="
          overrideRolloutStatus(feature, 'sandbox') !== 'INACTIVE' ||
          overrideRolloutStatus(feature, 'staging') !== 'INACTIVE' ||
          overrideRolloutStatus(feature, 'experimental') !== 'INACTIVE'
        "
        :complete="isFeatureActive(feature, 'staging')"
        :subtitle="
          overrideRolloutStatus(feature, 'sandbox') !== 'INACTIVE' ? stagingLaunchState : 'Launch sandbox first!'
        "
        :icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'staging')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'staging'))
          ]
        "
        :edit-icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'staging')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'staging'))
          ]
        "
        :complete-icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'staging')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'staging'))
          ]
        "
      />

      <v-divider style="max-width: 15%" />

      <v-stepper-item
        class="text-left"
        value="ouranians"
        title="Release apps"
        :color="
          featureOverrideState(feature, currentEnvRolloutTarget(feature, 'release')) === 'DISABLED'
            ? 'error'
            : progressColors[overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'release'))]
        "
        :error="
          overrideRolloutStatus(feature, 'disable') !== 'INACTIVE' ||
          (overrideRolloutStatus(feature, 'staging') === 'INACTIVE' &&
            overrideRolloutStatus(feature, 'release') === 'INACTIVE' &&
            overrideRolloutStatus(feature, 'ouranians') === 'INACTIVE')
        "
        :editable="
          overrideRolloutStatus(feature, 'staging') !== 'INACTIVE' ||
          overrideRolloutStatus(feature, 'release') !== 'INACTIVE' ||
          overrideRolloutStatus(feature, 'ouranians') !== 'INACTIVE'
        "
        :complete="isFeatureActive(feature, 'release')"
        :subtitle="
          overrideRolloutStatus(feature, 'staging') !== 'INACTIVE' ? releaseLaunchState : 'Launch staging first!'
        "
        :icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'release')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'release'))
          ]
        "
        :edit-icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'release')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'release'))
          ]
        "
        :complete-icon="
          statusIcons[
            featureOverrideState(feature, currentEnvRolloutTarget(feature, 'release')) === 'DISABLED'
              ? 'DISABLED'
              : overrideRolloutStatus(feature, currentEnvRolloutTarget(feature, 'release'))
          ]
        "
      />

      <v-spacer />

      <v-divider vertical class="my-4 mx-2" />

      <v-stepper-item
        title="Advanced view"
        value="advanced"
        class="text-left"
        icon="mdi-rocket"
        edit-icon="mdi-rocket"
        complete-icon="mdi-rocket"
        :error="
          overrideRolloutStatus(feature, 'disable') !== 'INACTIVE' ||
          (!hasAdvancedRollouts(feature) && overrideRolloutStatus(feature, 'sandbox') === 'INACTIVE')
        "
        :editable="hasAdvancedRollouts(feature) || overrideRolloutStatus(feature, 'sandbox') !== 'INACTIVE'"
        :complete="hasAdvancedRollouts(feature)"
        :color="hasAdvancedRollouts(feature) ? 'primary' : 'grey'"
        :subtitle="
          !hasAdvancedRollouts(feature) && overrideRolloutStatus(feature, 'sandbox') === 'INACTIVE'
            ? 'Launch sandbox first'
            : ''
        "
        @click="$router.push(`/features/${getProjectKey(feature)}/${feature.metadata?.name}/rollouts/advanced`)"
      />
    </v-stepper-header>
  </v-stepper>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { progressColors, statusColors, statusIcons } from '#views/features/constants'

  import {
    currentEnvRolloutTarget,
    featureOverrideState,
    hasAdvancedRollouts,
    isFeatureActive,
    overrideRolloutStatus,
  } from '#views/features/utilities'
  import { getProjectKey } from '#views/projects/utilities'

  import { Feature, RolloutTarget } from '#types'

  @Component({})
  class RolloutsStepper extends Vue {
    @Prop() public feature!: Feature

    @Prop() public rolloutView!: RolloutTarget

    public readonly statusIcons = statusIcons
    public readonly statusColors = statusColors
    public readonly progressColors = progressColors

    public readonly getProjectKey = getProjectKey

    public readonly isFeatureActive = isFeatureActive

    public readonly hasAdvancedRollouts = hasAdvancedRollouts

    public readonly featureOverrideState = featureOverrideState
    public readonly overrideRolloutStatus = overrideRolloutStatus

    public readonly currentEnvRolloutTarget = currentEnvRolloutTarget

    public get rolloutEnv() {
      switch (this.rolloutView) {
        case 'sandbox':
          return 'sandbox'

        case 'staging':
        case 'experimental':
          return 'experimental'

        case 'release':
        case 'ouranians':
        case 'release-ios':
        case 'release-android':
          return 'ouranians'

        default:
          return 'advanced'
      }
    }

    public set rolloutEnv(env: string) {
      this.$router.push(`/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/${env}`)
    }

    public get stagingLaunchState() {
      if (
        overrideRolloutStatus(this.feature, 'staging') !== 'INACTIVE' &&
        overrideRolloutStatus(this.feature, 'experimental') === 'INACTIVE'
      ) {
        return 'Staging app only'
      } else if (
        overrideRolloutStatus(this.feature, 'staging') === 'INACTIVE' &&
        overrideRolloutStatus(this.feature, 'experimental') !== 'INACTIVE'
      ) {
        return 'Experimental app only'
      }

      return ''
    }

    public get releaseLaunchState() {
      if (
        overrideRolloutStatus(this.feature, 'ouranians') !== 'INACTIVE' &&
        overrideRolloutStatus(this.feature, 'release') === 'INACTIVE' &&
        overrideRolloutStatus(this.feature, 'release-ios') === 'INACTIVE' &&
        overrideRolloutStatus(this.feature, 'release-android') === 'INACTIVE'
      ) {
        return 'Ouranians only'
      } else if (
        overrideRolloutStatus(this.feature, 'release-ios') !== 'INACTIVE' &&
        overrideRolloutStatus(this.feature, 'release-android') === 'INACTIVE'
      ) {
        return 'iOS only'
      } else if (
        overrideRolloutStatus(this.feature, 'release-ios') === 'INACTIVE' &&
        overrideRolloutStatus(this.feature, 'release-android') !== 'INACTIVE'
      ) {
        return 'Android only'
      }

      return ''
    }
  }

  export default toNative(RolloutsStepper)
</script>
