<template>
  <div>
    <v-select v-model="data.setting" :items="settingItems" label="Setting" />
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { settingItems } from '#views/messages/constants'

  import { UIComponentSetting } from '#types'

  @Component({})
  class Setting extends Vue {
    @Prop() public data!: UIComponentSetting

    public get settingItems() {
      return settingItems
    }
  }

  export default toNative(Setting)
</script>
