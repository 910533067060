<template>
  <div>
    <div>{{ getScoreCategoryValue(component.category) }}</div>
    <div class="half-circle"><div class="pt-6">75</div></div>
    <div>{{ component.text.text }}</div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { scoreCategoryItems } from '#views/messages/constants'

  import { UIComponentScore } from '#types'

  @Component({})
  class ScorePreview extends Vue {
    @Prop() public component!: UIComponentScore

    public getScoreCategoryValue(key: string) {
      const scoreItem = scoreCategoryItems.find((item) => item.value === key)

      return scoreItem?.title
    }
  }

  export default toNative(ScorePreview)
</script>

<style lang="sass" scoped>
  .half-circle
    margin: auto
    width: 140px
    height: 70px
    border-top-left-radius: 100px
    border-top-right-radius: 100px
    border: 6px solid white
    border-bottom: 0
    box-sizing: border-box
</style>
