<template>
  <v-select
    v-model="currentNamedCriterias"
    class="mb-n4"
    multiple
    small-chips
    no-data-text="No existing segments"
    :items="validNamedCriterias"
    :label="currentNamedCriterias.length ? 'Segments to target' : 'At least one segment needed!'"
    @update:model-value="updateNamedCriterias($event)"
  >
    <template #selection="{ item, index }">
      <v-chip small>
        {{ item.title.toUpperCase() }}
      </v-chip>

      <template v-if="index < currentNamedCriterias.length - 1">
        <span
          class="caption mx-1"
          :class="namedCriteriasOperator(override) === 'not' ? 'text-orange' : 'text-blue'"
          @mousedown.stop
          @click.stop="toggleNamedCriteriasOperator()"
        >
          {{ namedCriteriasOperator(override).toUpperCase() }}
        </span>
      </template>
    </template>
  </v-select>

  <div v-if="currentCustomCriterias.length > 0" class="my-8" style="position: relative; text-align: center">
    <v-divider v-if="currentCustomCriterias.length > 1" style="border-color: black" />

    <span
      class="px-4"
      style="position: absolute; color: black; font-weight: bold; background: white; transform: translateX(-50%)"
      :style="{ top: currentCustomCriterias.length > 1 ? '-14px' : '-28px' }"
    >
      <v-chip small>{{ currentCustomCriterias.length > 1 ? 'AND ONE OF' : 'AND' }}</v-chip>
    </span>
  </div>

  <CriteriaPreds
    action="Add custom criteria"
    :limited="unauthenticated"
    :expressions="currentCustomCriterias"
    @change="updateCustomCriterias($event)"
    @validate-expressions="emitValidate()"
  />

  <v-alert
    v-if="hasTwoOrMoreUIDPredicates"
    text="Multiple User UID's are preferrably handeled with segments, as they can be managed independently"
    class="mt-2 mb-6"
    color="warning"
  />
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { Expression } from '@jouzen/control-api/criteria'
  import { Context } from '@jouzen/control-api/metadata'

  import { createNamedCriteria, forEachNamedCriteria, namedCriteriasOperator } from '#views/features/utilities'
  import { getProjectKey } from '#views/projects/utilities'

  import { SegmentsStore } from '#stores'

  import { Override, Reference } from '#types'

  @Component({})
  class RolloutTarget extends Vue {
    @Prop() public active!: number

    @Prop() public override!: Override

    @Prop() public references!: Reference[]

    @Prop() public unauthenticated!: boolean

    public readonly namedCriteriasOperator = namedCriteriasOperator

    private readonly segmentsStore = new SegmentsStore()

    public get existingCriterias() {
      return this.segmentsStore.criterias
    }

    public get validNamedCriterias() {
      const criterias: any = {
        'percentage-sandbox': ['app_flavor_sandbox'],
        'percentage-staging': ['app_flavor_staging'],
        'percentage-release': ['app_flavor_production'],
        'percentage-ouranians': ['app_flavor_production'],
        'percentage-experimental': ['app_flavor_experimental'],
        'percentage-release-ios': ['app_flavor_production'],
        'percentage-release-android': ['app_flavor_production'],
      }

      const commonSegments = this.existingCriterias
        .filter(
          (c) =>
            getProjectKey(c) === 'common' &&
            c.expression?.oneOf?.$case !== 'predicate' &&
            (!this.unauthenticated ||
              c.metadata?.contextSpec?.oneOf?.$case !== 'contexts' ||
              c.metadata?.contextSpec?.oneOf?.contexts?.contexts?.includes(Context.UNAUTHENTICATED_DEVICE)),
        )
        .map((c) => ({
          title: c.metadata?.name?.toUpperCase(),
          value: c.metadata?.name,
          props: {
            disabled: criterias[this.override.metadata!.informative!.labels.template]?.includes(c.metadata?.name),
          },
        }))

      const userGroupLabelSegments = this.existingCriterias
        .filter(
          (c) =>
            !commonSegments.find((s) => s.value === c.metadata?.name) &&
            c.expression?.oneOf?.$case === 'predicate' &&
            (!this.unauthenticated ||
              c.metadata?.contextSpec?.oneOf?.$case !== 'contexts' ||
              c.metadata?.contextSpec?.oneOf?.contexts?.contexts?.includes(Context.UNAUTHENTICATED_DEVICE)),
        )
        .sort((a, b) => a.metadata!.name!.localeCompare(b.metadata!.name))
        .map((c) => ({
          title: c.metadata?.name.toUpperCase(),
          value: c.metadata?.name,
        }))

      const customPredicatesSegments = this.existingCriterias
        .filter(
          (c) =>
            !commonSegments.find((s) => s.value === c.metadata?.name) &&
            c.expression?.oneOf?.$case !== 'predicate' &&
            (!this.unauthenticated ||
              c.metadata?.contextSpec?.oneOf?.$case !== 'contexts' ||
              c.metadata?.contextSpec?.oneOf?.contexts?.contexts?.includes(Context.UNAUTHENTICATED_DEVICE)),
        )
        .sort((a, b) => a.metadata!.name!.localeCompare(b.metadata!.name))
        .map((c) => ({
          title: c.metadata?.name.toUpperCase(),
          value: c.metadata?.name,
        }))

      return ([] as any[]).concat(
        {
          value: 'common-target-segments',
          title: 'Common target segments',
          props: { disabled: true, class: 'label-only' },
        },
        commonSegments,
        {
          value: 'user-group-label-segments',
          title: 'User group label segments',
          props: { disabled: true, class: 'label-only' },
        },
        userGroupLabelSegments,
        {
          value: 'custom-predicates-segments',
          title: 'Custom predicates segments',
          props: { disabled: true, class: 'label-only' },
        },
        customPredicatesSegments,
      )
    }

    public get currentNamedCriterias() {
      const criterias: string[] = []

      forEachNamedCriteria(this.override, (criteria) => criterias.push(criteria))

      return criterias
    }

    public get currentCustomCriterias() {
      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 1 : 2

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      return expression?.oneOf?.$case === 'or' ? expression?.oneOf.or.expressions : []
    }

    public get hasTwoOrMoreUIDPredicates() {
      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 1 : 2

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      const expressions = (expression?.oneOf?.$case === 'or' && expression?.oneOf.or.expressions) || []

      const predicates = expressions.flatMap((e) => (e.oneOf as any)[e.oneOf!.$case].expressions)

      return (
        predicates.filter((e) => {
          return (
            e.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'userUid' ||
            e.oneOf?.not?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'userUid'
          )
        }).length >= 2
      )
    }

    @Emit('validate')
    public emitValidate() {
      return !!this.currentNamedCriterias.length
    }

    public updateNamedCriterias(value: string[], operator?: string) {
      operator = operator || namedCriteriasOperator(this.override)

      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 0 : 1

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      if (expression) {
        const expressions = value.map((criteria, index) => {
          return index > 0 && operator === 'not'
            ? ({
                oneOf: {
                  $case: 'not',
                  not: {
                    expression: createNamedCriteria(
                      criteria,
                      this.existingCriterias.find((c) => c.metadata?.name === criteria)?.metadata?.project,
                    ),
                  },
                },
              } as Expression)
            : createNamedCriteria(
                criteria,
                this.existingCriterias.find((c) => c.metadata?.name === criteria)?.metadata?.project,
              )
        })

        if (operator === 'or') {
          expression.oneOf = {
            $case: 'or',
            or: {
              expressions: expressions,
            },
          }
        } else {
          expression.oneOf = {
            $case: 'and',
            and: {
              expressions: expressions,
            },
          }
        }
      }

      this.emitValidate()
    }

    public updateCustomCriterias(expressions: Expression[]) {
      const index = this.override.metadata?.informative?.labels.template.startsWith('percentage') ? 1 : 2

      const expression =
        (this.override.criteria?.oneOf?.$case === 'and' &&
          this.override.criteria?.oneOf?.and.expressions &&
          this.override.criteria?.oneOf?.and.expressions[index]) ||
        null

      if (
        !expression &&
        this.override.criteria?.oneOf?.$case === 'and' &&
        this.override.criteria?.oneOf?.and.expressions
      ) {
        this.override.criteria.oneOf.and.expressions.push({
          oneOf: {
            $case: 'or',
            or: {
              expressions: expressions,
            },
          },
        })
      } else if (
        expression &&
        expression.oneOf?.$case === 'or' &&
        !expression.oneOf.or.expressions.length &&
        this.override.criteria?.oneOf?.$case === 'and' &&
        this.override.criteria?.oneOf?.and.expressions
      ) {
        this.override.criteria?.oneOf?.and.expressions.splice(index, 1)
      }

      this.emitValidate()
    }

    public toggleNamedCriteriasOperator() {
      const current = namedCriteriasOperator(this.override)

      const operator =
        current === 'and'
          ? 'not'
          : current === 'not'
            ? this.override.metadata!.informative!.labels.template === 'percentage-advanced'
              ? 'or'
              : 'and'
            : 'and'

      this.updateNamedCriterias(this.currentNamedCriterias, operator)
    }
  }

  export default toNative(RolloutTarget)
</script>

<style lang="scss">
  .v-menu {
    .v-list {
      .label-only {
        .v-checkbox-btn {
          display: none !important;
        }
      }
    }
  }
</style>
