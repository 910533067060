<template>
  <div class="d-flex align-baseline">
    <div class="flex-grow-1">
      <v-form v-model="formValid" validate-on="lazy">
        <v-alert v-if="isAlreadyInUse" class="mb-4" dense color="warning">
          Criteria is in use by active rollouts, you should be
          <b>very</b>
          mindful of what you edit!
        </v-alert>

        <div class="text-button">Metadata</div>

        <v-row class="d-flex flex-nowrap align-center pa-3" style="white-space: nowrap">
          <v-text-field
            v-model="edited.metadata!.name"
            class="uppercased"
            label="Criteria name / category color *"
            hint="Name is permanent and can not be changed!"
            placeholder="Enter name (will be uppercased)"
            :rules="[
              (v: string) =>
                features.find((e) => !edited.metadata?.changeRecord?.createdAt && e.metadata?.name === v)
                  ? 'There is a flag with this name'
                  : criterias.find((e) => !edited.metadata?.changeRecord?.createdAt && e.metadata?.name === v)
                    ? 'Criteria with this name already exists'
                    : true,
            ]"
            :disabled="!!edited.metadata?.changeRecord?.createdAt"
            @blur="updateName(edited.metadata!.name)"
          />

          <v-menu>
            <template #activator="{ props: menuProps }">
              <div class="d-flex flex-row mb-4" v-bind="menuProps">
                <v-icon :color="edited.metadata!.informative!.labels.color + '-darken-1'">mdi-menu-down</v-icon>

                <v-tooltip top>
                  <template #activator="{ props: tooltipProps }">
                    <v-avatar
                      rounded="rounded"
                      v-bind="tooltipProps"
                      size="24"
                      :color="edited.metadata?.informative?.labels.color + '-lighten-3'"
                    />
                  </template>
                  <span>
                    {{
                      segmentCategories.find((c) => c.value === edited.metadata?.informative?.labels.color)?.title || ''
                    }}
                  </span>
                </v-tooltip>
              </div>
            </template>

            <v-list>
              <v-list-item
                v-for="color in segmentCategories"
                :key="color.value"
                dense
                :title="color.title"
                @click="edited.metadata!.informative!.labels.color = color.value"
              >
                <template #append>
                  <v-avatar size="24" :color="color.value + '-lighten-3'" />
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>

        <v-textarea
          v-model="edited.metadata!.informative!.description"
          label="Purpose description *"
          rows="3"
          auto-grow
        />

        <v-text-field
          v-model="edited.metadata!.informative!.referenceUrls[0]"
          label="More info link *"
          append-icon="mdi-open-in-new"
          :rules="[(v: string) => !v || (v.startsWith('https://') && v.length > 8)]"
          @click:append="openUrl(edited.metadata!.informative!.referenceUrls[0])"
        />

        <v-autocomplete
          label="Project *"
          item-title="name"
          item-value="id"
          :return-object="false"
          :items="validCriteriaProjects"
          :model-value="edited.metadata?.informative?.labels.project || null"
          @update:model-value="
            edited.metadata!.project = createProjectData((edited.metadata!.informative!.labels.project = $event))
          "
        >
          <template #append>
            <v-tooltip
              max-width="390"
              location="top end"
              text="Project controls who can edit the criteria so choose it carefully, see docs for more info"
            >
              <template #activator="{ props }">
                <v-icon v-bind="props">mdi-information-outline</v-icon>
              </template>
            </v-tooltip>
          </template>
        </v-autocomplete>

        <div class="text-button">Settings</div>

        <v-select
          label="Criteria type"
          :model-value="edited.expression?.oneOf?.$case === 'predicate' ? 'user-labels' : 'predicates'"
          :items="segmentConfigTypes"
          :disabled="!!edited.metadata?.changeRecord?.createdAt && !isWaltariAdmin"
          @update:model-value="updateType($event)"
        />

        <template v-if="edited.expression?.oneOf?.$case === 'predicate'">
          <div class="text-button">Label</div>

          <CriteriaLabel :criteria="edited" @validate="emitValidate($event)" />
        </template>

        <template v-else>
          <v-tooltip max-width="390" open-delay="500" location="top center">
            <template #activator="{ props }">
              <v-switch
                v-bind="props"
                hide-details
                label="Target unauthenticated users"
                :model-value="
                  edited.metadata?.contextSpec?.oneOf &&
                  edited.metadata?.contextSpec?.oneOf?.$case === 'contexts' &&
                  !!edited.metadata?.contextSpec.oneOf.contexts.contexts.find(
                    (c) => c === MetadataContext.UNAUTHENTICATED_DEVICE,
                  )
                "
                :disabled="
                  !edited.metadata?.contextSpec?.oneOf ||
                  edited.metadata?.contextSpec?.oneOf?.$case !== 'contexts' ||
                  (original.metadata?.contextSpec?.oneOf &&
                    original.metadata?.contextSpec?.oneOf?.$case === 'contexts' &&
                    !!original.metadata?.contextSpec.oneOf.contexts.contexts.find(
                      (c) => c === MetadataContext.UNAUTHENTICATED_DEVICE,
                    ))
                "
                @update:model-value="toggleContext(!!$event)"
              />
            </template>
            Turning this on will make the criteria available for feature flags targeting unauthenticated users who have
            not logged in yet, this can not be changed after creation, see docs for more info!
          </v-tooltip>

          <v-alert
            v-if="
              edited.metadata?.contextSpec?.oneOf &&
              edited.metadata?.contextSpec?.oneOf?.$case === 'contexts' &&
              !!edited.metadata?.contextSpec.oneOf.contexts.contexts.find(
                (c) => c === MetadataContext.UNAUTHENTICATED_DEVICE,
              )
            "
            color="warning"
            class="mt-2 mb-6"
          >
            Please notice that user predicates wont have an effect on unauthenticated users, see the
            <a
              target="_blank"
              class="text-warning text-no-wrap text-decoration-underline font-weight-bold"
              href="https://ouraring.atlassian.net/wiki/spaces/SW/pages/4068115084/Feature+Flags+Management"
            >
              &nbsp;docs
            </a>
            &nbsp;for more info!
          </v-alert>

          <div class="text-button my-2">Predicates</div>

          <CriteriaPreds
            v-if="edited.expression?.oneOf?.$case === 'or'"
            action="Add predicate"
            :expressions="edited.expression.oneOf.or.expressions"
            @validate="emitValidate(!!edited.expression.oneOf.or.expressions.length)"
          />
        </template>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
  import slug from 'slug'

  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Context } from '@jouzen/control-api/metadata'
  import { Rollout_Stage_StageState } from '@jouzen/control-api/rollout'

  import { segmentCategories, segmentConfigTypes } from '#views/segments/constants'

  import { createProjectData } from '#views/projects/utilities'
  import { createDefaultCriteria } from '#views/segments/utilities'

  import { AppStore, ProjectsStore } from '#stores'

  import { Criteria, CriteriaOverrides, Feature } from '#types'

  @Component({})
  class CriteriaEditor extends Vue {
    @Prop() public edited!: Criteria
    @Prop() public original!: Criteria

    @Prop() public features!: Feature[]
    @Prop() public criterias!: Criteria[]

    @Prop() public overrides!: CriteriaOverrides

    public formValid = false
    public labelValid = false

    public MetadataContext = Context

    public readonly createProjectData = createProjectData

    public readonly segmentCategories = segmentCategories
    public readonly segmentConfigTypes = segmentConfigTypes

    protected readonly appStore = new AppStore()
    protected readonly projectsStore = new ProjectsStore()

    public get isWaltariAdmin() {
      return this.appStore.isWaltariAdmin
    }

    public get isAlreadyInUse() {
      return this.overrides[this.original.metadata!.name]?.find(
        (i) =>
          i.override?.rolloutOneOf?.$case === 'rollout' &&
          i.override?.rolloutOneOf?.rollout?.stages &&
          i.override?.rolloutOneOf?.rollout?.stages.find((s: any) => s.state === Rollout_Stage_StageState.CURRENT),
      )
    }

    public get validCriteriaProjects() {
      return this.projectsStore.projects.filter(
        (p) => (p.key && p.sections.features) || p.id === this.edited?.metadata?.informative?.labels?.project,
      )
    }

    @Emit('validate')
    public emitValidate(valid?: boolean) {
      if (valid !== undefined) {
        this.labelValid = valid
      }

      return this.formValid !== false && this.labelValid !== false ? this.edited : null
    }

    @Watch('edited', { deep: true, immediate: true })
    protected contentDataChanged() {
      this.formValid = true

      this.labelValid = this.edited.expression?.oneOf?.$case !== 'predicate'

      this.emitValidate()
    }

    public openUrl(url: string) {
      if (url) {
        window.open(url, '_blank')
      }
    }

    public updateName(name: string) {
      this.edited.metadata!.name = slug(name, '_').replace(/^(\d|_)+/g, '')
    }

    public updateType(type: string) {
      this.edited.expression = createDefaultCriteria(type).expression
    }

    public toggleContext(value: boolean) {
      if (this.edited.metadata?.contextSpec?.oneOf?.$case === 'contexts') {
        this.edited.metadata.contextSpec.oneOf.contexts.contexts = !value
          ? [Context.OURA_USER]
          : [Context.OURA_USER, Context.UNAUTHENTICATED_DEVICE]
      }
    }
  }

  export default toNative(CriteriaEditor)
</script>

<style lang="scss" scoped>
  :deep(.uppercased) {
    input {
      text-transform: uppercase;
    }
  }

  :deep(.v-list-item__content) {
    text-overflow: ellipsis;
    max-width: 300px;
  }
</style>
