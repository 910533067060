<template>
  <div class="px-2 pt-2">
    <div class="body-2 pb-2">{{ component.id }}</div>
  </div>
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { UIComponentBadgeReward } from '#types'

  @Component({})
  class BadgeRewardPreview extends Vue {
    @Prop() public component!: UIComponentBadgeReward
  }

  export default toNative(BadgeRewardPreview)
</script>
