<template>
  <v-row dense>
    <v-col cols="2">
      <v-select v-model="data.prepend_icon" clearable :items="icons" label="Prepend icon" />
    </v-col>
    <v-col cols="2">
      <v-select v-model="data.style" :items="buttonStyles" label="Style" />
    </v-col>
    <v-col cols="2">
      <v-select v-model="data.append_icon" clearable :items="icons" label="Append icon" />
    </v-col>
    <v-col>
      <v-text-field v-model="data.analytics_id" label="Analytics identifier" />
    </v-col>
  </v-row>

  <Translatable :data="data.title" class="mt-6">
    <v-text-field v-model="data.title.text" spellcheck="true" lang="en" label="Title" />
  </Translatable>

  <v-text-field v-model="data.url" label="URL" />
</template>

<script lang="ts">
  import { sortBy } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { messageIcons } from '#views/messages/constants'

  import { UIComponentButton } from '#types'

  @Component({ inheritAttrs: false })
  class Button extends Vue {
    @Prop() public data!: UIComponentButton

    public get icons() {
      return messageIcons
    }

    public buttonStyles = sortBy(
      [
        { value: 'text', title: '[Deprecated] Text' },
        { value: 'rounded', title: '[Deprecated] Rounded' },
        { value: 'primary', title: '[Deprecated] Primary' },
        { value: 'small_filled', title: 'Small Filled' },
        { value: 'small_filled_dark', title: 'Small Filled Dark' },
        { value: 'small_outlined', title: 'Small Outlined' },
        { value: 'small_text', title: 'Small Text' },
        { value: 'large_filled', title: 'Large Filled' },
        { value: 'large_outlined', title: 'Large Outlined' },
        { value: 'large_text', title: 'Large Text' },
      ],
      'title',
    )
  }

  export default toNative(Button)
</script>
