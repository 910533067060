<template>
  <div v-if="!!message.facets.home">
    <v-row dense>
      <v-col>
        <v-select v-model="message.facets.home.display" :items="displayStyles" label="Display style" />
      </v-col>

      <v-col>
        <v-select
          clearable
          label="Background image"
          :model-value="(message.facets.home.background && message.facets.home.background.key) || ''"
          :items="messageBackgroundImages"
          @update:model-value="updateBackgroundImage($event)"
        />
      </v-col>
    </v-row>

    <Translatable v-if="message.facets.home.header" :data="message.facets.home.header">
      <v-text-field v-model="message.facets.home.header.text" spellcheck="true" lang="en" label="Header" />
    </Translatable>

    <Translatable v-if="message.facets.home.title" :data="message.facets.home.title">
      <v-text-field v-model="message.facets.home.title.text" spellcheck="true" lang="en" label="Title" />
    </Translatable>
    <template v-if="message.facets.home.placeholders && message.facets.home.placeholders.title">
      <div v-for="placeholder in message.facets.home.placeholders.title" :key="placeholder.offset" class="mb-4">
        <span class="body-1">Title placeholder #{{ placeholder.index }}</span>
        <Statement v-model="placeholder.statement" type="string" required label="Statement" />
      </div>
    </template>
    <v-divider v-if="message.facets.home.placeholders && message.facets.home.placeholders.title" class="my-4" />

    <Translatable v-if="message.facets.home.body" :data="message.facets.home.body">
      <v-textarea v-model="message.facets.home.body.text" spellcheck="true" lang="en" label="Body" rows="1" auto-grow />
    </Translatable>
    <template v-if="message.facets.home.placeholders && message.facets.home.placeholders.body">
      <div v-for="placeholder in message.facets.home.placeholders.body" :key="placeholder.offset" class="mb-4">
        <span class="body-1">Body placeholder #{{ placeholder.index }}</span>
        <Statement v-model="placeholder.statement" type="string" required label="Statement" />
      </div>
    </template>
    <v-divider v-if="message.facets.home.placeholders && message.facets.home.placeholders.body" class="my-4" />

    <HomeFacetAction
      v-for="(action, index) in facet.actions"
      :key="action.id"
      :action="action"
      @delete="deleteAction(index)"
    />
    <div class="my-6">
      <v-btn text="+ Add action" color="primary" @click="createAction()" />
    </div>

    <ComponentsList
      v-if="message.facets.home && insight && insight.group_id == 'bedtime'"
      :insight="insight"
      :message="message"
      :template="template"
      :allowed-components="['content_recommendation']"
      :required-components="[]"
      :components="message.facets.home.components"
      @delete="deleteComponentAtIndex($event)"
      @remove-info="removeInfo($event)"
    />
  </div>
</template>

<script lang="ts">
  import { isEmpty } from 'lodash-es'

  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { messageBackgroundImages, messageHomeFacetDisplayStyles } from '#views/messages/constants'

  import { createHomeFacetAction } from '#views/messages/utilities'

  import { getFormatPlaceholders } from '#views/messages/components/richTextEditorHelper'

  import { AppStore } from '#stores'

  import { Insight, Message, MessageHomeFacet, MessageHomeFacetPlaceholders, Template } from '#types'

  @Component({})
  class HomeFacet extends Vue {
    @Prop() public message!: Message
    @Prop() public insight!: Insight
    @Prop() public template!: Template

    @Prop({ default: () => [] }) public allowedComponents!: string[]
    @Prop({ default: () => [] }) public requiredComponents!: string[]

    private appStore = new AppStore()

    public get displayStyles() {
      return messageHomeFacetDisplayStyles
    }

    public get messageBackgroundImages() {
      return messageBackgroundImages
    }

    public get facet(): MessageHomeFacet {
      return this.message.facets.home!
    }

    public get inTemplates() {
      return this.appStore.inTemplates
    }

    @Watch('message.facets.home.body.text')
    protected bodyChanged() {
      this.checkPlaceholders('body')
    }

    @Watch('message.facets.home.title.text')
    protected titleChanged() {
      this.checkPlaceholders('title')
    }

    public createAction() {
      this.facet.actions.push(createHomeFacetAction(this.message.id))
    }

    public deleteAction(index: number) {
      this.facet.actions.splice(index, 1)
    }

    public updateBackgroundImage(value: string) {
      if (value) {
        this.message.facets.home!.background = {
          key: value,
          type: 'image',
        }
      } else {
        this.message.facets.home!.background = undefined

        delete this.message.facets.home!.background
      }
    }

    public removeInfo(idx: number) {
      if (!this.inTemplates) {
        this.message.facets.home!.components = this.message.facets.home!.components.map((c, i) => {
          if (idx == i) {
            delete c.info
          }
          return c
        })
      }
    }

    public deleteComponentAtIndex(index: number) {
      this.message.facets.home!.components.splice(index, 1)
    }

    private checkPlaceholders(placeholder: MessageHomeFacetPlaceholders) {
      if (this.message.facets.home) {
        const placeholders = getFormatPlaceholders(
          this.message.facets.home[placeholder].text || '',
          (this.message.facets.home.placeholders && this.message.facets.home.placeholders[placeholder]) || [],
        )

        if (placeholders.length) {
          if (!this.message.facets.home.placeholders) {
            this.message.facets.home.placeholders = {}
          }

          this.message.facets.home.placeholders![placeholder] = placeholders
        } else if (this.message.facets.home.placeholders) {
          delete this.message.facets.home.placeholders[placeholder]
        }

        if (isEmpty(this.message.facets.home.placeholders)) {
          delete this.message.facets.home.placeholders
        }
      }
    }
  }

  export default toNative(HomeFacet)
</script>
