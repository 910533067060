<template>
  <div class="mt-6">
    <div class="d-flex">
      <span class="headline flex-grow-1">Action</span>
      <v-btn icon="mdi-delete-outline" @click="deleteFacet()" />
    </div>

    <v-row dense>
      <v-col cols="2">
        <v-select v-model="action.type" :items="actions" label="Type" />
      </v-col>
      <v-col>
        <v-text-field v-model="action.analytics_id" label="Analytics identifier" />
      </v-col>
    </v-row>

    <Translatable :data="action.title">
      <v-text-field v-model="action.title.text" spellcheck="true" lang="en" aria-required label="Title" />
    </Translatable>

    <v-text-field v-model="action.url" spellcheck="true" lang="en" label="URL" @blur="deleteEmptyUrl()" />

    <Statement v-model="action.condition" simplify />
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { messageHomeFacetActions } from '#views/messages/constants'

  import { MessageHomeFacetAction } from '#types'

  @Component({})
  class HomeFacetAction extends Vue {
    @Prop() public action!: MessageHomeFacetAction

    @Emit('delete')
    public deleteFacet() {
      return true
    }

    public get actions() {
      return messageHomeFacetActions
    }

    public deleteEmptyUrl() {
      if (!this.action.url) {
        this.action.url = undefined

        delete this.action.url
      }
    }
  }

  export default toNative(HomeFacetAction)
</script>
